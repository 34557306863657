import React, { Component } from "react";
import { Modal, Button } from "@material-ui/core";
import styles from "./ShowModal.module.scss";
class ShowModal extends Component {
  render() {
    const {
      isModalOpen,
      Content,
      onModalClose,
      onOkay,
      onOkayLabel = "Ok",
      onCancel,
      onCancelLabel = "Cancel",
    } = this.props;
    return (
      <>
        <Modal
          className={styles.modalWrap}
          open={isModalOpen}
          onClose={onModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={styles.modalPaper}>
            {Content}
            <br /> <br />
            <div className={styles.footer}>
              {typeof onOkay !== "undefined" && (
                <>
                  <Button
                    variant="contained"
                    className={`${styles.btn_purplue} btn btn-purplue color-white`}
                    onClick={onOkay}
                  >
                    {onOkayLabel}
                  </Button>
                  {"  "}
                </>
              )}
              {typeof onCancel !== "undefined" && (
                <>
                  <Button
                    variant="contained"
                    className={`${styles.btn_purplue} btn btn-purplue color-white`}
                    onClick={onCancel}
                  >
                    {onCancelLabel}
                  </Button>
                  {"  "}
                </>
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default ShowModal;
