import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import axiosInstance from "../../../helpers/Axios";

const ListMappings = ({ mappings, data, fetchMappings }) => {
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [deleteMapId, setDeleteMapId] = useState("");

  const toggleDeleteModal = () => {
    setDeleteModal(!isDeleteModalOpen);
  };
  const openDeleteModal = (item) => {
    setDeleteMapId(item?.id);
    setDeleteModal(true);
  };

  const getVendor = (id) => {
    let name = "";

    const item = data.afs.find((af) => af.id === id);

    if (item) {
      name = item.name;
    }

    return name;
  };

  const getVendorCategory = (vId, id) => {
    let name = "";

    const selectedVendor = data.afs.find((af) => af.id === vId);

    if (selectedVendor) {
      const item =
        selectedVendor.categories &&
        selectedVendor.categories.find((category) => category.id === id);

      if (item) {
        name = item.name;
      }
    }

    return name;
  };

  const getSmartbCategory = (id) => {
    let name = "";

    const item = data.mcategories.find((af) => af.id === id);

    if (item) {
      name = item.name;
    }

    return name;
  };

  const removeMap = async () => {
    if (deleteMapId) {
      const { status } = await axiosInstance.delete("/af/map/" + deleteMapId);
      if (status === 200) {
        setDeleteModal(false);
        fetchMappings();
      }
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className="listTable" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>DID</TableCell>
              <TableCell>Vendor</TableCell>
              <TableCell>Vendor Category</TableCell>
              <TableCell>Smartb Category</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappings.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{getVendor(item.afId)}</TableCell>
                <TableCell>
                  {getVendorCategory(item.afId, item.afcatId)}
                </TableCell>
                <TableCell>{getSmartbCategory(item.mcatId)}</TableCell>
                <TableCell>
                  <DeleteOutlineIcon
                    onClick={() => openDeleteModal(item)}
                    color="secondary"
                    className="cursor iconBtn"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        className="modal"
        open={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={"paper"}>
          Are you sure you want to delete?
          <br /> <br />
          <Button color="primary" onClick={removeMap}>
            Yes
          </Button>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ListMappings;
