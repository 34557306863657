import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { eventsFormModel } from "./form-constant";
import Form from "../../../../library/common/components/Form";
import ButtonComponent from "../../../../library/common/components/Button";
import ActionMessage from "../../../../library/common/components/ActionMessage";
// import { URLS } from "../../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../../library/utilities";
import axiosInstance from "../../../../helpers/Axios";

// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import moment from "moment";
import { setValidation } from "../../../../helpers/common";

let eventsFormModelArray = eventsFormModel;

class CreateEvents extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        marketName: "",
      },
      eventsForm: [],
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentEvents(this.props.id);
    }

    eventsFormModelArray = eventsFormModelArray?.map((fieldItem) => {
      if (fieldItem?.field === "key") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...this.props.MarketKey?.map((option, i) => {
              return {
                id: i,
                value: parseInt(option?.id),
                label: option?.key,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "bookKeepersId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...this.props.bookKeeper?.map((option, i) => {
              return {
                id: i,
                value: parseInt(option?.id),
                label: option?.name,
              };
            }),
          ],
        };
      }
      return fieldItem;
    });
  }

  componentWillUnmount() {
    eventsFormModelArray = eventsFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchCurrentEvents = async (id) => {
    const { status, data } = await axiosInstance.get(`sports/market/${id}`);
    if (status === 200) {
      this.setState({
        values: data.result,
      });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { marketName } = this.state.values;
    let flag = true;

    if (marketName === "") {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }

    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `/sports/market/${this.props.id}`
        : `sports/market`;

      const values = removeErrorFieldsFromValues(form.formData);
      const editFormdata = {
        marketName: values?.marketName,
      };
      let createFormData = {
        marketName: values?.marketName,
        eventId: parseInt(this.props.eventId),
        isRaceEvent: false,
        partipantsId: null,
        teamId: null,
        playerId: null,
      };
      let dataToPass = isEditMode ? editFormdata : createFormData;

      eventsFormModelArray = eventsFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, dataToPass);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllEvents();
          this.setActionMessage(
            true,
            "Success",
            `Events ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${isEditMode ? "editing" : "creating"} Events`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    eventsFormModelArray = eventsFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  render() {
    var { values, messageBox, isLoading } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={eventsFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateEvents;
