import { Validators } from '../../../../library/utilities/Validators'

export const eventsFormModel = [
  {
    label: 'Market Name*',
    value: '',
    type: 'text',
    placeholder: 'Market Name',
    field: 'marketName',
    validators: [{ check: Validators.required }],
    required: true,
    className: '12',
  },
]
