import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { bookkeeperFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import FileUploader from "../../../library/common/components/FileUploader";
import { config } from "../../../helpers/config";

class CreateBookkeeper extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        variation: "",
        status: "",
      },
      apiProviderId: null,
      logo: [],
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentBookkeeper(this.props.id);
    }
  }

  fetchCurrentBookkeeper = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.bookkeeper + `/${id}`
    );
    if (status === 200) {
      this.setState({
        values: data.result[0],
        apiProviderId: data.result[0].apiProviderId,
      });
      let variationArray = JSON.parse("[" + data.result[0].variation + "]");
      if (variationArray.length > 0) {
        this.setState(() => {
          return {
            values: {
              ...this.state.values,
              ["variation"]: variationArray[0],
            },
          };
        });
      }
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { name, status } = this.state.values;
    let { apiProviderId } = this.state;
    let flag = true;

    if (name === "" || status === "" || apiProviderId === null) {
      flag = false;
      this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      this.setActionMessage(false);
    }

    return flag;
  };

  setMedia = async (files) => {
    const formData = new FormData();
    formData.append("image", files ? files : null);
    if (files !== undefined) {
      const { status, data } = await axiosInstance.post(URLS.media, formData, {
        header: { "Content-Type": "multipart/form-data" },
      });
      if (status === 200) {
        return data;
      } else {
        return false;
      }
    }
    return false;
  };

  handleSave = async () => {
    const { apiProviderId, logo } = this.state;
    const { isEditMode } = this.props;
    this.setState({ isLoading: true });

    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.bookkeeper}/${this.props.id}`
        : URLS.bookkeeper;

      const values = removeErrorFieldsFromValues(form.formData);
      values["apiProviderId"] = apiProviderId;

      if (this.validate()) {
        if (logo.length > 0) {
          let fileData = await this.setMedia(logo[0]);
          if (fileData) {
            values["logo"] = fileData?.image?.filePath;
          }
        }

        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllBookkeeper();
          this.setActionMessage(
            true,
            "Success",
            `Bookkeeper ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${
          isEditMode ? "editing" : "creating"
        } Bookkeeper`
      );
    }
  };

  handleChange = (field, value) => {
    this.setState(() => {
      return {
        values: { ...this.state.values, [field]: value },
      };
    });
    this.setActionMessage(false);
  };

  handleSelect = (e) => {
    let value = e.target.value;
    this.setState({ apiProviderId: value });
  };

  handleFileUpload = (name, files) => {
    this.setState({ [name]: files });
  };

  render() {
    const { values, messageBox, isLoading, apiProviderId, logo } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <h3 className="text-left">
                {!isEditMode ? "Create New Bookkeeper" : "Edit Bookkeeper"}
              </h3>
              <Form
                values={values}
                model={bookkeeperFormModel}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <div style={{ marginTop: 15 }}>
                <label>API Provider</label>
                <select
                  className="select-box-manual"
                  name="eventFeatureId"
                  onChange={this.handleSelect}
                  value={apiProviderId}
                >
                  <option value={null}>No API Provider Selected</option>
                  {this.props.allApiProvider?.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.providerName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="blog-file-upload">
                <h6>Logo</h6>
                <FileUploader
                  onDrop={(logo) => this.handleFileUpload("logo", logo)}
                />
                <div className="logocontainer">
                  {logo?.length > 0
                    ? logo.map((file, index) => (
                        <img
                          className="auto-width"
                          key={index}
                          src={file.preview}
                          alt="file"
                        />
                      ))
                    : values?.logo &&
                      values?.logo !== "" && (
                        <img
                          className="auto-width"
                          src={config.baseUrl + values?.logo}
                          alt="file"
                        />
                      )}
                </div>
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateBookkeeper;
