import { Validators } from "../../../library/utilities/Validators";

export const participantTypeFormModel = [
  {
    label: "Name*",
    value: "",
    type: "text",
    placeholder: "Name",
    field: "name",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "12",
  },
  {
    label: "Sports*",
    value: "",
    type: "dropdown",
    placeholder: "Sports",
    field: "sports",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
    options: [],
  },
];
