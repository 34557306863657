import React, { Component, Suspense } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import ReactGA from "react-ga";

import { adminRoutes } from "./adminRoutes";
import { publicRoutes } from "./publicRoutes";
import { secureRoutes } from "./secureRoutes";
import NotFound from "../404/404";
import Layout from "../layouts/layout/layout";

import DefaultLayout from "../../admin/Module/DefaultLayout";
//import UserLayout from "../layouts/userlayout/userlayout";
import BlankLayout from "../layouts/blanklayout/blanklayout";

class AppRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.openRoutes = [...publicRoutes];
    this.secureRoutes = [...secureRoutes, ...publicRoutes];
    this.adminRoutes = [...adminRoutes];
  }

  componentDidMount() {
    ReactGA.initialize("UA-85164364-32");
    ReactGA.pageview(this.props.location.pathname);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      ReactGA.pageview(this.props.location.pathname);
    }
    window.scrollTo(0, 0);
  }

  renderRoutes() {
    var routes_arr = this.openRoutes;
    const {
      // auth, login,
      isLoggedIn,
      loginuser,
    } = this.props;
    // console.log("route : ", loginuser);
    if (isLoggedIn) {
      routes_arr = this.secureRoutes;
      if (loginuser?.role === "admin") {
        routes_arr = [...this.secureRoutes, ...this.adminRoutes];
      }
      return routes_arr.map((route, i) => {
        const RouteComponent = route.component;
        return (
          <Route
            key={i + route.index}
            path={route.path}
            render={(props) =>
              route?.adminroute ? (
                <DefaultLayout {...this.props} {...props}>
                  <RouteComponent {...this.props} {...props} />
                </DefaultLayout>
              ) : route.blankLayout ? (
                <BlankLayout {...this.props}>
                  <RouteComponent {...this.props} {...props} />
                </BlankLayout>
              ) : route.normalLayout ? (
                <Layout {...this.props}>
                  <RouteComponent {...this.props} {...props} />
                </Layout>
              ) : (
                <Layout {...this.props}>
                  <RouteComponent {...this.props} {...props} />
                </Layout>
              )
            }
            exact={route.exact}
          />
        );
      });
    } else {
      return routes_arr.map((route) => {
        const RouteComponent = route.component;
        return (
          <Route
            key={route.index}
            path={route.path}
            render={(props) =>
              route.blankLayout ? (
                <BlankLayout {...this.props}>
                  <RouteComponent {...this.props} {...props} />
                </BlankLayout>
              ) : (
                <Layout {...this.props}>
                  <RouteComponent {...this.props} {...props} />
                </Layout>
              )
            }
            exact={route.exact}
          />
        );
      });
    }
  }

  render() {
    let { isLoggedIn } = this.props;
    return (
      <Suspense fallback={<>Loading...</>}>
        <Switch>
          {this.renderRoutes()}
          <Route
            path="/"
            exact
            render={() => (
              <Redirect to={isLoggedIn ? "/dashboard" : "/login"} />
            )}
          />
          {/* <Route
            render={(props) => (
              <Layout {...this.props}>
                <NotFound {...this.props} {...props} />
              </Layout>
            )}
          /> */}
        </Switch>
      </Suspense>
    );
  }
}

AppRoutes.propTypes = {
  auth: PropTypes.object,
};

AppRoutes.defaultProps = {
  auth: {},
};

export default withRouter(AppRoutes);
