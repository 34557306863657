import React from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import axiosInstance from "../../../helpers/Axios";
import Select from "react-select";

const selectboxStyles = {
  container: (styles) => ({ ...styles, width: "96.7%", marginBottom: "15px" }),
  control: (styles) => ({
    ...styles,
    width: "100%",
    borderRadius: "5px",
    borderColor: "#cccccc",
    boxShadow: "none",

    "&:hover": {
      borderColor: "#cccccc",
      boxShadow: "none",
    },
  }),
  option: (styles) => {
    return {
      ...styles,
      color: "#000",
    };
  },
  indicatorContainer: (styles) => ({ ...styles, padding: "0 2px" }),
  input: (styles) => ({ ...styles, color: "#000" }),
  placeholder: (styles) => ({ ...styles, fontSize: "14px", color: "#656565" }),
  singleValue: (styles) => ({
    ...styles,
    color: "#000",
    fontSize: "14px",
  }),
};

class CreateRankings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fighters: [],
      weightClass: "",
      isEditMode: false,
      error: {},
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentRanking(this.props.id);
    }
  }
  fetchCurrentRanking = async (id) => {
    const { playersDetails } = this.props;
    const { status, data } = await axiosInstance.get(
      `sports/champian/ranking/${id}`
    );
    if (status === 200) {
      if (Object.entries(data?.result).length !== 0) {
        let fighterName = playersDetails.find(
          (item) => item?.Player?.id === data?.result?.fighterId
        );
        let fighter_array = [];
        let fighter_obj = {
          label:
            fighterName !== undefined &&
            Object.entries(fighterName).length !== 0 &&
            fighterName?.Player?.name,
          value: data?.result?.fighterId,
          rank: data?.result?.rank,
          champion: data?.result?.champion,
          interimChampion: data?.result?.interimChampion,
        };
        fighter_array.push(fighter_obj);
        this.setState({
          fighters: fighter_array,
          weightClass: data?.result.weightClassId,
        });
      }
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { fighters, weightClass } = this.state;
    let flag = true;

    let error_obj = {};

    if (weightClass === "") {
      flag = false;
      error_obj.weightClass = "This field is mandatory";
      this.setState({ isLoading: false });
    }

    if (fighters?.length <= 0) {
      flag = false;
      error_obj.fighters = "This field is mandatory";
      this.setState({ isLoading: false });
    }

    if (weightClass !== "" && fighters?.length > 0) {
      flag = true;
    }

    this.setState({ error: error_obj });
    return flag;
  };

  handleSave = async () => {
    const { isEditMode, leagueId } = this.props;
    const { fighters, weightClass } = this.state;
    this.setState({ isLoading: true });
    try {
      const method = "post";
      const url = `sports/champian/ranking`;

      let fighters_send = fighters;

      fighters_send = fighters_send?.map((obj) => {
        return {
          fighterId: obj?.value,
          rank:
            obj?.rank !== undefined
              ? obj?.rank !== ""
                ? parseInt(obj?.rank)
                : 1
              : 1,
          champion: obj?.champion !== undefined ? obj?.champion : false,
          interimChampion:
            obj?.interimChampion !== undefined ? obj?.interimChampion : false,
        };
      });

      let values = {
        leagueId: parseInt(leagueId),
        weightClassId: parseInt(weightClass),
        fighters: fighters_send,
      };
      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchRankingsByLeague();
          this.setActionMessage(
            true,
            "Success",
            `Rankings ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${
          isEditMode ? "editing" : "creating"
        } Rankings`
      );
    }
  };

  handleChange = (e) => {
    let field = e.target.name;
    let value = e.target.value;
    this.setState({
      [field]: value,
      error: { ...this.state.error, [field]: "" },
    });
  };

  handleSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
      error: { ...this.state.error, [name]: "" },
    });
  };

  handlePlayerChange = (item) => {
    this.setState({
      fighters: item,
      error: { ...this.state.error, fighters: "" },
    });
  };

  handleFighterRankManage = (e, obj) => {
    let { fighters } = this.state;
    let { name, value, checked } = e.target;

    let target_obj = fighters?.map((item) => {
      return item?.value === obj?.value
        ? { ...obj, [name]: name === "rank" ? value : checked }
        : item;
    });
    this.setState({ fighters: target_obj });
  };

  render() {
    let { messageBox, isLoading, error, fighters, weightClass } = this.state;
    let { isEditMode, playersDetails, WeightClassData } = this.props;
    let fighter_data = [];
    fighter_data = playersDetails?.map((obj) => {
      return {
        ...fighter_data,
        label: obj?.Player?.name,
        value: obj?.Player?.id,
        rank: "",
      };
    });
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}
              <div>
                <label>Fighters</label>
                <Select
                  isDisabled={isEditMode ? true : false}
                  placeholder="fighter name"
                  name="fighters"
                  id="fighters"
                  isMulti
                  styles={selectboxStyles}
                  value={fighters}
                  options={fighter_data}
                  onChange={(selectedOptions) =>
                    this.handlePlayerChange(selectedOptions)
                  }
                />
                {typeof error.fighters !== "undefined" &&
                  error.fighters !== "" && (
                    <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                      {error.fighters}
                    </p>
                  )}

                <label>Weight Class</label>
                <select
                  disabled={isEditMode ? true : false}
                  className="select-box-manual"
                  name="weightClass"
                  onChange={(e) => this.handleChange(e)}
                  value={weightClass}
                  style={{ width: "96.5%" }}
                >
                  <option value="">No weight class selected</option>
                  {WeightClassData?.length > 0 &&
                    WeightClassData?.map((obj, i) => (
                      <option value={obj?.id} key={i}>
                        {obj?.name}
                      </option>
                    ))}
                </select>
                {typeof error.weightClass !== "undefined" &&
                  error.weightClass !== "" && (
                    <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                      {error.weightClass}
                    </p>
                  )}
              </div>

              <label>Rank</label>
              <Box
                style={{
                  border: "1px solid #cccccc",
                  borderRadius: "5px",
                  width: "96.5%",
                }}
              >
                {fighters?.length > 0 ? (
                  fighters?.map((obj, i) => (
                    <Box
                      key={obj?.value}
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #cccccc",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ width: "28%" }}>{`${i + 1}. ${
                        obj?.label
                      }`}</span>
                      <lable style={{ width: "20%" }}>
                        Champion
                        <input
                          type="checkbox"
                          style={{
                            height: "18px",
                            width: "18px",
                            verticalAlign: "middle",
                            marginLeft: "10px",
                          }}
                          name="champion"
                          onChange={(e) => this.handleFighterRankManage(e, obj)}
                          value={obj?.champion}
                          checked={obj?.champion}
                        />
                      </lable>
                      <lable style={{ width: "27%" }}>
                        Interim Champion
                        <input
                          type="checkbox"
                          style={{
                            height: "18px",
                            width: "18px",
                            verticalAlign: "middle",
                            marginLeft: "10px",
                          }}
                          name="interimChampion"
                          onChange={(e) => this.handleFighterRankManage(e, obj)}
                          value={obj?.interimChampion}
                          checked={obj?.interimChampion}
                        />
                      </lable>
                      <select
                        style={{
                          border: "1px solid #cccccc",
                          borderRadius: "5px",
                          padding: "5px",
                          width: "25%",
                        }}
                        name="rank"
                        placeholder="rank"
                        onChange={(e) => this.handleFighterRankManage(e, obj)}
                        value={obj?.rank}
                      >
                        <option value="">No Rank Selected</option>
                        {[...Array(15)].map((x, i) => (
                          <option value={i + 1} key={i}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                      {/* <input
                        
                      /> */}
                    </Box>
                  ))
                ) : (
                  <Box
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #cccccc",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    No Fighter Selected
                  </Box>
                )}
              </Box>
              <Grid container>
                <Grid item xs={3}>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      marginBottom: "250px",
                    }}
                  >
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateRankings;
