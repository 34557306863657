import React from "react";
import "./sidebarStyles.scss";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import IconButton from "@material-ui/core/IconButton";
import { ExitToAppRounded } from "@material-ui/icons";
// import { makeStyles } from "@material-ui/core/styles";

import { logoutUser } from "../../../library/common/actions/AuthActions";
import { Link } from "react-router-dom";

// const logo = require("../../../images/logo.svg");
const logo = require("../../../images/SmartB_Logo.svg");

// const useStyles = makeStyles((theme) => ({
//   title: {
//     flexGrow: 1,
//   },
// }));

const DefaultHeader = ({ user, logoutUser }) => {
  // const classes = useStyles();
  var loginuser = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : JSON.parse(localStorage.getItem("user"));

  const handleLogout = () => {
    logoutUser();
  };

  return (
    <div className="headerWrapper">
      <AppBar position="static" className="bg-green top-bar">
        <Toolbar variant="dense" className="header">
          {/* <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
          <Link to="/dashboard">
            <img src={logo} alt="smartb logo" />
          </Link>

          <div className="header-user">
            <button
              className="go-to-main-btn"
              onClick={() => (window.location.href = "/")}
            >
              Go To Main Site
            </button>
            <p className="loginuser_name">{loginuser && loginuser?.username}</p>
            <button
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              className="logout-icon"
              onClick={handleLogout}
            >
              <ExitToAppRounded />
            </button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    user: authReducer.user,
  };
};

export default connect(mapStateToProps, { logoutUser })(DefaultHeader);
