import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { fixturesFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
// import { URLS } from "../../../library/common/constants";
// import { removeErrorFieldsFromValues } from "../../../library/utilities";
// import axiosInstance from "../../../helpers/Axios";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

class CreateFixtures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        eventname: "",
        description: "",
        comment: "",
      },
      sportId: null,
      categoryId: null,
      location: null,
      weather: null,
      eventDate: new Date(),
      eventToDate: new Date(),
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      //this.fetchCurrentApiProvider(this.props.id);
    }
  }

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { eventname, description, comment } = this.state.values;
    let flag = true;

    if (eventname === "" || description === "" || comment === "") {
      flag = false;
      this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      this.setActionMessage(false);
    }

    return flag;
  };

  //   handleSave = async () => {
  //     const { isEditMode } = this.props;
  //     this.setState({ isLoading: true });
  //     try {
  //       const { current } = this.formRef;
  //       const form = current.getFormData();

  //       const method = isEditMode ? "put" : "post";
  //       const url = isEditMode
  //         ? `${URLS.apiProvider}/${this.props.id}`
  //         : URLS.apiProvider;

  //       const values = removeErrorFieldsFromValues(form.formData);

  //       if (this.validate()) {
  //         const { status } = await axiosInstance[method](url, values);
  //         if (status === 200) {
  //           this.setState({ isLoading: false });
  //           this.props.inputModal();
  //           window.location.reload();
  //           this.setActionMessage(
  //             true,
  //             "Success",
  //             `Api Provider ${isEditMode ? "Edited" : "Created"} Successfully`
  //           );
  //         }
  //       }
  //     } catch (err) {
  //       this.setState({ isLoading: false });
  //       this.setActionMessage(
  //         true,
  //         "Error",
  //         `An error occurred while ${
  //           isEditMode ? "editing" : "creating"
  //         } Api Provider`
  //       );
  //     }
  //   };

  handleChange = (field, value) => {
    this.setState(() => {
      return {
        values: { ...this.state.values, [field]: value },
      };
    });
    this.setActionMessage(false);
  };

  handleSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "sportId") {
      this.setState({ sportId: value });
    } else if (name === "categoryId") {
      this.setState({ categoryId: value });
    } else if (name === "location") {
      this.setState({ location: value });
    } else {
      this.setState({ weather: value });
    }
  };

  handleEventDateChange = (date) => {
    let selectedDate = new Date(date);
    this.setState({ eventDate: selectedDate });
  };

  handleEventToDateChange = (date) => {
    let selectedDate = new Date(date);
    this.setState({ eventToDate: selectedDate });
  };
  render() {
    var { values, messageBox, isLoading, eventDate, eventToDate } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider fixture-paper">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <h3 className="text-left">
                {!isEditMode ? "Create New Fixtures" : "Edit Fixtures"}
              </h3>
              <div>
                <label>Sport</label>
                <select
                  className="select-box-manual"
                  name="sportId"
                  onChange={this.handleSelect}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
              </div>
              <Form
                values={values}
                model={fixturesFormModel}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  name="eventDate"
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="date-picker-inline"
                  label="Event Date"
                  value={eventDate}
                  onChange={this.handleEventDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardDatePicker
                  style={{ marginBottom: 20 }}
                  name="eventToDate"
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="date-picker-inline"
                  label="Event To Date"
                  value={eventToDate}
                  onChange={this.handleEventToDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              <div>
                <label>Category</label>
                <select
                  className="select-box-manual"
                  name="categoryId"
                  onChange={this.handleSelect}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
                <label>Location</label>
                <select
                  className="select-box-manual"
                  name="location"
                  onChange={this.handleSelect}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
                <label>Weather</label>
                <select
                  className="select-box-manual"
                  name="weather"
                  onChange={this.handleSelect}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateFixtures;
