import { config } from "../../../helpers/config";

export const URLS = {
  users: "/user",
  login: "/user/login",
  register: "/user/register",
  sendCode: "/user/resend/",
  verifyEmail: "/user/varify/",
  apiProvider: "/apiProviders/apiProvider",
  apiProviderUpdate: "/apiProviders/update",
  apiFieldMappingByProviderUpdateId: (id) =>
    `/apiProviders/apiMapping/apiProviderUpdate/${id}`,
  apiFieldMapping: "/apiProviders/apiMapping",
  apiKeyIdentifire: "/apiProviders/keyIdentifier",
  apiIdentifireValue: "/sync/apiIdentifier",
  dbtable: "/apiProviders/table",
  animalType: "/animal/type",
  animalColour: "/animal/colour",
  animalGender: "/animal/gender",
  sports: "/sports/sport",
  sportType: "/sports/type",
  markets: "/sports/market",
  marketsValue: "/sports/marketValue",
  marketValueKeys: "sports/marketValueKey",
  country: "/countries/country",
  state: "/countries/state",
  cities: "/countries/city",
  weather: "/weather",
  playersType: "/teams/playerType",
  players: "/teams/player",
  playerOptionValues: "/teams/playerOption",
  teams: "/teams/team",
  teamPlayers: "/teams/teamPlayer",
  participantType: "/participants/type",
  participants: "/participants/participant",
  participantValue: "/participants/participantValue",
  bookkeeper: "/apiProviders/bookKeeper",
  location: "/countries/location",
  events: "/sports/event",
  eventOptionValues: "event/options",
  apiEventIdentifire: "event/identifier",
  league: "/sports/league",
  cmsPage: "/cms/page",
  cmsPageContent: "/cms/content",
  cmsMenu: "/cms/menu",
  adPlaceHolder: "/placeHolder/ad",
  adPlaceHolderPage: "/placeHolder/page",
  bannerPlaceHolder: "/placeHolder/banner",
  banners: "/page/banner",
  races: "/races/race?type=admin",
  racesoption: "/races/raceOption",
  apiRaceIdentifire: "race/identifier",
  track: "track",
  animal: "animal",
  vehicle: "vehicle",
  racingParticipants: "race/participant",
  racingParticipantsMember: "race/participant/member",
  statsKeys: "stats/key",
  stats: "stats",
  distance: "/distance",
  allCategory: "/category",
  contact: "/contact",
  page: "/page",
  importFixtures: `/sync/fixture`,
  updateFixtures: `/adminNotification/eventList/create`,
  importFixturesOtherSport: `/sync/fixture/sport`,
  importOdds: `/sync/odds`,
  importOddsOtherSport: `/sync/odds/sports`,
  removeRow: (id) => `/page/${id}`,
  forgotPassword: (id) => `/user/forgot-password/${id}`,
  resetPassword: (id) => `/user/reset-password/${id}`,
  ads: "/ads",
  footer: "/footer",
  media: "/media",
  uploadPath: config.baseUrl + "/uploads/",
  getJockeys: "/teams/player/type/",
};
