import { Validators } from "../../../../library/utilities/Validators";

export const runnerFormModel = [
  {
    label: "Event Card*",
    value: "",
    type: "text",
    placeholder: "Event Card",
    field: "eventCard",
    required: true,
    className: "6",
  },
  {
    label: "Fight Name*",
    value: "",
    type: "text",
    placeholder: "Fight Name",
    field: "eventName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Fighter A*",
    value: "",
    type: "text",
    placeholder: "Fighter A",
    field: "playerA",
    required: true,
    validators: [
      { check: Validators.required },
      { check: Validators.number, message: "Insert Number Only" },
    ],
    className: "6",
  },
  {
    label: "Fighter B*",
    value: "",
    type: "text",
    placeholder: "Fighter B",
    field: "playerB",
    required: true,
    validators: [{ check: Validators.required }],
    className: "6",
  },
  {
    label: "Weight Class",
    value: "",
    type: "text",
    placeholder: "Weight Class",
    field: "description",
    required: false,
    className: "6",
  },
  {
    label: "Fighter Order*",
    value: "",
    type: "text",
    placeholder: "Fighter Order",
    field: "fighterOrder",
    required: true,
    className: "6",
    validators: [
      { check: Validators.required },
      { check: Validators.number, message: "Insert Number Only" },
    ],
  },
  // {
  //   label: "Locations",
  //   value: "",
  //   type: "dropdown",
  //   placeholder: "Location",
  //   field: "locationId",
  //   required: false,
  //   className: "6",
  //   options: [],
  // },
];
