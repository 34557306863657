import { Validators } from "../../../library/utilities/Validators";
import { CMS_MENU_STATUS } from "../../../helpers/constants";

export const cmsPageContentFormModel = [
  {
    label: "Key*",
    value: "",
    type: "dropdown",
    placeholder: "Key",
    field: "key",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      { id: 1, value: "H1", label: "H1" },
      { id: 2, value: "H2", label: "H2" },
      { id: 3, value: "P", label: "P" },
      { id: 4, value: "Body", label: "Body" },
      { id: 5, value: "Image", label: "Image" },
      { id: 6, value: "Slider", label: "Slider" },
    ],
  },
  {
    label: "Placeholder*",
    value: "",
    type: "dropdown",
    placeholder: "Placeholder",
    field: "placeholder",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      { id: 1, value: "Header", label: "Header" },
      { id: 2, value: "Body", label: "Body" },
      { id: 3, value: "Footer", label: "Footer" },
    ],
  },
  {
    label: "Column*",
    value: "",
    type: "dropdown",
    placeholder: "Column",
    field: "column",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
    options: [
      { id: 1, value: "firstColumn", label: "firstColumn" },
      { id: 2, value: "secondColumn", label: "secondColumn" },
      { id: 3, value: "header", label: "header" },
      { id: 4, value: "footer", label: "footer" },
    ],
  },
  {
    label: "Content*",
    value: "",
    type: "editor",
    placeholder: "Content",
    field: "content",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
  {
    label: "Display Order*",
    value: "",
    type: "text",
    placeholder: "Display Order",
    field: "displayOrder",
    validators: [
      { check: Validators.required },
      { check: Validators.number, message: "Please enter number only" },
    ],
    required: true,
    className: "6",
  },
  {
    label: "Status*",
    value: "",
    type: "dropdown",
    placeholder: "Status",
    field: "status",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      ...CMS_MENU_STATUS?.map((item, i) => {
        return { id: 1, value: item, label: item };
      }),
    ],
  },
];
