import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { teamsFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";
import Select from "react-select";
import FileUploader from "../../../library/common/components/FileUploader";
import { config } from "../../../helpers/config";

const selectboxStyles = {
  container: (styles) => ({ ...styles, width: "96.7%" }),
  control: (styles) => ({
    ...styles,
    width: "100%",
    borderRadius: "5px",
    borderColor: "#cccccc",
    boxShadow: "none",

    "&:hover": {
      borderColor: "#cccccc",
      boxShadow: "none",
    },
  }),
  option: (styles) => {
    return {
      ...styles,
      color: "#000",
    };
  },
  indicatorContainer: (styles) => ({ ...styles, padding: "0 2px" }),
  input: (styles) => ({ ...styles, color: "#000" }),
  placeholder: (styles) => ({ ...styles, fontSize: "14px", color: "#656565" }),
  singleValue: (styles) => ({
    ...styles,
    color: "#000",
    fontSize: "14px",
  }),
};

let teamsFormModelArray = teamsFormModel;

class CreateTeams extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        variation: "",
      },
      files: [],
      sportId: "",
      leagueId: [],
      leagueIdError: "",
      eventId: "",
      state: null,
      stateAll: [],
      country: null,
      countryAll: [],
      isEditMode: false,
      error: {},
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentTeam(this.props.id);
    }
    this.fetchAllCountry();
  }

  componentWillUnmount() {
    teamsFormModelArray = teamsFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchCurrentTeam = async (id) => {
    const { allLeague } = this.props;
    const { status, data } = await axiosInstance.get(URLS.teams + `/${id}`);
    if (status === 200) {
      let leagueFilter =
        data?.result?.leagueIds?.length > 0
          ? data?.result?.leagueIds?.map((obj) =>
              allLeague?.find((item) => item?.id === obj?.leagueId)
            )
          : [];

      let temp_array = [];
      temp_array = leagueFilter.map((obj) => {
        return { ...temp_array, label: obj?.leagueName, value: obj?.id };
      });
      this.setState({
        values: data.result,
        country: data.result.country,
        state: data.result.state,
        leagueId: temp_array,
      });
      this.fetchAllState(data.result.country);
    }
  };

  fetchAllCountry = async () => {
    const { status, data } = await axiosInstance.get(URLS.country);
    if (status === 200) {
      this.setState({ countryAll: data.result });
    }
  };

  fetchAllState = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.state + `/country/${id}`
    );
    if (status === 200) {
      this.setState({ stateAll: data.result });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { name } = this.state.values;
    let { /*country, state,*/ error, leagueId } = this.state;
    let flag = true;

    if (name === "") {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      // flag = true;
      //this.setActionMessage(false);
    }

    if (leagueId?.length <= 0) {
      flag = false;
      this.setState({
        leagueIdError: "This field is mandatory",
        isLoading: false,
      });
    } else if (leagueId === null) {
      flag = false;
      this.setState({
        leagueIdError: "This field is mandatory",
        isLoading: false,
      });
    } else {
      this.setState({ leagueIdError: "" });
    }

    // if (country === null) {
    //   error.country = "This field id mandatory";
    //   flag = true;
    //   this.setState({ isLoading: false });
    // } else {
    //   error.country = "";
    // }

    // if (state === null) {
    //   error.state = "This field is mandatory";
    //   flag = true;
    //   this.setState({ isLoading: false });
    // } else {
    //   error.state = "";
    // }

    this.setState({ error });
    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;
    const { country, state, leagueId, files } = this.state;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.teams}/${this.props.id}`
        : `teams/addTeamIntoLeague`;

      const values = removeErrorFieldsFromValues(form.formData);
      // values['country'] = country
      // values['state'] = state
      values["sportid"] = this.props.sportId;
      let leagueArray = [];
      leagueArray = leagueId?.map((obj) => {
        return obj?.value;
      });
      values["leagueIds"] = leagueArray;
      teamsFormModelArray = teamsFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        if (files.length > 0) {
          let fileData = await this.setMedia(files[0]);
          if (fileData) {
            values["image"] = fileData?.image?.filePath;
          }
        }
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllTeams();
          this.setActionMessage(
            true,
            "Success",
            `Teams ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${isEditMode ? "editing" : "creating"} Teams`
      );
    }
  };

  setMedia = async (files) => {
    const formData = new FormData();
    formData.append("image", files ? files : null);
    if (files !== undefined) {
      const { status, data } = await axiosInstance.post(URLS.media, formData, {
        header: { "Content-Type": "multipart/form-data" },
      });
      if (status === 200) {
        return data;
      } else {
        return false;
      }
    }
    return false;
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    teamsFormModelArray = teamsFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  handleSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "state") {
      this.setState({
        state: value,
        error: { ...this.state.error, ["state"]: "" },
      });
    }
    if (name === "country") {
      this.setState(
        { country: value, error: { ...this.state.error, ["country"]: "" } },
        () => this.fetchAllState(value)
      );
    }
  };

  handleLeagueChange = (item) => {
    this.setState({ leagueId: item, leagueIdError: "" });
  };

  handleFileUpload = (files) => {
    this.setState({ files: files });
  };

  render() {
    var {
      values,
      messageBox,
      isLoading,
      countryAll,
      stateAll,
      country,
      state,
      error,
      sportsId,
      leagueId,
      eventId,
      leagueIdError,
      files,
    } = this.state;
    var { isEditMode, allSports, isMainForm, allLeague } = this.props;
    let leagueList = [];
    leagueList = allLeague?.map((obj) => {
      return {
        ...leagueList,
        label: obj.leagueName,
        value: obj.id,
      };
    });
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              {isMainForm && (
                <div>
                  <label>Sports</label>
                  <select
                    className="select-box-manual"
                    name="sportsId"
                    onChange={this.handleSelect}
                    value={sportsId}
                  >
                    <option value="">No Sport Selected</option>
                    {allSports?.map((obj, i) => (
                      <option key={i} value={obj.id}>
                        {obj.sportName}
                      </option>
                    ))}
                  </select>
                  {/* {typeof error !== "undefined" && error !== "" && (
                  <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                    {error}
                  </p>
                )} */}

                  <label>Event*</label>
                  <select
                    className="select-box-manual"
                    name="eventId"
                    onChange={this.handleSelect}
                    value={eventId}
                  >
                    <option value="">No Event Selected</option>
                    {/* {allSports?.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.name}
                    </option>
                  ))} */}
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              )}

              <div style={{ marginBottom: "20px" }}>
                <label>League</label>
                <Select
                  placeholder="League Name"
                  name="leagueId"
                  id="leagueId"
                  isMulti
                  styles={selectboxStyles}
                  value={leagueId}
                  options={leagueList}
                  onChange={(selectedOptions) =>
                    this.handleLeagueChange(selectedOptions)
                  }
                />
                {leagueIdError !== "" && (
                  <p className="errorText" style={{ margin: "0" }}>
                    {leagueIdError}
                  </p>
                )}
              </div>

              <Form
                values={values}
                model={teamsFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              {isMainForm && (
                <div>
                  <label>Country</label>
                  <select
                    className="select-box-manual"
                    name="country"
                    onChange={this.handleSelect}
                    value={country}
                  >
                    <option>No Country Selected</option>
                    {countryAll?.map((obj, i) => (
                      <option key={i} value={obj.id}>
                        {obj.country}
                      </option>
                    ))}
                  </select>
                  {typeof error.country !== "undefined" &&
                    error.country !== "" && (
                      <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                        {error.country}
                      </p>
                    )}
                  <label>State</label>
                  <select
                    className="select-box-manual"
                    name="state"
                    onChange={this.handleSelect}
                    value={state}
                  >
                    {this.state.country === null ? (
                      <option>Select Country First</option>
                    ) : (
                      <>
                        <option>No State Selected</option>
                        {stateAll?.map((obj, i) => (
                          <option key={i} value={obj.id}>
                            {obj.state}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  {typeof error.state !== "undefined" && error.state !== "" && (
                    <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                      {error.state}
                    </p>
                  )}
                </div>
              )}

              <div style={{ width: "96.5%" }}>
                <p style={{ margin: 0 }}>Team Image</p>
                <FileUploader
                  onDrop={(files) => this.handleFileUpload(files)}
                />
                {files?.length > 0
                  ? files.map((file, index) => (
                      <img key={index} src={file.preview} alt="file" />
                    ))
                  : values?.image &&
                    values?.image !== "" && (
                      <img src={config.baseUrl + values?.image} alt="file" />
                    )}
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateTeams;
