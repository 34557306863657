import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Button,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { MdKeyboardBackspace } from "react-icons/md";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ButtonComponent from "../../library/common/components/Button";
import ShowModal from "../../components/common/ShowModal/ShowModal";
import CreateAnimalGender from "./CreateAnimalGender";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import ActionMessage from "../../library/common/components/ActionMessage";
import { Loader } from "../../library/common/components";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";
import { showVariations } from "../../helpers/common";

class AnimalGender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animalgender: [],
      allAnimalType: [],
      isModalOpen: false,
      isInputModalOpen: false,
      idToSend: null,
      isLoading: false,
      itemToDelete: null,
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      currentPage: 1,
      rowPerPage: 20,
    };
  }

  componentDidMount() {
    this.fetchAllAnimalGender();
  }

  async fetchAllAnimalGender() {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(
      URLS.animalGender + `/type/${this.props.match.params.id}`
    );
    if (status === 200) {
      this.setState({ animalgender: data.result, isLoading: false });
      this.fetchAllAnimalType();
    }
  }

  async fetchAllAnimalType() {
    const { status, data } = await axiosInstance.get(URLS.animalType);
    if (status === 200) {
      this.setState({ allAnimalType: data.result });
    }
  }

  getAnimalType = (id) => {
    let { allAnimalType } = this.state;
    let name = allAnimalType
      .filter((obj) => obj.id === id)
      .map((object) => object.name);
    return name;
  };

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  inputModal = (id, type) => () => {
    this.setState({ isInputModalOpen: true });
    if (type === "edit") {
      this.setState({ idToSend: id, isEditMode: true });
    } else {
      this.setState({ isEditMode: false });
    }
  };

  toggleInputModal = () => {
    this.setState({
      isInputModalOpen: !this.state.isInputModalOpen,
    });
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } }, () =>
      setTimeout(
        () =>
          this.setState({
            messageBox: { display: false, type: "", message: "" },
          }),
        3000
      )
    );
  };

  setItemToDelete = (id) => () => {
    this.setState({ itemToDelete: id, isModalOpen: true });
  };

  afterChangeRefresh = () => {
    this.fetchAllAnimalGender();
  };

  deleteItem = async () => {
    try {
      const { status } = await axiosInstance.delete(
        `${URLS.animalGender}/${this.state.itemToDelete}`
      );
      if (status === 200) {
        this.setState({ itemToDelete: null, isModalOpen: false }, () => {
          this.fetchAllAnimalGender();
        });
        this.setActionMessage(
          true,
          "Success",
          "Animal Gender Deleted Successfully!"
        );
      }
    } catch (err) {
      this.setActionMessage(true, "Error", "An error occurred while deleting.");
    }
  };

  backToNavigatePage = () => {
    this.props.history.push(`/admin/animaltypes`);
  };

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, rowPerPage, animalgender } = this.state;
    if (navDirection === "prev") {
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    } else {
      if (currentPage < animalgender.length / rowPerPage)
        this.setState({ currentPage: currentPage + 1 });
    }
  };

  render() {
    var {
      animalgender,
      isModalOpen,
      isInputModalOpen,
      messageBox,
      isLoading,
      rowPerPage,
      currentPage,
      isEditMode,
    } = this.state;
    const pageNumbers = [];
    let currentPageRow = animalgender;

    if (animalgender?.length > 0) {
      const indexOfLastTodo = currentPage * rowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      currentPageRow = animalgender.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(animalgender.length / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}
              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={10}>
                  <Button
                    className="admin-btn-back"
                    onClick={this.backToNavigatePage}
                  >
                    <MdKeyboardBackspace />
                  </Button>
                  <h3 className="text-left admin-page-heading">
                    Animal Gender
                    {` ( ${this.getAnimalType(
                      Number(this.props.match.params.id)
                    )} )`}
                  </h3>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent
                    className="addButton admin-btn-green"
                    onClick={this.inputModal(null, "create")}
                    color="primary"
                    value="Add New"
                  />
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && animalgender.length === 0 && (
                <p>No Data Available</p>
              )}
              {!isLoading && animalgender.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table className="listTable" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>DID</TableCell>
                          <TableCell style={{ width: "25%" }}>Name</TableCell>
                          <TableCell style={{ width: "25%" }}>
                            Variation
                          </TableCell>
                          <TableCell style={{ width: "25%" }}>
                            Animal Type
                          </TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentPageRow?.map((animal, i) => (
                          <TableRow key={i}>
                            <TableCell>{animal.id}</TableCell>
                            <TableCell>{animal.name}</TableCell>
                            <TableCell>
                              {showVariations(animal.variation)}
                            </TableCell>
                            <TableCell>
                              {this.getAnimalType(animal.animalTypeId)}
                            </TableCell>
                            <TableCell>
                              <EditIcon
                                onClick={this.inputModal(animal.id, "edit")}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                              <DeleteOutlineIcon
                                onClick={this.setItemToDelete(animal.id)}
                                color="secondary"
                                className="cursor iconBtn admin-btn-orange"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="tablePagination">
                    <button
                      className={
                        animalgender.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={
                        animalgender.length / rowPerPage > 1 ? false : true
                      }
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={
                        animalgender.length / rowPerPage > 1 ? false : true
                      }
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size="small"
                    />
                    <button
                      className={
                        animalgender.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={
                        animalgender.length / rowPerPage > 1 ? false : true
                      }
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </>
              )}
            </Paper>

            <ShowModal
              isModalOpen={isModalOpen}
              onClose={this.toggleModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.deleteItem}
              onCancel={this.toggleModal}
            />

            <Modal
              className="modal modal-input"
              open={isInputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {!isEditMode
                    ? "Create New Animal Gender"
                    : "Edit Animal Gender"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />
                <CreateAnimalGender
                  inputModal={this.toggleInputModal}
                  id={this.state.idToSend}
                  isEditMode={isEditMode}
                  animalTypeId={this.props.match.params.id}
                  fetchAllAnimalGender={this.afterChangeRefresh}
                />
              </div>
            </Modal>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default AnimalGender;
