import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./404.scss";

const NotFound = (props) => {
  return (
    <div className="error-page-content">
      <div>
        {window.location.pathname === "/dashboard" ? (
          <p style={{ fontSize: "24px" }}>
            you dont have access to admin panel. contact to admin for access
            admin panel.
          </p>
        ) : (
          <>
            <p> 404 Error </p>
            <p> We couldn’t find what you are looking for ! </p>
          </>
        )}

        <Link
          className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary"
          to="/"
          style={{ textDecoration: "none" }}
        >
          Go to Home
        </Link>
      </div>
    </div>
  );
};

NotFound.propTypes = {
  classes: PropTypes.object,
};

NotFound.defaultProps = {
  classes: {},
};

export default NotFound;
