import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { AddressesFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";

class CreateAddresses extends React.Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      values: {
        addressline1: "",
        addressline2: "",
        state: "",
        country: "",
        postcode: "",
        hash: "",
      },
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
    };
  }

  componentDidMount() {
    if (this.props.match.url.includes("edit")) {
      //this.fetchCurrentAd(this.props.match.params.id);
      this.setState({ isEditMode: true });
    }
  }

  back = () => {
    this.props.history.push("/admin/Addresses");
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  handleSave = async () => {
    const { isEditMode } = this.state;
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      if (form.isFormValid) {
        this.setActionMessage(
          true,
          "Success",
          `Addresses ${isEditMode ? "Edited" : "Created"} Successfully`
        );
      } else {
        this.setActionMessage(
          true,
          "Error",
          `An error occurred while ${
            isEditMode ? "editing" : "creating"
          } Addresses`
        );
      }
    } catch (err) {
      // this.setActionMessage(
      //   true,
      //   "Error",
      //   `An error occurred while ${isEditMode ? "editing" : "creating"} Addresses`
      // );
    }
  };

  handleChange = (field, value) => {
    this.setState((prevState) => {
      return {
        values: { ...prevState.values, [field]: value },
      };
    });
  };

  render() {
    var { values, messageBox } = this.state;
    var isCreate = this.props.match.url === "/add-addresses" ? true : false;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <h3 className="text-left">
                {isCreate ? "Create New Addresses" : "Edit Addresses"}
              </h3>
              <Form
                values={values}
                model={AddressesFormModel}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px" }}>
                    {isCreate ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value="Save"
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value="Update"
                      />
                    )}

                    <ButtonComponent
                      onClick={this.back}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateAddresses;
