import React, { useState } from "react";
import moment from "moment";
import axiosInstance from "../../../helpers/Axios";
import {
  TableRow,
  // TextField,
  TableCell,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "../../../library/common/components/Loader";

const ListItemMapping = ({ item, index, mappingMeetings }) => {
  const [value, setValue] = useState("");
  const [itemMappingMetting, setItemMapping] = useState(item?.mappingMetting);
  const [isLoading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const onChangeValue = (newValue) => {
    if (newValue !== "" && newValue !== null) setShowButton(true);
    else setShowButton(false);
    setValue(newValue);
  };
  const saveItem = async () => {
    setLoading(true);
    const { status } = await axiosInstance.put("/meetingMapping/" + item?.id, {
      mappingMetting: value,
    });
    if (status === 200) {
      setLoading(false);
      setShowButton(false);
      setItemMapping(value);
    }
  };
  const mappingMeetingSelectbox = (item) => {
    return (
      <>
        <Autocomplete
          id="controlled-demo"
          value={value}
          onChange={(event, newValue) => {
            onChangeValue(newValue);
          }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: "200px",
                  borderRadius: "4px",
                  lineHeight: "20px",
                  border: "1px solid #e3e3e3",
                  height: "24px",
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
          options={mappingMeetings}
          // renderInput={(params) => <TextField {...params}  margin="normal" />}
        />
      </>
    );
  };

  return (
    <>
      <TableRow key={index}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{moment(item?.createdAt).format("YYYY-MM-DD")}</TableCell>
        <TableCell>{item?.MeetingName}</TableCell>
        <TableCell size="small">
          {itemMappingMetting !== "" && itemMappingMetting !== null
            ? itemMappingMetting
            : mappingMeetingSelectbox()}
        </TableCell>
        <TableCell>
          <Button
            disabled={!showButton}
            variant="contained"
            color="secondary"
            onClick={() => saveItem()}
          >
            {isLoading === true ? <Loader /> : "Save"}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ListItemMapping;
