import { Validators } from "../../../library/utilities/Validators";

export const sportTypeFormModel = [
  {
    label: "Sport Type*",
    value: "",
    type: "text",
    placeholder: "Sport Type",
    field: "sportType",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
];
