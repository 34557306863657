import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  Modal,
  Box,
  Breadcrumbs,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { Loader } from "../../../library/common/components";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
// import arrowLeft from "../../../images/blog_img/paginationArrowLeft.svg";
// import arrowRight from "../../../images/blog_img/paginationArrowRight.svg";
// import { ReactSVG } from "react-svg";
import { URLS } from "../../../library/common/constants";
import axiosInstance from "../../../helpers/Axios";
import moment from "moment";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CreateEvents from "../../events/CreateEvents/index";
import FixturesFilter from "./Filters/FixturesFilter";
import CreateApiEventIdentifire from "../../apiEventIdentifire/CreateApiEventIdentifire";
// import CreateRaceTable from "../../raceTable/CreateRaceTable";
import ShowModal from "../../../components/common/ShowModal/ShowModal";
import SearchIcons from "../../../images/searchIcon.svg";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";

class RacingMeetings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meetingsDetails: [],
      identifireDetails: [],
      raceDetail: [],
      isLoading: false,
      currentPage: 1,
      rowPerPage: 20,
      offset: 0,
      sportCount: null,
      inputModalOpen: false,
      identifireModalOpen: false,
      isInputFormModalOpen: false,
      isRaceDetailModal: false,
      inputRaceFormOpen: false,
      isDeleteModalOpen: false,
      isDeleteRaceModalOpen: false,
      isDeleteIdentifireModalOpen: false,
      isEditMode: false,
      // isRaceEdit: false,
      idToSend: "",
      searchInput: "",
      // sportAll: [],
      locationAll: [],
      weatherAll: [],
      trackAll: [],
      allEvents: [],
      allProvider: [],
      // distanceAll: [],
      eventIdToSend: null,
      itemToDelete: null,
      selectedEvent: "",
      searchIdentifire: "",
      selectedDate: null,
      isDeleteLoading: "",
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (this.props.match.params.name === "all") {
      this.fetchTrackList('1,2,3', "Aus/NZ,Intl", moment().utc().format("YYYY-MM-DD"));
    }
    else {
      this.fetchAllEvents(id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(prevProps.match, this.props.match, "componentDidUpdatecomponentDidUpdate")
    let id = this.props.match.params.id;
    if (prevProps.match !== this.props.match) {
      if (this.state.offset === 0) {
        // this.fetchAllEvents(id);
        if (this.props.match.params.name === "all") {
          this.fetchTrackList('1,2,3', "Aus/NZ,Intl", moment().utc().format("YYYY-MM-DD"));
        }
        else {
          this.fetchAllEvents(id);
        }
      } else {
        this.setState({ offset: 0, currentPage: 1, searchInput: "" });
      }
      this.setState({ searchInput: "" });
    }
    if (prevState.offset !== this.state.offset) {
      // this.fetchAllEvents(id);
      if (this.props.match.params.name === "all") {
        this.fetchTrackList('1,2,3', "Aus/NZ,Intl", moment().utc().format("YYYY-MM-DD"));
      }
      else {
        this.fetchAllEvents(id);
      }
    }
  }

  fetchAllEvents = async () => {
    let id = this.props.match.params.id;
    let { rowPerPage, offset, searchInput, selectedDate } = this.state;
    let date_pass =
      selectedDate !== null
        ? moment.utc(selectedDate).format("YYYY-MM-DD")
        : "";
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(
      `/events/sportId/${id}?limit=${rowPerPage}&offset=${offset}&matchString=${searchInput}&todate=${date_pass}`
    );
    if (status === 200) {
      console.log("data", data?.events);
      this.setState({
        meetingsDetails: data.events.rows,
        isLoading: false,
        sportCount: data.count,
      });
    }
    // this.fetchAllSport();
    this.fetchAllLocation();
    // this.fetchAllWeather();
    this.fetchAllTrack();
    // this.fetchEvents();
    this.fetchAllBookkeepers();
    // this.fetchAllDistance();
  };
  fetchTrackList = async (ids, countries, todate) => {
    try {
      const { status, data } = await axiosInstance.get(
        `events/trackList/?sportId=${ids}&MeetingState=${countries}&todate=${todate}&countryId=${""}&stateId=${""}&timezone=${""}`
      );
      if (status === 200) {
        this.setState({
          meetingsDetails: data.events,
          sportCount: data.count,
        });
      }
    } catch (err) {
    }
  };
  // fetchFilterEvents = async () => {
  //   let id = this.props.match.params.id;
  //   let { rowPerPage, offset, searchInput, selectedDate } = this.state;
  //   this.setState({ isLoading: true });
  //   const { status, data } = await axiosInstance.get(
  //     `/events/sportId/${id}?limit=${rowPerPage}&offset=${offset}&matchString=${searchInput}`
  //   );
  //   if (status === 200) {
  //     let data_array = data.events;
  //     if (selectedDate !== null) {
  //       data_array = data.events?.filter(
  //         (obj) =>
  //           moment(obj.eventToDate).format("YYYY/MM/DD") ==
  //           moment(selectedDate).format("YYYY/MM/DD")
  //       );
  //     }
  //     this.setState({
  //       meetingsDetails: data_array,
  //       isLoading: false,
  //       sportCount: data.count,
  //     });
  //   }
  // };

  // fetchAllSport = async (id) => {
  //   const { status, data } = await axiosInstance.get(URLS.sports);
  //   if (status === 200) {
  //     this.setState({ sportAll: data.result });
  //   }
  // };

  fetchAllLocation = async (id) => {
    const { status, data } = await axiosInstance.get(URLS.location);
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["venueName"], ["asc"]);
      this.setState({ locationAll: data_obj });
    }
  };

  // fetchAllWeather = async (id) => {
  //   const { status, data } = await axiosInstance.get(URLS.weather);
  //   if (status === 200) {
  //     this.setState({ weatherAll: data.result });
  //   }
  // };

  fetchAllTrack = async () => {
    const { status, data } = await axiosInstance.get(URLS.track);
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["name"], ["asc"]);
      this.setState({ trackAll: data_obj });
    }
  };

  // async fetchEvents() {
  //   const { status, data } = await axiosInstance.get(
  //     URLS.events + `/${this.props.match.params.id}`
  //   );
  //   if (status === 200) {
  //     this.setState({ allEvents: data.result });
  //   }
  // }

  async fetchAllBookkeepers() {
    const { status, data } = await axiosInstance.get(URLS.apiProvider);
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["providerName"], ["asc"]);
      this.setState({ allProvider: data_obj });
    }
  }

  // fetchAllDistance = async () => {
  //   const { status, data } = await axiosInstance.get(URLS.distance);
  //   if (status === 200) {
  //     this.setState({ distanceAll: data.result });
  //   }
  // };

  getProvider = (id) => {
    let { allProvider } = this.state;
    let provider = allProvider
      .filter((obj) => obj.id === id)
      .map((object) => object.providerName);
    return provider;
  };

  handlePaginationClick = (event, page) => {
    let {
      rowPerPage,
      //  offset
    } = this.state;
    this.setState({
      currentPage: Number(page),
      offset: (Number(page) - 1) * rowPerPage,
    });
  };

  toggleInputModal = () => {
    this.setState({ inputModalOpen: false });
  };

  inputModal = (id) => () => {
    this.setState({ inputModalOpen: true, idToSend: id });
  };

  toggleIdentifireModal = () => {
    this.setState({
      identifireModalOpen: !this.state.identifireModalOpen,
      searchIdentifire: "",
    });
  };

  toggleInputFormModal = () => {
    this.setState({ isInputFormModalOpen: !this.state.isInputFormModalOpen });
  };

  toggleRaceDetailModal = () => {
    this.setState({ isRaceDetailModal: !this.state.isRaceDetailModal });
  };

  inputFormModal = (id, type) => {
    this.setState({
      isInputFormModalOpen: true,
      isEditMode: type,
      searchIdentifire: "",
    });
  };

  showIdentifire = async (id) => {
    let { eventIdToSend } = this.state;
    this.setState({
      identifireModalOpen: true,
      isLoading: true,
    });
    let getId = eventIdToSend === null ? id : eventIdToSend;
    try {
      const { status, data } = await axiosInstance.get(
        `/event/identifier/eventid/${getId}`
      );
      if (status === 200) {
        this.setState({
          identifireDetails: data.eventIdentifiers,
          isLoading: false,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isLoading: false });
    }
  };

  showRaceModel = async (id) => {
    this.setState({
      isRaceDetailModal: true,
      isLoading: true,
      eventIdToSend: id,
    });
    try {
      const { status, data } = await axiosInstance.get(`/events/race/${id}`);
      if (status === 200) {
        this.setState({
          raceDetail: data.result,
          isLoading: false,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isLoading: false });
    }
  };

  // toggleRaceFormModal = () => {
  //   this.setState({ inputRaceFormOpen: !this.state.inputRaceFormOpen });
  // };

  // inputRaceFormModal = (id, type) => {
  //   this.setState({ inputRaceFormOpen: true, idToSend: id, isRaceEdit: type });
  // };

  afterChangeRefresh = async () => {
    let id = this.props.match.params.id;
    this.fetchAllEvents(id);
  };

  handleEventsDelete = async () => {
    let { itemToDelete } = this.state;
    this.setState({ isDeleteLoading: "eventDelete", isDeleteModalOpen: false });
    try {
      const { status } = await axiosInstance.delete(
        `events/event/${itemToDelete}`
      );
      if (status === 200) {
        this.afterChangeRefresh();
        this.setState({ isDeleteLoading: "", itemToDelete: null });
      }
    } catch (err) { }
  };

  handleIdentifireDelete = async () => {
    let { itemToDelete } = this.state;
    this.setState({
      isDeleteLoading: "identifireDelete",
      isDeleteIdentifireModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `/event/identifier/${itemToDelete}`
      );
      if (status === 200) {
        this.showIdentifire();
        this.setState({ isDeleteLoading: "", itemToDelete: null });
      }
    } catch (err) { }
  };

  handleRaceDelete = async () => {
    let { itemToDelete } = this.state;
    try {
      const { status } = await axiosInstance.delete(
        `races/race/${itemToDelete}`
      );
      if (status === 200) {
        this.afterChangeRefresh();
      }
    } catch (err) { }
  };

  setEventToDelete = (id) => {
    this.setState({ itemToDelete: id, isDeleteModalOpen: true });
  };

  toggleDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
      itemToDelete: null,
    });
  };

  setRaceToDelete = (id) => {
    this.setState({ itemToDelete: id, isDeleteRaceModalOpen: true });
  };

  toggleRaceDeleteModal = () => {
    this.setState({
      isDeleteRaceModalOpen: !this.state.isDeleteRaceModalOpen,
      itemToDelete: null,
    });
  };

  setIdentifireToDelete = (id) => {
    this.setState({ itemToDelete: id, isDeleteIdentifireModalOpen: true });
  };

  toggleIdentifireDeleteModal = () => {
    this.setState({
      isDeleteIdentifireModalOpen: !this.state.isDeleteIdentifireModalOpen,
      itemToDelete: null,
    });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  handlePaginationButtonClick = (navDirection) => {
    let {
      currentPage,
      //  rowPerPage,
      offset,
    } = this.state;
    // if (navDirection === "prev") {
    //   if (currentPage > 1) {
    //     this.setState({ currentPage: currentPage - 1 });
    //   }
    // } else {
    //   if (currentPage < meetingsDetails.length / rowPerPage)
    //     this.setState({ currentPage: currentPage + 1 });
    // }

    if (navDirection === "prev") {
      if (offset >= 20) {
        this.setState({ offset: offset - 20, currentPage: currentPage - 1 });
      }
    } else {
      this.setState({ offset: offset + 20, currentPage: currentPage + 1 });
    }
  };

  render() {
    const { match } = this.props;
    let {
      meetingsDetails,
      identifireDetails,
      isLoading,
      rowPerPage,
      currentPage,
      searchInput,
      inputModalOpen,
      identifireModalOpen,
      isInputFormModalOpen,
      // isRaceDetailModal,
      // inputRaceFormOpen,
      locationAll,
      weatherAll,
      trackAll,
      // sportAll,
      allEvents,
      allProvider,
      // distanceAll,
      isEditMode,
      // isRaceEdit,
      idToSend,
      eventIdToSend,
      offset,
      sportCount,
      // raceDetail,
      // itemToDelete,
      isDeleteModalOpen,
      isDeleteIdentifireModalOpen,
      // isDeleteRaceModalOpen,
      selectedEvent,
      searchIdentifire,
      selectedDate,
      isDeleteLoading,
    } = this.state;
    const pageNumbers = [];
    console.log("props", this.props);

    // searchInput !== "" &&
    //   (meetingsDetails = meetingsDetails?.filter(
    //     (obj) =>
    //       obj?.eventName
    //         ?.toString()
    //         .toLowerCase()
    //         .includes(searchInput.toString().toLowerCase()) ||
    //       obj?.variation
    //         ?.toString()
    //         .toLowerCase()
    //         .includes(searchInput.toString().toLowerCase())
    //   ));

    searchIdentifire !== "" &&
      (identifireDetails = identifireDetails?.filter(
        (obj) =>
          obj?.apiEventId
            ?.toString()
            .toLowerCase()
            .includes(searchIdentifire.toString().toLowerCase()) ||
          obj?.id
            ?.toString()
            .toLowerCase()
            .includes(searchIdentifire.toString().toLowerCase())
      ));

    // let currentPageRow = meetingsDetails;

    if (sportCount > 0) {
      // const indexOfLastTodo = currentPage * rowPerPage;
      // const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      // currentPageRow = meetingsDetails.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(sportCount / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    console.log(meetingsDetails, "meetingsDetailsmeetingsDetails");

    return (
      <>
        <Grid container className="page-content adminLogin pageWrapper">
          <Grid item xs={12}>
            {/* <Paper className="pageWrapper"> */}
            {isDeleteLoading === "eventDelete" && (
              <div class="admin-delete-loader">
                <Loader />
              </div>
            )}
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/admin/dashboard">
                  Home
                </Link>
                <Link underline="hover" color="inherit">
                  racing
                </Link>
                <Link underline="hover" color="inherit">
                  {/* Horse Racing*/}
                  {this.props.match.params.name}
                </Link>
                <Typography className="active_p">Fixtures</Typography>
              </Breadcrumbs>
            </Box>
            <Grid
              container
              direction="row"
              alignItems="space-around"
              style={{ alignItems: "center" }}
            >
              <Grid item xs={4}>
                {/* <h3 className="text-left">Event Details</h3> */}
                <Typography variant="h1" align="left">
                  Event Details
                </Typography>
              </Grid>
              {
                this.props.match.params.name !== 'all' && (
                  <>
                    <Grid item xs={4} className="admin-filter-wrap">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container style={{ justifyContent: "end" }}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            placeholder="MM/DD/YYY"
                            margin="normal"
                            id="date-picker-inline"
                            inputVariant="outlined"
                            value={selectedDate}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            style={{ marginRight: 5 }}
                            className="details-search-picker"
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        className="textfield-tracks"
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{
                          width: "370px",
                          color: "#D4D6D8",
                        }}
                        // label="Search"
                        placeholder="Search (searches tracks)"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={SearchIcons} />
                              {/* <SearchIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        value={searchInput}
                        onChange={(e) =>
                          this.setState({
                            ...this.state.searchInput,
                            searchInput: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#4455C7",
                          color: "#fff",
                          borderRadius: "8px",
                          textTransform: "capitalize",
                          padding: "13px 24px 12px",
                        }}
                        onClick={() => this.fetchAllEvents()}
                      >
                        Search
                      </Button>
                    </Grid>
                  </>
                )
              }
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {
                  this.props.match.params.name === 'all' && (
                    <FixturesFilter
                      fetchTrackList={this.fetchTrackList} />
                  )
                }
              </Grid>
            </Grid>
            {isLoading && <Loader />}
            {!isLoading && meetingsDetails.length === 0 && (
              <p>No Data Available</p>
            )}

            {
              !isLoading && meetingsDetails.length > 0 && (
                <>
                  <TableContainer>
                    <Table
                      className="listTable api-provider-listTable"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow className="tableHead-row">
                          <TableCell>DID</TableCell>
                          <TableCell>Event Name</TableCell>
                          <TableCell>Event Date</TableCell>
                          <TableCell>Track</TableCell>
                          <TableCell>Variation</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Race Details</TableCell>
                          <TableCell>Edit</TableCell>
                          <TableCell>Delete</TableCell>
                          <TableCell>View/Add Identifier</TableCell>
                          <TableCell>Created At</TableCell>
                          <TableCell>R1</TableCell>
                          <TableCell>R2</TableCell>
                          <TableCell>R3</TableCell>
                          <TableCell>R4</TableCell>
                          <TableCell>R5</TableCell>
                          <TableCell>R6</TableCell>
                          <TableCell>R7</TableCell>
                          <TableCell>R8</TableCell>
                          <TableCell>R9</TableCell>
                          <TableCell>R11</TableCell>
                          <TableCell>R12</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="table_body">
                        <TableRow className="table_row">
                          <TableCell
                            colSpan={100}
                            className="table-seprator"
                          ></TableCell>
                        </TableRow>
                        {meetingsDetails.map((meetingsDetails, i) => (
                          <>
                            <TableRow key={i} className="listTable-Row">
                              <TableCell>{meetingsDetails.id}</TableCell>
                              <TableCell>{meetingsDetails.eventName}</TableCell>
                              <TableCell>
                                {moment(meetingsDetails.eventToDate).format(
                                  "DD/MM/YYYY hh:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell>{meetingsDetails.trackId}</TableCell>
                              <TableCell>{meetingsDetails.variation}</TableCell>
                              <TableCell>{meetingsDetails.description}</TableCell>
                              <TableCell>
                                <Button
                                  style={{
                                    fontSize: "9px",
                                    backgroundColor: "#4455C7",
                                    color: "#fff",
                                    fontWeight: "400",
                                    textTransform: "none",
                                    padding: "5px 0",
                                  }}
                                  onClick={() =>
                                    // this.showRaceModel(meetingsDetails?.id)
                                    this.props.history.push(
                                      `/admin/racing/${match?.params?.name}/${match?.params?.id}/${meetingsDetails?.id}/${meetingsDetails.eventName}`
                                    )
                                  }
                                >
                                  View
                                </Button>
                              </TableCell>
                              <TableCell
                                onClick={this.inputModal(meetingsDetails.id)}
                              >
                                {/* <EditIcon
                                  
                                    color="primary"
                                    className="mr10 cursor iconBtn admin-btn-green"
                                  /> */}
                                Edit
                              </TableCell>
                              <TableCell
                                onClick={() =>
                                  this.setEventToDelete(meetingsDetails?.id)
                                }
                              >
                                {/* <DeleteOutlineIcon
                                    onClick={() =>
                                      this.setEventToDelete(meetingsDetails?.id)
                                    }
                                    color="primary"
                                    className="mr10 cursor iconBtn admin-btn-orange"
                                  /> */}
                                Delete
                              </TableCell>
                              <TableCell>
                                <Button
                                  style={{
                                    fontSize: "9px",
                                    backgroundColor: "#4455C7",
                                    color: "#fff",
                                    fontWeight: "400",
                                    textTransform: "none",
                                    padding: "5px 5px",
                                  }}
                                  onClick={() => {
                                    this.setState(
                                      {
                                        selectedEvent: meetingsDetails.eventName,
                                        eventIdToSend: meetingsDetails?.id,
                                      },
                                      () => this.showIdentifire(meetingsDetails?.id)
                                    );
                                  }}
                                >
                                  View/Add Identifier
                                </Button>
                              </TableCell>
                              <TableCell>
                                {moment(meetingsDetails.createdAt).format(
                                  "DD/MM/YYYY hh:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell>R1</TableCell>
                              <TableCell>R2</TableCell>
                              <TableCell>R3</TableCell>
                              <TableCell>R4</TableCell>
                              <TableCell>R5</TableCell>
                              <TableCell>R6</TableCell>
                              <TableCell>R7</TableCell>
                              <TableCell>R8</TableCell>
                              <TableCell>R9</TableCell>
                              <TableCell>R11</TableCell>
                              <TableCell>R12</TableCell>
                            </TableRow>
                          </>
                        ))}
                        {
                          this.props.match.params.name !== 'all' && (
                            <TableRow>
                              <TableCell colSpan={100} className="pagination">
                                <div className="tablePagination">
                                  {/* <button
                                    className={
                                      offset >= 20
                                        ? "btn-navigation"
                                        : "btn-navigation-disabled"
                                    }
                                    disabled={offset >= 20 ? false : true}
                                    // disabled={
                                    //   meetingsDetails.length / rowPerPage > 1 ? false : true
                                    // }
                                    onClick={() =>
                                      this.handlePaginationButtonClick("prev")
                                    }
                                  >
                                    <ReactSVG src={arrowLeft} />
                                  </button> */}
                                  <Pagination
                                    hideNextButton
                                    hidePrevButton
                                    disabled={sportCount > 0 ? false : true}
                                    page={currentPage}
                                    onChange={this.handlePaginationClick}
                                    count={pageNumbers[pageNumbers?.length - 1]}
                                    siblingCount={2}
                                    boundaryCount={1}
                                    size="small"
                                  />
                                  {/* <button
                                    className={
                                      rowPerPage + offset < sportCount
                                        ? "btn-navigation"
                                        : "btn-navigation-disabled"
                                    }
                                    disabled={
                                      rowPerPage + offset < sportCount ? false : true
                                    }
                                    onClick={() =>
                                      this.handlePaginationButtonClick("next")
                                    }
                                  >
                                    <ReactSVG src={arrowRight} />
                                  </button> */}
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )
            }

            {/* </Paper> */}
            <Modal
              className="modal modal-input"
              open={inputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center modal-head">
                  {`Edit ${match.params.name} Details`}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />
                <CreateEvents
                  inputModal={this.toggleInputModal}
                  id={idToSend}
                  isEditMode={true}
                  fetchAllEvents={this.afterChangeRefresh}
                  sportId={match.params.id}
                  // sportAll={sportAll}
                  locationAll={locationAll}
                  weatherAll={weatherAll}
                  trackAll={trackAll}
                  isOtherSport={false}
                />
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={identifireModalOpen}
              onClose={this.toggleIdentifireModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <Grid container>
                  <Grid item xs={7}>
                    <h3 className="text-center">{`Bookmaker Feed Identifiers (${selectedEvent})`}</h3>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ marginTop: "15px" }}>
                      <input
                        type="text"
                        placeholder="search"
                        value={searchIdentifire}
                        onChange={(e) => {
                          this.setState({
                            ...this.state.searchIdentifire,
                            searchIdentifire: e.target.value,
                          });
                        }}
                        style={{
                          fontSize: "16px",
                          borderRadius: "3px",
                          minHeight: "30px",
                          border: "1px solid #ddd",
                          marginRight: "15px",
                        }}
                      />
                      <Button
                        style={{
                          textTransform: "none",
                          height: "28px",
                          fontSize: "12px",
                          backgroundColor: "#ff6b00",
                          color: "#fff",
                          fontWeight: "400",
                        }}
                        onClick={() => {
                          this.inputFormModal(null, false);
                        }}
                      >
                        Add Identifier
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleIdentifireModal}
                />
                {isDeleteLoading === "identifireDelete" && (
                  <div class="admin-delete-modal-loader">
                    <Loader />
                  </div>
                )}
                {isLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
                {!isLoading && identifireDetails.length === 0 && (
                  <p>No Data Available</p>
                )}
                {!isLoading && identifireDetails.length > 0 && (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        className="listTable api-provider-listTable"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>DID</TableCell>
                            <TableCell>Meeting Id</TableCell>
                            <TableCell>Bookmaker</TableCell>
                            <TableCell>CreatedAt</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {identifireDetails?.map((api, i) => (
                            <TableRow key={i}>
                              <TableCell>{api.id}</TableCell>
                              <TableCell>{api.apiEventId}</TableCell>
                              <TableCell>
                                {this.getProvider(api.providerId)}
                              </TableCell>
                              <TableCell>
                                {moment(api.createdAt).format(
                                  "DD/MM/YYYY hh:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell>
                                <EditIcon
                                  onClick={() => {
                                    this.inputFormModal(api.id, true);
                                    this.setState({ idToSend: api.id });
                                  }}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-green"
                                />
                              </TableCell>
                              <TableCell>
                                <DeleteOutlineIcon
                                  onClick={() =>
                                    this.setIdentifireToDelete(api?.id)
                                  }
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-orange"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isInputFormModalOpen}
              onClose={this.toggleInputFormModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {!isEditMode
                    ? "Create Bookmaker Feed Identifiers"
                    : "Edit Bookmaker Feed Identifier"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputFormModal}
                />
                <CreateApiEventIdentifire
                  inputModal={this.toggleInputFormModal}
                  id={idToSend}
                  eventIdToSend={eventIdToSend}
                  isEditMode={isEditMode}
                  fetchAllEventIdentifire={this.showIdentifire}
                  isMeetings={true}
                  allEvents={allEvents}
                  allProvider={allProvider}
                  isOtherSport={true}
                  otherSportName={"Meeting"}
                />
              </div>
            </Modal>

            <ShowModal
              isModalOpen={isDeleteModalOpen}
              onClose={this.toggleDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleEventsDelete}
              onCancel={this.toggleDeleteModal}
            />

            <ShowModal
              isModalOpen={isDeleteIdentifireModalOpen}
              onClose={this.toggleIdentifireDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleIdentifireDelete}
              onCancel={this.toggleIdentifireDeleteModal}
            />

            {/* <Modal
              className='modal modal-input'
              open={isRaceDetailModal}
              onClose={this.toggleRaceDetailModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3 className='text-center'>{`Race Details`}</h3>
                  <Button
                    style={{
                      textTransform: "none",
                      height: "28px",
                      marginTop: "15px",
                      fontSize: "12px",
                      backgroundColor: "#ff6b00",
                      color: "#fff",
                      fontWeight: "400",
                    }}
                    onClick={() => this.inputRaceFormModal(null, false)}
                  >
                    Add Race
                  </Button>
                </div>
                <CancelIcon
                  className='admin-close-icon'
                  onClick={this.toggleRaceDetailModal}
                />

                {isLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
                {!isLoading && raceDetail.length === 0 && (
                  <p>No Data Available</p>
                )}
                {!isLoading && raceDetail.length > 0 && (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        className='listTable api-provider-listTable'
                        aria-label='simple table'
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>DID</TableCell>
                            <TableCell>Race Name</TableCell>
                            <TableCell>Race Number</TableCell>
                            <TableCell>Distance</TableCell>
                            <TableCell>Track</TableCell>
                            <TableCell>Weather</TableCell>
                            <TableCell>Race Date</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {raceDetail?.map((api, i) => (
                            <TableRow key={i}>
                              <TableCell>{api?.id}</TableCell>
                              <TableCell>{api?.raceName}</TableCell>
                              <TableCell>{api?.raceNumber}</TableCell>
                              <TableCell>{api?.distance?.name}</TableCell>
                              <TableCell>{api?.trackId?.name}</TableCell>
                              <TableCell>{api?.weather?.name}</TableCell>
                              <TableCell>
                                {moment(api.startDate).format(
                                  "DD/MM/YYYY hh:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell>
                                <EditIcon
                                  onClick={() =>
                                    this.inputRaceFormModal(api.id, true)
                                  }
                                  color='primary'
                                  className='mr10 cursor iconBtn admin-btn-green'
                                />
                              </TableCell>
                              <TableCell>
                                <DeleteOutlineIcon
                                  onClick={() => this.handleRaceDelete(api?.id)}
                                  color='primary'
                                  className='mr10 cursor iconBtn admin-btn-orange'
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </Modal> */}

            {/* <Modal
              className='modal modal-input'
              open={inputRaceFormOpen}
              onClose={this.toggleRaceFormModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className='text-center'>
                  {isRaceEdit
                    ? `Edit ${match.params.name} Details`
                    : `Add ${match.params.name} Details`}
                </h3>
                <CancelIcon
                  className='admin-close-icon'
                  onClick={this.toggleRaceFormModal}
                />

                <CreateRaceTable
                  inputModal={this.toggleRaceFormModal}
                  id={idToSend}
                  isEditMode={isRaceEdit}
                  fetchAllRace={this.afterChangeRefresh}
                  eventsAll={allEvents}
                  distanceAll={distanceAll}
                  weatherAll={weatherAll}
                  allSports={sportAll}
                />
              </div>
            </Modal> */}
          </Grid >
        </Grid >
      </>
    );
  }
}
export default RacingMeetings;
