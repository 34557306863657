import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { categoriesFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
// import { URLS } from "../../../library/common/constants";
// import { removeErrorFieldsFromValues } from "../../../library/utilities";
// import axiosInstance from "../../../helpers/Axios";

class CreateCategories extends React.Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      values: { sporttype: "", categoriesname: "", variation: "" },
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      //this.fetchCurrentCountry(this.props.id);
    }
  }

  // fetchCurrentCountry = async (id) => {
  //   const { status, data } = await axiosInstance.get(URLS.country + `/${id}`);
  //   if (status === 200) {
  //     this.setState({ values: data.result });
  //   }
  // };

  // back = () => {
  //   this.props.history.push("/admin/categories");
  // };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { sporttype, categoriesname, variation } = this.state.values;
    let flag = true;

    if (sporttype === "" || categoriesname === "" || variation === "") {
      flag = false;
      this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      this.setActionMessage(false);
    }

    return flag;
  };

  // handleSave = async () => {
  //   const { isEditMode } = this.state;
  //   this.setState({ isLoading: true });
  //   try {
  //     const { current } = this.formRef;
  //     const form = current.getFormData();

  //     const method = isEditMode ? "put" : "post";
  //     const url = isEditMode
  //       ? `${URLS.country}/${this.props.id}`
  //       : URLS.country;

  //     const values = removeErrorFieldsFromValues(form.formData);

  //     if (this.validate()) {
  //       const { status } = await axiosInstance[method](url, values);
  //       if (status === 200) {
  //         this.setState({ isLoading: false });
  //         this.props.inputModal();
  //         this.props.fetchAllCountry();
  //         this.setActionMessage(
  //           true,
  //           "Success",
  //           `Categories ${isEditMode ? "Edited" : "Created"} Successfully`
  //         );
  //       }
  //     }
  //   } catch (err) {
  //     this.setActionMessage(
  //       true,
  //       "Error",
  //       `An error occurred while ${
  //         isEditMode ? "editing" : "creating"
  //       } Categories`
  //     );
  //   }
  // };

  handleChange = (field, value) => {
    this.setState((prevState) => {
      return {
        values: { ...prevState.values, [field]: value },
      };
    });
  };

  render() {
    var { values, messageBox, isLoading } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <h3 className="text-left">
                {!isEditMode ? "Create New Categories" : "Edit Categories"}
              </h3>
              <Form
                values={values}
                model={categoriesFormModel}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateCategories;
