import React, { Component, createRef } from "react";
import { CircularProgress } from "@material-ui/core";

import FileUploader from "../../../library/common/components/FileUploader";
import ButtonComponent from "../../../library/common/components/Button";
import axiosInstance from "../../../helpers/Axios";
import { URLS } from "../../../library/common/constants";

export default class Homepage extends Component {
  formRef = createRef();
  state = {
    files: [],
    isLoading: false,
  };

  handleFileUpload = (files) => {
    this.setState({ files });
    // this.sendData();
  };

  sendData = () => {
    const { files } = this.state;
    const data = new FormData();
    files.forEach((file) => {
      data.append("image", file);
    });
    this.props.onChange(data);
  };

  componentDidMount() {
    this.setState({ urls: this.props.data });
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ urls: this.props.data });
    }
  }

  saveImage = () => {
    const { files } = this.state;

    if (files.length > 0) {
      let url = "/page/banner/" + this.props.pageId;

      const formData = new FormData();
      formData.append("image", files[0]);

      this.setState({ isLoading: true });

      axiosInstance
        .post(url, formData, {
          header: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.status === 200) {
            this.props.onSuccess("Banner Image added successfully!");
            this.setState({ files: [], isLoading: false });
          }
        });
      // .catch(this.setState({ isLoading: false }));
    }
  };

  deleteImage = (id) => () => {
    this.setState({ isLoading: true });

    axiosInstance.delete("/page/banner/" + id).then((res) => {
      if (res.status === 200) {
        this.props.onSuccess("Banner Image deleted successfully!");
        this.setState({ isLoading: false });
      }
    });
    // .catch(this.setState({ isLoading: false }));
  };

  render() {
    const { files, isLoading } = this.state;
    const { data } = this.props;

    return (
      <div className="home-template-container">
        <h4>Add Images for Banner</h4>

        <div className="file-container">
          {data?.map((file, index) => (
            <div className="file-item">
              <img
                key={index}
                src={URLS.uploadPath + file.fileName}
                alt="file"
              />
              <br />
              <ButtonComponent
                onClick={this.deleteImage(file.id)}
                color="secondary"
                value="Delete"
              />
            </div>
          ))}
        </div>

        <FileUploader accept="image/*" onDrop={this.handleFileUpload} />

        {isLoading && <CircularProgress />}

        {files?.map((file, index) => (
          <img key={index} src={file.preview} alt="file" />
        ))}

        <p>
          <small>
            <em>Note: Please save the file to add new</em>
          </small>
        </p>

        <ButtonComponent
          onClick={this.saveImage}
          color="primary"
          value="Add Banner"
        />
      </div>
    );
  }
}
