import React from "react";
import {
  Box,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
  Modal,
  Button,
} from "@material-ui/core";
import { Loader } from "../../library/common/components";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../images/blog_img/paginationArrowRight.svg";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import ButtonComponent from "../../library/common/components/Button";
import { ReactSVG } from "react-svg";
import axiosInstance from "../../helpers/Axios";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import "../sportsDetails/sportDetails.scss";
import CancelIcon from "@material-ui/icons/Cancel";
import PersonIcon from "@material-ui/icons/Person";
import CreateLeague from "../league/CreateLeague";
import CreateTeams from "../teams/CreateTeams";
import { URLS } from "../../library/common/constants";
import CreatePlayers from "../players/CreatePlayers";
import ShowModal from "../../components/common/ShowModal/ShowModal";
import { config } from "../../helpers/config";
import _ from "lodash";
import CreateWeightClass from "./createWeightClass";
import CreateRankings from "./createRankings";
import CreateChampionHistory from "./createChampionHistory";

class SportsDetailsTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
      sportsDetails: [],
      teamsDetail: [],
      playersDetails: [],
      allSportsType: [],
      allSports: [],
      allPlayersType: [],
      playersOnly: false,
      isLoading: false,
      isPlayerLoading: false,
      isModalOpen: false,
      isPlayerModalOpen: false,
      isInputModalOpen: false,
      isInputTeamModalOpen: false,
      isInputPlayerModalOpen: false,
      idToSend: null,
      leagueName: "",
      leagueIdToSend: null,
      searchInput: "",
      searchPlayerInput: "",
      isSubDropdownOpen: "",
      currentPage: 1,
      rowPerPage: 20,
      offset: 0,
      sportCount: null,
      PlayerRowPerPage: 6,
      playerCurrentPage: 1,
      teamListSearch: "",
      TeamRowPerPage: 20,
      TeamCurrentPage: 1,
      orgDeleteModalOpen: false,
      orgToDelete: null,
      isEditMode: false,
      playerToDelete: null,
      teamIdToLoad: null,
      playerDeleteModelOpen: false,
      leagueTeamEditMode: false,
      TeamDeleteModalOpen: false,
      TeamToDelete: null,
      isDeleteLoading: "",
      isWeightModalOpen: false,
      isInputWeightModalOpen: false,
      searchWeightInput: "",
      isRankingModalOpen: false,
      isInputRankingModalOpen: false,
      isChampionModalOpen: false,
      isInputChampionModalOpen: false,
      weightDeleteModalOpen: false,
      rankingDeleteModalOpen: false,
      championDeleteModalOpen: false,
      isWeightLoading: false,
      isRankingLoading: false,
      isChampionLoading: false,
      allWeightClassData: [],
      weightToDelete: null,
      championToDelete: null,
      isTeamLoading: false,
      championHistoryData: [],
      championRankingData: [],
      rankingToDelete: null,
      weightClassFilter: "",
      rankingRowPerPage: 6,
      rankingCurrentPage: 1,
      historyRowPerPage: 6,
      historyCurrentPage: 1,
      weightRowPerPage: 6,
      weightCurrentPage: 1,
    };
  }

  componentDidMount() {
    let { name } = this.props.match.params;
    if (
      name === "Boxing" ||
      name === "Mixed Martial Arts" ||
      name === "Tennis"
    ) {
      this.setState({ playersOnly: true });
    } else {
      this.setState({ playersOnly: false });
    }
    // this.onSubmit();
    this.fetchAllLeague();
    // this.fetchAllSportsType();
    this.fetchAllSports();
    this.fetchAllPlayersType();
    this.fetchAllWeightClass();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match !== this.props.match) {
      let { name } = this.props.match.params;
      if (
        name === "Boxing" ||
        name === "Mixed Martial Arts" ||
        name === "Tennis"
      ) {
        this.setState({ playersOnly: true });
      } else {
        this.setState({ playersOnly: false });
      }
      // this.onSubmit();
      if (this.state.offset === 0) {
        this.fetchAllLeague();
      } else {
        this.setState({ offset: 0, currentPage: 1 });
      }
      this.setState({ searchInput: "" });
    }
    if (prevState.offset !== this.state.offset) {
      this.fetchAllLeague();
    }
  }

  // async fetchAllSportsType() {
  //   const { status, data } = await axiosInstance.get(URLS.sportType);
  //   if (status === 200) {
  //     this.setState({ allSportsType: data.result });
  //   }
  // }

  fetchAllChampionHistory = async () => {
    this.setState({ isChampionLoading: true });
    try {
      const { status, data } = await axiosInstance.get(
        "sports/champian/histroy"
      );
      if (status === 200) {
        let league_champion = data?.result;
        league_champion = league_champion?.filter(
          (obj) => obj?.leagueId === this.state.leagueIdToSend
        );

        this.setState({
          championHistoryData: league_champion,
          isChampionLoading: false,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isChampionLoading: false });
    }
  };

  fetchRankingsByLeague = async () => {
    this.setState({ isRankingLoading: true });
    try {
      const { status, data } = await axiosInstance.get(
        `sports/champian/ranking?leagueId=${this.state.leagueIdToSend}&wClassId=${this.state.weightClassFilter}`
      );
      if (status === 200) {
        let data_sort = _.orderBy(data?.result, ["rank"], ["asc"]);
        this.setState({
          championRankingData: data_sort,
          isRankingLoading: false,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isRankingLoading: false });
    }
  };

  async fetchAllSports() {
    const { status, data } = await axiosInstance.get(URLS.sports);
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["sportName"], ["asc"]);
      this.setState({ allSports: data_obj });
    }
  }

  async fetchAllPlayersType() {
    const { status, data } = await axiosInstance.get(URLS.playersType);
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["name"], ["asc"]);
      this.setState({ allPlayersType: data_obj });
    }
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      teamListSearch: "",
    });
  };

  toggleOrgModal = () => {
    this.setState({
      orgDeleteModalOpen: !this.state.orgDeleteModalOpen,
      orgToDelete: null,
    });
  };

  toggleTeamModal = () => {
    this.setState({
      TeamDeleteModalOpen: !this.state.TeamDeleteModalOpen,
      TeamToDelete: null,
    });
  };

  togglePlayerDeleteModal = () => {
    this.setState({
      playerDeleteModelOpen: !this.state.playerDeleteModelOpen,
      playerToDelete: null,
    });
  };
  setOrgToDelete = (id) => () => {
    this.setState({ orgToDelete: id, orgDeleteModalOpen: true });
  };

  setTeamToDelete = (id) => () => {
    this.setState({ TeamToDelete: id, TeamDeleteModalOpen: true });
  };

  togglePlayerModal = () => {
    this.setState({
      isPlayerModalOpen: !this.state.isPlayerModalOpen,
      isModalOpen: false,
      searchPlayerInput: "",
    });
  };

  toggleWeightModal = () => {
    this.setState({
      isWeightModalOpen: !this.state.isWeightModalOpen,
      searchWeightInput: "",
      // isModalOpen: false,
    });
  };

  toggleWeightDeleteModal = () => {
    this.setState({
      weightDeleteModalOpen: !this.state.weightDeleteModalOpen,
      // orgToDelete: null,
      weightToDelete: null,
    });
  };

  toggleRankingModal = () => {
    this.setState({
      isRankingModalOpen: !this.state.isRankingModalOpen,
      // isModalOpen: false,
    });
  };

  toggleRankingDeleteModal = () => {
    this.setState({
      rankingDeleteModalOpen: !this.state.rankingDeleteModalOpen,
      // orgToDelete: null,
    });
  };

  toggleChampionModal = () => {
    this.setState({
      isChampionModalOpen: !this.state.isChampionModalOpen,
      // isModalOpen: false,
    });
  };

  toggleChampionDeleteModal = () => {
    this.setState({
      championDeleteModalOpen: !this.state.championDeleteModalOpen,
      // orgToDelete: null,
    });
  };

  toggleInputModal = () => {
    this.setState({
      isInputModalOpen: !this.state.isInputModalOpen,
    });
  };

  toggleTeamInputModal = () => {
    this.setState({
      isInputTeamModalOpen: !this.state.isInputTeamModalOpen,
    });
  };

  togglePlayerInputModal = () => {
    this.setState({
      isInputPlayerModalOpen: !this.state.isInputPlayerModalOpen,
    });
  };

  toggleWeightInputModal = () => {
    this.setState({
      isInputWeightModalOpen: !this.state.isInputWeightModalOpen,
    });
  };

  toggleRankingsInputModal = () => {
    this.setState({
      isInputRankingModalOpen: !this.state.isInputRankingModalOpen,
    });
  };

  toggleChampionInputModal = () => {
    this.setState({
      isInputChampionModalOpen: !this.state.isInputChampionModalOpen,
    });
  };

  inputModal = (id, type) => () => {
    this.setState({ isInputModalOpen: true, idToSend: id, isEditMode: type });
  };

  inputTeamModal = (id) => {
    this.setState({
      isInputTeamModalOpen: true,
      idToSend: id,
      // isModalOpen: false,
      teamListSearch: "",
    });
  };

  inputPlayerModal = (id, type) => () => {
    this.setState({
      isInputPlayerModalOpen: true,
      idToSend: id,
      // isPlayerModalOpen: false,
      isEditMode: type,
      searchPlayerInput: "",
    });
  };

  inputWeightModal = (id, type) => () => {
    this.setState({
      isInputWeightModalOpen: true,
      idToSend: id,
      isEditMode: type,
      searchWeightInput: "",
    });
  };

  inputRankingModal = (id, type) => () => {
    this.setState({
      isInputRankingModalOpen: true,
      idToSend: id,
      // isRankingModalOpen: false,
      isEditMode: type,
    });
  };

  inputChampionModal = (id, type) => () => {
    this.setState({
      isInputChampionModalOpen: true,
      idToSend: id,
      // isChampionModalOpen: false,
      isEditMode: type,
    });
  };

  showTeam = (team, name, id) => {
    let { playersOnly } = this.state;
    if (playersOnly) {
      let player_sort = _.orderBy(team, ["Player.name"], ["asc"]);
      this.setState({
        playersDetails: player_sort,
        playerCurrentPage: 1,
        isPlayerModalOpen: true,
        leagueName: name,
        leagueIdToSend: id,
      });
    } else {
      let team_sort = _.orderBy(team, ["Team.name"], ["asc"]);
      this.setState({
        teamsDetail: team_sort,
        playerCurrentPage: 1,
        isModalOpen: true,
        leagueName: name,
        leagueIdToSend: id,
      });
    }
  };

  showPlayer = async (id) => {
    this.setState({
      isPlayerModalOpen: true,
      isPlayerLoading: true,
      isModalOpen: false,
      teamIdToLoad: id,
    });

    try {
      const { status, data } = await axiosInstance.get(
        `sports/league/team/player/${id}`
      );
      if (status === 200) {
        this.setState({ playersDetails: data.result, isPlayerLoading: false });
      }
    } catch (err) {
      this.setState({ isPlayerLoading: false });
    }
  };

  fetchAllLeague = async () => {
    let { id } = this.props.match.params;
    let { rowPerPage, offset, searchInput } = this.state;
    try {
      this.setState({ isLoading: true });
      const { status, data } = await axiosInstance.get(
        `sports/league/list/admin/${id}?limit=${rowPerPage}&offset=${offset}&matchString=${searchInput}`
      );
      if (status === 200) {
        this.setState({
          sportsDetails: data.result,
          isLoading: false,
          sportCount: data.count,
        });
      }
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  afterChangeTeamRefresh = async () => {
    let { id } = this.props.match.params;
    let { rowPerPage, offset, searchInput, leagueIdToSend } = this.state;
    try {
      this.setState({ isTeamLoading: true });
      const { status, data } = await axiosInstance.get(
        `sports/league/list/admin/${id}?limit=${rowPerPage}&offset=${offset}&matchString=${searchInput}`
      );
      if (status === 200) {
        let data_array = data?.result;
        data_array = data_array?.find((obj) => obj?.id === leagueIdToSend);

        this.setState({
          sportsDetails: data.result,
          teamsDetail: data_array?.team,
          isTeamLoading: false,
        });
      }
    } catch (err) {
      this.setState({ isTeamLoading: false });
    }
  };

  afterChangePlayerRefresh = async () => {
    let { id } = this.props.match.params;
    let { rowPerPage, offset, searchInput, leagueIdToSend } = this.state;
    try {
      this.setState({ isPlayerLoading: true });
      const { status, data } = await axiosInstance.get(
        `sports/league/list/admin/${id}?limit=${rowPerPage}&offset=${offset}&matchString=${searchInput}`
      );
      if (status === 200) {
        let data_array = data?.result;
        data_array = data_array?.find((obj) => obj?.id === leagueIdToSend);
        this.setState({
          sportsDetails: data.result,
          playersDetails: data_array?.players,
          isPlayerLoading: false,
        });
      }
    } catch (err) {
      this.setState({ isPlayerLoading: false });
    }
  };

  afterChangeRefresh = () => {
    this.fetchAllLeague();
  };

  // onSubmit = async () => {
  //   let { name, id } = this.props.match.params;
  //   this.setState({ isLoading: true });
  //   let { selectedDate } = this.state;
  //   let params = {
  //     date: moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
  //     endDate: moment(selectedDate)
  //       .utc()
  //       .add(1, "days")
  //       .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
  //     //date: "2021-04-14T00:00:00.000Z",
  //     //endDate: "2021-04-16T00:00:00.000Z",
  //   };
  //   try {
  //     // const { status, data } = await axios.post(
  //     //   "https://d88c1c8f4118.ngrok.io/private/eventDetails/4",
  //     //   params
  //     // );
  //     const { status, data } = await axiosInstance.post(
  //       `/private/eventDetails/${id}`,
  //       params
  //     );
  //     if (status === 200) {
  //       this.setState({ sportsDetails: data.events, isLoading: false });
  //     }
  //   } catch (err) {
  //     this.setState({ isLoading: false });
  //   }
  // };

  playerToDelete = (id) => () => {
    this.setState({ playerToDelete: id, playerDeleteModelOpen: true });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  handlePlayerPaginationClick = (event, page) => {
    this.setState({
      playerCurrentPage: Number(page),
    });
  };

  handleTeamPaginationClick = (event, page) => {
    this.setState({
      TeamCurrentPage: Number(page),
    });
  };

  handleRankingPaginationClick = (event, page) => {
    this.setState({
      rankingCurrentPage: Number(page),
    });
  };

  handleHistoryPaginationClick = (event, page) => {
    this.setState({
      historyCurrentPage: Number(page),
    });
  };

  handleWeightPaginationClick = (event, page) => {
    this.setState({
      weightCurrentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let {
      currentPage,
      //  rowPerPage,
      offset,
    } = this.state;
    // if (navDirection === "prev") {
    //   if (currentPage > 1) {
    //     this.setState({ currentPage: currentPage - 1 });
    //   }
    // } else {
    //   if (currentPage < sportsDetails.length / rowPerPage)
    //     this.setState({ currentPage: currentPage + 1 });
    // }

    if (navDirection === "prev") {
      if (offset >= 20) {
        this.setState({ offset: offset - 20, currentPage: currentPage - 1 });
      }
    } else {
      this.setState({ offset: offset + 20, currentPage: currentPage + 1 });
    }
  };

  handlePaginationClick = (event, page) => {
    let { rowPerPage } = this.state;
    this.setState({
      currentPage: Number(page),
      offset: (Number(page) - 1) * rowPerPage,
    });
  };

  handlePlayerPaginationButtonClick = (navDirection) => {
    let { playerCurrentPage, PlayerRowPerPage, playersDetails } = this.state;
    if (navDirection === "prev") {
      if (playerCurrentPage > 1) {
        this.setState({ playerCurrentPage: playerCurrentPage - 1 });
      }
    } else {
      if (playerCurrentPage < playersDetails.length / PlayerRowPerPage)
        this.setState({ playerCurrentPage: playerCurrentPage + 1 });
    }
  };

  handleTeamPaginationButtonClick = (navDirection) => {
    let { teamsDetail, TeamRowPerPage, TeamCurrentPage } = this.state;
    if (navDirection === "prev") {
      if (TeamCurrentPage > 1) {
        this.setState({ TeamCurrentPage: TeamCurrentPage - 1 });
      }
    } else {
      if (TeamCurrentPage < teamsDetail.length / TeamRowPerPage)
        this.setState({ TeamCurrentPage: TeamCurrentPage + 1 });
    }
  };

  handleRankingPaginationButtonClick = (navDirection) => {
    let { rankingCurrentPage, rankingRowPerPage, championRankingData } =
      this.state;
    if (navDirection === "prev") {
      if (rankingCurrentPage > 1) {
        this.setState({ rankingCurrentPage: rankingCurrentPage - 1 });
      }
    } else {
      if (rankingCurrentPage < championRankingData.length / rankingRowPerPage)
        this.setState({ rankingCurrentPage: rankingCurrentPage + 1 });
    }
  };

  handleHistoryPaginationButtonClick = (navDirection) => {
    let { historyCurrentPage, historyRowPerPage, championHistoryData } =
      this.state;
    if (navDirection === "prev") {
      if (historyCurrentPage > 1) {
        this.setState({ historyCurrentPage: historyCurrentPage - 1 });
      }
    } else {
      if (historyCurrentPage < championHistoryData.length / historyRowPerPage)
        this.setState({ historyCurrentPage: historyCurrentPage + 1 });
    }
  };

  handleWeightPaginationButtonClick = (navDirection) => {
    let { weightCurrentPage, weightRowPerPage, allWeightClassData } =
      this.state;
    if (navDirection === "prev") {
      if (weightCurrentPage > 1) {
        this.setState({ weightCurrentPage: weightCurrentPage - 1 });
      }
    } else {
      if (weightCurrentPage < allWeightClassData.length / weightRowPerPage)
        this.setState({ weightCurrentPage: weightCurrentPage + 1 });
    }
  };

  handleSubDropdown = (id) => {
    if (String(this.state.isSubDropdownOpen) === String(id)) {
      this.setState({ isSubDropdownOpen: "" });
    } else {
      this.setState({ isSubDropdownOpen: id });
    }
  };

  handleLeagueDelete = async (id) => {
    this.setState({
      isDeleteLoading: "leagueDelete",
      orgDeleteModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `sports/league/${this.state.orgToDelete}`
      );
      if (status === 200) {
        this.setState({ orgToDelete: null, isDeleteLoading: "" });
        this.afterChangeRefresh();
      }
    } catch (err) {}
  };

  handleTeamDelete = async (id) => {
    this.setState({
      isDeleteLoading: "teamDelete",
      TeamDeleteModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `teams/team/${this.state.TeamToDelete}/${this.state.leagueIdToSend}`
      );
      if (status === 200) {
        this.setState({
          TeamToDelete: null,
          isDeleteLoading: "",
          // isModalOpen: false,
        });
        this.afterChangeTeamRefresh();
      }
    } catch (err) {}
  };

  handlePlayerDelete = async (id) => {
    this.setState({
      isDeleteLoading: "playerDelete",
      playerDeleteModelOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `sports/league/team/player/${this.state.playerToDelete}/${this.state.leagueIdToSend}`
      );
      if (status === 200) {
        this.setState({
          playerToDelete: null,
          isDeleteLoading: "",
          // isPlayerModalOpen: false,
        });
        this.afterChangePlayerRefresh();
      }
    } catch (err) {}
  };

  fetchAllWeightClass = async () => {
    this.setState({ isWeightLoading: true });
    try {
      const { status, data } = await axiosInstance.get("sports/weightClass");
      if (status === 200) {
        let data_obj = _.orderBy(data?.result, ["name"], ["asc"]);
        this.setState({
          allWeightClassData: data_obj,
          isWeightLoading: false,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isWeightLoading: false });
    }
  };

  handleWeightDelete = async () => {
    this.setState({
      isDeleteLoading: "weightDelete",
      weightDeleteModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `sports/weightClass/${this.state.weightToDelete}`
      );
      if (status === 200) {
        this.setState({
          weightToDelete: null,
          isDeleteLoading: "",
        });
        this.fetchAllWeightClass();
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isDeleteLoading: "" });
    }
  };

  handleChampionDelete = async () => {
    this.setState({
      isDeleteLoading: "championDelete",
      championDeleteModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `sports/champian/histroy/${this.state.championToDelete}`
      );
      if (status === 200) {
        this.setState({
          championToDelete: null,
          isDeleteLoading: "",
        });
        this.fetchAllChampionHistory();
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isDeleteLoading: "" });
    }
  };

  handleRankingDelete = async () => {
    this.setState({
      isDeleteLoading: "rankingDelete",
      rankingDeleteModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `sports/champian/ranking/${this.state.rankingToDelete}`
      );
      if (status === 200) {
        this.setState({ rankingToDelete: null, isDeleteLoading: "" });
        this.fetchRankingsByLeague();
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isDeleteLoading: "" });
    }
  };

  render() {
    const { match } = this.props;
    let {
      // selectedDate,
      playersOnly,
      sportsDetails,
      teamsDetail,
      playersDetails,
      isLoading,
      isPlayerLoading,
      isInputModalOpen,
      isInputTeamModalOpen,
      isInputPlayerModalOpen,
      rowPerPage,
      currentPage,
      isModalOpen,
      isPlayerModalOpen,
      searchInput,
      searchPlayerInput,
      // isSubDropdownOpen,
      allSportsType,
      allSports,
      leagueName,
      PlayerRowPerPage,
      playerCurrentPage,
      teamListSearch,
      TeamRowPerPage,
      TeamCurrentPage,
      offset,
      sportCount,
      orgDeleteModalOpen,
      isEditMode,
      playerDeleteModelOpen,
      leagueTeamEditMode,
      TeamDeleteModalOpen,
      isDeleteLoading,
      isWeightModalOpen,
      searchWeightInput,
      isInputWeightModalOpen,
      isRankingModalOpen,
      isInputRankingModalOpen,
      isChampionModalOpen,
      isInputChampionModalOpen,
      weightDeleteModalOpen,
      rankingDeleteModalOpen,
      championDeleteModalOpen,
      isWeightLoading,
      isRankingLoading,
      isChampionLoading,
      leagueIdToSend,
      allWeightClassData,
      idToSend,
      isTeamLoading,
      championHistoryData,
      championRankingData,
      weightClassFilter,
      rankingRowPerPage,
      rankingCurrentPage,
      historyRowPerPage,
      historyCurrentPage,
      weightCurrentPage,
      weightRowPerPage,
    } = this.state;
    const pageNumbers = [];
    const playerPageNumbers = [];
    const teamListPageNumber = [];
    const rankingPageNumber = [];
    const historyPageNumber = [];
    const weightPageNumber = [];

    // searchInput !== '' &&
    //   (sportsDetails = sportsDetails?.filter((obj) =>
    //     obj?.leagueName
    //       ?.toString()
    //       .toLowerCase()
    //       .includes(searchInput.toString().toLowerCase()),
    //   ))

    teamListSearch !== "" &&
      (teamsDetail = teamsDetail?.filter((obj) =>
        obj?.Team?.name
          ?.toString()
          .toLowerCase()
          .includes(teamListSearch.toString().toLowerCase())
      ));

    let championRankArray = [];
    championRankingData = championRankingData?.map((obj) =>
      obj?.rankings?.map((item) => championRankArray.push(item))
    );

    searchPlayerInput !== "" &&
      (playersDetails = playersDetails?.filter((obj) =>
        obj?.Player?.name
          ?.toString()
          .toLowerCase()
          .includes(searchPlayerInput.toString().toLowerCase())
      ));

    searchWeightInput !== "" &&
      (allWeightClassData = allWeightClassData?.filter(
        (obj) =>
          obj?.name
            ?.toString()
            .toLowerCase()
            .includes(searchWeightInput.toString().toLowerCase()) ||
          obj?.inPound
            ?.toString()
            .toLowerCase()
            .includes(searchWeightInput.toString().toLowerCase()) ||
          obj?.inKg
            ?.toString()
            .toLowerCase()
            .includes(searchWeightInput.toString().toLowerCase())
      ));

    // let currentPageRow = sportsDetails;
    let CurrentPlayerPageRow = playersDetails;
    let CurrentteamListPageRow = teamsDetail;
    let CurrentRankingPageRow = championRankArray;
    let CurrentHistoryPageRow = championHistoryData;
    let CurrentWeightPageRow = allWeightClassData;

    if (sportCount > 0) {
      // const indexOfLastTodo = currentPage * rowPerPage;
      // const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      // currentPageRow = sportsDetails.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(sportCount / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }

    if (playersDetails?.length > 0) {
      const indexOfLastTodo = playerCurrentPage * PlayerRowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - PlayerRowPerPage;
      CurrentPlayerPageRow = playersDetails.slice(
        indexOfFirstTodo,
        indexOfLastTodo
      );

      for (
        let i = 1;
        i <= Math.ceil(playersDetails.length / PlayerRowPerPage);
        i++
      ) {
        playerPageNumbers.push(i);
      }
    }

    if (teamsDetail?.length > 0) {
      const indexOfLastTodo = TeamCurrentPage * TeamRowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - TeamRowPerPage;
      CurrentteamListPageRow = teamsDetail.slice(
        indexOfFirstTodo,
        indexOfLastTodo
      );

      for (
        let i = 1;
        i <= Math.ceil(teamsDetail.length / TeamRowPerPage);
        i++
      ) {
        teamListPageNumber.push(i);
      }
    }

    if (championRankArray?.length > 0) {
      const indexOfLastTodo = rankingCurrentPage * rankingRowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - rankingRowPerPage;
      CurrentRankingPageRow = championRankArray.slice(
        indexOfFirstTodo,
        indexOfLastTodo
      );

      for (
        let i = 1;
        i <= Math.ceil(championRankArray.length / rankingRowPerPage);
        i++
      ) {
        rankingPageNumber.push(i);
      }
    }

    if (championHistoryData?.length > 0) {
      const indexOfLastTodo = historyCurrentPage * historyRowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - historyRowPerPage;
      CurrentHistoryPageRow = championHistoryData.slice(
        indexOfFirstTodo,
        indexOfLastTodo
      );

      for (
        let i = 1;
        i <= Math.ceil(championHistoryData.length / historyRowPerPage);
        i++
      ) {
        historyPageNumber.push(i);
      }
    }

    if (allWeightClassData?.length > 0) {
      const indexOfLastTodo = weightCurrentPage * weightRowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - weightRowPerPage;
      CurrentWeightPageRow = allWeightClassData.slice(
        indexOfFirstTodo,
        indexOfLastTodo
      );

      for (
        let i = 1;
        i <= Math.ceil(allWeightClassData.length / weightRowPerPage);
        i++
      ) {
        weightPageNumber.push(i);
      }
    }

    return (
      <>
        <Grid container className="page-content adminLogin sports_details">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              {isDeleteLoading === "leagueDelete" && (
                <div class="admin-delete-loader">
                  <Loader />
                </div>
              )}
              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={6}>
                  <h3 className="text-left">{match.params.name} Details</h3>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    variant="outlined"
                    color="primary"
                    size="small"
                    placeholder="Search"
                    value={searchInput}
                    onChange={(e) =>
                      this.setState({
                        ...this.state.searchInput,
                        searchInput: e.target.value,
                      })
                    }
                  />
                  <div>
                    <Button
                      variant="contained"
                      onClick={() => {
                        this.fetchAllLeague();
                      }}
                      style={{
                        backgroundColor: "#00833e",
                        color: "#fff",
                        marginRight: 10,
                      }}
                    >
                      Search
                    </Button>
                    <Button
                      onClick={this.inputModal(null, false)}
                      className="mr10 cursor admin-btn-green"
                    >
                      {match?.params?.name === "Mixed Martial Arts" ||
                      match?.params?.name === "Boxing"
                        ? "Add Organisation"
                        : "Add League"}
                    </Button>
                  </div>
                </Grid>
                {/* <Grid item xs={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify='space-around'>
                      <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='MM/dd/yyyy'
                        margin='normal'
                        id='date-picker-inline'
                        value={selectedDate}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent
                    className='addButton admin-btn-green btn-submit'
                    color='primary'
                    value='Submit'
                    onClick={this.onSubmit}
                  />
                </Grid> */}
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && sportsDetails.length === 0 && (
                <p>No Data Available</p>
              )}
              {!isLoading && sportsDetails.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      className="listTable api-provider-listTable"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>DID</TableCell>
                          <TableCell>
                            {match?.params?.name === "Mixed Martial Arts" ||
                            match?.params?.name === "Boxing"
                              ? "Organisation Name"
                              : "League Name"}
                          </TableCell>
                          <TableCell>Variation</TableCell>
                          <TableCell>Show Detail</TableCell>
                          {(match?.params?.name === "Mixed Martial Arts" ||
                            match?.params?.name === "Boxing") && (
                            <>
                              <TableCell>Weight Class</TableCell>
                              <TableCell>Rankings</TableCell>
                              <TableCell>Championship History</TableCell>
                            </>
                          )}
                          <TableCell>Edit</TableCell>
                          <TableCell>Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sportsDetails?.map((sportsDetails, i) => (
                          <>
                            <TableRow>
                              <TableCell>{sportsDetails?.id}</TableCell>
                              <TableCell
                              // onClick={() =>
                              //   this.handleSubDropdown(sportsDetails?.id)
                              // }
                              >
                                {sportsDetails?.leagueName}
                              </TableCell>
                              <TableCell>{sportsDetails?.variation}</TableCell>
                              <TableCell>
                                {/* <MoreHorizIcon
                                  onClick={() =>
                                    playersOnly
                                      ? this.showTeam(
                                          sportsDetails?.players,
                                          sportsDetails?.leagueName
                                        )
                                      : this.showTeam(
                                          sportsDetails?.team,
                                          sportsDetails?.leagueName
                                        )
                                  }
                                  color='primary'
                                  className='mr10 cursor iconBtn admin-btn-green'
                                /> */}
                                <Button
                                  style={{
                                    fontSize: "9px",
                                    backgroundColor: "#ff6b00",
                                    color: "#fff",
                                    fontWeight: "400",
                                    textTransform: "none",
                                    padding: "5px 0",
                                  }}
                                  onClick={() =>
                                    playersOnly
                                      ? this.showTeam(
                                          sportsDetails?.players,
                                          sportsDetails?.leagueName,
                                          sportsDetails?.id
                                        )
                                      : this.showTeam(
                                          sportsDetails?.team,
                                          sportsDetails?.leagueName,
                                          sportsDetails?.id
                                        )
                                  }
                                >
                                  Show Detail
                                </Button>
                              </TableCell>
                              {(match?.params?.name === "Mixed Martial Arts" ||
                                match?.params?.name === "Boxing") && (
                                <>
                                  <TableCell>
                                    <Button
                                      style={{
                                        fontSize: "9px",
                                        backgroundColor: "#ff6b00",
                                        color: "#fff",
                                        fontWeight: "400",
                                        textTransform: "none",
                                        padding: "5px 0",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          isWeightModalOpen: true,
                                          leagueIdToSend: sportsDetails?.id,
                                          weightCurrentPage: 1,
                                        });
                                        this.fetchAllWeightClass();
                                      }}
                                    >
                                      Weight Class
                                    </Button>
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      style={{
                                        fontSize: "9px",
                                        backgroundColor: "#ff6b00",
                                        color: "#fff",
                                        fontWeight: "400",
                                        textTransform: "none",
                                        padding: "5px 0",
                                      }}
                                      onClick={() => {
                                        this.setState(
                                          {
                                            isRankingModalOpen: true,
                                            rankingCurrentPage: 1,
                                            leagueIdToSend: sportsDetails?.id,
                                            playersDetails:
                                              sportsDetails?.players,
                                          },
                                          () => this.fetchRankingsByLeague()
                                        );
                                      }}
                                    >
                                      Rankings
                                    </Button>
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      style={{
                                        fontSize: "9px",
                                        backgroundColor: "#ff6b00",
                                        color: "#fff",
                                        fontWeight: "400",
                                        textTransform: "none",
                                        padding: "5px 0",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          isChampionModalOpen: true,
                                          leagueIdToSend: sportsDetails?.id,
                                          playersDetails:
                                            sportsDetails?.players,
                                        });
                                        this.fetchAllChampionHistory();
                                      }}
                                    >
                                      History
                                    </Button>
                                  </TableCell>
                                </>
                              )}
                              <TableCell>
                                <EditIcon
                                  onClick={this.inputModal(
                                    sportsDetails?.id,
                                    true
                                  )}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-green"
                                />
                              </TableCell>
                              <TableCell>
                                <DeleteOutlineIcon
                                  onClick={this.setOrgToDelete(
                                    sportsDetails?.id
                                  )}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-orange"
                                />
                              </TableCell>
                            </TableRow>
                            {/* <Collapse
                              in={isSubDropdownOpen == sportsDetails?.id}
                              timeout='auto'
                              unmountOnExit
                            >
                              <TableRow>
                                <p>{sportsDetails?.leagueName}</p>
                              </TableRow>
                            </Collapse> */}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="tablePagination">
                    <button
                      className={
                        offset >= 20
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={offset >= 20 ? false : true}
                      // disabled={
                      //   meetingsDetails.length / rowPerPage > 1 ? false : true
                      // }
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={sportCount > 0 ? false : true}
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size="small"
                    />
                    <button
                      className={
                        rowPerPage + offset < sportCount
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={rowPerPage + offset < sportCount ? false : true}
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </>
              )}
            </Paper>

            <ShowModal
              isModalOpen={orgDeleteModalOpen}
              onClose={this.toggleOrgModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleLeagueDelete}
              onCancel={this.toggleOrgModal}
            />
            <ShowModal
              isModalOpen={playerDeleteModelOpen}
              onClose={this.togglePlayerDeleteModal}
              Content="Are you sure you want to delete player?"
              onOkayLabel="Yes"
              onOkay={this.handlePlayerDelete}
              onCancel={this.togglePlayerDeleteModal}
            />
            <Modal
              className="modal modal-input"
              open={isModalOpen}
              onClose={this.toggleModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <Grid container>
                  <Grid item xs={7}>
                    <Typography variant="h6" style={{ marginTop: 0 }}>
                      {leagueName + " Team List "}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <div>
                      <input
                        type="text"
                        placeholder="search"
                        value={teamListSearch}
                        onChange={(e) => {
                          this.setState({ teamListSearch: e.target.value });
                        }}
                        style={{
                          fontSize: "16px",
                          borderRadius: "3px",
                          minHeight: "30px",
                          border: "1px solid #ddd",
                          marginRight: "15px",
                          marginLeft: "15px",
                        }}
                      />
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#00833e",
                          color: "#fff",
                        }}
                        onClick={() => {
                          this.inputTeamModal();
                          this.setState({ leagueTeamEditMode: false });
                        }}
                      >
                        Add Team
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleModal}
                />
                {isDeleteLoading === "teamDelete" && (
                  <div class="admin-delete-modal-loader">
                    <Loader />
                  </div>
                )}
                {isTeamLoading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Loader />
                  </div>
                )}
                {!isTeamLoading &&
                  (teamsDetail?.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table
                        className="listTable api-provider-listTable"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: "30%" }}>
                              Team Name
                            </TableCell>
                            <TableCell style={{ width: "30%" }}>
                              Team Variations
                            </TableCell>
                            <TableCell
                              style={{ width: "20%", textAlign: "center" }}
                            >
                              Image
                            </TableCell>
                            <TableCell style={{ width: "20%" }}>Edit</TableCell>
                            <TableCell style={{ width: "20%" }}>
                              Delete
                            </TableCell>
                            {match?.params?.name !== "Rugby League" &&
                              match?.params?.name !== "Australian Rules" &&
                              match?.params?.name !== "Soccer" &&
                              match?.params?.name !== "Cricket" &&
                              match?.params?.name !== "Rugby Union" && (
                                <TableCell>Players</TableCell>
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {teamsDetail?.map((team, i) => (
                            <TableRow>
                              <TableCell>{team?.Team?.name}</TableCell>
                              <TableCell>{team?.Team?.variation}</TableCell>
                              <TableCell style={{ width: "20%" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {team?.Team?.image !== null ? (
                                    <img
                                      style={{ height: 60, width: 60 }}
                                      src={config.baseUrl + team?.Team?.image}
                                      alt={team?.Team?.name}
                                    />
                                  ) : (
                                    <div className="imagePlaceholder">
                                      No Img
                                    </div>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>
                                <EditIcon
                                  onClick={() => {
                                    this.inputTeamModal(team?.teamId);
                                    this.setState({
                                      leagueTeamEditMode: true,
                                    });
                                  }}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-green"
                                />
                              </TableCell>
                              <TableCell>
                                <DeleteOutlineIcon
                                  onClick={this.setTeamToDelete(team?.teamId)}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-orange"
                                />
                              </TableCell>
                              {match?.params?.name !== "Rugby League" &&
                                match?.params?.name !== "Australian Rules" &&
                                match?.params?.name !== "Soccer" &&
                                match?.params?.name !== "Cricket" &&
                                match?.params?.name !== "Rugby Union" && (
                                  <TableCell>
                                    <PersonIcon
                                      onClick={() =>
                                        this.showPlayer(team?.teamId)
                                      }
                                      color="primary"
                                      className="mr10 cursor iconBtn admin-btn-green"
                                    />
                                  </TableCell>
                                )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p>No Data Available</p>
                    </div>
                  ))}

                <div
                  className="tablePagination"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button
                    className={
                      teamsDetail.length / TeamRowPerPage > 1
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={
                      teamsDetail.length / TeamRowPerPage > 1 ? false : true
                    }
                    onClick={() => this.handleTeamPaginationButtonClick("prev")}
                  >
                    <ReactSVG src={arrowLeft} />
                  </button>
                  <Pagination
                    hideNextButton
                    hidePrevButton
                    disabled={
                      teamsDetail.length / TeamRowPerPage > 1 ? false : true
                    }
                    page={TeamCurrentPage}
                    onChange={this.handleTeamPaginationClick}
                    count={teamListPageNumber[teamListPageNumber?.length - 1]}
                    siblingCount={2}
                    boundaryCount={1}
                    size="small"
                  />
                  <button
                    className={
                      teamsDetail.length / TeamRowPerPage > 1
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={
                      teamsDetail.length / TeamRowPerPage > 1 ? false : true
                    }
                    onClick={() => this.handleTeamPaginationButtonClick("next")}
                  >
                    <ReactSVG src={arrowRight} />
                  </button>
                </div>
              </div>
            </Modal>

            <ShowModal
              isModalOpen={TeamDeleteModalOpen}
              onClose={this.toggleTeamModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleTeamDelete}
              onCancel={this.toggleTeamModal}
            />

            <Modal
              className="modal modal-input"
              open={isPlayerModalOpen}
              onClose={this.togglePlayerModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography style={{ marginTop: 0 }} variant="h4">
                    {match?.params?.name === "Mixed Martial Arts" ||
                    match?.params?.name === "Boxing"
                      ? "Fighters List"
                      : "Players List"}
                  </Typography>
                  <Box>
                    <TextField
                      size="small"
                      placeholder="Search"
                      variant="outlined"
                      value={searchPlayerInput}
                      onChange={(e) =>
                        this.setState({
                          ...this.state.searchPlayerInput,
                          searchPlayerInput: e.target.value,
                          playerCurrentPage: 1,
                        })
                      }
                      style={{ marginRight: 20 }}
                    />
                    <Button
                      onClick={this.inputPlayerModal(null, false)}
                      className="mr10 cursor admin-btn-green"
                      style={{ marginRight: "15px" }}
                    >
                      {match?.params?.name === "Mixed Martial Arts" ||
                      match?.params?.name === "Boxing"
                        ? "Add Fighters"
                        : "Add Players"}
                    </Button>
                    <CancelIcon
                      className="admin-close-icon"
                      onClick={this.togglePlayerModal}
                    />
                  </Box>
                </Box>

                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.togglePlayerModal}
                />
                {isDeleteLoading === "playerDelete" && (
                  <div class="admin-delete-modal-loader">
                    <Loader />
                  </div>
                )}
                {isPlayerLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
                {!isPlayerLoading && (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        className="listTable api-provider-listTable"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {match?.params?.name === "Mixed Martial Arts" ||
                              match?.params?.name === "Boxing"
                                ? "Fighter Name"
                                : "Player Name"}
                            </TableCell>
                            <TableCell>Weight</TableCell>
                            <TableCell>Variation</TableCell>
                            <TableCell
                              style={{ width: "20%", textAlign: "center" }}
                            >
                              Image
                            </TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {CurrentPlayerPageRow?.map((player, i) => {
                            let data = [];
                            let push = player?.Player?.weightClasses.map(
                              (itemP) => {
                                data.push(itemP?.weightClass?.name);
                              }
                            );

                            return (
                              <TableRow>
                                <TableCell>{player?.Player?.name}</TableCell>
                                <TableCell>{data.join(", ")}</TableCell>
                                <TableCell>
                                  {player?.Player?.variation}
                                </TableCell>
                                <TableCell style={{ width: "20%" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {player?.Player?.image !== null ? (
                                      <img
                                        style={{ height: 60, width: 60 }}
                                        src={
                                          config.baseUrl + player?.Player?.image
                                        }
                                        alt={player?.Player?.name}
                                      />
                                    ) : (
                                      <div className="imagePlaceholder">
                                        No Img
                                      </div>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <EditIcon
                                    onClick={this.inputPlayerModal(
                                      player?.Player?.id,
                                      true
                                    )}
                                    color="primary"
                                    className="mr10 cursor iconBtn admin-btn-green"
                                  />
                                </TableCell>
                                <TableCell>
                                  <DeleteOutlineIcon
                                    onClick={this.playerToDelete(
                                      player?.Player?.id
                                    )}
                                    color="primary"
                                    className="mr10 cursor iconBtn admin-btn-orange"
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div
                      className="tablePagination"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <button
                        className={
                          playersDetails.length / PlayerRowPerPage > 1
                            ? "btn-navigation"
                            : "btn-navigation-disabled"
                        }
                        disabled={
                          playersDetails.length / PlayerRowPerPage > 1
                            ? false
                            : true
                        }
                        onClick={() =>
                          this.handlePlayerPaginationButtonClick("prev")
                        }
                      >
                        <ReactSVG src={arrowLeft} />
                      </button>
                      <Pagination
                        hideNextButton
                        hidePrevButton
                        disabled={
                          playersDetails.length / PlayerRowPerPage > 1
                            ? false
                            : true
                        }
                        page={playerCurrentPage}
                        onChange={this.handlePlayerPaginationClick}
                        count={playerPageNumbers[playerPageNumbers?.length - 1]}
                        siblingCount={2}
                        boundaryCount={1}
                        size="small"
                      />
                      <button
                        className={
                          playersDetails.length / PlayerRowPerPage > 1
                            ? "btn-navigation"
                            : "btn-navigation-disabled"
                        }
                        disabled={
                          playersDetails.length / PlayerRowPerPage > 1
                            ? false
                            : true
                        }
                        onClick={() =>
                          this.handlePlayerPaginationButtonClick("next")
                        }
                      >
                        <ReactSVG src={arrowRight} />
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isInputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {match?.params?.name === "Mixed Martial Arts" ||
                  match?.params?.name === "Boxing"
                    ? isEditMode
                      ? "Edit Organisation"
                      : "Add Organisation"
                    : isEditMode
                    ? "Edit League"
                    : "Add League"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />
                <CreateLeague
                  inputModal={this.toggleInputModal}
                  id={this.state.idToSend}
                  isEditMode={isEditMode}
                  fetchAllLeagues={this.afterChangeRefresh}
                  allSports={allSports}
                  allSportsType={allSportsType}
                  isMainForm={false}
                  isLeague={true}
                  sportId={match.params.id}
                  nameOfSport={match?.params?.name}
                />
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isWeightModalOpen}
              onClose={this.toggleWeightModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography style={{ marginTop: 0 }} variant="h4">
                    Weight Class
                  </Typography>
                  <Box>
                    <TextField
                      size="small"
                      placeholder="Search"
                      variant="outlined"
                      value={searchWeightInput}
                      onChange={(e) =>
                        this.setState({
                          ...this.state.searchWeightInput,
                          searchWeightInput: e.target.value,
                          weightCurrentPage: 1,
                        })
                      }
                      style={{ marginRight: 20 }}
                    />
                    <Button
                      onClick={this.inputWeightModal(null, false)}
                      className="mr10 cursor admin-btn-green"
                      style={{ marginRight: "15px" }}
                    >
                      Add
                    </Button>
                    <CancelIcon
                      className="admin-close-icon"
                      onClick={this.toggleWeightModal}
                    />
                  </Box>
                </Box>

                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleWeightModal}
                />
                {isDeleteLoading === "weightDelete" && (
                  <div class="admin-delete-modal-loader">
                    <Loader />
                  </div>
                )}
                {isWeightLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
                {!isWeightLoading &&
                  (allWeightClassData?.length > 0 ? (
                    <>
                      <TableContainer component={Paper}>
                        <Table
                          className="listTable api-provider-listTable"
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>DID</TableCell>
                              <TableCell>Weight Class</TableCell>
                              <TableCell>Pounds</TableCell>
                              <TableCell>Kgs</TableCell>
                              <TableCell>Edit</TableCell>
                              <TableCell>Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {CurrentWeightPageRow?.map((name, i) => (
                              <TableRow>
                                <TableCell>{name?.id}</TableCell>
                                <TableCell>{name?.name}</TableCell>
                                <TableCell>{name?.inPound}</TableCell>
                                <TableCell>{name?.inKg}</TableCell>
                                <TableCell>
                                  <EditIcon
                                    onClick={this.inputWeightModal(
                                      name?.id,
                                      true
                                    )}
                                    color="primary"
                                    className="mr10 cursor iconBtn admin-btn-green"
                                  />
                                </TableCell>
                                <TableCell>
                                  <DeleteOutlineIcon
                                    onClick={() =>
                                      this.setState({
                                        weightDeleteModalOpen: true,
                                        weightToDelete: name?.id,
                                      })
                                    }
                                    color="primary"
                                    className="mr10 cursor iconBtn admin-btn-orange"
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div
                        className="tablePagination"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          className={
                            weightCurrentPage > 1
                              ? "btn-navigation"
                              : "btn-navigation-disabled"
                          }
                          disabled={weightCurrentPage > 1 ? false : true}
                          onClick={() =>
                            this.handleWeightPaginationButtonClick("prev")
                          }
                        >
                          <ReactSVG src={arrowLeft} />
                        </button>
                        <Pagination
                          hideNextButton
                          hidePrevButton
                          disabled={
                            allWeightClassData.length / weightRowPerPage > 1
                              ? false
                              : true
                          }
                          page={weightCurrentPage}
                          onChange={this.handleRankingPaginationClick}
                          count={weightPageNumber[weightPageNumber?.length - 1]}
                          siblingCount={2}
                          boundaryCount={1}
                          size="small"
                        />
                        <button
                          className={
                            Math.ceil(
                              allWeightClassData.length / weightRowPerPage
                            ) > weightCurrentPage
                              ? "btn-navigation"
                              : "btn-navigation-disabled"
                          }
                          disabled={
                            Math.ceil(
                              allWeightClassData.length / weightRowPerPage
                            ) > weightCurrentPage
                              ? false
                              : true
                          }
                          onClick={() =>
                            this.handleWeightPaginationButtonClick("next")
                          }
                        >
                          <ReactSVG src={arrowRight} />
                        </button>
                      </div>
                    </>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p>No Data Available</p>
                    </div>
                  ))}
              </div>
            </Modal>

            <ShowModal
              isModalOpen={weightDeleteModalOpen}
              onClose={this.toggleWeightDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleWeightDelete}
              onCancel={this.toggleWeightDeleteModal}
            />

            <Modal
              className="modal modal-input"
              open={isRankingModalOpen}
              onClose={this.toggleRankingModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography style={{ marginTop: 0 }} variant="h4">
                    Rankings
                  </Typography>
                  <Box style={{ display: "flex", padding: "10px 0px" }}>
                    <select
                      className="select-box-manual"
                      name="weightClassFilter"
                      onChange={(e) =>
                        this.setState(
                          {
                            weightClassFilter: e.target.value,
                            rankingCurrentPage: 1,
                          },
                          () => this.fetchRankingsByLeague()
                        )
                      }
                      value={weightClassFilter}
                      style={{ margin: "0px 10px" }}
                    >
                      <option value="">No weight class selected</option>
                      {allWeightClassData?.length > 0 &&
                        allWeightClassData?.map((obj, i) => (
                          <option value={obj?.id} key={i}>
                            {obj?.name}
                          </option>
                        ))}
                    </select>
                    <Button
                      onClick={this.inputRankingModal(null, false)}
                      className="mr10 cursor admin-btn-green"
                      style={{ marginRight: "15px" }}
                    >
                      Add Rank
                    </Button>
                    <CancelIcon
                      className="admin-close-icon"
                      onClick={this.toggleRankingModal}
                    />
                  </Box>
                </Box>

                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleRankingModal}
                />
                {isDeleteLoading === "rankingDelete" && (
                  <div class="admin-delete-modal-loader">
                    <Loader />
                  </div>
                )}
                {isRankingLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
                {!isRankingLoading &&
                  (championRankArray?.length > 0 ? (
                    <>
                      <TableContainer component={Paper}>
                        <Table
                          className="listTable api-provider-listTable"
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>DID</TableCell>
                              <TableCell>Players Name</TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>Weight Class</TableCell>
                              <TableCell>Rank</TableCell>
                              <TableCell>Edit</TableCell>
                              <TableCell>Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {CurrentRankingPageRow?.map((player, i) => {
                              let fighterName = playersDetails.find(
                                (obj) => obj?.Player?.id === player?.fighterId
                              );
                              let weightClass = allWeightClassData?.find(
                                (obj) => obj?.id === player?.weightClassId
                              );
                              return (
                                <TableRow key={i}>
                                  <TableCell>{player?.id}</TableCell>
                                  <TableCell>
                                    {fighterName !== undefined &&
                                      Object.entries(fighterName).length !==
                                        0 &&
                                      fighterName?.Player !== null &&
                                      fighterName?.Player?.name}
                                  </TableCell>
                                  <TableCell>
                                    {player?.champion !== false && "Champion"}
                                  </TableCell>
                                  <TableCell>
                                    {player?.interimChampion !== false &&
                                      "Interim Champion"}
                                  </TableCell>
                                  <TableCell>
                                    {weightClass !== undefined &&
                                      Object.entries(weightClass).length !==
                                        0 &&
                                      weightClass?.name}
                                  </TableCell>
                                  <TableCell>{player?.rank}</TableCell>
                                  <TableCell>
                                    <EditIcon
                                      onClick={this.inputRankingModal(
                                        player?.id,
                                        true
                                      )}
                                      color="primary"
                                      className="mr10 cursor iconBtn admin-btn-green"
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <DeleteOutlineIcon
                                      // onClick={this.playerToDelete(
                                      //   player?.Player?.id
                                      // )}
                                      onClick={() =>
                                        this.setState({
                                          rankingDeleteModalOpen: true,
                                          rankingToDelete: player?.id,
                                        })
                                      }
                                      color="primary"
                                      className="mr10 cursor iconBtn admin-btn-orange"
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div
                        className="tablePagination"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          className={
                            rankingCurrentPage > 1
                              ? "btn-navigation"
                              : "btn-navigation-disabled"
                          }
                          disabled={rankingCurrentPage > 1 ? false : true}
                          onClick={() =>
                            this.handleRankingPaginationButtonClick("prev")
                          }
                        >
                          <ReactSVG src={arrowLeft} />
                        </button>
                        <Pagination
                          hideNextButton
                          hidePrevButton
                          disabled={
                            championRankArray.length / rankingRowPerPage > 1
                              ? false
                              : true
                          }
                          page={rankingCurrentPage}
                          onChange={this.handleRankingPaginationClick}
                          count={
                            rankingPageNumber[rankingPageNumber?.length - 1]
                          }
                          siblingCount={2}
                          boundaryCount={1}
                          size="small"
                        />
                        <button
                          className={
                            Math.ceil(
                              championRankArray.length / rankingRowPerPage
                            ) > rankingCurrentPage
                              ? "btn-navigation"
                              : "btn-navigation-disabled"
                          }
                          disabled={
                            Math.ceil(
                              championRankArray.length / rankingRowPerPage
                            ) > rankingCurrentPage
                              ? false
                              : true
                          }
                          onClick={() =>
                            this.handleRankingPaginationButtonClick("next")
                          }
                        >
                          <ReactSVG src={arrowRight} />
                        </button>
                      </div>
                    </>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p>No Data Available</p>
                    </div>
                  ))}
              </div>
            </Modal>

            <ShowModal
              isModalOpen={rankingDeleteModalOpen}
              onClose={this.toggleRankingDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleRankingDelete}
              onCancel={this.toggleRankingDeleteModal}
            />

            <Modal
              className="modal modal-input"
              open={isChampionModalOpen}
              onClose={this.toggleChampionModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography style={{ marginTop: 0 }} variant="h4">
                    Champion History
                  </Typography>
                  <Box>
                    <Button
                      onClick={this.inputChampionModal(null, false)}
                      className="mr10 cursor admin-btn-green"
                      style={{ marginRight: "15px" }}
                    >
                      Add
                    </Button>
                    <CancelIcon
                      className="admin-close-icon"
                      onClick={this.toggleChampionModal}
                    />
                  </Box>
                </Box>

                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleChampionModal}
                />
                {isDeleteLoading === "championDelete" && (
                  <div class="admin-delete-modal-loader">
                    <Loader />
                  </div>
                )}
                {isChampionLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
                {!isChampionLoading &&
                  (championHistoryData?.length > 0 ? (
                    <>
                      <TableContainer component={Paper}>
                        <Table
                          className="listTable api-provider-listTable"
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>DID</TableCell>
                              <TableCell>Champion Name</TableCell>
                              <TableCell>Weight Class</TableCell>
                              <TableCell>Champion From</TableCell>
                              <TableCell>Champion To</TableCell>
                              <TableCell>Number of defenses</TableCell>
                              <TableCell>Edit</TableCell>
                              <TableCell>Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {CurrentHistoryPageRow?.map((player, i) => {
                              let fighterName = playersDetails.find(
                                (obj) => obj?.Player?.id === player?.fighterId
                              );
                              let weightClass = allWeightClassData?.find(
                                (obj) => obj?.id === player?.weightClassId
                              );
                              return (
                                <>
                                  <TableRow key={i}>
                                    <TableCell>{player?.id}</TableCell>
                                    <TableCell>
                                      {fighterName !== undefined &&
                                        Object.entries(fighterName).length !==
                                          0 &&
                                        fighterName?.Player !== null &&
                                        fighterName?.Player?.name}
                                    </TableCell>
                                    <TableCell>
                                      {weightClass !== undefined &&
                                        Object.entries(weightClass).length !==
                                          0 &&
                                        weightClass?.name}
                                    </TableCell>
                                    <TableCell>
                                      {moment(player?.from).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {moment(player?.to).format("DD/MM/YYYY")}
                                    </TableCell>
                                    <TableCell>{player?.defenses}</TableCell>
                                    <TableCell>
                                      <EditIcon
                                        onClick={this.inputChampionModal(
                                          player?.id,
                                          true
                                        )}
                                        color="primary"
                                        className="mr10 cursor iconBtn admin-btn-green"
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <DeleteOutlineIcon
                                        // onClick={this.playerToDelete(
                                        //   player?.Player?.id
                                        // )}
                                        onClick={() =>
                                          this.setState({
                                            championDeleteModalOpen: true,
                                            championToDelete: player?.id,
                                          })
                                        }
                                        color="primary"
                                        className="mr10 cursor iconBtn admin-btn-orange"
                                      />
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div
                        className="tablePagination"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          className={
                            historyCurrentPage > 1
                              ? "btn-navigation"
                              : "btn-navigation-disabled"
                          }
                          disabled={historyCurrentPage > 1 ? false : true}
                          onClick={() =>
                            this.handleHistoryPaginationButtonClick("prev")
                          }
                        >
                          <ReactSVG src={arrowLeft} />
                        </button>
                        <Pagination
                          hideNextButton
                          hidePrevButton
                          disabled={
                            championHistoryData.length / historyRowPerPage > 1
                              ? false
                              : true
                          }
                          page={historyCurrentPage}
                          onChange={this.handleHistoryPaginationClick}
                          count={
                            historyPageNumber[historyPageNumber?.length - 1]
                          }
                          siblingCount={2}
                          boundaryCount={1}
                          size="small"
                        />
                        <button
                          className={
                            Math.ceil(
                              championHistoryData.length / historyRowPerPage
                            ) > historyCurrentPage
                              ? "btn-navigation"
                              : "btn-navigation-disabled"
                          }
                          disabled={
                            Math.ceil(
                              championHistoryData.length / historyRowPerPage
                            ) > historyCurrentPage
                              ? false
                              : true
                          }
                          onClick={() =>
                            this.handleHistoryPaginationButtonClick("next")
                          }
                        >
                          <ReactSVG src={arrowRight} />
                        </button>
                      </div>
                    </>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p>No Data Available</p>
                    </div>
                  ))}
              </div>
            </Modal>

            <ShowModal
              isModalOpen={championDeleteModalOpen}
              onClose={this.toggleChampionDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleChampionDelete}
              onCancel={this.toggleChampionDeleteModal}
            />

            <Modal
              className="modal modal-input"
              open={isInputTeamModalOpen}
              onClose={this.toggleTeamInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {leagueTeamEditMode ? "Edit Teams" : "Add Team"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleTeamInputModal}
                />
                <CreateTeams
                  inputModal={this.toggleTeamInputModal}
                  id={this.state.idToSend}
                  isEditMode={leagueTeamEditMode}
                  fetchAllTeams={this.afterChangeTeamRefresh}
                  allSports={allSports}
                  leagueId={this.state.leagueIdToSend}
                  sportId={match?.params?.id}
                  allLeague={sportsDetails}
                />
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isInputPlayerModalOpen}
              onClose={this.togglePlayerInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {isEditMode
                    ? match?.params?.name === "Mixed Martial Arts" ||
                      match?.params?.name === "Boxing"
                      ? "Edit Fighters"
                      : "Edit Players"
                    : match?.params?.name === "Mixed Martial Arts" ||
                      match?.params?.name === "Boxing"
                    ? "Add Fighters"
                    : "Add Players"}
                  {}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.togglePlayerInputModal}
                />
                <CreatePlayers
                  inputModal={this.togglePlayerInputModal}
                  id={this.state.idToSend}
                  isEditMode={this.state.isEditMode}
                  fetchAllPlayers={this.afterChangePlayerRefresh}
                  allPlayersType={this.state.allPlayersType}
                  isLeague={true}
                  allLeague={sportsDetails}
                  leagueId={this.state.leagueIdToSend}
                  editJockeys={false}
                  sport={match?.params?.name}
                  allWeightClass={allWeightClassData}
                />
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isInputWeightModalOpen}
              onClose={this.toggleWeightInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {isEditMode === true
                    ? "Edit Weight Class"
                    : "Add Weight Class"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleWeightInputModal}
                />
                <CreateWeightClass
                  inputModal={this.toggleWeightInputModal}
                  id={idToSend}
                  isEditMode={this.state.isEditMode}
                  fetchAllWeight={this.fetchAllWeightClass}
                  allLeague={sportsDetails}
                  leagueIdToSend={leagueIdToSend}
                />
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isInputRankingModalOpen}
              onClose={this.toggleRankingsInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {isEditMode === true ? "Edit Rankings" : "Add Rankings"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleRankingsInputModal}
                />
                <CreateRankings
                  inputModal={this.toggleRankingsInputModal}
                  id={idToSend}
                  isEditMode={isEditMode}
                  fetchRankingsByLeague={this.fetchRankingsByLeague}
                  playersDetails={playersDetails}
                  leagueId={leagueIdToSend}
                  WeightClassData={allWeightClassData}
                />
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isInputChampionModalOpen}
              onClose={this.toggleChampionInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {isEditMode === true
                    ? "Edit Champion History"
                    : "Add Champion History"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleChampionInputModal}
                />
                <CreateChampionHistory
                  inputModal={this.toggleChampionInputModal}
                  id={idToSend}
                  isEditMode={isEditMode}
                  fetchAllHistory={this.fetchAllChampionHistory}
                  leagueId={leagueIdToSend}
                  playersDetails={playersDetails}
                  WeightClassData={allWeightClassData}
                />
              </div>
            </Modal>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default SportsDetailsTeam;
