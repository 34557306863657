import React from "react";
import { Grid, Paper } from "@material-ui/core";

class NoData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Grid container className='page-content adminLogin sports_details'>
          <Grid item xs={12}>
            <Paper className='pageWrapper'>
              <Grid>
                <p>No Data Available</p>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default NoData;
