import React from "react";
import { Grid, Paper } from "@material-ui/core";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import axiosInstance from "../../../helpers/Axios";
// import Select from "react-select";

// const selectboxStyles = {
//   container: (styles) => ({ ...styles, width: "96.7%" }),
//   control: (styles) => ({
//     ...styles,
//     width: "100%",
//     borderRadius: "5px",
//     borderColor: "#cccccc",
//     boxShadow: "none",

//     "&:hover": {
//       borderColor: "#cccccc",
//       boxShadow: "none",
//     },
//   }),
//   option: (styles) => {
//     return {
//       ...styles,
//       color: "#000",
//     };
//   },
//   indicatorContainer: (styles) => ({ ...styles, padding: "0 2px" }),
//   input: (styles) => ({ ...styles, color: "#000" }),
//   placeholder: (styles) => ({ ...styles, fontSize: "14px", color: "#656565" }),
//   singleValue: (styles) => ({
//     ...styles,
//     color: "#000",
//     fontSize: "14px",
//   }),
// };

class CreateWeightClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weightClass: "",
      pounds: "",
      kgs: "",
      leagueId: [],
      isEditMode: false,
      error: {},
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrenWeightClass(this.props.id);
    }
  }
  fetchCurrenWeightClass = async (id) => {
    const { status, data } = await axiosInstance.get(
      `/sports/weightClass/${id}`
    );
    if (status === 200) {
      if (data.message !== null) {
        this.setState({
          weightClass: data?.result?.name,
          pounds: data?.result?.inPound,
          kgs: data?.result?.inKg,
        });
      }
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { weightClass, pounds, kgs } = this.state;
    let flag = true;

    let error_obj = {};

    if (weightClass === "") {
      flag = false;
      error_obj.weightClass = "This field is mandatory";
      this.setState({ isLoading: false });
    }

    if (pounds === "") {
      flag = false;
      error_obj.pounds = "This field is mandatory";
      this.setState({ isLoading: false });
    }

    if (kgs === "") {
      flag = false;
      error_obj.kgs = "This field is mandatory";
      this.setState({ isLoading: false });
    }

    if (weightClass !== "" && pounds !== "" && kgs !== "") {
      flag = true;
    }

    this.setState({ error: error_obj });
    return flag;
  };

  handleSave = async () => {
    const { isEditMode, leagueIdToSend } = this.props;
    const { weightClass, pounds, kgs } = this.state;
    this.setState({ isLoading: true });
    try {
      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `sports/weightClass/${this.props.id}`
        : `sports/weightClass`;

      let values = {
        name: weightClass,
        inPound: Number(pounds),
        inKg: Number(kgs),
        leagueId: leagueIdToSend,
      };

      if (this.validate()) {
        // console.log("data : ", values);
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllWeight();
          this.setActionMessage(
            true,
            "Success",
            `Weight Class ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${
          isEditMode ? "editing" : "creating"
        } Weight Class`
      );
    }
  };

  handleChange = (e, type) => {
    let field = e.target.name;
    let value = e.target.value;
    if (type === "number") {
      let regexp = /^[0-9\b]+$/;
      if (value === "" || regexp.test(value)) {
        this.setState({
          [field]: value,
          error: { ...this.state.error, [field]: "" },
        });
      }
    } else {
      this.setState({
        [field]: value,
        error: { ...this.state.error, [field]: "" },
      });
    }
  };

  handleLeagueChange = (item) => {
    this.setState({ leagueId: item, leagueIdError: "" });
  };

  render() {
    var { messageBox, isLoading, error, weightClass, pounds, kgs } = this.state;
    var { isEditMode, allLeague } = this.props;
    let leagueList = [];
    leagueList = allLeague?.map((obj) => {
      return {
        ...leagueList,
        label: obj.leagueName,
        value: obj.id,
      };
    });
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}
              <div>
                {/* <label>League</label>
                <Select
                  placeholder="League Name"
                  name="leagueId"
                  id="leagueId"
                  isMulti
                  styles={selectboxStyles}
                  value={leagueId}
                  options={leagueList}
                  onChange={(selectedOptions) =>
                    this.handleLeagueChange(selectedOptions)
                  }
                /> */}

                <label>Weight Class</label>
                <input
                  className="select-box-manual"
                  name="weightClass"
                  onChange={(e) => this.handleChange(e, "text")}
                  value={weightClass}
                  placeholder="weight class"
                />
                {typeof error.weightClass !== "undefined" &&
                  error.weightClass !== "" && (
                    <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                      {error.weightClass}
                    </p>
                  )}

                <label>Pounds</label>
                <input
                  className="select-box-manual"
                  name="pounds"
                  onChange={(e) => this.handleChange(e, "number")}
                  value={pounds}
                  placeholder="pounds"
                />
                {typeof error.pounds !== "undefined" && error.pounds !== "" && (
                  <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                    {error.pounds}
                  </p>
                )}

                <label>Kgs</label>
                <input
                  className="select-box-manual"
                  name="kgs"
                  onChange={(e) => this.handleChange(e, "number")}
                  value={kgs}
                  placeholder="kgs"
                />
                {typeof error.kgs !== "undefined" && error.kgs !== "" && (
                  <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                    {error.kgs}
                  </p>
                )}
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateWeightClass;
