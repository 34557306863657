import React, { createRef } from "react";
import { Grid, Paper, Chip } from "@material-ui/core";
import FileUploader from "../../library/common/components/FileUploader";
import axiosInstance from "../../helpers/Axios";
import ButtonComponent from "../../library/common/components/Button";
import ActionMessage from "../../library/common/components/ActionMessage";
import Loader from "../../library/common/components/Loader";

class ImportCSV extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      sportType: [
        { name: "Racing" },
        { name: "Rugby League" },
        { name: "MMA" },
        { name: "Aussie Rules" },
        { name: "Boxing" },
        { name: "Soccer" },
        { name: "Cricket" },
        { name: "Rugby Union" },
      ],
      files: [],
      importErrorArray: [],
      sportTypeValue: "Racing",
      variation: "Meeting",
      error: "",
      isLoading: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
    };
  }

  handleFileUpload = (name, files) => {
    this.setState({ [name]: files, error: "", importErrorArray: [] });
    this.setActionMessage(false);
  };

  handleSelect = (e) => {
    this.setState({
      sportTypeValue: e.target.value,
      variation:
        e.target.value === "Racing"
          ? "Meeting"
          : e.target.value === "Rugby League"
          ? "League and Team"
          : e.target.value === "MMA"
          ? "League and Player"
          : e.target.value === "Aussie Rules"
          ? "League and Team"
          : e.target.value === "Boxing"
          ? "League and Player"
          : e.target.value === "Soccer"
          ? "League and Team"
          : e.target.value === "Cricket"
          ? "League and Team"
          : e.target.value === "Rugby Union"
          ? "League and Team"
          : "",
    });
    this.setActionMessage(false);
  };

  handleSelectVariation = (e) => {
    this.setState({ variation: e.target.value });
    this.setActionMessage(false);
  };

  onSubmit = async () => {
    let { variation, files } = this.state;
    if (files.length > 0) {
      let file = await this.setMedia(files[0], variation);
    } else {
      this.setState({ error: "Select file first" });
    }
  };

  getUrl = (variation) => {
    switch (variation) {
      case "Racing":
        return "/events/import/races";
      case "Meeting":
        return "/events/import/events";
      case "Runner":
        return "/events/import/raceRunner";
      case "jockey_trainers":
        return "/events/import/jockeys";
      case "Animal":
        return "/events/import/allAnimal";
      case "Tracks":
        return "/events/import/tracks";
      case "Rugby League Fixture":
        return "sync/import/fixture/csv";
      case "League and Team":
        return "sync/import/fixture/league_team/csv";
      // MMA and Boxing
      case "League and Player":
        return "sync/import/fixture/league_player/csv";
      case "MMA Event":
        return "/events/import/sportMMA";
      case "MMA Fight":
        return "events/import/sportMMAChild";
      case "Australian Rules Fixture":
        return "sync/import/fixture/csv";
      case "add Result & champ":
        return "sports/importResultCSV";
      case "insert result":
        return "sports/importResultCSV";
      default:
        return "/events/import/races";
    }
  };

  setMedia = async (files, variation) => {
    const formData = new FormData();
    formData.append("file", files ? files : null);
    let url = this.getUrl(variation);

    if (files !== undefined) {
      this.setState({ isLoading: true });
      try {
        const { status, data } = await axiosInstance.post(url, formData, {
          header: { "Content-Type": "multipart/form-data" },
        });
        if (status === 200) {
          this.setState({ isLoading: false });
          this.setActionMessage(true, "Success", data.message);
          return data;
        } else {
          return false;
        }
      } catch (err) {
        this.setState({ isLoading: false });
        if (err.hasOwnProperty("response")) {
          this.setActionMessage(true, "Error", err.response.data.message);
          this.setState({ importErrorArray: err.response.data.errors });
        }
      }
    }
    return false;
  };

  renderOptions = (sport) => {
    switch (sport) {
      case "Racing":
        return (
          <>
            <option value="Meeting">Meeting & Race Fixture</option>
            {/* <option value='Racing'>Racing</option> */}
            <option value="Runner">Runner Fixture</option>
            <option value="Animal">All Animal</option>
            <option value="Tracks">Tracks</option>
            <option value="jockey_trainers">jockey and Trainers</option>
          </>
        );
      case "Rugby League":
        return (
          <>
            <option value="League and Team">League and Team</option>
            <option value="Rugby League Fixture">Rugby League Fixture</option>
            <option value="insert result">Insert Result</option>
          </>
        );
      case "MMA":
        return (
          <>
            <option value="League and Player">Organisation and Fighter</option>
            <option value="MMA Event">MMA Event</option>
            <option value="MMA Fight">MMA Fight</option>
            <option value="add Result & champ">Add Result & champ</option>
          </>
        );
      case "Aussie Rules":
        return (
          <>
            <option value="League and Team">League and Team</option>
            <option value="Australian Rules Fixture">
              Australian Rules Fixture
            </option>
            <option value="insert result">Insert Result</option>
          </>
        );
      case "Boxing":
        return (
          <>
            <option value="League and Player">Organisation and Fighter</option>
            <option value="MMA Event">Boxing Event</option>
            <option value="MMA Fight">Boxing Fight</option>
            <option value="add Result & champ">Add Result & champ</option>
          </>
        );
      case "Soccer":
        return (
          <>
            <option value="League and Team">League and Team</option>
            <option value="Rugby League Fixture">Soccer Fixture</option>
            <option value="insert result">Insert Result</option>
          </>
        );
      case "Cricket":
        return (
          <>
            <option value="League and Team">League and Team</option>
            <option value="Rugby League Fixture">Cricket Fixture</option>
            <option value="insert result">Insert Result</option>
          </>
        );
      case "Rugby Union":
        return (
          <>
            <option value="League and Team">League and Team</option>
            <option value="Rugby League Fixture">Rugby Union Fixture</option>
            <option value="insert result">Insert Result</option>
          </>
        );
      default:
        return "";
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  render() {
    let {
      sportType,
      sportTypeValue,
      variation,
      error,
      messageBox,
      isLoading,
      files,
      importErrorArray,
    } = this.state;
    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}
              {isLoading && <Loader />}
              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={10}>
                  <h3 className="text-left">Import Fixture Using CSV</h3>
                </Grid>
              </Grid>
              {/* <Grid container style={{ margin: "15px 0px 25px" }}>
                <Grid item xs={2}>
                  <label style={{ display: "flex" }}>
                    Select Sport Type :{" "}
                  </label>
                </Grid>
                <Grid item xs={10} style={{ display: "flex" }}>
                  {sportType?.length > 0 &&
                    sportType?.map((obj) => (
                      <>
                        <input
                          type='radio'
                          id={obj.name}
                          value={obj.name}
                          checked={sportTypeValue == obj.name}
                          name={obj.name}
                          onChange={(e) => this.handleSelect(e)}
                          style={{
                            marginTop: 5,
                            marginLeft: 15,
                            cursor: "pointer",
                          }}
                        />
                        <label for={obj.name}>{obj.name}</label>
                      </>
                    ))}
                </Grid>
              </Grid> */}
              <Grid container style={{ marginTop: 10 }}>
                <Grid item xs={6}>
                  <div className="text-left">
                    <label>Import Fixture Using CSV</label>
                    <select
                      className="select-box-manual"
                      name="sportTypeValue"
                      onChange={(e) => this.handleSelect(e)}
                      value={sportTypeValue}
                    >
                      {sportType?.map((obj, i) => (
                        <option key={i} value={obj?.name}>
                          {obj?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="text-left">
                    <label>variation</label>
                    <select
                      className="select-box-manual"
                      name="variation"
                      onChange={(e) => this.handleSelectVariation(e)}
                      value={variation}
                    >
                      {this.renderOptions(sportTypeValue)}
                    </select>
                  </div>
                </Grid>
              </Grid>

              <div className="blog-file-upload">
                <label style={{ display: "flex" }}>Post File</label>
                <FileUploader
                  onDrop={(files) => this.handleFileUpload("files", files)}
                />
                {typeof error !== "undefined" && error !== "" && (
                  <p
                    className="errorText"
                    style={{ margin: "-20px 0 0 0", textAlign: "left" }}
                  >
                    {error}
                  </p>
                )}
                {files?.length > 0 &&
                  files.map((file, index) => (
                    <div style={{ display: "flex", position: "relative" }}>
                      <Chip
                        key={index}
                        label={file?.name}
                        onDelete={() => this.handleFileUpload("files", [])}
                        color="primary"
                        style={{ position: "absolute" }}
                      />
                    </div>
                  ))}
                {importErrorArray?.length > 0 && (
                  <>
                    <p
                      style={{
                        textAlign: "left",
                        marginTop: "40px",
                        color: "red",
                        marginBottom: "10px",
                      }}
                    >
                      Import Errors. correct the errors and import again
                    </p>
                    {importErrorArray?.map((obj, i) => (
                      <p
                        key={i}
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >{`Line ${obj?.line}: ${obj?.errorMessage}`}</p>
                    ))}
                  </>
                )}
              </div>
              <div style={{ paddingTop: 50 }}>
                <ButtonComponent
                  className="mt-3 admin-btn-green"
                  onClick={this.onSubmit}
                  color="primary"
                  value="Import"
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default ImportCSV;
