export default function CommonReducer(
  state = {
    loader: false,
    upcomingRaces: [],
  },
  action
) {
  switch (action.type) {
    case "LOADER_VIEW":
      return Object.assign({}, state, { loader: action.payload });
    case "ERROR_HANDLER":
      return Object.assign({}, state, { error: action.payload });
    case "AUTH_HANDLER":
      return Object.assign({}, state, { auth: action.payload });
    case "CLEAR_AUTH":
      return {};
    case "SET_UPCOMING_RACE":
      return Object.assign({}, state, { upcomingRaces: action.payload });
    case "REMOVE_UPCOMMING_RACE":
      return Object.assign({}, state, {
        upcomingRaces: state.upcomingRaces?.filter(
          (itemrace) => itemrace?.id !== action.payload?.id
        ),
      });
    default:
      return state;
  }
}
