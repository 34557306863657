import Dashboard from "../../admin/dashBoard/Dashboard";
import AboutUs from "../../admin/aboutUs/AboutUs";
import AddNew from "../../admin/dashBoard/AddNew";
import Blog from "../../admin/Blog";
import CreateBlog from "../../admin/Blog/CreateBlog";
import Ads from "../../admin/Ads";
import CreateAd from "../../admin/Ads/CreateAd";
import Media from "../../admin/Media";
import FooterLogo from "../../admin/FooterLogo";
import Category from "../../admin/Category";
import CreateCategory from "../../admin/Category/CreateCategory";
import CreateSubCategory from "../../admin/SubCategory/CreateSubCategory";
import SubCategory from "../../admin/SubCategory";
import Affiliate from "../../admin/Affiliate";
import AdReports from "../../admin/AdReports";
import Contact from "../../admin/Contact";
import GWMeetingMapping from "../../admin/GWMeetingMapping";
import Sports from "../../admin/sports";
import SportType from "../../admin/sportType";
import ApiProvider from "../../admin/APIprovider";
import ApiProviderUpdate from "../../admin/APIprovider/APIproviderUpdate";
import ApiProviderApisFeildMapping from "../../admin/APIprovider/apiProviderApisFeildMapping";
import AnimalTypes from "../../admin/animalTypes";
import AnimalColour from "../../admin/animalColour";
import AnimalGender from "../../admin/animalGender";
import Categories from "../../admin/Categories";
import CreateCategories from "../../admin/Categories/CreateCategories";
import Addresses from "../../admin/Addresses";
import CreateAddresses from "../../admin/Addresses/CreateAddresses";
import Users from "../../admin/Users";
import CreateUsers from "../../admin/Users/CreateUsers";
import PlayersType from "../../admin/playersType";
import Players from "../../admin/players";
import Teams from "../../admin/teams";
import TeamPlayers from "../../admin/teamPlayers";
import Country from "../../admin/country";
import States from "../../admin/states";
import Location from "../../admin/location";
import Weather from "../../admin/weather";
import Fixtures from "../../admin/fixtures";
import RacingParticipants from "../../admin/racingParticipants";
import ParticipantsType from "../../admin/participantType";
import Participants from "../../admin/participants";
import participantValue from "../../admin/participantsValue";
import Bookkeeper from "../../admin/bookkeeper";
import AdPlaceHolder from "../../admin/adPlaceHolder";
import AdPlaceHolderPages from "../../admin/adPlaceHolderPage";

import RacingMarket from "../../admin/sportsDetailsTeam/RacingMarket";
import RacingMissingData from "../../admin/sportsDetailsTeam/RacingMissingData";
import MissingOtherSportsData from "../../admin/sportsDetailsTeam/OtherSportMissingData";
import RacingErrorLogs from "../../admin/sportsDetailsTeam/RacingErrorLogs";
import RacingMergePlayer from "../../admin/sportsDetailsTeam/racePlayerMerge";
import Markets from "../../admin/Markets";
import MarketValues from "../../admin/Markets/MarketValues";
import ImportOdds from "../../admin/importOdds";
import BannerPlaceHolders from "../../admin/bannerPlaceHolders";
import CmsPages from "../../admin/cmsPages";
import Events from "../../admin/events";
import ApiEventIdentifire from "../../admin/apiEventIdentifire";
import League from "../../admin/league";
import CmsMenu from "../../admin/cmsMenu";
import CmsPageContent from "../../admin/cmsPageContent";

import EventOptionValues from "../../admin/eventOptionValues";
import RaceTable from "../../admin/raceTable";
import RaceTableOptionValues from "../../admin/raceTableOptionValues";
import Distance from "../../admin/distance";
import PlayerOptionValues from "../../admin/playerOptionValues";
import Cities from "../../admin/cities";
import MarketValueKeys from "../../admin/marketValueKeys";
import Banners from "../../admin/banners";
import ApiKeyIdentifire from "../../admin/apiKeyIdentifire";
import Track from "../../admin/track";
import ApiRaceIdentifire from "../../admin/apiRaceIdentifire";
import RacingParticipantsMember from "../../admin/raceingParticipantsMembers";
import Animal from "../../admin/animal";
import Vehicle from "../../admin/vehicle";
import Statskeys from "../../admin/statsKeys";
import Stats from "../../admin/stats";
import ImportCSV from "../../admin/importCsv";
import SportsDetails from "../../admin/sportsDetails";
import SportsDetailsTeam from "../../admin/sportsDetailsTeam";
import RunnersDetails from "../../admin/sportsDetails/runnersDetails";
import RaceMarketRunnerDetails from "../../admin/sportsDetailsTeam/RunnerDetails";
import SportsFixturesDetails from "../../admin/sportsDetailsTeam/sportsFixturesDetails";

import AnimalDetails from "../../admin/sportsDetails/animals/index";
import JockeyDetails from "../../admin/sportsDetails/jockeys/index";
// import TrainersDetails from '../../admin/sportsDetails/trainers/index'
import NoData from "../../admin/noData";
import TracksDetails from "../../admin/sportsDetails/tracksDetails";
import SportsEventsCards from "../../admin/sportsDetailsTeam/sportsEventsCard";
import SportsMarkets from "../../admin/sportsDetailsTeam/sportsMarket";
import SportMarketDetais from "../../admin/sportsDetailsTeam/sportsMarket/SportMarketDetails";
import RacingMeetings from "../../admin/sportsDetails/meetings";
import SportsPlace from "../../admin/sportsDetailsTeam/sportsPlace";

import AdminNotifcation from "../../admin/adminNotification";

const adminRoutesArray = [
  { path: "/dashboard", component: Dashboard },
  { path: "/admin/about", component: AboutUs },
  { path: "/admin/blog", component: Blog },
  { path: "/admin/ads", component: Ads },
  { path: "/admin/media", component: Media },
  { path: "/admin/add", component: AddNew },
  { path: "/admin/add-blog", component: CreateBlog },
  { path: "/admin/edit-blog/:id", component: CreateBlog },
  { path: "/admin/new-ad", component: CreateAd },
  { path: "/admin/edit-ad/:id", component: CreateAd },
  { path: "/admin/edit-page/:id", component: AddNew },
  { path: "/admin/footer-logo", component: FooterLogo },
  { path: "/admin/category", component: Category },
  { path: "/admin/add-category", component: CreateCategory },
  { path: "/admin/edit-category/:id", component: CreateCategory },
  { path: "/admin/sub-category", component: SubCategory },
  { path: "/admin/add-sub-category", component: CreateSubCategory },
  { path: "/admin/edit-sub-category/:id", component: CreateSubCategory },
  { path: "/admin/affiliate", component: Affiliate },
  { path: "/admin/ad-reports", component: AdReports },
  { path: "/admin/contact", component: Contact },
  { path: "/admin/gwmeetingmapping", component: GWMeetingMapping },
  { path: "/admin/sports", component: Sports },
  { path: "/admin/sporttype", component: SportType },
  {
    path: "/admin/racing/:name/:id/:eventid/:eventname",
    component: SportsDetails,
  },
  { path: "/admin/racing/tracks", component: TracksDetails },
  { path: "/admin/racing/markets", component: RacingMarket },
  { path: "/admin/racing/missingData", component: RacingMissingData },
  {
    path: "/admin/:sportName/:sportId/missingData",
    component: MissingOtherSportsData,
  },
  { path: "/admin/racing/errorLogs", component: RacingErrorLogs },
  { path: "/admin/mergePlayer", component: RacingMergePlayer },
  {
    path: "/admin/racingdetail/:name/:id/animal/:animalname/:typeid",
    component: AnimalDetails,
  },
  {
    path: "/admin/racingdetail/:name/:id/:player/:typeid",
    component: JockeyDetails,
  },
  // { path: '/admin/racing/:name/:id/trainers', component: TrainersDetails },
  { path: "/admin/racing/meetings/:name/:id", component: RacingMeetings },
  { path: "/admin/racing/:name", component: RacingMeetings },
  { path: "/admin/sport/:name/:id", component: SportsDetailsTeam },
  { path: "/admin/sport/markets/:name/:id", component: SportsMarkets },
  { path: "/admin/sport/fixture/:name/:id", component: SportsFixturesDetails },
  {
    path: "/admin/sport/markets/:name/:id/details/:marketId",
    component: SportMarketDetais,
  },
  { path: "/admin/sport/fixture/:name/:id", component: SportsFixturesDetails },
  {
    path: "/admin/sport/cards/:name/:id/:eventId",
    component: SportsEventsCards,
  },
  {
    path: "/admin/masterdata/:placename/:name/:id",
    component: SportsPlace,
  },
  { path: "/admin/noData/:pagename", component: NoData },
  {
    path: "/admin/:sportname/:sportid/:eventname/:eventid/runners/:id/:raceName",
    component: RunnersDetails,
  },
  {
    path: "/admin/runners/:id/market/:raceName",
    component: RaceMarketRunnerDetails,
  },
  { path: "/admin/apiprovider", component: ApiProvider },
  {
    path: "/admin/apiprovider/apifieldmapping/:providerid/:id",
    component: ApiProviderApisFeildMapping,
  },
  {
    path: "/admin/apiprovider/apiproviderupdate/:id",
    component: ApiProviderUpdate,
  },
  { path: "/admin/apikeyidentifire", component: ApiKeyIdentifire },
  { path: "/admin/animaltypes", component: AnimalTypes },
  { path: "/admin/animaltypes/animalcolour/:id", component: AnimalColour },
  { path: "/admin/animaltypes/animalgender/:id", component: AnimalGender },
  { path: "/admin/categories", component: Categories },
  { path: "/admin/add-categories", component: CreateCategories },
  { path: "/admin/edit-categories/:id", component: CreateCategories },
  { path: "/admin/addresses", component: Addresses },
  { path: "/admin/add-addresses", component: CreateAddresses },
  { path: "/admin/edit-addresses/:id", component: CreateAddresses },
  { path: "/admin/users", component: Users },
  { path: "/admin/add-users", component: CreateUsers },
  { path: "/admin/edit-users/:id", component: CreateUsers },
  { path: "/admin/playerstype", component: PlayersType },
  { path: "/admin/players", component: Players },
  { path: "/admin/playeroptionvalues", component: PlayerOptionValues },
  { path: "/admin/teams", component: Teams },
  { path: "/admin/teams/teamplayers/:id", component: TeamPlayers },
  { path: "/admin/countries", component: Country },
  { path: "/admin/countries/states/:id", component: States },
  { path: "/admin/countries/cities/:countryid/:id", component: Cities },
  { path: "/admin/locations", component: Location },
  { path: "/admin/weather", component: Weather },
  { path: "/admin/fixtures", component: Fixtures },
  { path: "/admin/importCsv", component: ImportCSV },
  { path: "/admin/racingparticipants", component: RacingParticipants },
  { path: "/admin/adminNotifications", component: AdminNotifcation },

  {
    path: "/admin/racingparticipantsmember",
    component: RacingParticipantsMember,
  },
  { path: "/admin/animal", component: Animal },
  { path: "/admin/vehicle", component: Vehicle },
  { path: "/admin/participantstype", component: ParticipantsType },
  { path: "/admin/participantstype/participants/:id", component: Participants },
  { path: "/admin/participantsvalue", component: participantValue },
  { path: "/admin/bookkeeper", component: Bookkeeper },
  { path: "/admin/markets", component: Markets },
  { path: "/admin/markets/marketsValues", component: MarketValues },
  { path: "/admin/markets/marketvaluekeys", component: MarketValueKeys },
  { path: "/admin/adplaceholder", component: AdPlaceHolder },
  {
    path: "/admin/adplaceholder/adplaceholderpage/:id",
    component: AdPlaceHolderPages,
  },
  { path: "/admin/banners", component: Banners },
  { path: "/admin/importOdds", component: ImportOdds },
  {
    path: "/admin/adplaceholder/bannerplaceholders/:placeholderid/:id",
    component: BannerPlaceHolders,
  },
  { path: "/admin/cmspages", component: CmsPages },
  { path: "/admin/cmspages/cmspagecontent/:id", component: CmsPageContent },
  { path: "/admin/cmsmenu", component: CmsMenu },
  { path: "/admin/events", component: Events },
  { path: "/admin/apieventidentifire", component: ApiEventIdentifire },
  { path: "/admin/eventoptionvalues", component: EventOptionValues },
  { path: "/admin/racetable", component: RaceTable },
  { path: "/admin/racetableoptionvalues", component: RaceTableOptionValues },
  { path: "/admin/apiraceidentifire", component: ApiRaceIdentifire },
  { path: "/admin/distance", component: Distance },
  { path: "/admin/league", component: League },
  { path: "/admin/track", component: Track },
  { path: "/admin/statskeys", component: Statskeys },
  { path: "/admin/stats", component: Stats },
];

export const adminRoutes = adminRoutesArray?.map((item) => {
  return { ...item, exact: true, adminroute: true };
});
