import React, { Component } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  withStyles,
  Box,
  Tabs,
  Tab,
} from "@material-ui/core";
import "./errorLogs.scss";
import Pagination from "@material-ui/lab/Pagination";

export class Compltedtable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  render() {
    console.log("props", this.props?.newdata);
    console.log("propssportCount", this.props?.sportCount);
    let { isLoading } = this.state;
    return (
      <>
        <Box>
          {!isLoading && this.props?.newdata?.length === 0 && (
            <p>No Data Available</p>
          )}
          {!isLoading && this.props?.newdata?.length > 0 && (
            <TableContainer component={Paper}>
              <Table
                className="listTable api-provider-listTable"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow className="tableHead-row">
                    <TableCell>SportId</TableCell>
                    <TableCell>ProviderId</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Value To Insert</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Import Type</TableCell>
                    <TableCell>Is Creating Duplicate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="table_row">
                    <TableCell
                      colSpan={100}
                      className="table-seprator"
                    ></TableCell>
                  </TableRow>
                  {this.props?.newdata?.map((item) => {
                    return (
                      <>
                        {item?.ReportMeetings?.map((obj) => {
                          return (
                            <>
                              {obj?.errors?.message?.map((data) => {
                                return (
                                  <>
                                    <TableRow className="listTable-Row">
                                      <TableCell>{data?.sportId}</TableCell>
                                      <TableCell>{data?.providerId}</TableCell>
                                      <TableCell>{data?.status}</TableCell>
                                      <TableCell>
                                        {data?.valueToInsert}
                                      </TableCell>
                                      <TableCell>{data?.description}</TableCell>
                                      <TableCell>{data?.importType}</TableCell>
                                      <TableCell>
                                        {data?.isCreatingDuplicate}
                                      </TableCell>
                                    </TableRow>{" "}
                                  </>
                                );
                              })}{" "}
                            </>
                          );
                        })}{" "}
                      </>
                    );
                  })}
                  {/* <TableRow>
                  <TableCell colSpan={100} className="pagination">
                    <div className="tablePagination"> */}
                  {/* <button
                              className={
                                offset >= 20
                                  ? "btn-navigation"
                                  : "btn-navigation-disabled"
                              }
                              disabled={offset >= 20 ? false : true}
                              // disabled={
                              //   meetingsDetails.length / rowPerPage > 1 ? false : true
                              // }
                              onClick={() =>
                                this.handlePaginationButtonClick("prev")
                              }
                            >
                              <ReactSVG src={arrowLeft} />
                            </button> */}
                  {/* <Pagination
                        hideNextButton
                        hidePrevButton
                        disabled={sportCount > 0 ? false : true}
                        page={currentPage}
                        onChange={this.handlePaginationClick}
                        count={pageNumbers[pageNumbers?.length - 1]}
                        siblingCount={2}
                        boundaryCount={1}
                        size="small"
                      /> */}
                  {/* <button
                              className={
                                rowPerPage + offset < sportCount
                                  ? "btn-navigation"
                                  : "btn-navigation-disabled"
                              }
                              disabled={
                                rowPerPage + offset < sportCount ? false : true
                              }
                              onClick={() =>
                                this.handlePaginationButtonClick("next")
                              }
                            >
                              <ReactSVG src={arrowRight} />
                            </button> */}
                  {/* </div>
                  </TableCell>
                </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </>
    );
  }
}

export default Compltedtable;
