import { Validators } from '../../../library/utilities/Validators'

export const racingParticipantsFormModel = [
  {
    label: 'Particiant Identifier*',
    value: '',
    type: 'text',
    placeholder: 'Particiant Identifier',
    field: 'particiantIdetifier',
    validators: [
      { check: Validators.required },
      { check: Validators.number, message: 'Please enter number only' },
    ],
    required: true,
    className: '6',
  },
  {
    label: 'Particiant*',
    value: '',
    type: 'dropdown',
    placeholder: 'Particiant',
    field: 'particiantId',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
    options: [
      { id: 1, value: 1, label: 1 },
      { id: 2, value: 2, label: 2 },
      { id: 3, value: 3, label: 3 },
      { id: 4, value: 4, label: 4 },
      { id: 5, value: 5, label: 5 },
    ],
  },
  {
    label: 'Barrier Number*',
    value: '',
    type: 'text',
    placeholder: 'Barrier Number',
    field: 'barrierNumber',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
  },
  // {
  //   label: "Name",
  //   value: "",
  //   type: "text",
  //   placeholder: "Name",
  //   field: "name",
  //   validators: [
  //     { check: Validators.required },
  //   ],
  //   required: true,
  //   className: "6",
  // },
  {
    label: 'Runner Number*',
    value: '',
    type: 'text',
    placeholder: 'Runner Number',
    field: 'runnerNumber',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
  },
  // {
  //   label: "Age",
  //   value: "",
  //   type: "text",
  //   placeholder: "Age",
  //   field: "age",
  //   validators: [
  //     { check: Validators.required },
  //   ],
  //   required: true,
  //   className: "6",
  // },
  // {
  //   label: "Silk Colour",
  //   value: "",
  //   type: "text",
  //   placeholder: "Silk Colour",
  //   field: "silkColour",
  //   validators: [
  //     { check: Validators.required },
  //   ],
  //   required: true,
  //   className: "6",
  // },
  // {
  //   label: "Jockey Weight",
  //   value: "",
  //   type: "text",
  //   placeholder: "Jockey Weight",
  //   field: "jockeyWeight",
  //   validators: [
  //     { check: Validators.required },
  //   ],
  //   required: true,
  //   className: "6",
  // },
  // {
  //   label: "Jockey Total Weight",
  //   value: "",
  //   type: "text",
  //   placeholder: "Jockey Total Weight",
  //   field: "jockeyTotalWeight",
  //   validators: [
  //     { check: Validators.required },
  //   ],
  //   required: true,
  //   className: "6",
  // },
  {
    label: 'Is Scratched*',
    value: '',
    type: 'dropdown',
    placeholder: 'Is Scratched',
    field: 'isScratched',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
    options: [
      { id: 1, value: 0, label: 'False' },
      { id: 2, value: 1, label: 'True' },
    ],
  },
  {
    label: 'Runner Comments',
    value: '',
    type: 'text',
    placeholder: 'Runner Comments',
    field: 'runnerComments',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
  },
]
