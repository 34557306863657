import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Button,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ButtonComponent from "../../library/common/components/Button";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import ActionMessage from "../../library/common/components/ActionMessage";
import { Loader } from "../../library/common/components";
import CreateSportType from "./createSportType";
import ShowModal from "../../components/common/ShowModal/ShowModal";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";

class SportType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sportType: [],
      isInputModalOpen: false,
      idToSend: null,
      isModalOpen: false,
      isLoading: false,
      itemToDelete: null,
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      currentPage: 1,
      rowPerPage: 20,
    };
  }

  componentDidMount() {
    this.fetchAllSportType();
  }

  async fetchAllSportType() {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(URLS.sportType);
    if (status === 200) {
      this.setState({ sportType: data.result, isLoading: false });
    }
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      itemToDelete: null,
    });
  };

  toggleInputModal = () => {
    this.setState({
      isInputModalOpen: !this.state.isInputModalOpen,
    });
  };

  inputModal = (id, type) => () => {
    this.setState({ isInputModalOpen: true });
    if (type === "edit") {
      this.setState({ idToSend: id, isEditMode: true });
    } else {
      this.setState({ isEditMode: false });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } }, () =>
      setTimeout(
        () =>
          this.setState({
            messageBox: { display: false, type: "", message: "" },
          }),
        3000
      )
    );
  };

  setItemToDelete = (id) => () => {
    this.setState({ itemToDelete: id, isModalOpen: true });
  };

  afterChangeRefresh = () => {
    this.fetchAllSportType();
  };

  deleteItem = async () => {
    try {
      const { status } = await axiosInstance.delete(
        `${URLS.sportType}/${this.state.itemToDelete}`
      );
      if (status === 200) {
        this.setState({ itemToDelete: null, isModalOpen: false }, () => {
          this.fetchAllSportType();
        });
        this.setActionMessage(
          true,
          "Success",
          "Sport Type Deleted Successfully!"
        );
      }
    } catch (err) {
      this.setActionMessage(true, "Error", "An error occurred while deleting.");
    }
  };

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, rowPerPage, sportType } = this.state;
    if (navDirection === "prev") {
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    } else {
      if (currentPage < sportType.length / rowPerPage)
        this.setState({ currentPage: currentPage + 1 });
    }
  };

  render() {
    var {
      sportType,
      isModalOpen,
      isLoading,
      messageBox,
      isInputModalOpen,
      isEditMode,
      rowPerPage,
      currentPage,
    } = this.state;

    const pageNumbers = [];
    let currentPageRow = sportType;

    if (sportType?.length > 0) {
      const indexOfLastTodo = currentPage * rowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      currentPageRow = sportType.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(sportType.length / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <Grid container className="page-content adminLogin">
        <Grid item xs={12}>
          <Paper className="pageWrapper">
            {messageBox.display && (
              <ActionMessage
                message={messageBox.message}
                type={messageBox.type}
                styleClass={messageBox.styleClass}
              />
            )}
            <Grid container direction="row" alignItems="space-around">
              <Grid item xs={10}>
                <h3 className="text-left">Sport Type</h3>
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  className="addButton admin-btn-green"
                  onClick={this.inputModal(null, "create")}
                  color="primary"
                  value="Add New"
                />
              </Grid>
            </Grid>
            {isLoading && <Loader />}
            {!isLoading && sportType.length === 0 && <p>No Data Available</p>}
            {!isLoading && sportType.length > 0 && (
              <>
                <TableContainer component={Paper}>
                  <Table className="listTable" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>DID</TableCell>
                        <TableCell style={{ width: "70%" }}>
                          Sport Type
                        </TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentPageRow?.map((sport, i) => (
                        <TableRow key={i}>
                          <TableCell>{sport.id}</TableCell>
                          <TableCell>{sport.sportType}</TableCell>
                          <TableCell>
                            <EditIcon
                              onClick={this.inputModal(sport.id, "edit")}
                              color="primary"
                              className="mr10 cursor iconBtn admin-btn-green"
                            />
                            <DeleteOutlineIcon
                              onClick={this.setItemToDelete(sport.id)}
                              color="secondary"
                              className="cursor iconBtn admin-btn-orange"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="tablePagination">
                  <button
                    className={
                      sportType.length / rowPerPage > 1
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={sportType.length / rowPerPage > 1 ? false : true}
                    onClick={() => this.handlePaginationButtonClick("prev")}
                  >
                    <ReactSVG src={arrowLeft} />
                  </button>
                  <Pagination
                    hideNextButton
                    hidePrevButton
                    disabled={sportType.length / rowPerPage > 1 ? false : true}
                    page={currentPage}
                    onChange={this.handlePaginationClick}
                    count={pageNumbers[pageNumbers?.length - 1]}
                    siblingCount={2}
                    boundaryCount={1}
                    size="small"
                  />
                  <button
                    className={
                      sportType.length / rowPerPage > 1
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={sportType.length / rowPerPage > 1 ? false : true}
                    onClick={() => this.handlePaginationButtonClick("next")}
                  >
                    <ReactSVG src={arrowRight} />
                  </button>
                </div>
              </>
            )}
          </Paper>

          <ShowModal
            isModalOpen={isModalOpen}
            onClose={this.toggleModal}
            Content="Are you sure you want to delete?"
            onOkayLabel="Yes"
            onOkay={this.deleteItem}
            onCancel={this.toggleModal}
          />

          <Modal
            className="modal modal-input"
            open={isInputModalOpen}
            onClose={this.toggleInputModal}
          >
            <div
              className={"paper modal-show-scroll"}
              style={{ position: "relative" }}
            >
              <h3 className="text-center">
                {!isEditMode ? "Create New Sport Type" : "Edit Sport Type"}
              </h3>
              <CancelIcon
                className="admin-close-icon"
                onClick={this.toggleInputModal}
              />
              <CreateSportType
                inputModal={this.toggleInputModal}
                id={this.state.idToSend}
                isEditMode={isEditMode}
                fetchAllSportType={this.afterChangeRefresh}
              />
            </div>
          </Modal>
        </Grid>
      </Grid>
    );
  }
}
export default SportType;
