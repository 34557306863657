import React, { Component, createRef } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

// import { Loader } from "../../library/common/components";
// import { affiliateFormModel } from "./form-constant";
import ButtonComponent from "../../library/common/components/Button";
import ActionMessage from "../../library/common/components/ActionMessage";
import axiosInstance from "../../helpers/Axios";
import AddMapping from "./frames/AddMapping";
import ListMappings from "./frames/ListMappings";

export default class Affiliate extends Component {
  formRef = createRef();

  state = {
    isModalOpen: false,
    itemToDelete: null,
    isLoading: false,
    messageBox: {
      display: false,
      type: "",
      message: "",
    },
    mappings: [],
    affiliateCategories: {
      mcategories: [],
      afs: [],
    },
    enableAdd: false,
  };

  componentDidMount() {
    this.fetchAffiliateCategories();
    this.fetchMappings();
  }

  async fetchAffiliateCategories() {
    const { status, data } = await axiosInstance.get("/af");
    if (status === 200) {
      this.setState({
        affiliateCategories: data,
      });
    }
  }

  fetchMappings = async () => {
    const { status, data } = await axiosInstance.get("/af/map");
    if (status === 200) {
      this.setState({
        mappings: data.mappings,
      });
    }
  };

  // handleAdd = () => {
  //   const { current } = this.formRef;
  //   const formValues = current.getFormData();

  //   if (formValues.isFormValid) {
  //     const value = removeErrorFieldsFromValues(formValues.formData);
  //     this.setState(
  //       {
  //         addedCategories: [...this.state.addedCategories, value],
  //       },
  //       () => {
  //         current.resetForm();
  //       }
  //     );
  //   }
  // };

  // removeValue = (index) => {
  //   const { addedCategories } = this.state;
  //   const clonedAddedCategories = [...addedCategories];
  //   clonedAddedCategories.splice(index, 1);

  //   this.setState({ addedCategories: clonedAddedCategories });
  // };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  handleSave = async (isSuccess) => {
    const type = isSuccess ? "Success" : "Error";
    const message = isSuccess
      ? "Mapping created successfully"
      : "An error occurred while mapping";

    this.setActionMessage(true, type, message);
    this.setState({ enableAdd: false });
    if (isSuccess) {
      this.fetchMappings();
    }

    setTimeout(() => {
      this.setActionMessage(false, "", "");
    }, 3000);
  };

  render() {
    const { messageBox, mappings, affiliateCategories, enableAdd } = this.state;

    return (
      <Grid container className="page-content adminLogin">
        <Grid item xs={12}>
          <Paper className="pageWrapper text-left">
            {messageBox.display && (
              <ActionMessage
                message={messageBox.message}
                type={messageBox.type}
                styleClass={messageBox.styleClass}
              />
            )}

            <Grid container direction="row" alignItems="space-around">
              <Grid item xs={6}>
                <h3 className="text-left">Affiliate Ad Mapping</h3>
              </Grid>
              <Grid item xs={6}>
                <ButtonComponent
                  className="addButton admin-btn-green"
                  onClick={() => this.setState({ enableAdd: true })}
                  color="primary"
                  value="Add New"
                />
              </Grid>
            </Grid>

            {enableAdd && (
              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={12}>
                  <AddMapping
                    mappings={mappings}
                    data={affiliateCategories}
                    onSave={this.handleSave}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container direction="row" alignItems="space-around">
              <Grid item xs={12}>
                <div style={{ marginTop: "30px" }}>
                  <ListMappings
                    mappings={mappings}
                    data={affiliateCategories}
                    fetchMappings={this.fetchMappings}
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
