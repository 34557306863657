import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Modal,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Pagination from "@material-ui/lab/Pagination";
import { ReactSVG } from "react-svg";
import EditIcon from "@material-ui/icons/Edit";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import arrowLeft from "./../../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "./../../../../images/blog_img/paginationArrowRight.svg";
import "./market.scss";
import axiosInstance from "./../../../../helpers/Axios";
import { Loader } from "./../../../../library/common/components";
import moment from "moment";
import EditMarketdetails from "../EditMarketDetails";
import { MdKeyboardBackspace } from "react-icons/md";
import ShowModal from "../../../../components/common/ShowModal/ShowModal";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInputModalOpen, setIsInputModalOpen] = useState(false);
  const [idToSend, setIdToSend] = useState(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sportCount, setSportCount] = useState(null);
  const [rowPerPage] = useState(20); //setRowPerPage
  const [offset, setOffset] = useState(0);
  const [pageNumbers] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [marketKey, setMarketKey] = useState([]);
  const [bookKeeper, setBookkeeper] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [sortLabel, setSortLabel] = useState(true);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState("");

  // let currentPageRow = data

  //   useEffect(() => {
  //     fetchAllMarketDetails()
  //   }, [])

  useEffect(() => {
    getMarketKey();
    getBookKeeper();
    if (offset === 0) {
      fetchAllMarketDetails();
    } else {
      setOffset(0);
      setCurrentPage(1);
    }
  }, [props.match]);

  useEffect(() => {
    fetchAllMarketDetails();
  }, [offset]);

  const fetchAllMarketDetails = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `sports/marketValueByMarket/${props.match.params.marketId}?limit=${rowPerPage}&offset=${offset}`
      );
      if (status === 200) {
        setData(data.result);
        setIsLoading(false);
        setSportCount(data.count);
      }
    } catch (err) {
      // console.log(err);
      setIsLoading(false);
    }
  };

  const getMarketKey = async () => {
    try {
      const { status, data } = await axiosInstance.get(`sports/marketValueKey`);
      if (status === 200) {
        setMarketKey(data.result);
        setIsLoading(false);
      }
    } catch (err) {
      // console.log(err);
      setIsLoading(false);
    }
  };

  const AddMarketValue = () => {
    setIsInputModalOpen(true);
  };

  const afterChangeRefresh = () => {
    fetchAllMarketDetails();
  };

  const toggleInputModal = () => {
    setIsInputModalOpen(!isInputModalOpen);
  };

  const getBookKeeper = async () => {
    const {
      //  status,
      data,
    } = await axiosInstance.get(`apiProviders/bookKeeper`);
    setBookkeeper(data.result);
  };

  const inputModal = (id) => {
    setIsInputModalOpen(true);
    setIdToSend(id);
  };

  const HandleMarketDelete = async (id) => {
    setIsDeleteLoading("marketDelete");
    setisModalOpen(false);
    try {
      const { status } = await axiosInstance.delete(
        `sports/marketValue/${itemToDelete}`
      );
      if (status === 200) {
        afterChangeRefresh();
        setItemToDelete(null);
        setIsDeleteLoading("");
      }
    } catch (err) {}
  };

  const handlePaginationButtonClick = (navDirection) => {
    // if (navDirection === "prev") {
    //   if (currentPage > 1) {
    //     setCurrentPage(currentPage - 1);
    //   }
    // } else {
    //   if (currentPage < data.length / rowPerPage)
    //     setCurrentPage(currentPage + 1);
    // }

    if (navDirection === "prev") {
      if (offset >= 20) {
        setOffset(offset - 20);
        setCurrentPage(currentPage - 1);
      }
    } else {
      setOffset(offset + 20);
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePaginationClick = (event, page) => {
    setCurrentPage(Number(page));
    setOffset((Number(page) - 1) * rowPerPage);
  };

  if (sportCount > 0) {
    // const indexOfLastTodo = currentPage * rowPerPage;
    // const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
    // currentPageRow = data.slice(indexOfFirstTodo, indexOfLastTodo);

    for (let i = 1; i <= Math.ceil(sportCount / rowPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  const backToNavigatePage = () => {
    const { name, id } = props.match.params;
    props.history.push(`/admin/sport/markets/${name}/${id}`);
  };

  const DateSortLabel = () => {
    setSortLabel(!sortLabel);
  };

  const toggleModal = () => {
    setisModalOpen(!isModalOpen);
    setItemToDelete(null);
  };

  const setItemToDeleteData = (id) => () => {
    setItemToDelete(id);
    setisModalOpen(true);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className="pageWrapper">
          {isDeleteLoading === "marketDelete" && (
            <div class="admin-delete-loader">
              <Loader />
            </div>
          )}
          <Button
            className="admin-btn-back"
            onClick={() => {
              backToNavigatePage();
            }}
          >
            <MdKeyboardBackspace />
          </Button>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">
              {props.match.params.animalname}
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                className={classes.margin}
                label="Search"
                size="small"
                variant="outlined"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <Button
                style={{
                  fontSize: "10px",
                  backgroundColor: "#ff6b00",
                  color: "#fff",
                  fontWeight: "400",
                  textTransform: "none",
                  padding: "5px 5",
                }}
                onClick={() => {
                  setEditMode(false);
                  AddMarketValue();
                }}
              >
                Add Market Value
              </Button>
            </div>
          </Box>
          {isLoading && <Loader />}
          {!isLoading && data.length === 0 && <p>No Data Available</p>}
          {!isLoading && data.length > 0 && (
            <TableContainer component={Paper}>
              <Table className="listTable" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>DID</TableCell>
                    <TableCell>Key</TableCell>
                    <TableCell>Odds</TableCell>
                    <TableCell>BookKeeper Name</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={true}
                        direction={sortLabel ? "asc" : "desc"}
                        onClick={DateSortLabel}
                      />
                      CreatedAt Date
                    </TableCell>
                    <TableCell align="right">Edit</TableCell>
                    <TableCell align="right">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .filter((data) => {
                      if (search === "") return data;
                      else if (
                        data?.MarketValueKey?.key
                          .toString()
                          .toLowerCase()
                          .includes(search.toString().toLowerCase()) ||
                        data?.bookKeeper?.name
                          .toString()
                          .toLowerCase()
                          .includes(search.toString().toLowerCase()) ||
                        data?.id
                          .toString()
                          .toLowerCase()
                          .includes(search.toString().toLowerCase())
                      ) {
                        return data;
                      }
                      return "";
                    })
                    /* .slice(
                      currentPage * rowPerPage - rowPerPage,
                      currentPage * rowPerPage
                    ) */
                    .sort((a, b) => {
                      var key1 = a.createdAt;
                      var key2 = b.createdAt;
                      if (sortLabel ? key1 < key2 : key1 > key2) {
                        return -1;
                      } else if (key1 === key2) {
                        return 0;
                      } else {
                        return 1;
                      }
                    })
                    .map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row?.id}</TableCell>
                        <TableCell>{row?.MarketValueKey?.key}</TableCell>
                        <TableCell>{row?.value}</TableCell>
                        <TableCell>{row?.bookKeeper?.name}</TableCell>
                        <TableCell>
                          {moment(row?.createdAt).format(
                            "DD/MM/YYYY hh:mm:ss a"
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <EditIcon
                            onClick={() => {
                              inputModal(row?.id);
                              setSelectedRow(row);
                              setEditMode(true);
                            }}
                            color="primary"
                            className="mr10 cursor iconBtn admin-btn-green"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <DeleteOutlinedIcon
                            onClick={setItemToDeleteData(row?.id)}
                            color="secondary"
                            className="cursor iconBtn admin-btn-orange"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {data?.length > 0 && (
                <div className="tablePagination market-pagination">
                  <button
                    className={
                      offset >= 20
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={offset >= 20 ? false : true}
                    onClick={() => handlePaginationButtonClick("prev")}
                  >
                    <ReactSVG src={arrowLeft} />
                  </button>
                  <Pagination
                    hideNextButton
                    hidePrevButton
                    disabled={rowPerPage + offset < sportCount ? false : true}
                    page={currentPage}
                    onChange={handlePaginationClick}
                    count={pageNumbers[pageNumbers?.length - 1]}
                    siblingCount={2}
                    boundaryCount={1}
                    size="small"
                  />
                  <button
                    className={
                      rowPerPage + offset < sportCount
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={rowPerPage + offset < sportCount ? false : true}
                    onClick={() => handlePaginationButtonClick("next")}
                  >
                    <ReactSVG src={arrowRight} />
                  </button>
                </div>
              )}
            </TableContainer>
          )}
        </Paper>

        <ShowModal
          isModalOpen={isModalOpen}
          onClose={toggleModal}
          Content="Are you sure you want to delete?"
          onOkayLabel="Yes"
          onOkay={HandleMarketDelete}
          onCancel={toggleModal}
        />

        <Modal
          className="modal modal-input"
          open={isInputModalOpen}
          onClose={toggleInputModal}
        >
          <div
            className={"paper modal-show-scroll"}
            style={{ position: "relative" }}
          >
            <h3 className="text-center">
              {editMode ? "Edit Market Value" : "Add Market Value"}
            </h3>
            <CancelIcon
              className="admin-close-icon"
              onClick={toggleInputModal}
            />
            <EditMarketdetails
              inputModal={toggleInputModal}
              id={idToSend}
              isEditMode={editMode}
              fetchAllEvents={afterChangeRefresh}
              MarketKey={marketKey}
              bookKeeper={bookKeeper}
              selectedRow={selectedRow}
              marketId={props.match.params.marketId}
            />
          </div>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default Index;
