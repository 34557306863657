import React from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  withStyles,
  Box,
  Tabs,
  Tab,
  Breadcrumbs,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { ReactSVG } from "react-svg";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../../images/blog_img/paginationArrowRight.svg";
import ButtonComponent from "./../../../library/common/components/Button";
import axiosInstance from "./../../../helpers/Axios";
import moment from "moment";
import "./errorLogs.scss";
import Compltedtable from "./compltedtable";
import { Link } from "react-router-dom";
import Inprogresstable from "./inprogresstable";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);
const tabs = [
  {
    id: 0,
    tabsName: "completed Sync",
  },
  {
    id: 1,
    tabsName: "InProgress Sync",
  },
];
const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class RaceErrorLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: "",
      errlgList: [],
      eventLoader: false,
      selectedDate: null,
      value: 0,
      completed: "",
      inprogerss: "",
      newdata: [],
      inProgerssNewData: [],
      currentPage: 1,
      rowPerPage: 20,
      offset: 0,
      sportCount: null,
    };
  }

  componentDidMount = () => {
    this.fetchAllErrorLogs();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState?.selectedDate !== this.state.selectedDate) {
      this.fetchAllErrorLogs();
    }
    if (prevState.offset !== this.state.offset) {
      this.fetchAllErrorLogs();
    }
  };

  searchInputHandler = (e) => {
    this.setState({ searchVal: e.target.value });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  fetchAllErrorLogs = async () => {
    let { selectedDate, rowPerPage, offset } = this.state;
    let date =
      selectedDate === null
        ? moment().format("YYYY-MM-DD")
        : moment(selectedDate).format("YYYY-MM-DD");

    this.setState({ eventLoader: true });
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      const { status, data } = await axiosInstance.get(
        // `/adminNotification/error?date=${date}`
        `/adminNotification/reports?date=${date}&trackOnly=1&timezone=${timezone}&limit=${rowPerPage}&offset=${offset}`
      );

      if (status === 200) {
        console.log("errordata", data?.errors?.rows);
        this.setState({
          errlgList: data.errors.rows,
          eventLoader: false,
          sportCount: data?.errors?.count,
        });
        const completedData = data?.errors?.rows.filter(
          (obj) => obj?.status === "completed"
        );

        const reportMeetingsData = completedData.map((item) => {
          let datas = {
            ...item,
            ReportMeetings: item?.ReportMeetings?.map((obj) => {
              let errorData = {
                ...obj,
                errors: obj?.errors ? JSON.parse(obj?.errors) : [],
              };
              return errorData;
            }),
          };
          return datas;
        });
        const inProgressdData = data?.errors?.rows.filter(
          (obj) => obj?.status === "inprogress"
        );
        const inProgressReportMeetingsData = inProgressdData.map((item) => {
          let datas = {
            ...item,
            ReportMeetings: item?.ReportMeetings?.map((obj) => {
              let errorData = {
                ...obj,
                errors: obj?.errors ? JSON.parse(obj?.errors) : [],
              };
              return errorData;
            }),
          };
          return datas;
        });
        console.log("reportMeetingsData", reportMeetingsData);
        console.log(
          "inProgressReportMeetingsData",
          inProgressReportMeetingsData
        );
        this.setState({
          newdata: reportMeetingsData,
          inProgerssNewData: inProgressReportMeetingsData,
        });
      }
    } catch (error) {
      this.setState({ eventLoader: false });
    }
  };
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  render() {
    const providerList = [
      {
        id: 1,
        name: "PUP",
      },
      {
        id: 2,
        name: "VBT",
      },
      {
        id: 3,
        name: "Neds",
      },
      {
        id: 4,
        name: "Ladbrokes",
      },
      {
        id: 5,
        name: "Bookmaker",
      },
      {
        id: 6,
        name: "BetStar",
      },
      {
        id: 8,
        name: "Bet365",
      },
      {
        id: 9,
        name: "PalmerBet",
      },
      {
        id: 10,
        name: "Unibet",
      },
      {
        id: 11,
        name: "TOP",
      },
    ];

    const { match } = this.props;
    let {
      errlgList,
      searchVal,
      eventLoader,
      selectedDate,
      value,
      newdata,
      sportCount,
      inProgerssNewData,
    } = this.state;

    let errLogData = errlgList;
    // ?.filter((data) => {
    //   if (searchVal === "") return data;
    //   else if (
    //     data?.id
    //       .toString()
    //       .toLowerCase()
    //       .includes(searchVal.toString().toLowerCase())
    //   ) {
    //     return data;
    //   }
    //   return "";
    // });
    // console.log("sportCount", sportCount);
    return (
      <>
        <Grid container className="page-content adminLogin sports_details">
          <Grid item xs={12} className="pageWrapper">
            {/* <Paper className="pageWrapper"> */}
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/admin/dashboard">
                  Home
                </Link>
                <Link underline="hover" color="inherit">
                  racing
                </Link>
                <Typography className="active_p">Error Logs</Typography>
              </Breadcrumbs>
            </Box>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={3}>
                {/* <h1 className="text-left">Racing Error Logs</h1> */}
                <Typography variant="h1" align="left">
                  Racing Error Logs
                </Typography>
              </Grid>
              <Grid item xs={4} className="admin-filter-wrap">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container style={{ justifyContent: "end" }}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      placeholder="MM/DD/YYY"
                      margin="normal"
                      id="date-picker-inline"
                      value={selectedDate}
                      inputVariant="outlined"
                      onChange={this.handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      style={{ marginRight: 5 }}
                      className="details-search-picker"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <input
                  type="text"
                  className=""
                  placeholder="search"
                  value={searchVal}
                  onChange={(e) => {
                    this.searchInputHandler(e);
                  }}
                  style={{
                    fontSize: "16px",
                    borderRadius: "3px",
                    minHeight: "40px",
                    border: "1px solid #ddd",
                    paddingLeft: "10px",
                    marginLeft: "-100px",
                    marginTop: "4px",
                    width: "370px",
                    color: "#D4D6D8",
                  }}
                />
              </Grid>

              <Grid
                item
                xs={1}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <ButtonComponent
                  className="admin-btn-green"
                  color="primary"
                  value="Submit"
                  onClick={() => {
                    // console.log("search click");
                  }}
                />
              </Grid>
            </Grid>
            <Box className="sport-tab error-tab">
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                className="racing-tab-detail"
                disableRipple
                disableFocusRipple
              >
                {tabs?.map((item, index) => {
                  return (
                    <>
                      <Tab
                        disableRipple
                        disableFocusRipple
                        label={item?.tabsName}
                        value={item?.id}
                        className={item?.id == value ? "active" : ""}
                        onChange={(event, newValue) =>
                          this.handleChange(event, item?.id)
                        }
                      />
                    </>
                  );
                })}
              </Tabs>
            </Box>
            {value == 0 ? (
              <Compltedtable newdata={newdata} sportCount={sportCount} />
            ) : value == 1 ? (
              <Inprogresstable inProgerssNewData={inProgerssNewData} />
            ) : (
              ""
            )}
            {/* <Box mt={3} mb={3}>
                {eventLoader ? (
                  <Box
                    mt={3}
                    mb={3}
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <CircularProgress size={18} />
                  </Box>
                ) : errLogData?.length > 0 ? (
                  <Box>
                    <TableContainer component={Paper}>
                      <Table
                        className="listTable api-provider-listTable"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>DID</TableCell>
                            <TableCell>RaceId</TableCell>
                            <TableCell>EventId</TableCell>
                            <TableCell>Status Code</TableCell>
                            <TableCell>Error Type</TableCell>
                            <TableCell>Provider</TableCell>
                            <TableCell>Log</TableCell>
                            <TableCell>Created At</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {errLogData?.map((data, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{data?.id}</TableCell>
                                <TableCell>{data?.raceId}</TableCell>
                                <TableCell>{data?.eventId}</TableCell>
                                <TableCell>{data?.statusCode}</TableCell>
                                <TableCell>{data?.errorType}</TableCell>
                                <TableCell>
                                  {
                                    providerList?.filter(
                                      (obj) => obj.id === data?.providerId
                                    )[0]?.name
                                  }
                                </TableCell>
                                <TableCell>{data?.log}</TableCell>
                                <TableCell>
                                  {moment(data?.createdAt).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ) : (
                  <Typography align="center">Data Not Available</Typography>
                )}
              </Box> */}
            {/* </Paper> */}
          </Grid>
        </Grid>
      </>
    );
  }
}
export default RaceErrorLogs;
