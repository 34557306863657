import React, { Component } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import Grid from "@material-ui/core/Grid";

import { InputField } from "../index";
import TextEditor from "../textEditor/TextEditor";
import DateInput from "../DateInput";
import Dropdown from "../Dropdown";
import ButtonComponent from "../Button";
import VariationsInput from "../../../../components/common/VariationsInput/VariationsInput";

export default class Form extends Component {
  state = {
    formData: {},
    isFormValid: false,
  };

  componentDidMount() {
    this.prepareFormData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.values !== prevProps.values) {
      this.prepareFormData();
    }
  }

  handleChange = (value, field, error) => {
    const formData = cloneDeep(this.state.formData);
    formData[field] = value;
    formData[field + "Error"] = error && error.error;
    this.setState({
      formData,
      isFormValid: this.validateForm(formData),
    });
    if (this.props.onChange) {
      this.props.onChange(field, value);
    }
  };

  validateForm = (formData) => {
    const { model } = this.props;
    let isFormValid = true;
    model.forEach((item) => {
      if (item.required) {
        isFormValid = isFormValid && !formData[item.field + "Error"];
      }
    });
    return isFormValid;
  };

  getFormData = () => {
    const { formData, isFormValid } = this.state;
    return { formData, isFormValid };
  };

  resetForm = () => {
    this.prepareFormData();
  };

  prepareFormData() {
    const { model, values } = this.props;
    const formData = {};
    if (Object.keys(values).length !== 0) {
      model.forEach((item) => {
        formData[item.field] =
          values &&
          (values[item.field] || values[item.field] === 0) &&
          values[item.field] !== ""
            ? values[item.field]
            : "";
        formData[item.field + "Error"] =
          values &&
          values[item.field] &&
          values[item.field !== ""] &&
          item.required
            ? true
            : false;
      });
      this.setState({ formData, isFormValid: this.validateForm(formData) });
    } else {
      model.forEach((item) => {
        formData[item.field] =
          values && values[item.field] ? values[item.field] : "";
        formData[item.field + "Error"] = item.required;
      });

      this.setState({ formData, isFormValid: this.validateForm(formData) });
    }
  }

  renderFormFields() {
    const { model } = this.props;
    const { formData } = this.state;
    const arrayOfFields = [];

    model.forEach((item, key) => {
      switch (item.type) {
        case "url":
        case "text":
        case "file":
        case "email":
        case "number":
        case "password":
        case "textarea":
          arrayOfFields.push(
            <Grid item key={key} xs={item.className} className={"form-group"}>
              <InputField
                field={item.field}
                inputValue={
                  formData[item.field] || formData[item.field] === 0
                    ? formData[item.field]
                    : ""
                }
                type={item.type}
                label={item.label}
                testId={item.testId}
                placeholder={item.placeholder}
                validators={item.validators}
                onChange={this.handleChange}
                extraProps={item.extraProps && item.extraProps}
              />
              {typeof item.errorMessage !== "undefined" &&
                item.errorMessage !== "" && (
                  <p className="errorText" style={{ margin: "-20px 0 0 0" }}>
                    {item.errorMessage}
                  </p>
                )}
            </Grid>
          );
          break;
        case "editor":
          arrayOfFields.push(
            <Grid item key={key} xs={item.className} className={"form-group"}>
              <TextEditor
                field={item.field}
                updateContent={this.handleChange}
                updatedBookingMessageList={
                  formData[item.field] ? formData[item.field] : ""
                }
              />
              {typeof item.errorMessage !== "undefined" &&
                item.errorMessage !== "" && (
                  <p className="errorText" style={{ margin: "0" }}>
                    {item.errorMessage}
                  </p>
                )}
            </Grid>
          );
          break;
        case "dropdown":
          arrayOfFields.push(
            <Grid item key={key} xs={item.className} className={"form-group"}>
              <Dropdown
                onChange={this.handleChange}
                // options={dropdownData && dropdownData[item.field]}
                options={item.options}
                label={item.label}
                testId={item.testId}
                placeholder={item.placeholder}
                validators={item.validators}
                field={item.field}
                value={formData[item.field] ? formData[item.field] : ""}
                isMulti={item.isMulti}
                isSearchable={item.isSearchable}
                isDisabled={item.isDisabled}
                closeMenuOnSelect={item.closeMenuOnSelect}
                extraProps={item.extraProps}
              />
              {typeof item.errorMessage !== "undefined" &&
                item.errorMessage !== "" && (
                  <p className="errorText" style={{ margin: "-15px 0 0 0" }}>
                    {item.errorMessage}
                  </p>
                )}
            </Grid>
          );
          break;
        case "date":
          arrayOfFields.push(
            <Grid item key={key} xs={item.className} className={"form-group"}>
              <DateInput
                label={item.label}
                testId={item.testId}
                validators={item.validators}
                field={item.field}
                value={
                  formData[item.field] ? new Date(formData[item.field]) : ""
                }
                disabled={item.disabled}
                onChange={this.handleChange}
              />
            </Grid>
          );
          break;
        case "variations":
          arrayOfFields.push(
            <Grid item key={key} xs={item.className} className={"form-group"}>
              <label>{item.label}</label>
              <VariationsInput
                label={item.label}
                testId={item.testId}
                validators={item.validators}
                field={item.field}
                value={formData[item.field]}
                disabled={item.disabled}
                onChange={this.handleChange}
              />
            </Grid>
          );
          break;
        case "button":
          arrayOfFields.push(
            <Grid
              item
              key={key}
              xs={item.className}
              className={`${item?.parentClasses} form-group`}
              style={{ marginTop: "30px" }}
            >
              <ButtonComponent
                color={item.color}
                value={item.value}
                onClick={item.onClick}
              />
            </Grid>
          );
          break;
        default:
          break;
      }
    });

    return arrayOfFields;
  }

  render() {
    return (
      <Grid container spacing={1} data-test={this.props.testId}>
        {this.renderFormFields()}
      </Grid>
    );
  }
}

Form.defaultProps = {
  values: {},
  testId: "",
  dropdownData: {},
};

Form.propTypes = {
  model: PropTypes.array.isRequired,
  values: PropTypes.object,
  testId: PropTypes.string,
  dropdownData: PropTypes.object,
};
