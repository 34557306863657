import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { UsersFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
// import LocationSearchInput from "../../../components/common/LocationSearchInput/LocationSearchInput";
import { setValidation } from "../../../helpers/common";

let UsersFormModelArray = UsersFormModel;

class CreateUsers extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        firstName: "",
        lastName: "",
        username: "",
        password: "",
        role: "member",
        addressid: "",
        otpcode: "",
        isVarify: "false",
      },
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentUser(this.props.id);
    }
  }

  componentWillUnmount() {
    UsersFormModelArray = UsersFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchCurrentUser = async (id) => {
    const { status, data } = await axiosInstance.get(URLS.users + `/${id}`);
    if (status === 200) {
      this.setState({ values: data });
      this.setState(() => {
        return {
          values: {
            ...this.state.values,
            ["isVarify"]: data.isVarify === true ? true : "false",
          },
        };
      });
    }
  };

  // back = () => {
  //   this.props.history.push("/admin/Users");
  // };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    this.setActionMessage();
    let { firstName, lastName, username, password, role, isVarify } =
      this.state.values;
    let flag = true;

    if (
      firstName === "" ||
      lastName === "" ||
      username === "" ||
      password === "" ||
      role === "" ||
      isVarify === ""
    ) {
      flag = false;
      this.setState({ isLoading: false });
    } else {
      flag = true;
    }

    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;

    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode ? `${URLS.users}/${this.props.id}` : URLS.register;

      let values = removeErrorFieldsFromValues(form.formData);
      let address = this.state.address;
      values = {
        ...values,
        address: {
          addressLine1: address?.address,
          addressLine2: address?.addressLine2,
          state: address?.state,
          country: address?.country,
          postCode: address?.postCode,
        },
      };

      UsersFormModelArray = UsersFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        this.setState({ isLoading: true });
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.props.inputModal();
          this.props.fetchAllUsers();
          this.setActionMessage(
            true,
            "Success",
            `Users ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
        this.setState({ isLoading: false });
      }
    } catch (err) {
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${isEditMode ? "editing" : "creating"} Users`
      );
      this.setState({ isLoading: false });
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    UsersFormModelArray = UsersFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  setAddress = (field, address) => {
    this.setState({ address: address });
  };

  render() {
    var { values, messageBox, isLoading } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={UsersFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />
              {/* <LocationSearchInput 
                handleChange={(addressObj) => this.handleChange('address',addressObj)}
                address={this.state.address}
                onChange={(addressObj) => this.setAddress('address',addressObj)}
              /> */}

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateUsers;
