import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Modal,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import axiosInstance from "../../../helpers/Axios";
import { Loader } from "../../../library/common/components";
import moment from "moment";
import CancelIcon from "@material-ui/icons/Cancel";
import { MdKeyboardBackspace } from "react-icons/md";
import EditEventDetails from "./CreateEvent";
import CreateApiEventIdentifire from "../../apiEventIdentifire/CreateApiEventIdentifire";
import { URLS } from "../../../library/common/constants";
import ShowModal from "../../../components/common/ShowModal/ShowModal";
import CreateResult from "../createResult";
import _ from "lodash";

class SportsEventsCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardsDetails: [],
      mainEvent: "",
      isLoading: false,
      isInputModalOpen: false,
      identifireModalOpen: false,
      editMode: false,
      leagueId: null,
      participantsTypeId: null,
      idToSend: null,
      selectedRow: {},
      allPlayers: [],
      identifireDetails: [],
      eventIdToSend: null,
      isInputFormModalOpen: false,
      IdentifierEdit: false,
      IdentifierIdToSend: null,
      allEvents: [],
      allProvider: [],
      locationAll: [],
      allWeightClass: [],
      deleteFightModalOpen: false,
      deleteIdentifierModalOpen: false,
      IdentifierToDelete: null,
      FightToDelete: null,
      selectedEvent: "",
      searchIdentifire: "",
      isDeleteLoading: "",
      resultModalOpen: false,
      resultData: null,
      isResultCreateModal: false,
      participantsToSend: [],
      isResultLoading: false,
      resultToDelete: null,
      resultDeleteModal: false,
    };
  }

  componentDidMount() {
    this.fetchCardDetails();
    //this.fetchEvents();
    this.fetchAllBookkeepers();
    //this.fetchAllLocation();
    this.fetchAllWeightClass();
  }

  fetchAllLocation = async () => {
    const { status, data } = await axiosInstance.get(URLS.location);
    if (status === 200) {
      this.setState({ locationAll: data.result });
    }
  };

  toggleFightModal = () => {
    this.setState({
      deleteFightModalOpen: !this.state.deleteFightModalOpen,
      FightToDelete: null,
    });
  };

  toggleIdentifierModal = () => {
    this.setState({
      deleteIdentifierModalOpen: !this.state.deleteIdentifierModalOpen,
      IdentifierToDelete: null,
    });
  };

  setFightToDelete = (id) => () => {
    this.setState({ FightToDelete: id, deleteFightModalOpen: true });
  };

  setIdentifierToDelete = (id) => () => {
    this.setState({ IdentifierToDelete: id, deleteIdentifierModalOpen: true });
  };

  afterChangeRefresh = () => {
    this.showIdentifire();
  };

  toggleInputModal = () => {
    this.setState({ isInputModalOpen: !this.state.isInputModalOpen });
  };

  toggleInputFormModal = () => {
    this.setState({ isInputFormModalOpen: !this.state.isInputFormModalOpen });
  };

  toggleIdentifireModal = () => {
    this.setState({ identifireModalOpen: !this.state.identifireModalOpen });
  };

  async fetchEvents() {
    // const { status, data } = await axiosInstance.get(URLS.events);
    // if (status === 200) {
    //   this.setState({ allEvents: data.result });
    // }
    this.setState({ allEvents: [] });
  }

  fetchAllWeightClass = async () => {
    try {
      const { status, data } = await axiosInstance.get("/sports/weightClass");
      if (status === 200) {
        this.setState({ allWeightClass: data?.result });
      }
    } catch (err) {
      // console.log(err);
    }
  };

  async fetchAllBookkeepers() {
    const { status, data } = await axiosInstance.get(URLS.apiProvider);
    if (status === 200) {
      let provider_sort = data.result?.sort(function (a, b) {
        if (a.providerName.toLowerCase() < b.providerName.toLowerCase()) {
          return -1;
        }
        if (a.providerName.toLowerCase() > b.providerName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      this.setState({ allProvider: provider_sort });
    }
  }

  fetchAllPlayers = async () => {
    let { leagueId } = this.state;
    const { status, data } = await axiosInstance.get(
      `/sports/league/players/${leagueId}`
    );

    if (status === 200) {
      let player_sort = data.result?.sort(function (a, b) {
        if (a?.Player?.name.toLowerCase() < b?.Player?.name.toLowerCase()) {
          return -1;
        }
        if (a?.Player?.name.toLowerCase() > b?.Player?.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      this.setState({
        allPlayers: player_sort,
      });
    }
  };

  showIdentifire = async (id) => {
    let { eventIdToSend } = this.state;

    let getId = eventIdToSend === null ? id : eventIdToSend;
    this.setState({
      isLoading: true,
    });
    try {
      const { status, data } = await axiosInstance.get(
        `/event/identifier/eventid/${getId}`
      );
      if (status === 200) {
        this.setState({
          identifireDetails: data.eventIdentifiers,
          isLoading: false,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isLoading: false });
    }
  };

  handleIdentifireDelete = async (id) => {
    this.setState({
      isDeleteLoading: "identifireDelete",
      deleteIdentifierModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `/event/identifier/${this.state.IdentifierToDelete}`
      );
      if (status === 200) {
        this.setState({
          IdentifierToDelete: null,
          isDeleteLoading: "",
        });
        this.afterChangeRefresh();
      }
    } catch (err) {}
  };

  inputFormModal = (id, type) => () => {
    this.setState({
      isInputFormModalOpen: true,
      idToSend: id,
      IdentifierEdit: type,
    });
  };

  getProvider = (id) => {
    let { allProvider } = this.state;
    let provider = allProvider
      .filter((obj) => obj.id === id)
      .map((object) => object.providerName);
    return provider;
  };

  fetchCardDetails = async () => {
    let { eventId } = this.props.match.params;
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(
      `/events/cardfight/${eventId}`
    );
    if (status === 200) {
      let raw_data = data.result;

      this.setState({
        leagueId: data.mainevent?.leagueId,
        participantsTypeId: data.result[0]?.Participants[0]?.participantsTypeId
          ? data.result[0]?.Participants[0]?.participantsTypeId
          : 1,
      });
      this.fetchAllPlayers();
      let data_clone = [];
      let string_array = [];
      raw_data.map((element) => {
        if (
          string_array.includes(
            element.eventOption?.length > 0 && element.eventOption[0].value
          )
        ) {
          let index = string_array.indexOf(
            element.eventOption?.length > 0 && element.eventOption[0].value
          );
          let item_copy = data_clone[index].array_item;
          item_copy.push(element);
          let obj = {
            valueName:
              element.eventOption?.length > 0 && element.eventOption[0].value,
            array_item: item_copy,
          };
          data_clone[index] = obj;
        } else {
          let obj = {
            valueName:
              element.eventOption?.length > 0
                ? element.eventOption[0].value
                : "No Card",
            array_item: [element],
          };
          data_clone.push(obj);
          string_array.push(
            element.eventOption?.length > 0 && element.eventOption[0].value
          );
        }
      });
      data_clone = _.orderBy(data_clone, ["valueName"], ["asc"]);
      let dummy_array = data_clone.map((obj) => {
        return obj.array_item?.sort(function (a, b) {
          if (a.eventName.toLowerCase() < b.eventName.toLowerCase()) {
            return -1;
          }
          if (a.eventName.toLowerCase() > b.eventName.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      });
      this.setState({
        cardsDetails: data_clone,
        isLoading: false,
        mainEvent: data.mainevent.eventName,
      });
    }
  };

  DeleteFight = async (id) => {
    this.setState({
      isDeleteLoading: "fightDelete",
      deleteFightModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `/events/fight/delete/${this.state.FightToDelete}`
      );
      if (status === 200) {
        this.setState({ FightToDelete: null, isDeleteLoading: "" });
        this.fetchCardDetails();
      }
    } catch (err) {}
  };

  backToNavigatePage = () => {
    const { name, id } = this.props.match.params;
    this.props.history.push(`/admin/sport/fixture/${name}/${id}`);
  };

  toggleResultFormModal = () => {
    this.setState({ isResultCreateModal: !this.state.isResultCreateModal });
  };

  toggleResultModal = () => {
    this.setState({ resultModalOpen: !this.state.resultModalOpen });
  };

  afterChangeRefreshResult = () => {
    this.fetchResultByEvent(this.state.eventIdToSend);
  };

  setResultToDelete = (id) => {
    this.setState({ resultToDelete: id, resultDeleteModal: true });
  };

  toggleResultDeleteModal = () => {
    this.setState({
      resultDeleteModal: !this.state.resultDeleteModal,
      resultToDelete: null,
    });
  };

  resultFormModal = (id, type) => () => {
    this.setState({
      isResultCreateModal: true,
      idToSend: id,
      editMode: type,
    });
  };

  fetchResultByEvent = async (id) => {
    this.setState({ isResultLoading: true });
    try {
      const { status, data } = await axiosInstance.get(`/sports/result/${id}`);
      if (status === 200) {
        this.setState({ resultData: data?.message, isResultLoading: false });
      }
    } catch (err) {
      // console.log(err);
    }
  };

  handleResultDelete = async () => {
    this.setState({
      isDeleteLoading: "resultDelete",
      resultDeleteModal: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `/sports/result/${this.state.resultToDelete}`
      );
      if (status === 200) {
        this.setState({ isDeleteLoading: "", resultToDelete: null });
        this.afterChangeRefreshResult();
      }
    } catch (err) {}
  };

  getResultPlayer = (id) => {
    const { participantsToSend } = this.state;
    let player = "";
    let obj = participantsToSend?.find((obj) => obj?.Team?.id === id);
    player = obj && obj?.Team && obj?.Team?.name;
    return player;
  };

  render() {
    const { match } = this.props;
    let {
      cardsDetails,
      isLoading,
      mainEvent,
      isInputModalOpen,
      identifireModalOpen,
      editMode,
      leagueId,
      idToSend,
      selectedRow,
      allPlayers,
      eventIdToSend,
      identifireDetails,
      IdentifierEdit,
      IdentifierIdToSend,
      isInputFormModalOpen,
      allEvents,
      allProvider,
      participantsTypeId,
      deleteFightModalOpen,
      FightToDelete,
      deleteIdentifierModalOpen,
      locationAll,
      selectedEvent,
      searchIdentifire,
      isDeleteLoading,
      resultModalOpen,
      resultData,
      isResultCreateModal,
      participantsToSend,
      isResultLoading,
      resultDeleteModal,
      allWeightClass,
    } = this.state;

    searchIdentifire !== "" &&
      (identifireDetails = identifireDetails?.filter(
        (obj) =>
          obj?.apiEventId
            ?.toString()
            .toLowerCase()
            .includes(searchIdentifire.toString().toLowerCase()) ||
          obj?.id
            ?.toString()
            .toLowerCase()
            .includes(searchIdentifire.toString().toLowerCase())
      ));

    let sportName = match?.params?.name;
    let sportId = match?.params?.id;

    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              {isDeleteLoading === "fightDelete" && (
                <div class="admin-delete-loader">
                  <Loader />
                </div>
              )}
              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={12}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Button
                        className="admin-btn-back"
                        onClick={this.backToNavigatePage}
                      >
                        <MdKeyboardBackspace />
                      </Button>
                      <h3 className="text-left admin-page-heading">
                        Event Cards ( {mainEvent} )
                      </h3>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#00833e", color: "#fff" }}
                        onClick={() => {
                          this.setState({ editMode: false });
                          this.toggleInputModal();
                        }}
                      >
                        Add Fight
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && cardsDetails.length === 0 && (
                <p>No Data Available</p>
              )}

              {!isLoading &&
                cardsDetails.length > 0 &&
                cardsDetails
                  ?.filter((data) => data.valueName !== "No Card")
                  .map((obj) => (
                    <>
                      <h4>{obj?.valueName}</h4>
                      <TableContainer component={Paper}>
                        <Table
                          className="listTable api-provider-listTable"
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>DID</TableCell>
                              <TableCell>Fight Name</TableCell>
                              <TableCell>Fighter A</TableCell>
                              <TableCell>Fighter B</TableCell>
                              <TableCell>Fight Date</TableCell>
                              <TableCell>Weight Class</TableCell>
                              <TableCell>View/Add Identifier</TableCell>
                              <TableCell>Edit</TableCell>
                              <TableCell>Delete</TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                Result
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {obj?.array_item?.map((item, i) => (
                              <TableRow key={i}>
                                <TableCell>{item?.id}</TableCell>
                                <TableCell>{item?.eventName}</TableCell>
                                <TableCell>
                                  {item?.Participants?.length > 1
                                    ? item?.Participants[0]?.Team?.name
                                    : ""}
                                </TableCell>
                                <TableCell>
                                  {item?.Participants?.length > 1
                                    ? item?.Participants[1]?.Team?.name
                                    : ""}
                                </TableCell>
                                <TableCell>
                                  {moment(item?.eventToDate).format(
                                    "DD/MM/YYYY hh:mm:ss a"
                                  )}
                                </TableCell>
                                <TableCell>{item?.description}</TableCell>
                                <TableCell>
                                  <Button
                                    style={{
                                      fontSize: "9px",
                                      backgroundColor: "#ff6b00",
                                      color: "#fff",
                                      fontWeight: "400",
                                      textTransform: "none",
                                      padding: "5px 5px",
                                    }}
                                    onClick={async () => {
                                      await this.setState({
                                        identifireModalOpen: true,
                                        eventIdToSend: item?.id,
                                        selectedEvent: item?.eventName,
                                        searchIdentifire: "",
                                      });
                                      this.showIdentifire(item?.id);
                                    }}
                                  >
                                    View/Add Identifier
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <EditIcon
                                    onClick={() => {
                                      this.toggleInputModal();
                                      this.setState({
                                        editMode: true,
                                        idToSend: item?.id,
                                        selectedRow: item,
                                      });
                                    }}
                                    color="primary"
                                    style={{
                                      borderRadius: "50%",
                                      fontSize: 20,
                                      padding: 4,
                                    }}
                                    className="mr10 cursor iconBtn admin-btn-green"
                                  />
                                </TableCell>
                                <TableCell>
                                  <DeleteOutlineIcon
                                    onClick={this.setFightToDelete(item?.id)}
                                    color="primary"
                                    style={{
                                      borderRadius: "50%",
                                      fontSize: 20,
                                      padding: 4,
                                    }}
                                    className="mr10 cursor iconBtn admin-btn-orange"
                                  />
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {moment(item?.eventToDate).isBefore(
                                    new Date()
                                  ) === true ? (
                                    <Button
                                      style={{
                                        fontSize: "9px",
                                        backgroundColor: "#ff6b00",
                                        color: "#fff",
                                        fontWeight: "400",
                                        textTransform: "none",
                                        padding: "5px 0",
                                      }}
                                      onClick={() => {
                                        this.fetchResultByEvent(item?.id);
                                        let player_sort = _.orderBy(
                                          item.Participants,
                                          ["Team.name"],
                                          ["asc"]
                                        );
                                        this.setState({
                                          resultModalOpen: true,
                                          eventIdToSend: item?.id,
                                          selectedEvent: item?.eventName,
                                          participantsToSend: player_sort,
                                        });
                                      }}
                                    >
                                      Result
                                    </Button>
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ))}
              {!isLoading &&
                cardsDetails.length > 0 &&
                cardsDetails
                  ?.filter((data) => data.valueName === "No Card")
                  .map((obj) => (
                    <>
                      <h4>{obj?.valueName}</h4>
                      <TableContainer component={Paper}>
                        <Table
                          className="listTable api-provider-listTable"
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>DID</TableCell>
                              <TableCell>Fight Name</TableCell>
                              <TableCell>Fighter A</TableCell>
                              <TableCell>Fighter B</TableCell>
                              <TableCell>Fight Date</TableCell>
                              <TableCell>Weight Class</TableCell>
                              <TableCell>View/Add Identifier</TableCell>
                              <TableCell>Edit</TableCell>
                              <TableCell>Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {obj?.array_item?.map((item, i) => (
                              <TableRow key={i}>
                                <TableCell>{item?.id}</TableCell>
                                <TableCell>{item?.eventName}</TableCell>
                                <TableCell>
                                  {item?.Participants?.length > 1
                                    ? item?.Participants[0]?.Team?.name
                                    : ""}
                                </TableCell>
                                <TableCell>
                                  {item?.Participants?.length > 1
                                    ? item?.Participants[1]?.Team?.name
                                    : ""}
                                </TableCell>
                                <TableCell>
                                  {moment(item?.eventToDate).format(
                                    "DD/MM/YYYY hh:mm:ss a"
                                  )}
                                </TableCell>
                                <TableCell>{item?.description}</TableCell>
                                <TableCell>
                                  <Button
                                    style={{
                                      fontSize: "9px",
                                      backgroundColor: "#ff6b00",
                                      color: "#fff",
                                      fontWeight: "400",
                                      textTransform: "none",
                                      padding: "5px 5px",
                                    }}
                                    onClick={async () => {
                                      await this.setState({
                                        identifireModalOpen: true,
                                        eventIdToSend: item?.id,
                                        selectedEvent: item?.eventName,
                                      });
                                      this.showIdentifire(item?.id);
                                    }}
                                  >
                                    View/Add Identifier
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <EditIcon
                                    onClick={() => {
                                      this.toggleInputModal();
                                      this.setState({
                                        editMode: true,
                                        idToSend: item?.id,
                                        selectedRow: item,
                                      });
                                    }}
                                    color="primary"
                                    style={{
                                      borderRadius: "50%",
                                      fontSize: 20,
                                      padding: 4,
                                    }}
                                    className="mr10 cursor iconBtn admin-btn-green"
                                  />
                                </TableCell>
                                <TableCell>
                                  <DeleteOutlineIcon
                                    onClick={this.setFightToDelete(item?.id)}
                                    color="primary"
                                    style={{
                                      borderRadius: "50%",
                                      fontSize: 20,
                                      padding: 4,
                                    }}
                                    className="mr10 cursor iconBtn admin-btn-orange"
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ))}
            </Paper>

            <ShowModal
              isModalOpen={deleteFightModalOpen}
              onClose={this.toggleFightModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.DeleteFight}
              onCancel={this.toggleFightModal}
            />

            <Modal
              className="modal modal-input"
              open={isInputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {editMode ? "Edit Fight" : "Add Fight"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />

                <EditEventDetails
                  inputModal={this.toggleInputModal}
                  id={idToSend}
                  isEditMode={editMode}
                  fetchAllRunners={this.fetchCardDetails}
                  leagueID={leagueId}
                  sportId={this.props.match.params.id}
                  parent_event_id={this.props.match.params.eventId}
                  participantsTypeId={participantsTypeId}
                  selectedRow={selectedRow}
                  allPlayers={allPlayers}
                  locationAll={locationAll}
                  allWeightClass={allWeightClass}
                />
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={identifireModalOpen}
              onClose={this.toggleIdentifireModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <Grid container>
                  <Grid item xs={7}>
                    <h3 className="text-center">{`Bookmaker Feed Identifiers (${selectedEvent})`}</h3>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ marginTop: "15px" }}>
                      <input
                        type="text"
                        placeholder="search"
                        value={searchIdentifire}
                        onChange={(e) => {
                          this.setState({
                            ...this.state.searchIdentifire,
                            searchIdentifire: e.target.value,
                          });
                        }}
                        style={{
                          fontSize: "16px",
                          borderRadius: "3px",
                          minHeight: "30px",
                          border: "1px solid #ddd",
                          marginRight: "15px",
                          marginLeft: "15px",
                        }}
                      />
                      <Button
                        style={{
                          textTransform: "none",
                          height: "28px",
                          fontSize: "12px",
                          backgroundColor: "#ff6b00",
                          color: "#fff",
                          fontWeight: "400",
                        }}
                        onClick={this.inputFormModal(null, false)}
                      >
                        Add Identifier
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleIdentifireModal}
                />
                {isDeleteLoading === "identifireDelete" && (
                  <div class="admin-delete-modal-loader">
                    <Loader />
                  </div>
                )}
                {isLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
                {!isLoading && identifireDetails.length === 0 && (
                  <p>No Data Available</p>
                )}
                {!isLoading && identifireDetails.length > 0 && (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        className="listTable api-provider-listTable"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>DID</TableCell>
                            <TableCell>Api Event Id</TableCell>
                            <TableCell>Bookmaker</TableCell>
                            <TableCell>CreatedAt</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {identifireDetails?.map((api, i) => (
                            <TableRow key={i}>
                              <TableCell>{api.id}</TableCell>
                              <TableCell>{api.apiEventId}</TableCell>
                              <TableCell>
                                {this.getProvider(api.providerId)}
                              </TableCell>
                              <TableCell>
                                {moment(api.createdAt).format(
                                  "DD/MM/YYYY hh:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell>
                                <EditIcon
                                  onClick={this.inputFormModal(api.id, true)}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-green"
                                />
                              </TableCell>
                              <TableCell>
                                <DeleteOutlineIcon
                                  onClick={this.setIdentifierToDelete(api?.id)}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-orange"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={resultModalOpen}
              onClose={this.toggleResultModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <Grid container>
                  <Grid item xs={10}>
                    <h3>{`Result (${selectedEvent})`}</h3>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      style={{
                        textTransform: "none",
                        height: "28px",
                        fontSize: "12px",
                        backgroundColor: "#ff6b00",
                        color: "#fff",
                        fontWeight: "400",
                        float: "right",
                        marginTop: "15px",
                      }}
                      disabled={resultData === null ? false : true}
                      onClick={this.resultFormModal(null, false)}
                    >
                      Add Result
                    </Button>
                  </Grid>
                </Grid>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleResultModal}
                />
                {isDeleteLoading === "resultDelete" && (
                  <div className="admin-delete-modal-loader">
                    <Loader />
                  </div>
                )}
                {isResultLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
                {!isResultLoading && resultData === null && (
                  <p className="text-center">No Data Available</p>
                )}
                {!isResultLoading && resultData !== null && (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        className="listTable api-provider-listTable"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>DID</TableCell>
                            <TableCell>EventId</TableCell>
                            <TableCell>SportId</TableCell>
                            <TableCell>Winner</TableCell>
                            <TableCell>Loser</TableCell>
                            <TableCell>Round</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Method</TableCell>
                            <TableCell>description</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{resultData?.id}</TableCell>
                            <TableCell>{selectedEvent}</TableCell>
                            <TableCell>{sportName}</TableCell>
                            <TableCell>
                              {this.getResultPlayer(resultData?.winnerId)}
                            </TableCell>
                            <TableCell>
                              {this.getResultPlayer(resultData?.looserId)}
                            </TableCell>
                            <TableCell>{resultData?.round}</TableCell>
                            <TableCell>{resultData?.time}</TableCell>
                            <TableCell>{resultData?.method}</TableCell>
                            <TableCell>{resultData?.description}</TableCell>
                            <TableCell>
                              <EditIcon
                                onClick={this.resultFormModal(
                                  resultData?.id,
                                  true
                                )}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                            </TableCell>
                            <TableCell>
                              <DeleteOutlineIcon
                                onClick={() =>
                                  this.setResultToDelete(resultData?.id)
                                }
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-orange"
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isResultCreateModal}
              onClose={this.toggleResultFormModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {!editMode ? "Create Result" : "Edit Result"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleResultFormModal}
                />
                <CreateResult
                  inputModal={this.toggleResultFormModal}
                  id={idToSend}
                  sportId={sportId}
                  eventIdToSend={eventIdToSend}
                  isEditMode={editMode}
                  participants={participantsToSend}
                  fetchAllResult={this.afterChangeRefreshResult}
                />
              </div>
            </Modal>

            <ShowModal
              isModalOpen={resultDeleteModal}
              onClose={this.toggleResultDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleResultDelete}
              onCancel={this.toggleResultDeleteModal}
            />

            <ShowModal
              isModalOpen={deleteIdentifierModalOpen}
              onClose={this.toggleIdentifierModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleIdentifireDelete}
              onCancel={this.toggleIdentifierModal}
            />

            <Modal
              className="modal modal-input"
              open={isInputFormModalOpen}
              onClose={this.toggleInputFormModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {!IdentifierEdit
                    ? "Create Api Event Identifier"
                    : "Edit Api Event Identifier"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputFormModal}
                />
                <CreateApiEventIdentifire
                  inputModal={this.toggleInputFormModal}
                  id={idToSend}
                  eventIdToSend={eventIdToSend}
                  isEditMode={IdentifierEdit}
                  fetchAllEventIdentifire={this.afterChangeRefresh}
                  isMeetings={true}
                  allEvents={allEvents}
                  allProvider={allProvider}
                />
              </div>
            </Modal>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default SportsEventsCards;
