import {storeConstants} from "../../library/common/constants";

const initialState = {
    category: [],
};

const RegisterReducer = (state = initialState, action) => {
    switch (action.type) {
        case storeConstants.CATEGORY:
            return {
                ...state,
                category: action.payload,
            };
        default:
            return state;
    }
};

export default RegisterReducer;
