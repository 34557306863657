import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import Dropdown from "../../library/common/components/Dropdown";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const adsClick = [
  {
    title: "Main Banner",
    clicks: 34,
  },
  {
    title: "Right Banner Ad",
    clicks: 378,
  },
  {
    title: "Left Banner Ad",
    clicks: 378,
  },
];

export default class AdReports extends Component {
  handleRangeChange = () => {};

  render() {
    return (
      <Grid container className="page-content adminLogin">
        <Grid item xs={12}>
          <Paper className="pageWrapper">
            <Grid container direction="row" alignItems="space-around">
              <Grid item xs={6}>
                <h3 className="text-left">Ad Reports</h3>
              </Grid>
              <Grid item xs={6} style={{ marginTop: "20px" }}>
                <Dropdown
                  options={[
                    { value: "7", label: "Week" },
                    { value: "30", label: "Month" },
                    { value: "90", label: "Quarter" },
                    { value: "365", label: "Year" },
                  ]}
                  onChange={this.handleRangeChange}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              alignItems="space-around"
              style={{ marginTop: "50px" }}
            >
              <Grid item xs={6}>
                <LineChart
                  width={750}
                  height={500}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {/* <Line
                    type="monotone"
                    dataKey="pv"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  /> */}
                  <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                </LineChart>
              </Grid>
              <Grid item xs={6}>
                <TableContainer component={Paper}>
                  <Table className="listTable" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Ad Title</TableCell>
                        <TableCell>Clicks</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {adsClick.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.title}</TableCell>
                          <TableCell>{item.clicks}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
