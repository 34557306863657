import React, { createRef } from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import { trackFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";

let trackFormModelArray = trackFormModel;

const races = [
  { id: 1, name: "Horse Racing" },
  { id: 2, name: "Harness Racing" },
  { id: 3, name: "Greyhound Racing" },
];

class CreateTrack extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        variation: "",
      },
      state: null,
      stateAll: [],
      country: null,
      countryAll: [],
      city: null,
      cityAll: [],
      checkedRace: [],
      error: {},
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentTrack(this.props.id);
    }
    this.fetchAllCountry();
  }

  componentWillUnmount() {
    trackFormModelArray = trackFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchAllCountry = async () => {
    const { status, data } = await axiosInstance.get(URLS.country);
    if (status === 200) {
      this.setState({ countryAll: data.result });
    }
  };

  fetchAllState = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.state + `/country/${id}`
    );
    if (status === 200) {
      this.setState({ stateAll: data.result });
    }
  };

  fetchAllCity = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.cities + `/state/${id}`
    );
    if (status === 200) {
      this.setState({ cityAll: data.result });
    }
  };

  fetchCurrentTrack = async (id) => {
    let { rowToPass } = this.props;
    const { status, data } = await axiosInstance.get(URLS.track + `/${id}`);
    console.log("data", data.result);
    if (status === 200) {
      let temp_checkrace = {};
      let checkRace =
        data?.result?.trackType?.length > 0
          ? data?.result?.trackType?.map((obj) => {
              return (temp_checkrace[obj.sportId] = true);
            })
          : [];
      if (temp_checkrace !== {}) {
        checkRace = temp_checkrace;
      }
      this.setState({
        values: data.result,
        country: data?.result?.countryId,
        state: data?.result?.stateId,
        city: data?.result?.cityId,
        checkedRace: checkRace,
      });
      if (rowToPass?.countryId !== null) {
        this.fetchAllState(rowToPass?.countryId);
      }

      if (rowToPass?.stateId !== null) {
        this.fetchAllCity(rowToPass?.stateId);
      }

      // let variationArray = JSON.parse("[" + data?.result?.variation + "]");
      // if (variationArray.length > 0) {
      this.setState(() => {
        return {
          values: {
            ...this.state.values,
            ["variation"]: data?.result?.variation,
          },
        };
      });
      // }
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { name } = this.state.values;
    // let { state, country, city, error } = this.state;
    let flag = true;

    if (name === "") {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }

    // if (country === null) {
    //   error.country = "This field id mandatory";
    //   flag = false;
    //   this.setState({ isLoading: false });
    // } else {
    //   error.country = "";
    // }

    // if (state === null) {
    //   error.state = "This field is mandatory";
    //   flag = false;
    //   this.setState({ isLoading: false });
    // } else {
    //   error.state = "";
    // }

    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;
    const { country, state, city, checkedRace } = this.state;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode ? `${URLS.track}/${this.props.id}` : `/track`;

      const values = removeErrorFieldsFromValues(form.formData);

      let keyArray = Object.keys(checkedRace);
      let trackType = keyArray?.filter((obj) => checkedRace[obj] === true);

      const DataToCreate = {
        name: values?.name,
        variation: values?.variation,
        stateId: parseInt(state),
        countryId: parseInt(country),
        cityId: parseInt(city),
        trackType: trackType,
      };

      trackFormModelArray = trackFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });
      if (this.validate()) {
        const { status } = await axiosInstance[method](url, DataToCreate);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllTrack();
          this.setActionMessage(
            true,
            "Success",
            `Track ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${isEditMode ? "editing" : "creating"} Track`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    trackFormModelArray = trackFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  handleSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "state") {
      this.setState(
        { state: value /*error: { ...this.state.error, ["state"]: "" }*/ },
        () => this.fetchAllCity(value)
      );
    }
    if (name === "country") {
      this.setState(
        { country: value /*error: { ...this.state.error, ["country"]: "" }*/ },
        () => this.fetchAllState(value)
      );
    }
    if (name === "city") {
      this.setState({
        city: Number(value),
        /*error: { ...this.state.error, ["city"]: "" },*/
      });
    }
  };

  handleTrackRaceChange = (e) => {
    let value = e.target.value;
    let isChecked = e.target.checked;
    this.setState({
      checkedRace: { ...this.state.checkedRace, [value]: isChecked },
    });
  };

  render() {
    var {
      values,
      messageBox,
      isLoading,
      countryAll,
      stateAll,
      cityAll,
      country,
      state,
      city,
      checkedRace,
      // error,
    } = this.state;
    var { isEditMode } = this.props;
    console.log("props", this.props);
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12} className="modal-label">
            {/* <Paper className="pageWrapper api-provider"> */}
            {messageBox.display && (
              <ActionMessage
                message={messageBox.message}
                type={messageBox.type}
                styleClass={messageBox.styleClass}
              />
            )}

            <Form
              values={values}
              model={trackFormModelArray}
              ref={this.formRef}
              onChange={this.handleChange}
            />

            <div
              style={{
                // display: "flex",
                marginBottom: "27px",
                justifyContent: "space-between",
                width: "33%",
              }}
            >
              <label className="modal-label">Track Type</label>
              <div>
                {races?.map((obj, i) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{
                        height: "18px",
                        width: "18px",
                        cursor: "pointer",
                        marginRight: "12px",
                      }}
                      id={obj?.name}
                      value={obj?.id}
                      name={obj?.name}
                      checked={checkedRace[obj?.id] === true ? true : false}
                      onChange={(e) => this.handleTrackRaceChange(e)}
                    />
                    <label
                      htmlFor={obj?.name}
                      style={{
                        cursor: "pointer",
                        fontWeight: "500",
                      }}
                    >
                      {obj?.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="select-box">
              <label className="modal-label">Country</label>
              <select
                className="select-box-manual select-box-arrow"
                name="country"
                onChange={this.handleSelect}
                value={country}
              >
                <option>No Country Selected</option>
                {countryAll?.map((obj, i) => (
                  <option key={i} value={obj.id}>
                    {obj.country}
                  </option>
                ))}
              </select>
              {/* {typeof error.country !== "undefined" &&
                  error.country !== "" && (
                    <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                      {error.country}
                    </p>
                  )} */}
              <label className="modal-label">State</label>
              <select
                className="select-box-manual select-box-arrow"
                name="state"
                onChange={this.handleSelect}
                value={state}
              >
                {this.state.country === null ? (
                  <option>Select Country First</option>
                ) : (
                  <>
                    <option>No State Selected</option>
                    {stateAll?.map((obj, i) => (
                      <option key={i} value={obj.id}>
                        {obj.state}
                      </option>
                    ))}
                  </>
                )}
              </select>
              {/* {typeof error.state !== "undefined" && error.state !== "" && (
                  <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                    {error.state}
                  </p>
                )} */}
              <label className="modal-label">City</label>
              <select
                className="select-box-manual select-box-arrow"
                name="city"
                onChange={this.handleSelect}
                value={city}
              >
                {this.state.state === null ? (
                  <option value={null}>Select State First</option>
                ) : (
                  <>
                    <option value={null}>No City Selected</option>
                    {cityAll?.map((obj, i) => (
                      <option key={i} value={obj.id}>
                        {obj.cityName}
                      </option>
                    ))}
                  </>
                )}
              </select>
              {/* {typeof error.city !== "undefined" && error.city !== "" && (
                  <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                    {error.city}
                  </p>
                )} */}
            </div>

            <Grid container>
              <Grid item xs={3}>
                <div style={{ marginTop: "20px", display: "flex" }}>
                  {!isEditMode ? (
                    <ButtonComponent
                      className="mt-3 admin-btn-purple"
                      onClick={this.handleSave}
                      color="primary"
                      value={!isLoading ? "Save" : "Loading..."}
                      disabled={isLoading}
                    />
                  ) : (
                    <ButtonComponent
                      className="mt-3 admin-btn-purple"
                      onClick={this.handleSave}
                      color="secondary"
                      value={!isLoading ? "Update" : "Loading..."}
                      disabled={isLoading}
                    />
                  )}

                  {/* <ButtonComponent
                    onClick={this.props.inputModal}
                    className="mr-lr-30"
                    value="Back"
                  /> */}
                  <Button
                    className="mr-lr-30 admin-btn-outlined"
                    variant="outlined"
                    // color="primary"
                    onClick={this.props.inputModal}
                  >
                    Back
                  </Button>
                </div>
              </Grid>
            </Grid>
            {/* </Paper> */}
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateTrack;
