import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ButtonComponent from "../../library/common/components/Button";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import ActionMessage from "../../library/common/components/ActionMessage";
import { Loader } from "../../library/common/components";
import ShowModal from "../../components/common/ShowModal/ShowModal";
// import CreateAnimal from "./CreateAnimal";
import { showVariations } from "../../helpers/common";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";
import moment from "moment";

class adminNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNotification: [],
      allProviders: [],
      allSports: [],
      isInputModalOpen: false,
      idToSend: null,
      isModalOpen: false,
      isLoading: false,
      isImportLoadnig: false,
      itemToDelete: null,
      isEditMode: false,
      searchInput: "",
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      sportCount: null,
      offset: 0,
      currentPage: 1,
      rowPerPage: 20,
      sportList: [],
      selectedSport: "",
      sortLabelID: false,
      selectedSort: "",
      selectedSortKeys: "",
      checked: [],
    };
  }

  componentDidMount() {
    this.fetchAllNotification();
    this.fetchAllProvider();
    this.fetchAllSports();
    this.getSports();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.offset !== this.state.offset ||
      prevState.selectedSortKeys != this.state.selectedSortKeys
    ) {
      console.log(
        this.state.selectedSortKeys,
        this.state.sortLabelID,
        "  componentDidUpdate(prevProps, prevState) "
      );
      this.fetchAllNotification();
    }
  }

  getSports = async () => {
    try {
      const { status, data } = await axiosInstance.get("/sports/sport");
      if (status === 200) {
        this.setState({
          sportList: data.result.filter((x) => x.sportName !== null),
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  fetchAllNotification = async () => {
    let { rowPerPage, offset, selectedSortKeys } = this.state;
    const sortQueryRequest = selectedSortKeys
      ? `&sort=${JSON.stringify(selectedSortKeys)}`
      : "";

    const { status, data } = await axiosInstance.get(
      `adminNotification?limit=${rowPerPage}&offset=${offset}&search=${this.state.searchInput}&sportId=${this.state.selectedSport}`
    );
    if (status === 200) {
      this.setState({
        adminNotification: data.result,
        sportCount: data.totalRows,
      });
    }
  };

  selectLogHandler = (value) => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked.filter((x) => x !== 0)];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ checked: newChecked });
  };

  fetchAllProvider = async () => {
    const { status, data } = await axiosInstance.get(URLS.apiProvider);
    if (status === 200) {
      this.setState({ allProviders: data.result });
    }
  };

  // getProvider = (id) => {
  //   let { allProviders } = this.state;
  //   const provider = allProviders?.find((element) => {
  //     element.id = id;
  //   });
  //   return provider.providerName;
  // };

  fetchAllSports = async () => {
    const { status, data } = await axiosInstance.get(URLS.sports);
    if (status === 200) {
      this.setState({ allSports: data.result });
    }
  };

  sortLabelHandler = (e, sortKey) => {
    let { sortLabelID, selectedSort, selectedSortKeys } = this.state;

    this.setState({ sortLabelID: !sortLabelID });
    this.setState({
      selectedSortKeys: { key: sortKey, order: sortLabelID ? "desc" : "asc" },
    });
    console.log(
      this.state.selectedSortKeys,
      this.state.sortLabelID,
      "  update the sort"
    );
    //this.fetchAllNotification();
  };
  // getSports = (id) => {
  //   let { allSports } = this.state;
  //   const sport = allSports?.find((element) => {
  //     element.id = id;
  //   });
  //   return sport.sportName;
  // };

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      itemToDelete: null,
    });
  };

  toggleInputModal = () => {
    this.setState({
      isInputModalOpen: !this.state.isInputModalOpen,
    });
  };

  inputModal = (id, type) => () => {
    this.setState({ isInputModalOpen: true });
    if (type === "edit") {
      this.setState({ idToSend: id, isEditMode: true });
    } else {
      this.setState({ isEditMode: false });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } }, () =>
      setTimeout(
        () =>
          this.setState({
            messageBox: { display: false, type: "", message: "" },
          }),
        3000
      )
    );
  };

  setItemToDelete = (id) => () => {
    this.setState({ itemToDelete: id, isModalOpen: true });
  };

  afterChangeRefresh = () => {
    this.fetchAllNotification();
  };

  deleteItem = async () => {
    try {
      const { status } = await axiosInstance.delete(
        `${"adminNotification"}/${this.state.itemToDelete}`
      );
      if (status === 200) {
        this.setState({ itemToDelete: null, isModalOpen: false }, () => {
          this.fetchAllNotification();
        });
        this.setActionMessage(
          true,
          "Success",
          "Notification Deleted Successfully!"
        );
      }
    } catch (err) {
      this.setActionMessage(true, "Error", "An error occurred while deleting.");
    }
  };

  handlePaginationClick = (event, page) => {
    let { rowPerPage, offset } = this.state;
    this.setState({
      currentPage: Number(page),
      offset: (Number(page) - 1) * rowPerPage,
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, rowPerPage, adminNotification, offset } = this.state;
    // if (navDirection === "prev") {
    //   if (currentPage > 1) {
    //     this.setState({ currentPage: currentPage - 1 });
    //   }
    // } else {
    //   if (currentPage < adminNotification.length / rowPerPage)
    //     this.setState({ currentPage: currentPage + 1 });
    // }
    console.log(
      this.state.selectedSortKeys,
      this.state.sortLabelID,
      "pagination"
    );
    if (navDirection === "prev") {
      if (offset >= 20) {
        this.setState({ offset: offset - 20, currentPage: currentPage - 1 });
      }
    } else {
      this.setState({ offset: offset + 20, currentPage: currentPage + 1 });
    }
  };

  handleImportFixture = async (sportId, providerId, meetingName) => {
    let params = {
      providerId: providerId,
      sportId: sportId,
      meetings: [meetingName],
    };
    this.setState({ isImportLoadnig: true });
    try {
      const { status, data } = await axiosInstance.post(
        URLS.importFixtures,
        params
      );
      if (status === 200) {
        this.state({ isImportLoadnig: false });
      }
    } catch (err) {
      this.setState({ isImportLoadnig: false });
    }
  };

  // DELETE SELECTED ERROR LOGS
  deleteSelectedHandler = async () => {
    try {
      let { status, data } = await axiosInstance.delete(
        "/adminNotification/delete/multiple",
        {
          headers: {},
          data: this.state.checked,
        }
      );

      if (status === 200) {
        this.fetchAllNotification();
        this.setState({ checked: [] });
      }
    } catch (err) {}
  };

  // DELETE ALL ERROR LOGS
  deleteAllErrLogs = async () => {
    try {
      let { status, data } = await axiosInstance.delete(
        "/adminNotification/delete/multiple?isAll=1"
      );

      if (status === 200) {
        this.fetchAllNotification();
        this.setState({ checked: [] });
      }
    } catch (err) {}
  };

  render() {
    var {
      adminNotification,
      isLoading,
      isModalOpen,
      messageBox,
      isInputModalOpen,
      isEditMode,
      rowPerPage,
      currentPage,
      searchInput,
      offset,
      sportCount,
      isImportLoadnig,
      sortLabelID,
      selectedSort,
    } = this.state;
    const pageNumbers = [];

    // searchInput !== "" &&
    //   (adminNotification = adminNotification?.filter(
    //     (obj) =>
    //       obj?.name
    //         ?.toString()
    //         .toLowerCase()
    //         .includes(searchInput.toString().toLowerCase()) ||
    //       obj?.variation
    //         ?.toString()
    //         .toLowerCase()
    //         .includes(searchInput.toString().toLowerCase())
    //   ));

    let currentPageRow = adminNotification;

    // if (adminNotification?.length > 0) {
    //   const indexOfLastTodo = currentPage * rowPerPage;
    //   const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
    //   currentPageRow = adminNotification.slice(
    //     indexOfFirstTodo,
    //     indexOfLastTodo
    //   );

    //   for (
    //     let i = 1;
    //     i <= Math.ceil(adminNotification.length / rowPerPage);
    //     i++
    //   ) {
    //     pageNumbers.push(i);
    //   }
    // }

    if (sportCount > 0) {
      for (let i = 1; i <= Math.ceil(sportCount / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }

    // let sortTable = sortLabelID;
    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              {isImportLoadnig && (
                <div class="admin-delete-loader">
                  <Loader />
                </div>
              )}
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <h3 className="text-left">Import Error Logs</h3>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <select
                    name="sportName"
                    style={{
                      fontSize: "16px",
                      borderRadius: "3px",
                      minHeight: "44px",
                      border: "1px solid #ddd",
                      paddingLeft: "10px",
                      width: "100%",
                      marginRight: "15px",
                    }}
                    value={this.state.selectedSport}
                    onChange={(e) => {
                      this.setState({ selectedSport: e.target.value });
                    }}
                  >
                    <option value="">Select</option>
                    {this.state.sportList?.map((x) => {
                      return <option value={x.id}>{x.sportName}</option>;
                    })}
                  </select>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="text"
                    className=""
                    placeholder="search"
                    value={searchInput}
                    onChange={(e) =>
                      this.setState({
                        ...this.state.searchInput,
                        searchInput: e.target.value,
                      })
                    }
                    style={{
                      fontSize: "16px",
                      borderRadius: "3px",
                      minHeight: "40px",
                      border: "1px solid #ddd",
                      paddingLeft: "10px",
                    }}
                  />

                  <Box pl={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ background: "#00833e" }}
                      onClick={() => {
                        this.setState({ currentPage: 1, offset: 0 }, () => {
                          this.fetchAllNotification();
                        });
                      }}
                    >
                      search
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" mb={1}>
                  <Button
                    variant="contained"
                    style={{ textTransform: "capitalize" }}
                    onClick={() => {
                      this.deleteAllErrLogs();
                    }}
                  >
                    Delete All Logs
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.deleteSelectedHandler();
                    }}
                    style={{ textTransform: "capitalize", marginLeft: 15 }}
                  >
                    Delete Selected Logs
                  </Button>
                </Box>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && adminNotification.length === 0 && (
                <p>No Data Available</p>
              )}
              {!isLoading && adminNotification.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      className="listTable api-provider-listTable"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>DID</TableCell>
                          <TableCell>Import Type</TableCell>
                          <TableCell>Value to Import</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>isCreatingDuplicate</TableCell>
                          <TableCell>Provider Name</TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={true}
                              direction={sortLabelID ? "desc" : "asc"}
                              onClick={(e) => {
                                this.sortLabelHandler(e, "sportId");
                              }}
                            />
                            sport Name
                          </TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Created At</TableCell>
                          <TableCell>Import</TableCell>
                          <TableCell>Edit</TableCell>
                          <TableCell>Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentPageRow?.map((adminNoti, i) => (
                          <TableRow key={i}>
                            <TableCell>
                              <Checkbox
                                checked={
                                  this.state.checked.indexOf(adminNoti.id) !==
                                  -1
                                }
                                onClick={() => {
                                  this.selectLogHandler(adminNoti?.id);
                                }}
                              />
                            </TableCell>
                            <TableCell>{adminNoti?.id}</TableCell>
                            <TableCell>{adminNoti?.importType}</TableCell>
                            <TableCell>{adminNoti?.valueToInsert}</TableCell>
                            <TableCell>{adminNoti?.description}</TableCell>
                            <TableCell>
                              {adminNoti?.isCreatingDuplicate === 0
                                ? "false"
                                : "true"}
                            </TableCell>
                            <TableCell>
                              {adminNoti?.provider &&
                                adminNoti?.provider?.providerName}
                            </TableCell>
                            <TableCell>
                              {adminNoti?.sport && adminNoti?.sport?.sportName}
                            </TableCell>
                            <TableCell>{adminNoti?.status}</TableCell>
                            <TableCell>
                              {moment(adminNoti?.createdAt).format(
                                "DD/MM/YYYY hh:mm:ss a"
                              )}
                            </TableCell>
                            <TableCell>
                              <GetAppIcon
                                onClick={() =>
                                  this.handleImportFixture(
                                    adminNoti?.sportId,
                                    adminNoti?.providerId,
                                    adminNoti?.valueToInsert
                                  )
                                }
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                            </TableCell>
                            <TableCell>
                              <EditIcon
                                // onClick={this.inputModal(adminNoti.id, "edit")}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                            </TableCell>
                            <TableCell>
                              <DeleteOutlineIcon
                                onClick={this.setItemToDelete(adminNoti.id)}
                                color="secondary"
                                className="mr10 cursor iconBtn admin-btn-orange"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="tablePagination">
                    <button
                      className={
                        adminNotification.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={
                        adminNotification.length / rowPerPage > 1 ? false : true
                      }
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={sportCount > 0 ? false : true}
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size="small"
                    />
                    <button
                      className={
                        rowPerPage + offset < sportCount
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={rowPerPage + offset < sportCount ? false : true}
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </>
              )}
            </Paper>

            <ShowModal
              isModalOpen={isModalOpen}
              onClose={this.toggleModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.deleteItem}
              onCancel={this.toggleModal}
            />

            <Modal
              className="modal modal-input"
              open={isInputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {!isEditMode ? "Create New Animal" : "Edit Animal"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />
                {/* <CreateAnimal
                  inputModal={this.toggleInputModal}
                  id={this.state.idToSend}
                  isEditMode={isEditMode}
                  allProviders={this.state.allProviders}
                  allSports={this.state.allSports}
                  fetchAllAniaml={this.afterChangeRefresh}
                /> */}
              </div>
            </Modal>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default adminNotification;
