import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import axiosInstance from "../../../helpers/Axios";

class CreateResult extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      eventId: "",
      sportId: "",
      winnerId: "",
      looserId: "",
      winnerComment: "",
      mma_round: "",
      mma_time: "",
      mma_method: "",
      win_score: "",
      lose_score: "",
      loase_result: "",
      winResult: "",
      loseResult: "",
      looserComment: "",
      description: "",
      isEditMode: false,
      participantData: [],
      error: {},
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentResult(this.props.eventIdToSend);
    }
  }
  fetchCurrentResult = async (id) => {
    const { status, data } = await axiosInstance.get(`/sports/result/${id}`);
    if (status === 200) {
      if (data.message !== null) {
        this.setState({
          eventId: data?.message?.eventId,
          sportId: data?.message?.sportId,
          winnerId: data?.message?.winnerId,
          looserId: data?.message?.looserId,
          description: data?.message?.description,
          mma_round: data?.message?.round,
          mma_time: data?.message?.time,
          mma_method: data?.message?.method,
          win_score: data?.message?.winnerScore,
          lose_score: data?.message?.looserScore,
          winResult: data?.message?.winResult,
          loseResult: data?.message?.looseResult,
        });
      }
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { winnerId, looserId } = this.state;
    let flag = true;

    let error_obj = {};

    if (winnerId === "") {
      flag = false;
      error_obj.winnerId = "Please Fill Details First";
      this.setState({ isLoading: false });
    }

    if (looserId === "") {
      flag = false;
      error_obj.looserId = "Please Fill Details First";
      this.setState({ isLoading: false });
    }

    if (winnerId !== "" && looserId !== "") {
      flag = true;
    }

    this.setState({ error: error_obj });
    return flag;
  };

  handleSave = async () => {
    const { isEditMode, eventIdToSend, sportId } = this.props;
    const {
      winnerId,
      looserId,
      winResult,
      loseResult,
      description,
      win_score,
      lose_score,
      mma_round,
      mma_method,
      mma_time,
    } = this.state;
    this.setState({ isLoading: true });
    try {
      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `sports/result/${this.props.id}`
        : `sports/result`;

      let values = {
        eventId: eventIdToSend,
        sportId: sportId,
        winnerId: winnerId,
        looserId: looserId,
        description: description,
      };

      if (Number(sportId) === 12 || Number(sportId) === 8) {
        values["winnerScore"] = parseInt(win_score);
        values["looserScore"] = parseInt(lose_score);
      } else if (Number(sportId) === 5 || Number(sportId) === 6) {
        values["round"] = parseInt(mma_round);
        values["time"] = mma_time;
        values["method"] = mma_method;
      } else if (Number(sportId) === 9) {
        values["winResult"] = winResult;
        values["looseResult"] = loseResult;
      }

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllResult();
          this.setActionMessage(
            true,
            "Success",
            `Result ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${isEditMode ? "editing" : "creating"} Result`
      );
    }
  };

  handleChange = (e) => {
    let field = e.target.name;
    let value = e.target.value;
    if (
      field === "mma_round" ||
      field === "win_score" ||
      field === "lose_score"
    ) {
      let reg = new RegExp("[0-9]$");
      if (reg.test(value) || value === "") {
        this.setState({ [field]: value });
      }
    } else {
      this.setState({ [field]: value });
    }
  };

  handleSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value !== this.state.winnerId && value !== this.state.looserId) {
      this.setState({
        [name]: value,
        error: { ...this.state.error, [name]: "" },
      });
    }
  };

  render() {
    let {
      messageBox,
      isLoading,
      error,
      winnerId,
      looserId,
      winResult,
      loseResult,
      description,
      mma_round,
      mma_time,
      mma_method,
      win_score,
      lose_score,
    } = this.state;
    let { isEditMode, participants, sportId } = this.props;

    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}
              <div>
                <label>Winner*</label>
                <select
                  className="select-box-manual"
                  name="winnerId"
                  onChange={this.handleSelect}
                  value={winnerId}
                >
                  <option value="">No Winner Selected</option>
                  {participants?.map((obj, i) => (
                    <option
                      key={i}
                      value={obj?.pteam !== null ? obj?.pteam?.id : ""}
                    >
                      {obj?.pteam !== null ? obj?.pteam?.name : ""}
                    </option>
                  ))}
                </select>
                {typeof error.winnerId !== "undefined" &&
                  error.winnerId !== "" && (
                    <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                      {error.winnerId}
                    </p>
                  )}

                {(Number(sportId) === 12 ||
                  Number(sportId) === 8 ||
                  Number(sportId) === 9 ||
                  Number(sportId) === 4 ||
                  Number(sportId) === 13) && (
                  <>
                    <label>Winner Score</label>
                    <input
                      className="select-box-manual"
                      style={{
                        width: "96.5%",
                      }}
                      name="win_score"
                      onChange={(e) => this.handleChange(e)}
                      value={win_score}
                      placeholder="winner score"
                    />
                  </>
                )}

                {(Number(sportId) === 12 ||
                  Number(sportId) === 8 ||
                  Number(sportId) === 9 ||
                  Number(sportId) === 4 ||
                  Number(sportId) === 13) && (
                  <>
                    <label>Winner Result</label>
                    <input
                      className="select-box-manual"
                      style={{
                        width: "96.5%",
                      }}
                      name="winResult"
                      onChange={(e) => this.handleChange(e)}
                      value={winResult}
                      placeholder="winner result"
                    />
                  </>
                )}

                <label>Loser*</label>
                <select
                  className="select-box-manual"
                  name="looserId"
                  onChange={this.handleSelect}
                  value={looserId}
                >
                  <option value="">No Loser Selected</option>
                  {participants?.map((obj, i) => (
                    <option
                      key={i}
                      value={obj?.pteam !== null ? obj?.pteam?.id : ""}
                    >
                      {obj.pteam !== null ? obj?.pteam?.name : ""}
                    </option>
                  ))}
                </select>
                {typeof error.looserId !== "undefined" &&
                  error.looserId !== "" && (
                    <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                      {error.looserId}
                    </p>
                  )}

                {(Number(sportId) === 12 ||
                  Number(sportId) === 8 ||
                  Number(sportId) === 9 ||
                  Number(sportId) === 4 ||
                  Number(sportId) === 13) && (
                  <>
                    <label>Loser Score</label>
                    <input
                      className="select-box-manual"
                      style={{
                        width: "96.5%",
                      }}
                      name="lose_score"
                      onChange={(e) => this.handleChange(e)}
                      value={lose_score}
                      placeholder="loser score"
                    />
                  </>
                )}

                {(Number(sportId) === 12 ||
                  Number(sportId) === 8 ||
                  Number(sportId) === 9 ||
                  Number(sportId) === 4 ||
                  Number(sportId) === 13) && (
                  <>
                    <label>Loser Result</label>
                    <input
                      className="select-box-manual"
                      style={{
                        width: "96.5%",
                      }}
                      name="loseResult"
                      onChange={(e) => this.handleChange(e)}
                      value={loseResult}
                      placeholder="loser result"
                    />
                  </>
                )}

                {(Number(sportId) === 5 || Number(sportId) === 6) && (
                  <>
                    <label>Round</label>
                    <input
                      className="select-box-manual"
                      style={{
                        width: "96.5%",
                      }}
                      name="mma_round"
                      onChange={(e) => this.handleChange(e)}
                      value={mma_round}
                      placeholder="round"
                    />
                    <label>Time</label>
                    <input
                      className="select-box-manual"
                      style={{
                        width: "96.5%",
                      }}
                      name="mma_time"
                      onChange={(e) => this.handleChange(e)}
                      value={mma_time}
                      placeholder="time"
                    />
                    <label>Method</label>
                    <input
                      className="select-box-manual"
                      name="mma_method"
                      onChange={(e) => this.handleChange(e)}
                      value={mma_method}
                      placeholder="method"
                    />
                  </>
                )}

                <label>Description</label>
                <input
                  className="select-box-manual"
                  style={{
                    width: "96.5%",
                  }}
                  name="description"
                  onChange={(e) => this.handleChange(e)}
                  value={description}
                  placeholder="description"
                />
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateResult;
