// //import Help from "../help";
// import Feedback from "../feedback";
// import Competition from "../competition";
// import WhatsNew from "../whatsnew";
// import Promotion from "../promotion";
// import UserDetail from "../userdetail/userdetail";
// import TpisPayment from "../tipsPayment/tipsPayment";
// import AllSportsPage from "../allSportsNew/allSports/allSportsPage";
// import AllRacingPage from "../allSportsNew/racing/allRacing/allRacingPage";
// import TrackListPage from "../allSportsNew/racing/trackList/trackListPage";
// import RunnersTablePage from "../allSportsNew/racing/runnerTable/runnerTablePage";
// import AllRugbyLeaguePage from "../allSportsNew/rugbyleague/allRugbyLeague/allRugbyLeaguePage";
// import RlMatchDetailPage from "../allSportsNew/rugbyleague/rlMatchDetail/rlMatchDetailPage";
// import MmaAllLeaguePage from "../allSportsNew/mma/allLeague/allLeaguePage";
// import MmaAllEventsPage from "../allSportsNew/mma/allEvents/allEventsPage";
// import MmaEventDetailPage from "../allSportsNew/mma/eventDetail/eventDetailPage";
// import ShortCodeGenerator from "../shortCodeGenerator";

// export const secureRoutes = [
//   {
//     path: "/short-code",
//     component: ShortCodeGenerator,
//     exact: true,
//     index: 1,
//     name: "Short Code Generator",
//     secure: true,
//   },
//   {
//     path: "/allsports",
//     component: AllSportsPage,
//     exact: true,
//     index: 1,
//     name: "All Sports",
//     secure: true,
//   },

//   {
//     path: "/allsports/racing",
//     component: AllRacingPage,
//     exact: true,
//     index: 2,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/australian-racing",
//     component: AllRacingPage,
//     exact: true,
//     index: 2,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/all-racing",
//     component: AllRacingPage,
//     exact: true,
//     index: 2,
//     name: "All Sports",
//     secure: true,
//   },

//   {
//     path: "/allsports/racing/:race/:id",
//     component: TrackListPage,
//     exact: true,
//     index: 3,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/australian-racing/racing/:race/:id",
//     component: TrackListPage,
//     exact: true,
//     index: 3,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/all-racing/racing/:race/:id",
//     component: TrackListPage,
//     exact: true,
//     index: 3,
//     name: "All Sports",
//     secure: true,
//   },

//   {
//     path: "/allsports/racing/:race/:id/:raceId/:trackId",
//     component: RunnersTablePage,
//     exact: true,
//     index: 4,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/australian-racing/racing/:race/:id/:raceId/:trackId",
//     component: RunnersTablePage,
//     exact: true,
//     index: 4,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/all-racing/racing/:race/:id/:raceId/:trackId",
//     component: RunnersTablePage,
//     exact: true,
//     index: 4,
//     name: "All Sports",
//     secure: true,
//   },
//   // Rugby League
//   {
//     path: "/allsports/rugbyleague/:id",
//     component: AllRugbyLeaguePage,
//     exact: true,
//     index: 5,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/allsports/rugbyleague/:id/:leagueId/:leagueName/:eventId",
//     component: RlMatchDetailPage,
//     exact: true,
//     index: 6,
//     name: "All Sports",
//     secure: true,
//   },
//   // Australian Rules
//   {
//     path: "/allsports/australianrules/:id",
//     component: AllRugbyLeaguePage,
//     exact: true,
//     index: 7,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/allsports/australianrules/:id/:leagueId/:leagueName/:eventId",
//     component: RlMatchDetailPage,
//     exact: true,
//     index: 8,
//     name: "All Sports",
//     secure: true,
//   },
//   // Soccer
//   {
//     path: "/allsports/soccer/:id",
//     component: AllRugbyLeaguePage,
//     exact: true,
//     index: 7,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/allsports/soccer/:id/:leagueId/:leagueName/:eventId",
//     component: RlMatchDetailPage,
//     exact: true,
//     index: 8,
//     name: "All Sports",
//     secure: true,
//   },
//   // Cricket
//   {
//     path: "/allsports/cricket/:id",
//     component: AllRugbyLeaguePage,
//     exact: true,
//     index: 7,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/allsports/cricket/:id/:leagueId/:leagueName/:eventId",
//     component: RlMatchDetailPage,
//     exact: true,
//     index: 8,
//     name: "All Sports",
//     secure: true,
//   },
//   // Rugby Union
//   {
//     path: "/allsports/rugbyunion/:id",
//     component: AllRugbyLeaguePage,
//     exact: true,
//     index: 7,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/allsports/rugbyunion/:id/:leagueId/:leagueName/:eventId",
//     component: RlMatchDetailPage,
//     exact: true,
//     index: 8,
//     name: "All Sports",
//     secure: true,
//   },
//   // MMA
//   {
//     path: "/allsports/mma/:id",
//     component: MmaAllLeaguePage,
//     exact: true,
//     index: 9,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/allsports/mma/:id/:leagueId/:leagueName/:eventId",
//     component: MmaAllEventsPage,
//     exact: true,
//     index: 10,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/allsports/mma/:id/:leagueId/:leagueName/:eventId/:fightId",
//     component: MmaEventDetailPage,
//     exact: true,
//     index: 11,
//     name: "All Sports",
//     secure: true,
//   },

//   // Boxing
//   {
//     path: "/allsports/boxing/:id",
//     component: MmaAllLeaguePage,
//     exact: true,
//     index: 9,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/allsports/boxing/:id/:leagueId/:leagueName/:eventId",
//     component: MmaAllEventsPage,
//     exact: true,
//     index: 10,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/allsports/boxing/:id/:leagueId/:leagueName/:eventId/:fightId",
//     component: MmaEventDetailPage,
//     exact: true,
//     index: 11,
//     name: "All Sports",
//     secure: true,
//   },
//   {
//     path: "/help",
//     component: Help,
//     index: 0,
//     name: "Help",
//     secure: true,
//   },
//   {
//     path: "/feedback",
//     component: Feedback,
//     index: 0,
//     name: "Feedback",
//     secure: true,
//   },
//   {
//     path: "/competition",
//     component: Competition,
//     index: 0,
//     name: "Competition",
//     secure: true,
//   },
//   {
//     path: "/whats-new",
//     component: WhatsNew,
//     index: 0,
//     name: "What's New",
//     secure: true,
//   },
//   {
//     path: "/promotions",
//     component: Promotion,
//     index: 0,
//     name: "Promotions",
//     secure: true,
//   },
//   {
//     path: "/userdetail",
//     component: UserDetail,
//     index: 0,
//     exact: false,
//     name: "User Detail",
//     secure: true,
//     normalLayout: true,
//   },
//   {
//     path: "/tipspayment",
//     component: TpisPayment,
//     index: 0,
//     exact: false,
//     name: "Winning Edge Tips Payment",
//     secure: true,
//     normalLayout: true,
//   },
// ];
export const secureRoutes = [];