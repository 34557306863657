import React from "react";
import {
  Button,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Modal,
  Typography,
  Box,
  Breadcrumbs,
  InputAdornment,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Loader } from "../../../library/common/components";
import ButtonComponent from "../../../library/common/components/Button";
import Pagination from "@material-ui/lab/Pagination";
import CancelIcon from "@material-ui/icons/Cancel";
import arrowLeft from "../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";
// import { URLS } from "../../../library/common/constants";
import axiosInstance from "../../../helpers/Axios";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CreateTrack from "../../track/CreateTrack";
import ShowModal from "../../../components/common/ShowModal/ShowModal";
import SearchIcons from "../../../images/searchIcon.svg";
import SearchIcon from "@material-ui/icons/Search";
import "./tracksDetails.scss";

class TracksDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tracksDetails: [],
      isLoading: false,
      isInputModalOpen: false,
      isDeleteModalOpen: false,
      itemToDelete: null,
      idToSend: null,
      currentPage: 1,
      rowPerPage: 20,
      offset: 0,
      sportCount: null,
      isEditMode: false,
      searchInput: "",
      rowToPass: {},
      isDeleteLoading: "",
    };
  }
  componentDidMount() {
    this.fetchAllTracks();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match !== this.props.match) {
      this.fetchAllTracks();
    }
    if (prevState.offset !== this.state.offset) {
      this.fetchAllTracks();
    }
  }

  fetchAllTracks = async () => {
    let { rowPerPage, offset, searchInput } = this.state;
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(
      `track?limit=${rowPerPage}&offset=${offset}&matchString=${searchInput}`
    );
    if (status === 200) {
      console.log("data", data?.result);
      this.setState({
        tracksDetails: data.result,
        isLoading: false,
        sportCount: data.count,
      });
    }
  };

  inputModal = (id) => {
    this.setState({ isInputModalOpen: true, idToSend: id });
  };

  toggleInputModal = () => {
    this.setState({
      isInputModalOpen: !this.state.isInputModalOpen,
    });
  };

  handleTrackDelete = async () => {
    let { itemToDelete } = this.state;
    this.setState({
      isDeleteLoading: "trackDelete",
      isDeleteModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(`track/${itemToDelete}`);
      if (status === 200) {
        this.afterChangeRefresh();
        this.setState({
          isDeleteLoading: "",
          itemToDelete: null,
        });
      }
    } catch (err) {
      // console.log(err);
    }
  };

  setToDelete = (id) => {
    this.setState({ itemToDelete: id, isDeleteModalOpen: true });
  };

  toggleDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
      itemToDelete: null,
    });
  };

  afterChangeRefresh = () => {
    this.fetchAllTracks();
  };

  handlePaginationClick = (event, page) => {
    let {
      rowPerPage,
      // offset
    } = this.state;
    this.setState({
      currentPage: Number(page),
      offset: (Number(page) - 1) * rowPerPage,
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let {
      currentPage,
      //  rowPerPage,
      offset,
    } = this.state;
    // if (navDirection === "prev") {
    //   if (currentPage > 1) {
    //     this.setState({ currentPage: currentPage - 1 });
    //   }
    // } else {
    //   if (currentPage < tracksDetails.length / rowPerPage)
    //     this.setState({ currentPage: currentPage + 1 });
    // }

    if (navDirection === "prev") {
      if (offset >= 20) {
        this.setState({ offset: offset - 20, currentPage: currentPage - 1 });
      }
    } else {
      this.setState({ offset: offset + 20, currentPage: currentPage + 1 });
    }
  };

  render() {
    let {
      tracksDetails,
      isLoading,
      rowPerPage,
      isInputModalOpen,
      currentPage,
      offset,
      sportCount,
      isDeleteModalOpen,
      isEditMode,
      searchInput,
      rowToPass,
      isDeleteLoading,
    } = this.state;
    const pageNumbers = [];
    // let currentPageRow = tracksDetails;

    // searchInput !== "" &&
    //   (tracksDetails = tracksDetails?.filter(
    //     (obj) =>
    //       obj?.eventName
    //         ?.toString()
    //         .toLowerCase()
    //         .includes(searchInput.toString().toLowerCase()) ||
    //       obj?.variation
    //         ?.toString()
    //         .toLowerCase()
    //         .includes(searchInput.toString().toLowerCase())
    //   ));

    if (sportCount > 0) {
      // const indexOfLastTodo = currentPage * rowPerPage;
      // const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      // currentPageRow = tracksDetails.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(sportCount / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Box className="pageWrapper">
              {isDeleteLoading === "trackDelete" && (
                <div class="admin-delete-loader">
                  <Loader />
                </div>
              )}
              <Grid container direction="row">
                <Box className="bredcrumn-wrap">
                  <Breadcrumbs
                    separator="/"
                    aria-label="breadcrumb"
                    className="breadcrumb"
                  >
                    <Link
                      underline="hover"
                      color="inherit"
                      to="/dashboard"
                    >
                      Home
                    </Link>
                    <Link underline="hover" color="inherit" to="/">
                      racing
                    </Link>
                    <Typography className="active_p">tracks</Typography>
                  </Breadcrumbs>
                </Box>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="h1" align="left">
                      RACING TRACKS
                    </Typography>
                  </Grid>
                  <Grid item xs={8} className="admin-filter-wrap">
                    <TextField
                      className="textfield-tracks"
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{
                        width: "478px",
                        color: "#D4D6D8",
                      }}
                      // label="Search"
                      placeholder="Search (searches tracks)"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={SearchIcons} />
                            {/* <SearchIcon /> */}
                          </InputAdornment>
                        ),
                      }}
                      value={searchInput}
                      onChange={(e) =>
                        this.setState({
                          ...this.state.searchInput,
                          searchInput: e.target.value,
                        })
                      }
                    />
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#4455C7",
                        color: "#fff",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                        padding: "13px 24px 12px",
                      }}
                      onClick={() => this.fetchAllTracks()}
                    >
                      Search
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#4455C7",
                        color: "#fff",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                        padding: "13px 24px 12px",
                      }}
                      onClick={() => {
                        this.inputModal();
                        this.setState({ isEditMode: false });
                      }}
                    >
                      Add Track
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && tracksDetails?.length === 0 && (
                <p>No Data Available</p>
              )}
              {!isLoading && tracksDetails?.length > 0 && (
                <>
                  <TableContainer>
                    <Table
                      className="listTable api-provider-listTable"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow className="tableHead-row">
                          <TableCell>DID</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell style={{ width: "150px" }}>
                            Track Type
                          </TableCell>
                          <TableCell>Variation</TableCell>
                          <TableCell>City</TableCell>
                          <TableCell>State</TableCell>
                          <TableCell>Country</TableCell>
                          <TableCell>Edit</TableCell>
                          <TableCell>Delete</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody className="table_body">
                        <TableRow className="table_row">
                          <TableCell
                            colSpan={100}
                            className="table-seprator"
                          ></TableCell>
                        </TableRow>
                        {tracksDetails?.map((track, i) => {
                          let trackTypeAll = "";
                          let dummy =
                            track?.trackType?.length > 0
                              ? track?.trackType?.map((obj) => {
                                let sportName =
                                  obj?.sportId === 1
                                    ? "Horse Racing"
                                    : obj?.sportId === 2
                                      ? "Harness Racing"
                                      : obj?.sportId === 3
                                        ? "Greyhound Racing"
                                        : "";
                                trackTypeAll =
                                  trackTypeAll == ""
                                    ? sportName
                                    : trackTypeAll + " , " + sportName;
                              })
                              : "";
                          return (
                            <TableRow key={i} className="listTable-Row">
                              <TableCell>{track?.id}</TableCell>
                              <TableCell>{track?.name}</TableCell>
                              <TableCell>{trackTypeAll}</TableCell>
                              <TableCell>{track?.variation}</TableCell>
                              <TableCell>
                                {track?.city && track?.city?.cityName}
                              </TableCell>
                              <TableCell>
                                {track?.state && track?.state?.state}
                              </TableCell>
                              <TableCell>
                                {track?.country && track?.country?.country}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  this.inputModal(track?.id);
                                  this.setState({
                                    isEditMode: true,

                                    rowToPass: track,
                                  });
                                }}
                              >
                                {/* <Button
                                  className="listTable-btn"
                                 
                                > */}
                                Edit
                                {/* </Button> */}
                                {/* <EditIcon
                                  onClick={() => {
                                    this.inputModal(track?.id);
                                    this.setState({
                                      isEditMode: true,




                                      rowToPass: track,
                                    });
                                  }}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-green"
                                /> */}
                              </TableCell>
                              <TableCell
                                onClick={() => this.setToDelete(track?.id)}
                              >
                                {/* <Button
                                  className="listTable-btn"
                                 
                                > */}
                                Delete
                                {/* </Button> */}
                                {/* <DeleteOutlineIcon
                                  onClick={() => this.setToDelete(track?.id)}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-orange"
                                /> */}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        <TableRow>
                          <TableCell colSpan={100} className="pagination">
                            <div className="tablePagination">
                              {/* <button
                                className={
                                  offset >= 20
                                    ? "btn-navigation"
                                    : "btn-navigation-disabled"
                                }
                                disabled={offset >= 20 ? false : true}
                                // disabled={
                                //   meetingsDetails.length / rowPerPage > 1 ? false : true
                                // }
                                onClick={() =>
                                  this.handlePaginationButtonClick("prev")
                                }
                              >
                                <ReactSVG src={arrowLeft} />
                              </button> */}
                              <Pagination
                                hideNextButton
                                hidePrevButton
                                disabled={sportCount > 0 ? false : true}
                                page={currentPage}
                                onChange={this.handlePaginationClick}
                                count={pageNumbers[pageNumbers?.length - 1]}
                                siblingCount={2}
                                boundaryCount={1}
                                size="small"
                              />
                              {/* <button
                                className={
                                  rowPerPage + offset < sportCount
                                    ? "btn-navigation"
                                    : "btn-navigation-disabled"
                                }
                                disabled={
                                  rowPerPage + offset < sportCount
                                    ? false
                                    : true
                                }
                                onClick={() =>
                                  this.handlePaginationButtonClick("next")
                                }
                              >
                                <ReactSVG src={arrowRight} />
                              </button> */}
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Box>

            <Modal
              className="modal modal-input"
              open={isInputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center modal-head">
                  {isEditMode === true ? "Edit Track " : "Add Track"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />
                <CreateTrack
                  inputModal={this.toggleInputModal}
                  id={this.state.idToSend}
                  isEditMode={isEditMode}
                  fetchAllTrack={this.afterChangeRefresh}
                  rowToPass={rowToPass}
                />
              </div>
            </Modal>

            <ShowModal
              isModalOpen={isDeleteModalOpen}
              onClose={this.toggleDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleTrackDelete}
              onCancel={this.toggleDeleteModal}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
export default TracksDetails;
