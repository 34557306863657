import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import {
  playersFormModel,
  playersFormModelForJockeyEdit,
} from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";
import Select from "react-select";
import FileUploader from "../../../library/common/components/FileUploader";
import { config } from "../../../helpers/config";

let playersFormModelArray = [];

const selectboxStyles = {
  container: (styles) => ({ ...styles, width: "96.7%" }),
  control: (styles) => ({
    ...styles,
    width: "100%",
    borderRadius: "5px",
    borderColor: "#cccccc",
    boxShadow: "none",

    "&:hover": {
      borderColor: "#cccccc",
      boxShadow: "none",
    },
  }),
  option: (styles) => {
    return {
      ...styles,
      color: "#000",
    };
  },
  indicatorContainer: (styles) => ({ ...styles, padding: "0 2px" }),
  input: (styles) => ({ ...styles, color: "#000" }),
  placeholder: (styles) => ({ ...styles, fontSize: "14px", color: "#656565" }),
  singleValue: (styles) => ({
    ...styles,
    color: "#000",
    fontSize: "14px",
  }),
};

class CreatePlayers extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        variation: "",
        playertypeId: "",
      },
      files: [],
      leagueId: [],
      leagueIdError: "",
      weightClassIds: [],
      // weightClassError: "",
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    playersFormModelArray = this.props.editJockeys
      ? playersFormModelForJockeyEdit
      : playersFormModel;
    if (this.props.isEditMode) {
      this.fetchCurrentPlayers(this.props.id);
    }

    const { allPlayersType } = this.props;
    this.setState((prevState) => {
      return {
        values: {
          ...prevState.values,
          playertypeId: allPlayersType?.length > 0 ? allPlayersType[0].id : "",
        },
      };
    });
    playersFormModelArray = playersFormModelArray?.map((fieldItem) => {
      if (fieldItem?.field === "playertypeId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...allPlayersType?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      }
      return fieldItem;
    });
  }

  componentWillUnmount() {
    playersFormModelArray = playersFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchCurrentPlayers = async (id) => {
    const { allLeague, allWeightClass } = this.props;
    const { status, data } = await axiosInstance.get(URLS.players + `/${id}`);

    if (status === 200) {
      let leagueFilter =
        data?.result?.leagueIds?.length > 0
          ? data?.result?.leagueIds?.map((obj) =>
              allLeague?.find((item) => item?.id === obj?.leagueId)
            )
          : [];

      let temp_array = [];
      temp_array = leagueFilter.map((obj) => {
        return { ...temp_array, label: obj?.leagueName, value: obj?.id };
      });

      let weightClassFilter =
        data?.result?.weightClassIds?.length > 0
          ? data?.result?.weightClassIds?.map((obj) =>
              allWeightClass?.find((item) => item?.id === obj?.weightClassId)
            )
          : [];

      let temp_wc_array = [];
      temp_wc_array = weightClassFilter.map((obj) => {
        return { ...temp_wc_array, label: obj?.name, value: obj?.id };
      });

      this.setState({
        values: data.result,
        leagueId: temp_array,
        weightClassIds: temp_wc_array,
      });

      // console.log("player data:", leagueFilter, allLeague, temp_array);
      // let variationArray = JSON.parse("[" + data.result.variation + "]");
      // if (variationArray.length > 0) {
      //   this.setState(() => {
      //     return {
      //       values: {
      //         ...this.state.values,
      //         ["variation"]: variationArray[0],
      //       },
      //     };
      //   });
      // }
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { name } = this.state.values;
    let { leagueId /* weightClassIds */ } = this.state;
    const { isLeague /* sport */ } = this.props;
    let flag = true;

    if (name === "") {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      // flag = true;
      //this.setActionMessage(false);
    }

    if (isLeague) {
      if (leagueId?.length <= 0) {
        flag = false;
        this.setState({
          leagueIdError: "This field is mandatory",
          isLoading: false,
        });
      } else if (leagueId === null) {
        flag = false;
        this.setState({
          leagueIdError: "This field is mandatory",
          isLoading: false,
        });
      } else {
        this.setState({ leagueIdError: "" });
      }
    }

    // if (sport === "Mixed Martial Arts") {
    //   if (weightClassIds?.length <= 0) {
    //     flag = false;
    //     this.setState({
    //       weightClassError: "This field is mandatory",
    //       isLoading: false,
    //     });
    //   } else if (weightClassIds === null) {
    //     flag = false;
    //     this.setState({
    //       weightClassError: "This field is mandatory",
    //       isLoading: false,
    //     });
    //   } else {
    //     this.setState({ weightClassError: "" });
    //   }
    // }

    return flag;
  };

  handleSave = async () => {
    const { isEditMode, isLeague, playertypeId, sport } = this.props;
    const { leagueId, files, weightClassIds } = this.state;

    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.players}/${this.props.id}`
        : URLS.players;

      let leagueArray = [];
      leagueArray = leagueId?.map((obj) => {
        return obj?.value;
      });

      let weightArray = [];
      weightArray = weightClassIds?.map((obj) => {
        return obj?.value;
      });

      const values = removeErrorFieldsFromValues(form.formData);
      values["playertypeId"] = playertypeId;
      playersFormModelArray = playersFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        if (files.length > 0) {
          let fileData = await this.setMedia(files[0]);
          if (fileData) {
            values["image"] = fileData?.image?.filePath;
          }
        }
        if (isLeague) {
          values["leagueIds"] = leagueArray;
        }
        if (sport === "Mixed Martial Arts" || sport === "Boxing") {
          values["weightClassIds"] = weightArray;
        }
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllPlayers();
          this.setActionMessage(
            true,
            "Success",
            `Players ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${isEditMode ? "editing" : "creating"} Players`
      );
    }
  };

  setMedia = async (files) => {
    const formData = new FormData();
    formData.append("image", files ? files : null);
    if (files !== undefined) {
      const { status, data } = await axiosInstance.post(URLS.media, formData, {
        header: { "Content-Type": "multipart/form-data" },
      });
      if (status === 200) {
        return data;
      } else {
        return false;
      }
    }
    return false;
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    playersFormModelArray = playersFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  handleLeagueChange = (item) => {
    this.setState({ leagueId: item, leagueIdError: "" });
  };

  handleWeightClassChange = (item) => {
    this.setState({ weightClassIds: item /* weightClassError: "" */ });
  };

  handleFileUpload = (files) => {
    this.setState({ files: files });
  };

  render() {
    var {
      values,
      messageBox,
      isLoading,
      leagueId,
      leagueIdError,
      files,
      weightClassIds,
      // weightClassError,
    } = this.state;
    var { isEditMode, allLeague, isLeague, sport, allWeightClass } = this.props;
    let leagueList = [];
    leagueList = allLeague?.map((obj) => {
      return {
        ...leagueList,
        label: obj.leagueName,
        value: obj.id,
      };
    });

    let weightClassList = [];
    weightClassList = allWeightClass?.map((obj) => {
      return {
        ...weightClassList,
        label: obj.name,
        value: obj.id,
      };
    });
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              {isLeague && (
                <div style={{ marginBottom: "20px" }}>
                  <label>League</label>
                  <Select
                    placeholder="League Name"
                    name="leagueId"
                    id="leagueId"
                    isMulti
                    styles={selectboxStyles}
                    value={leagueId}
                    options={leagueList}
                    onChange={(selectedOptions) =>
                      this.handleLeagueChange(selectedOptions)
                    }
                  />
                  {leagueIdError !== "" && (
                    <p className="errorText" style={{ margin: "0" }}>
                      {leagueIdError}
                    </p>
                  )}
                </div>
              )}

              <Form
                values={values}
                model={playersFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              {(sport === "Mixed Martial Arts" || sport === "Boxing") && (
                <div style={{ marginBottom: "20px" }}>
                  <label>Weight Class</label>
                  <Select
                    placeholder="weight class"
                    name="weightClassIds"
                    id="weightClassIds"
                    isMulti
                    styles={selectboxStyles}
                    value={weightClassIds}
                    options={weightClassList}
                    onChange={(selectedOptions) =>
                      this.handleWeightClassChange(selectedOptions)
                    }
                  />
                  {/* {weightClassError !== "" && (
                    <p className="errorText" style={{ margin: "0" }}>
                      {weightClassError}
                    </p>
                  )} */}
                </div>
              )}

              <div style={{ width: "96.5%" }}>
                <p style={{ margin: 0 }}>Player Image</p>
                <FileUploader
                  onDrop={(files) => this.handleFileUpload(files)}
                />
                {files?.length > 0
                  ? files.map((file, index) => (
                      <img key={index} src={file.preview} alt="file" />
                    ))
                  : values?.image &&
                    values?.image !== "" && (
                      <img src={config.baseUrl + values?.image} alt="file" />
                    )}
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreatePlayers;
