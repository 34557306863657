import React, { Component, createRef } from "react";
import { CircularProgress } from "@material-ui/core";

import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import FileUploader from "../../../library/common/components/FileUploader";
import { membershipFormConstant } from "./form-constant";
import axiosInstance from "../../../helpers/Axios";

export default class Membership extends Component {
  formRef = createRef();
  state = {
    file: [],
    isLoading: false,
    value: null,
    isEditMode: false,
    memberships: [],
  };

  componentDidMount() {
    this.fetchMembership();
  }

  fetchMembership() {
    axiosInstance.get("page/membership/all").then((res) => {
      if (res.status === 200) {
        this.setState({ memberships: res.data.memberships });
      }
    });
  }

  handleFileUpload = (file) => {
    this.setState({ file: file });
  };

  addRecord = () => {
    const { isEditMode, file, value } = this.state;
    const { current } = this.formRef;
    const values = current.getFormData();

    let url = isEditMode ? "/page/membership/" + value.id : "/page/membership/";
    const method = isEditMode ? "put" : "post";

    this.setState({ isLoading: true });

    const body = new FormData();
    body.append("title", values.formData.title);
    body.append("description", values.formData.description);
    body.append("image", file.length > 0 ? file[0] : null);
    body.append(
      "isExternal",
      values?.formData?.isExternal ? values.formData.isExternal : false
    );
    body.append("image", file.length > 0 ? file[0] : null);

    axiosInstance[method](url, body, {
      header: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      if (res.status === 200) {
        this.props.onSuccess(
          `Membership ${isEditMode ? "edited" : "added"} successfully!`
        );

        this.fetchMembership();

        this.setState({
          file: [],
          isLoading: false,
          isEditMode: false,
          value: null,
        });
        current.resetForm();
      }
    });
    // .catch(this.setState({ isLoading: false }));
  };

  removeRecord = (id) => {
    this.setState({ isLoading: true });

    axiosInstance.delete("/page/membership/" + id).then((res) => {
      if (res.status === 200) {
        this.props.onSuccess("Membership deleted successfully!");
        this.setState({ file: [], isLoading: false });
        this.fetchMembership();
      }
    });
  };

  editRecord = (value) => {
    this.setState({ value, isEditMode: true });
    window.scrollTo(0, 300);
  };

  render() {
    const { file, isLoading, value, isEditMode, memberships } = this.state;

    return (
      <div className="about-template-container">
        <h4>Add Memberbership Data</h4>

        <div className="add-team">
          <Form
            model={membershipFormConstant()}
            values={isEditMode && value && value}
            ref={this.formRef}
          />
          <FileUploader accept="image/*" onDrop={this.handleFileUpload} />
          {file?.map((file, index) => (
            <img key={index} src={file.preview} alt="file" />
          ))}
          <br />

          <ButtonComponent
            onClick={this.addRecord}
            color="primary"
            value={isEditMode ? "Update Memberbership" : "Add Memberbership"}
          />

          {isLoading && <CircularProgress />}
        </div>

        {memberships.map((item, index) => (
          <>
            <hr />
            <Form
              model={membershipFormConstant(
                false,
                item,
                this.removeRecord,
                this.editRecord
              )}
              values={item}
              key={index}
            />
          </>
        ))}
      </div>
    );
  }
}
