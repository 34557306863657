import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Modal,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Pagination from "@material-ui/lab/Pagination";
import { ReactSVG } from "react-svg";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import arrowLeft from "../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../../images/blog_img/paginationArrowRight.svg";
import axiosInstance from "../../../helpers/Axios";
import { URLS } from "../../../library/common/constants";
import "./jockeys.scss";
import CreatePlayers from "../../players/CreatePlayers";
import { Loader } from "../../../library/common/components";
// import { errorHandler } from "../../../library/utilities";
import ShowModal from "../../../components/common/ShowModal/ShowModal";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInputModalOpen, setIsInputModalOpen] = useState(false);
  const [idToSend, setIdToSend] = useState(null);
  const [data, setData] = useState([]);
  const [allPlayersType, setAllPlayersType] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sportCount, setSportCount] = useState(null);
  const [rowPerPage] = useState(20); //setRowPerPage
  const [offset, setOffset] = useState(0);
  const [pageNumbers] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState("");

  const handlePaginationButtonClick = (navDirection) => {
    if (navDirection === "prev") {
      if (offset >= 20) {
        setOffset(offset - 20);
        setCurrentPage(currentPage - 1);
      }
    } else {
      setOffset(offset + 20);
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePaginationClick = (event, page) => {
    setCurrentPage(Number(page));
    setOffset((Number(page) - 1) * rowPerPage);
  };

  if (sportCount > 0) {
    // const indexOfLastTodo = currentPage * rowPerPage;
    // const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
    // currentPageRow = data.slice(indexOfFirstTodo, indexOfLastTodo);

    for (let i = 1; i <= Math.ceil(sportCount / rowPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  const getJockey = async () => {
    setIsLoading(true);
    let url =
      search === ""
        ? URLS.getJockeys +
          `${props.match.params.typeid}?limit=${rowPerPage}&offset=${offset}&matchString=${search}`
        : URLS.getJockeys +
          `${props.match.params.typeid}?limit=&offset=&matchString=${search}`;
    try {
      const { status, data } = await axiosInstance.get(url);
      if (status === 200) {
        setData(data?.result);
        setIsLoading(false);
        setSportCount(data.count);
      }
    } catch (err) {
      // console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPlayersType();
    if (offset === 0) {
      getJockey();
    } else {
      setOffset(0);
      setCurrentPage(1);
    }
    setSearch("");
    // eslint-disable-next-line
  }, [props.match]);

  useEffect(() => {
    getJockey();
    // eslint-disable-next-line
  }, [offset]);

  const afterChangeRefresh = () => {
    getJockey();
  };

  const toggleInputModal = () => {
    setIsInputModalOpen(!isInputModalOpen);
  };

  const fetchAllPlayersType = async () => {
    const { status, data } = await axiosInstance.get(URLS.playersType);
    if (status === 200) {
      setAllPlayersType(data.result);
    }
  };

  const inputModal = (id, type) => {
    setIsInputModalOpen(true);
    setIdToSend(id);
    setIsEditMode(type);
  };

  const handlePlayersDelete = async () => {
    setIsDeleteLoading("jockeyDelete");
    setIsDeleteModalOpen(false);
    try {
      const { status } = await axiosInstance.delete(
        `teams/player/${itemToDelete}`
      );
      if (status === 200) {
        afterChangeRefresh();
        setItemToDelete(null);
        setIsDeleteLoading("");
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const setItemDelete = (id) => {
    setItemToDelete(id);
    setIsDeleteModalOpen(true);
  };

  const toggleDeleteModal = () => {
    setItemToDelete(null);
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className="pageWrapper">
          {isDeleteLoading === "jockeyDelete" && (
            <div class="admin-delete-loader">
              <Loader />
            </div>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" style={{ margin: "0" }}>
              {props.match.params.player}
            </Typography>
            <div>
              <TextField
                className={classes.margin}
                label="Search"
                size="small"
                variant="outlined"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <Button
                variant="contained"
                className={classes.margin}
                style={{ backgroundColor: "#00833e", color: "#fff" }}
                onClick={getJockey}
              >
                Search
              </Button>
              <Button
                variant="contained"
                className={classes.margin}
                style={{ backgroundColor: "#00833e", color: "#fff" }}
                onClick={() => inputModal(null, false)}
              >
                Add {props.match.params.player}
              </Button>
            </div>
          </Box>
          {isLoading && <Loader />}
          {!isLoading && data.length === 0 && <p>No Data Available</p>}
          {!isLoading && data.length > 0 && (
            <TableContainer component={Paper}>
              <Table className="listTable" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>DID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Weight</TableCell>
                    <TableCell>Variation</TableCell>
                    <TableCell align="right">Edit</TableCell>
                    <TableCell align="right">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*                     .filter((val) => {
                      if (search === '' || search === null) return val
                      else if (
                        val?.name
                          .toString()
                          .toLowerCase()
                          .includes(search.toString().toLowerCase())
                      ) {
                        return val;
                      }
                    })
                    /* .slice(
                      currentPage * rowPerPage - rowPerPage,
                      currentPage * rowPerPage,
                    ) */}
                  {data.map((row, index) => (
                    <TableRow key={row?.id}>
                      <TableCell>{row?.id}</TableCell>
                      <TableCell>{row?.name}</TableCell>
                      <TableCell>{row?.weight}</TableCell>
                      <TableCell>{row?.variation}</TableCell>
                      <TableCell align="right">
                        <EditIcon
                          onClick={() => {
                            inputModal(row?.id, true);
                          }}
                          color="primary"
                          className="mr10 cursor iconBtn admin-btn-green"
                        />
                      </TableCell>
                      <TableCell align="right">
                        <DeleteOutlinedIcon
                          onClick={() => {
                            setItemDelete(row?.id);
                          }}
                          color="secondary"
                          className="cursor iconBtn admin-btn-orange"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {data?.length > 0 && (
                <div className="tablePagination jokeys-pagination">
                  <button
                    className={
                      offset >= 20
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={offset >= 20 ? false : true}
                    onClick={() => handlePaginationButtonClick("prev")}
                  >
                    <ReactSVG src={arrowLeft} />
                  </button>
                  <Pagination
                    hideNextButton
                    hidePrevButton
                    disabled={sportCount > 0 ? false : true}
                    page={currentPage}
                    onChange={handlePaginationClick}
                    count={pageNumbers[pageNumbers?.length - 1]}
                    siblingCount={2}
                    boundaryCount={1}
                    size="small"
                  />
                  <button
                    className={
                      rowPerPage + offset < sportCount
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={rowPerPage + offset < sportCount ? false : true}
                    onClick={() => handlePaginationButtonClick("next")}
                  >
                    <ReactSVG src={arrowRight} />
                  </button>
                </div>
              )}
            </TableContainer>
          )}
        </Paper>

        <Modal
          className="modal modal-input"
          open={isInputModalOpen}
          onClose={toggleInputModal}
        >
          <div
            className={"paper modal-show-scroll"}
            style={{ position: "relative" }}
          >
            <h3 className="text-center">
              {isEditMode
                ? `Edit ${props.match.params.player}`
                : `Create ${props.match.params.player}`}
            </h3>
            <CancelIcon
              className="admin-close-icon"
              onClick={toggleInputModal}
            />
            <CreatePlayers
              inputModal={toggleInputModal}
              id={idToSend}
              isEditMode={isEditMode}
              fetchAllPlayers={afterChangeRefresh}
              editJockeys={true}
              playertypeId={props.match.params.typeid}
            />
          </div>
        </Modal>

        <ShowModal
          isModalOpen={isDeleteModalOpen}
          onClose={toggleDeleteModal}
          Content="Are you sure you want to delete?"
          onOkayLabel="Yes"
          onOkay={handlePlayersDelete}
          onCancel={toggleDeleteModal}
        />
      </Grid>
    </Grid>
  );
};

export default Index;
