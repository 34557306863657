import { Validators } from "../../../library/utilities/Validators";

export const citiesFormModel = [
  {
    label: "City Name*",
    value: "",
    type: "text",
    placeholder: "City Name",
    field: "cityName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
  {
    label: "Variation",
    value: "",
    type: "text",
    placeholder: "Variation",
    field: "variation",
    required: false,
    className: "12",
  },
  {
    label: "State Code",
    value: "",
    type: "text",
    placeholder: "State Code",
    field: "stateCode",
    required: false,
    className: "6",
  },
  {
    label: "Country Code",
    value: "",
    type: "text",
    placeholder: "Country Code",
    field: "countryCode",
    required: false,
    className: "6",
  },
  {
    label: "Latitude",
    value: "",
    type: "text",
    placeholder: "Latitude",
    field: "latitude",
    required: false,
    className: "6",
  },
  {
    label: "Longitude",
    value: "",
    type: "text",
    placeholder: "Longitude",
    field: "longitude",
    required: false,
    className: "6",
  },
];
