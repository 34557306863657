import React from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  withStyles,
  Box,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { ReactSVG } from "react-svg";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../../images/blog_img/paginationArrowRight.svg";
import ButtonComponent from "./../../../library/common/components/Button";
import axiosInstance from "./../../../helpers/Axios";
import moment from "moment";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class SportsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: "",
      eventList: [],
      raceData: [],
      expanded: "",
      eventLoader: false,
      raceLoader: false,
      selectedDate: null,
      title: "",
    };
  }

  componentDidMount = () => {
    this.fetchAllEvents();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState?.selectedDate !== this.state.selectedDate) {
      this.fetchAllEvents();
    }
  };

  searchInputHandler = (e) => {
    this.setState({ searchVal: e.target.value });
  };

  handleChange = (panel) => {
    this.setState({ expanded: panel === this.state.expanded ? false : panel });

    if (panel !== this.state.expanded) {
      this.setState({ raceData: [] });
      this.fetchRaceDatas(panel);
    }
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  fetchAllEvents = async () => {
    let { selectedDate } = this.state;
    let date =
      selectedDate === null
        ? moment().format("YYYY-MM-DD")
        : moment(selectedDate).format("YYYY-MM-DD");

    this.setState({ eventLoader: true });
    try {
      const { status, data } = await axiosInstance.get(
        `/adminNotification/evnetList?date=${date}&limit=100&timezone=${timezone}`
      );

      if (status === 200) {
        this.setState({
          eventList: data.events.rows,
          eventLoader: false,
        });
      }
    } catch (error) {
      this.setState({ eventLoader: false });
    }
  };

  fetchRaceDatas = async (eventId) => {
    this.setState({ raceLoader: true });
    let date = moment().format("YYYY-MM-DD");

    try {
      const { status, data } = await axiosInstance.get(
        `adminNotification/missed?eventId=${eventId}&limit=100`
      );

      if (status === 200) {
        this.setState({ raceData: data?.events[0]?.race, raceLoader: false });
      }
    } catch (error) {
      this.setState({ raceLoader: false });
    }
  };

  render() {
    const { match } = this.props;
    let {
      eventList,
      raceData,
      expanded,
      searchVal,
      eventLoader,
      raceLoader,
      selectedDate,
    } = this.state;

    let eventData = eventList?.filter((data) => {
      if (searchVal === "") return data;
      else if (
        data?.id
          .toString()
          .toLowerCase()
          .includes(searchVal.toString().toLowerCase()) ||
        data.eventName
          .toString()
          .toLowerCase()
          .includes(searchVal.toString().toLowerCase()) ||
        data.race
          .map((obj) => {
            return String(obj?.raceName).toLowerCase();
          })
          .includes(searchVal.toLowerCase()) ||
        data.race
          .map((obj) => {
            return String(obj?.id);
          })
          .includes(searchVal)
      ) {
        return data;
      }
      return "";
    });
    let sportId = match?.params?.sportId;

    let title =
      sportId === 12
        ? "Rugby League"
        : sportId === 13
        ? "Rugby Union"
        : sportId === 8
        ? "Soccer"
        : sportId === 9
        ? "Australian Rules"
        : "Rugby League";
    return (
      <>
        <Grid container className="page-content adminLogin sports_details">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <h3 className="text-left">Missing {title} Details</h3>
                </Grid>
                <Grid item xs={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        value={selectedDate}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        style={{ marginRight: 5 }}
                        className="details-search-picker"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <input
                    type="text"
                    className=""
                    placeholder="search"
                    value={searchVal}
                    onChange={(e) => {
                      this.searchInputHandler(e);
                    }}
                    style={{
                      fontSize: "16px",
                      borderRadius: "3px",
                      minHeight: "40px",
                      border: "1px solid #ddd",
                      paddingLeft: "10px",
                      marginLeft: "-100px",
                      marginTop: "4px",
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <ButtonComponent
                    className="admin-btn-green"
                    color="primary"
                    value="Submit"
                    onClick={() => {
                      // console.log("search click");
                    }}
                  />
                </Grid>
              </Grid>

              <Box mt={3} mb={3}>
                {eventLoader ? (
                  <Box
                    mt={3}
                    mb={3}
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <CircularProgress size={18} />
                  </Box>
                ) : eventData?.length > 0 ? (
                  eventData?.map((obj, index) => {
                    return (
                      <Accordion
                        square
                        expanded={expanded === String(obj?.id)}
                        onClick={() => {
                          this.handleChange(String(obj?.id));
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Typography>{obj?.eventName}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {raceLoader ? (
                            <Box
                              mt={3}
                              mb={3}
                              width={"100%"}
                              display={"flex"}
                              justifyContent={"center"}
                            >
                              <CircularProgress size={18} />
                            </Box>
                          ) : raceData?.length > 0 ? (
                            <TableContainer
                              component={Paper}
                              style={{ minWidth: "100%" }}
                            >
                              <Table
                                className="listTable api-provider-listTable missing-racedata-tables"
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow className="main-missing-ch">
                                    <TableCell align="center">DID</TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ width: "297px" }}
                                    >
                                      Race
                                    </TableCell>
                                    <TableCell align="center">PUP</TableCell>
                                    <TableCell align="center">VBT</TableCell>
                                    <TableCell align="center">Neds</TableCell>
                                    <TableCell align="center">
                                      Ladbrokes
                                    </TableCell>
                                    <TableCell align="center">
                                      Bookmaker
                                    </TableCell>
                                    <TableCell align="center">
                                      Betstar
                                    </TableCell>
                                    <TableCell align="center">Bet365</TableCell>
                                    <TableCell align="center">
                                      Palmerbet
                                    </TableCell>
                                    <TableCell align="center">Unibet</TableCell>
                                    <TableCell align="center">Top</TableCell>
                                    <TableCell align="center">
                                      RealBookie
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {raceData?.map((obj, i) => (
                                    <TableRow key={i}>
                                      <TableCell className="rd-p-cell rdp-race">
                                        {obj?.id}
                                      </TableCell>
                                      <TableCell className="rd-p-cell rdp-race">
                                        {obj?.raceName}
                                      </TableCell>
                                      <TableCell className="rd-p-cell rdp-b">
                                        {/* PUP */}
                                        <div>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 1
                                            )[0]?.isIdentifier
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 1
                                            )[0]?.isOdd
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                        </div>
                                      </TableCell>
                                      <TableCell className="rd-p-cell rdp-b">
                                        {/* VBT */}
                                        <div>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 2
                                            )[0]?.isIdentifier
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 2
                                            )[0]?.isOdd
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                        </div>
                                      </TableCell>
                                      <TableCell className="rd-p-cell rdp-b">
                                        {/* NEDS */}
                                        <div>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 3
                                            )[0]?.isIdentifier
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 3
                                            )[0]?.isOdd
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                        </div>
                                      </TableCell>
                                      <TableCell className="rd-p-cell rdp-b">
                                        {/* Ladbrokes */}
                                        <div>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 4
                                            )[0]?.isIdentifier
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 4
                                            )[0]?.isOdd
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                        </div>
                                      </TableCell>
                                      <TableCell className="rd-p-cell rdp-b">
                                        {/* Bookmaker.com.au */}
                                        <div>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 5
                                            )[0]?.isIdentifier
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 5
                                            )[0]?.isOdd
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                        </div>
                                      </TableCell>
                                      <TableCell className="rd-p-cell rdp-b">
                                        {/* BetStar */}
                                        <div>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 6
                                            )[0]?.isIdentifier
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 6
                                            )[0]?.isOdd
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                        </div>
                                      </TableCell>
                                      <TableCell className="rd-p-cell rdp-b">
                                        {/* Bet365 */}
                                        <div>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 8
                                            )[0]?.isIdentifier
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 8
                                            )[0]?.isOdd
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                        </div>
                                      </TableCell>
                                      <TableCell className="rd-p-cell rdp-b">
                                        {/* Palmerbet */}
                                        <div>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 9
                                            )[0]?.isIdentifier
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 9
                                            )[0]?.isOdd
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                        </div>
                                      </TableCell>
                                      <TableCell className="rd-p-cell rdp-b">
                                        {/* Unibet */}
                                        <div>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 10
                                            )[0]?.isIdentifier
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 10
                                            )[0]?.isOdd
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                        </div>
                                      </TableCell>
                                      <TableCell className="rd-p-cell rdp-b">
                                        {/* Top */}
                                        <div>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 11
                                            )[0]?.isIdentifier
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 11
                                            )[0]?.isOdd
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                        </div>
                                      </TableCell>
                                      <TableCell className="rd-p-cell rdp-b">
                                        {/* RealBookie */}
                                        <div>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 13
                                            )[0]?.isIdentifier
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                          <TableCell className="rd-c-cell">
                                            {obj?.bookKeepers?.filter(
                                              (data) => data?.id === 13
                                            )[0]?.isOdd
                                              ? "YES"
                                              : "NO"}
                                          </TableCell>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          ) : (
                            <Typography align="center">
                              No Races Available
                            </Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <Typography align="center">Data Not Available</Typography>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default SportsDetails;
