import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Grid,
  Paper,
  Checkbox,
  FormControl,
  Breadcrumbs,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableHead,
  TableRow,
  TableContainer,
  Table,
  TableCell,
} from "@material-ui/core";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
// import axiosInstance from "../../helpers/Axios";
// import ButtonComponent from "../../library/common/components/Button";
// import Horse from "../../images/dark_horse.svg";
import Horse from "../../../../images/dark_horse.svg";
import Greys from "../../../../images/dar-greyhound.svg";
import Harnes from "../../../../images/dark_harness.svg";
// import TabHorses from "../../images/sport_icons/tab_horse.svg";
// import TabGreyhounds from "../../images/sport_icons/tab_greyhounds.svg";
// import TabHarness from "../../images/sport_icons/tab_harness.svg";
import { ReactComponent as Unchecked } from "../../../../images/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../../../images/smartb-check.svg";
const day = [
  {
    id: 0,
    name: "Yesterday",
    date: moment().utc().subtract(1, "days").format("YYYY-MM-DD"),
    dayName: moment().utc().subtract(1, "days").format("dddd"),
  },
  {
    id: 1,
    name: "Today",
    date: moment().utc().format("YYYY-MM-DD"),
    dayName: moment().utc().format("dddd"),
  },
  {
    id: 2,
    name: "Tomorrow",
    date: moment().utc().add(1, "days").format("YYYY-MM-DD"),
    dayName: moment().utc().add(1, "days").format("dddd"),
  },
  {
    id: 3,
    name: "Wednesday",
    date: moment().utc().add(2, "days").format("YYYY-MM-DD"),
    dayName: moment().utc().add(2, "days").format("dddd"),
  },
  {
    id: 4,
    name: "Thursday",
    date: moment().utc().add(3, "days").format("YYYY-MM-DD"),
    dayName: moment().utc().add(3, "days").format("dddd"),
  },
  {
    id: 5,
    name: "Friday",
    date: moment().utc().add(4, "days").format("YYYY-MM-DD"),
    dayName: moment().utc().add(4, "days").format("dddd"),
  },
  {
    id: 6,
    name: "Saturday",
    date: moment().utc().add(5, "days").format("YYYY-MM-DD"),
    dayName: moment().utc().add(5, "days").format("dddd"),
  },
  {
    id: 7,
    dayName: "Futures",
    date: "",
  },
  {
    id: 8,
    dayName: "Archive",
    date: "",
  },
];
const FixturesFilter = ({ fetchTrackList }) => {
  const [filterRaceType, setfilterRaceType] = useState([
    {
      id: 1,
      Racevalue: "Horse Racing",
      isRaceChecked: true,
      icon: (
        <span>
          <img src={Horse} />
        </span>
      ),
    },
    {
      id: 3,
      Racevalue: "Greyhound Racing",
      isRaceChecked: true,
      icon: (
        <span>
          <img src={Greys} />
        </span>
      ),
    },
    {
      id: 2,
      Racevalue: "Harness Racing",
      isRaceChecked: true,
      icon: (
        <span>
          <img src={Harnes} />
        </span>
      ),
    },
  ]);
  const [filterCountry, setfilterCountry] = useState([
    {
      id: 1,
      name: "Aus/NZ",
      value: "Aus/NZ",
      isChecked: true,
    },
    {
      id: 2,
      name: "Int'l",
      value: "Intl",
      isChecked: true,
    },
  ]);
  const navigate = useHistory();
  const [value, setValue] = useState(1);
  const [selectedRaceType, setselectedRaceType] = useState([1, 2, 3]);
  const [selectedCountryType, setselectedCountryType] = useState([
    "Aus/NZ",
    "Intl",
  ]);
  const [selectedDate, setselectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  //   const [isRefetch, setIsRefetch] = useState(false);

  // Change Day Tab
  const handleChange = (event, value) => {
    // if (value == "7" || value == "8") {
    //   navigate("/");
    // } else {
    setValue(value);
    let SelectDate1 = day.filter((item) => {
      return item.id === value;
    });
    let Dateselected = SelectDate1?.map((item) => {
      return item?.date;
    });
    if (Dateselected && Dateselected[0]) {
      setselectedDate(Dateselected[0]);
      fetchTrackList(
        selectedRaceType.join(),
        selectedCountryType.join(),
        Dateselected
      );
    } else {
      setselectedDate(moment().utc().format("YYYY-MM-DD"));
      fetchTrackList(
        selectedRaceType.join(),
        selectedCountryType.join(),
        moment().utc().format("YYYY-MM-DD")
      );
    }
    console.log(Dateselected, Dateselected[0], "selectedDateselectedDate");

    // }
  };

  // Change Racing Filter

  const ChangeRaceFilter = (event, item) => {
    const flag = event.target.checked;
    var races;
    if (flag) {
      const newItem = [...selectedRaceType, item];
      setselectedRaceType(newItem);
      races = newItem && newItem.join();
      console.log(item, newItem, "ChangeRaceFilter");
    } else {
      if (selectedRaceType?.length >= 2) {
        const removeItem = selectedRaceType?.filter((eItem) => eItem !== item);
        setselectedRaceType(removeItem);
        races = removeItem && removeItem.join();
        console.log(item, removeItem, "ChangeRaceFilter");
      }
    }
    console.log(item, races, selectedCountryType, "ChangeRaceFilter");
    // var urlPath = races && `/admin/racing/all/${races}/AusNZ,Intl`;
    // window.location.href = urlPath;
    // window.history.pushState({}, null, urlPath);
    races &&
      fetchTrackList(
        races,
        selectedCountryType.join(),
        moment().utc().format("YYYY-MM-DD")
      );
  };
  const handleDateChange = (date) => {
    setselectedDate(moment(date).format("YYYY-MM-DD"));
    fetchTrackList(
      selectedRaceType.join(),
      selectedCountryType.join(),
      moment(date).format("YYYY-MM-DD")
    );
  };
  const ChangeCountryFilter = (event, item) => {
    const flag = event.target.checked;
    var country;
    if (flag) {
      const newItem = [...selectedCountryType, item];
      setselectedCountryType(newItem);
      // if (newItem.includes("Aus/NZ") && newItem.includes("Intl")) {
      //   // country = "/admin/racing/all/1,2,3/AusNZ,Intl";
      //   country="AusNZ,Intl";
      // } else if (newItem.includes("Aus/NZ")) {
      //   // country = "/admin/racing/all/1,2,3/AusNZ";
      //   country="AusNZ";
      // } else if (newItem.includes("Intl")) {
      //   // country = "/admin/racing/all/1,2,3/Intl";
      //   country="Intl";
      // } else {
      //   // country = "/admin/racing/all/1,2,3/AusNZ,Intl";
      //   country="AusNZ,Intl";
      // }
      country = newItem.join();
      console.log(newItem, country, "ChangeCountryFilter");
    } else {
      if (selectedCountryType.length >= 2) {
        const removeItem = selectedCountryType.filter(
          (eItem) => eItem !== item
        );
        setselectedCountryType(removeItem);
        // if (removeItem.includes("Aus/NZ") && removeItem.includes("Intl")) {
        //   country = "/admin/racing/all/1,2,3/AusNZ,Intl";
        // } else if (removeItem.includes("Aus/NZ")) {
        //   country = "/admin/racing/all/1,2,3/AusNZ";
        // } else if (removeItem.includes("Intl")) {
        //   country = "/admin/racing/all/1,2,3/Intl";
        // } else {
        //   country = "/admin/racing/all/1,2,3/AusNZ,Intl";
        // }
        country = removeItem.join();
        console.log(removeItem, country, "ChangeCountryFilter");
      }
    }
    console.log(item, selectedRaceType, country, "ChangeCountryFilter");
    // // window.location.href = redPath;
    // window.history.pushState({}, null, country);
    country &&
      fetchTrackList(
        selectedRaceType.join(),
        country,
        moment().utc().format("YYYY-MM-DD")
      );
  };
  console.log(
    "racetypeeee",
    selectedDate,
    selectedRaceType,
    selectedCountryType
  );
  const Today = moment().utc().format("YYYY-MM-DD");
  const Tommorow = moment().utc().add(1, "days").format("YYYY-MM-DD");
  const yesterDay = moment().utc().subtract(1, "days").format("YYYY-MM-DD");
  return (
    // <Grid container className="page-content adminLogin">
    //   <Grid item xs={12}>
    //     <Box className="pageWrapper">

    <Box className="sport-tab">
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        className="racing-tab-detail"
        disableRipple
        disableFocusRipple
      >
        {day &&
          day?.map((item, index) => {
            return (
              <>
                <Tab
                  disableRipple
                  disableFocusRipple
                  //   label={item?.dayName}
                  label={
                    item?.date == Today
                      ? "Today"
                      : item?.date == Tommorow
                      ? "Tommorow"
                      : item?.date == yesterDay
                      ? "Yesterday"
                      : item?.dayName
                  }
                  value={item?.id}
                  className={item?.id == value ? "active" : ""}
                  onChange={(event, newValue) => handleChange(event, item?.id)}
                />
              </>
            );
          })}
      </Tabs>
      <Box className="Filteritemlist-wrap">
        <ul className="Filteritemlist-racing">
          {filterRaceType?.length > 0 &&
            filterRaceType?.map((filter, i) => (
              <li>
                <FormControl>
                  <label>
                    <Checkbox
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      className="filter-racing"
                      icon={<Unchecked />}
                      checkedIcon={<Checked />}
                      name="filter"
                      value={filter?.id}
                      onChange={(event) => {
                        ChangeRaceFilter(event, filter?.id);
                      }}
                      checked={selectedRaceType?.includes(filter?.id)}
                    />
                    {filter?.icon}
                  </label>
                </FormControl>
              </li>
            ))}
        </ul>

        <ul className="Filteritemlist-racing">
          {filterCountry?.length > 0 &&
            filterCountry?.map((countryItem, i) => (
              <li>
                <label>
                  <Checkbox
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    className="filter-country"
                    icon={<Unchecked />}
                    checkedIcon={<Checked />}
                    name="filtercountry"
                    value={countryItem?.value}
                    onChange={(event) => {
                      ChangeCountryFilter(event, countryItem?.value);
                    }}
                    checked={selectedCountryType.includes(countryItem?.value)}
                  />

                  {countryItem?.name}
                </label>
              </li>
            ))}
        </ul>
        {value == 8 || value == 7 ? (
          <Box className="Filteritemlist-datepicker">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container style={{ justifyContent: "end" }}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  placeholder="DD/MM//YYYY"
                  margin="normal"
                  id="date-picker-inline"
                  inputVariant="outlined"
                  value={selectedDate}
                  onChange={(e) => handleDateChange(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disableFuture={value == 8}
                  disablePast={value == 7}
                  // style={{ marginRight: 5 }}
                  className="details-search-picker"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default FixturesFilter;
