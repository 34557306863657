import { Validators } from "../../../library/utilities/Validators";

export const marketValueKeysFormModel = [
  {
    label: "Key*",
    value: "",
    type: "text",
    placeholder: "Key",
    field: "key",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "12",
  },
  {
    label: "Variation",
    value: "",
    type: "variations",
    placeholder: "Variation",
    field: "variation",
    required: true,
    className: "12",
  },
  {
    label: "Value Type*",
    value: "",
    type: "dropdown",
    placeholder: "Value Type",
    field: "valueType",
    options: [
      { id: 1, value: "string", label: "string" },
      { id: 2, value: "double", label: "double" },
      { id: 3, value: "int", label: "int" },
    ],
    required: true,
    className: "12",
  },
];
