import React, { Component, createRef } from "react";
import { LayoutsForm } from "./form-constant";
import {
  fetchErrorFields,
  removeErrorFieldsFromValues,
} from "../../../library/utilities";
import {
  ActionMessage,
  Button,
  Form,
} from "../../../library/common/components/";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";

export class MetaTag extends Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      formModel: LayoutsForm,
      metaList: [],
      dataObject: {},
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
    };
  }

  setActionMessage = (display = false, type = "", message = "") => {
    let setActionMessage = {
      display: display,
      type: type,
      message: message,
    };
    this.setState({ messageBox: setActionMessage });
  };

  componentDidUpdate(prevProps) {
    if (this.props.metaLists !== prevProps.metaLists) {
      this.setState({
        metaList: this.props.metaLists ? this.props.metaLists : [],
      });
    }
  }

  handleSave = () => {
    if (
      this.formRef.getFormData().isFormValid !== false &&
      this.formRef.state.formData.title !== "" &&
      this.formRef.state.formData.description !== ""
    ) {
      const data = removeErrorFieldsFromValues(
        this.formRef.getFormData().formData
      );
      let newObj = {
        title: data.title,
        description: data.description,
      };
      this.formRef.resetForm();
      this.setActionMessage();
      const { metaList } = this.state;
      let array = [...metaList];
      array.push(newObj);
      this.setState({ metaList: array }, () => {
        this.props.onAdd(array);
      });
    } else {
      this.setActionMessage(
        true,
        "Error",
        `Please fill details for ${fetchErrorFields(
          this.formRef.getFormData().formData,
          LayoutsForm
        ).join(", ")} `
      );
    }
  };

  deleteRow = (index) => {
    const { metaList } = this.state;
    const { onAdd } = this.props;
    let data = metaList;
    data.splice(index, 1);
    this.setState({ metaList: data }, () => {
      onAdd(data);
    });
  };

  render() {
    const {
      // formModel, isLoading,
      messageBox,
      metaList,
    } = this.state;

    return (
      <Grid container>
        <Grid item xs={12}>
          <hr />
          <h4>Metadata</h4>{" "}
          {messageBox.display && (
            <ActionMessage
              message={messageBox.message}
              type={messageBox.type}
              styleClass={messageBox.styleClass}
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <Form
            model={LayoutsForm && LayoutsForm}
            ref={(el) => (this.formRef = el)}
          />
          {metaList && Object.keys(metaList).length > 0 && (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metaList &&
                    metaList.map((row, key) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                          <DeleteIcon onClick={() => this.deleteRow(key)} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        <Grid item xs={2} className="saveAvailable">
          <Button
            color="primary"
            value="Add Row"
            className="admin-btn-green"
            onClick={this.handleSave}
          />
        </Grid>
        <hr />
      </Grid>
    );
  }
}

export default MetaTag;
