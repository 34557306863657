import { Validators } from '../../../library/utilities/Validators'

export const apiEventIdentifireFormModel = [
  {
    label: 'Event',
    value: '',
    type: 'dropdown',
    placeholder: 'Event',
    field: 'eventId',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
    options: [],
  },
  {
    label: 'Api Event Id*',
    value: '',
    type: 'text',
    placeholder: 'Api Event Id',
    field: 'apiEventId',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
  },
  {
    label: 'Api Provider*',
    value: '',
    type: 'dropdown',
    placeholder: 'Api Provider',
    field: 'providerId',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
    options: [],
  },
]

export const eventIdentifireForMeetings = [
  {
    label: 'Bookmaker*',
    value: '',
    type: 'dropdown',
    placeholder: 'Bookmaker',
    field: 'providerId',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
    options: [],
  },
  {
    label: 'Fight FeedID*',
    value: '',
    type: 'text',
    placeholder: 'Fight FeedID',
    field: 'apiEventId',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
  },
]
