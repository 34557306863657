import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Grid,
  Paper,
  Checkbox,
  FormControl,
  Breadcrumbs,
  Link,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableHead,
  TableRow,
  TableContainer,
  Table,
  TableCell,
  TableBody,
} from "@material-ui/core";
import moment from "moment";
import { URLS } from "../../library/common/constants";
import { Loader } from "../../library/common/components";
import axiosInstance from "../../helpers/Axios";
import TabHorses from "../../images/sport_icons/tab_horse.svg";
import TabGreyhounds from "../../images/sport_icons/tab_greyhounds.svg";
import TabHarness from "../../images/sport_icons/tab_harness.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import bet365 from "../../images/logo/bet365.png";
import ladbrokes from "../../images/logo/ladbrokes.png";
import betstar from "../../images/logo/betstar.png";
import bookmaker from "../../images/logo/bookmaker.png";
import neds from "../../images/logo/neds.png";
import playup from "../../images/logo/playup_.png";
import palmerbet from "../../images/logo/palmerbet.png";
import vicbet from "../../images/logo/vicebet.png";
import uniBet from "../../images/logo/_unibet.png";
import winningedge from "../../images/logo/winningedge.png";
import realbookie from "../../images/logo/realbookie.png";

import "./DashboardCollapase.scss";

function DashboardCollapase({
  selectedDate,
  selectedRaceType,
  selectedCountryType,
  isRefetch,
}) {
  const [RaceType, setRaceType] = useState([
    {
      id: 1,
      title: "Horses",
      icon: TabHorses,
    },
    {
      id: 3,
      title: "Greyhounds",
      icon: TabGreyhounds,
    },
    {
      id: 2,
      title: "Harness",
      icon: TabHarness,
    },
  ]);
  const [eventList, seteventList] = useState([]);
  const [hourseeventList, sethourseeventList] = useState([]);
  const [greyhoundsList, setgreyhoundsList] = useState([]);
  const [harnessList, setharnessList] = useState([]);
  const [isYesterday, setisYesterday] = useState(false);
  const [isTRaceeventLoading, setisisTRaceeventLoading] = useState(false);

  const fetchAllEvents = async () => {
    setisisTRaceeventLoading(true);
    let date = selectedDate;
    try {
      const { status, data } = await axiosInstance.get(
        `/adminNotification/evnetList?date=${date}`
      );

      if (status === 200) {
        seteventList(data.results);

        // Filter RaceType Hourse / GreyHounds / Harness
        let HourseEvent = data?.results?.filter((item) => {
          return item?.track?.sportId == 1;
        });
        let greyhoundsEvent = data?.results?.filter((item) => {
          return item?.track?.sportId == 3;
        });
        let harnessEvent = data?.results?.filter((item) => {
          return item?.track?.sportId == 2;
        });
        sethourseeventList(HourseEvent);
        setgreyhoundsList(greyhoundsEvent);
        setharnessList(harnessEvent);
        setisisTRaceeventLoading(false);
      }
    } catch (error) {
      setisisTRaceeventLoading(false);
    }
  };

  const updateFixture = async (eventId, providerId, statusId) => {
    let params = {
      eventId: eventId,
      providerId: providerId,
      status: statusId,
    };
    console.log("updateFixture", params);
    try {
      const { status, data } = await axiosInstance.post(
        URLS.updateFixtures,
        params
      );
      if (status === 200) {
        setTimeout(() => {
          fetchAllEvents();
        }, 500);
      }
    } catch (err) {}
  };

  //  Filter For Horse Aus/Nz and Intl Racing
  let newHorseEventList = {
    ausData: hourseeventList?.filter((item) => {
      return item?.track?.countryId == 13;
    }),
    intlData: hourseeventList?.filter((item) => {
      return item?.track?.countryId != 13;
    }),
  };

  //  Filter For GreyhoundsRaceData Aus/Nz and Intl Racing
  let newGreyhoundsEventList = {
    ausData: greyhoundsList?.filter((item) => {
      return item?.track?.countryId == 13;
    }),
    intlData: greyhoundsList?.filter((item) => {
      return item?.track?.countryId != 13;
    }),
  };

  //  Filter For HarnessRaceData Aus/Nz and Intl Racing
  let newHarnessEventList = {
    ausData: harnessList?.filter((item) => {
      return item?.track?.countryId == 13;
    }),
    intlData: harnessList?.filter((item) => {
      return item?.track?.countryId != 13;
    }),
  };

  // push All Racing Filterd Data in RaceType Object
  RaceType[0]["RaceData"] = newHorseEventList;
  RaceType[1]["RaceData"] = newGreyhoundsEventList;
  RaceType[2]["RaceData"] = newHarnessEventList;

  useEffect(() => {
    fetchAllEvents();
    if (
      selectedDate == moment().utc().subtract(1, "days").format("YYYY-MM-DD")
    ) {
      setisYesterday(true);
    } else {
      setisYesterday(false);
    }
  }, [selectedDate, isRefetch]);
  console.log("countryData", selectedCountryType);

  return (
    <>
      {isTRaceeventLoading ? (
        <Box style={{ paddingTop: "20px" }}>
          <Loader />
        </Box>
      ) : (
        <Box className="fixture-colleps-wrap">
          {RaceType?.map((item) => {
            return (
              <>
                {selectedRaceType?.includes(item?.id) ? (
                  <Box className="racing-colleps">
                    {(item.RaceData.ausData?.length > 0 &&
                      selectedCountryType?.includes("Aus/NZ")) ||
                    (item.RaceData.intlData?.length > 0 &&
                      selectedCountryType?.includes("Intl")) ? (
                      <Accordion defaultExpanded className="colleps-accordion">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <img src={item?.icon} />
                          <Typography>{item.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box className="accordion_details">
                            <TableContainer className="fixture-table-wrap">
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="rtable-hc1"></TableCell>
                                    <TableCell className="rt-thead">
                                      <img src={playup} />
                                    </TableCell>
                                    <TableCell className="rt-thead">
                                      <img src={vicbet} />
                                    </TableCell>
                                    <TableCell className="rt-thead">
                                      <img src={neds} />
                                    </TableCell>
                                    <TableCell className="rt-thead">
                                      <img src={ladbrokes} />
                                    </TableCell>
                                    <TableCell className="rt-thead">
                                      <img src={bookmaker} />
                                    </TableCell>
                                    <TableCell className="rt-thead">
                                      <img src={betstar} />
                                    </TableCell>
                                    <TableCell className="rt-thead">
                                      <img src={bet365} />
                                    </TableCell>
                                    <TableCell className="rt-thead">
                                      <img src={palmerbet} />
                                    </TableCell>
                                    <TableCell className="rt-thead">
                                      <img src={uniBet} />
                                    </TableCell>
                                    {/* <TableCell className="rt-thead">
                                <img src={realbookie} />
                              </TableCell>
                              <TableCell className="rt-thead">
                                <img src={winningedge} />
                              </TableCell> */}
                                  </TableRow>
                                </TableHead>
                              </Table>
                            </TableContainer>
                            {item.RaceData.ausData?.length > 0 &&
                            selectedCountryType?.includes("Aus/NZ") ? (
                              <Box className="fixture-wrape">
                                <Typography className="country-title">
                                  Australia
                                </Typography>
                                <TableContainer className="fixture-table-wrap">
                                  <Table>
                                    <TableBody>
                                      <TableRow></TableRow>
                                      {!isTRaceeventLoading &&
                                        (item.RaceData.ausData?.length > 0 ? (
                                          item.RaceData.ausData?.map(
                                            (race, index) => {
                                              return (
                                                <>
                                                  <TableRow>
                                                    <TableCell component="th">
                                                      <img
                                                        src={
                                                          race?.track
                                                            ?.countryObj
                                                            ?.country_flag
                                                        }
                                                        alt="Contry Flag"
                                                        class="flag-icon"
                                                      />
                                                      <Typography variant="h6">
                                                        {race?.eventName}
                                                      </Typography>
                                                    </TableCell>
                                                    {race?.providers?.map(
                                                      (providerItem) => {
                                                        return (
                                                          <>
                                                            <TableCell
                                                              className={
                                                                providerItem?.status ==
                                                                1
                                                                  ? "fixture"
                                                                  : providerItem?.status ==
                                                                    2
                                                                  ? "notfixture"
                                                                  : providerItem?.status ==
                                                                    3
                                                                  ? "ignore"
                                                                  : ""
                                                              }
                                                              onClick={() => {
                                                                if (
                                                                  providerItem?.status ==
                                                                    2 ||
                                                                  providerItem?.status ==
                                                                    3
                                                                ) {
                                                                  updateFixture(
                                                                    providerItem?.eventId,
                                                                    providerItem?.providerId,
                                                                    providerItem?.status ==
                                                                      2
                                                                      ? 3
                                                                      : 2
                                                                  );
                                                                }
                                                              }}
                                                            ></TableCell>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </TableRow>
                                                </>
                                              );
                                            }
                                          )
                                        ) : (
                                          <TableRow
                                            style={{
                                              border: "transparent",
                                              height: "1px",
                                            }}
                                          >
                                            <TableCell
                                              style={{
                                                textAlign: "center",
                                              }}
                                              colSpan={21}
                                            >
                                              No Race Available
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            ) : (
                              ""
                            )}
                            {item.RaceData.intlData?.length > 0 &&
                            selectedCountryType?.includes("Intl") ? (
                              <Box className="fixture-wrape">
                                <Typography className="country-title">
                                  International
                                </Typography>
                                <TableContainer className="fixture-table-wrap">
                                  <Table>
                                    <TableBody>
                                      <TableRow></TableRow>
                                      {!isTRaceeventLoading &&
                                        (item.RaceData.intlData?.length > 0 ? (
                                          item.RaceData.intlData?.map(
                                            (race, index) => {
                                              return (
                                                <>
                                                  <TableRow>
                                                    <TableCell component="th">
                                                      <img
                                                        src={
                                                          race?.track
                                                            ?.countryObj
                                                            ?.country_flag
                                                        }
                                                        alt="Contry Flag"
                                                        class="flag-icon"
                                                      />
                                                      <Typography variant="h6">
                                                        {race?.eventName}
                                                      </Typography>
                                                    </TableCell>
                                                    {race?.providers?.map(
                                                      (providerItem) => {
                                                        return (
                                                          <>
                                                            <TableCell
                                                              className={
                                                                providerItem?.status ==
                                                                1
                                                                  ? "fixture"
                                                                  : providerItem?.status ==
                                                                    2
                                                                  ? "notfixture"
                                                                  : providerItem?.status ==
                                                                    3
                                                                  ? "ignore"
                                                                  : ""
                                                              }
                                                              onClick={() => {
                                                                if (
                                                                  providerItem?.status ==
                                                                    2 ||
                                                                  providerItem?.status ==
                                                                    3
                                                                ) {
                                                                  updateFixture(
                                                                    providerItem?.eventId,
                                                                    providerItem?.providerId,
                                                                    providerItem?.status ==
                                                                      2
                                                                      ? 3
                                                                      : 2
                                                                  );
                                                                }
                                                              }}
                                                            ></TableCell>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </TableRow>
                                                </>
                                              );
                                            }
                                          )
                                        ) : (
                                          <TableRow
                                            style={{
                                              border: "transparent",
                                              height: "1px",
                                            }}
                                          >
                                            <TableCell
                                              style={{
                                                textAlign: "center",
                                              }}
                                              colSpan={21}
                                            >
                                              No Race Available
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      ""
                    )}
                  </Box>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </Box>
      )}
    </>
  );
}

export default DashboardCollapase;
