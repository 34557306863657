import { Validators } from '../../../library/utilities/Validators'

export const apiRaceIdentifireFormModel = [
  {
    label: 'Race*',
    value: '',
    type: 'dropdown',
    placeholder: 'Race',
    field: 'raceId',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
    options: [],
  },
  {
    label: 'Api Race Id*',
    value: '',
    type: 'text',
    placeholder: 'Api Race Id',
    field: 'apiRaceId',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
  },
  {
    label: 'Api Provider*',
    value: '',
    type: 'dropdown',
    placeholder: 'Api Provider',
    field: 'providerId',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
    options: [],
  },
]

export const apiRaceIdentifireForFixtures = [
  {
    label: 'Bookmaker*',
    value: '',
    type: 'dropdown',
    placeholder: 'Api Provider',
    field: 'providerId',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
    options: [],
  },
  {
    label: 'Race FeedId*',
    value: '',
    type: 'text',
    placeholder: 'Api Race Id',
    field: 'apiRaceId',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
  },
]
