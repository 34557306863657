import { Validators } from "../../../library/utilities/Validators";

export const fixturesFormModel = [
  {
    label: "Event Name",
    value: "",
    type: "text",
    placeholder: "Event Name",
    field: "eventname",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "12",
  },
  {
    label: "Description",
    value: "",
    type: "text",
    placeholder: "Description",
    field: "description",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "12",
  },
  {
    label: "Comment",
    value: "",
    type: "text",
    placeholder: "Comment",
    field: "comment",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "12",
  },
];
