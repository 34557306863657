import React, { createRef } from "react";
import { Grid, Paper, TextField } from "@material-ui/core";
import { apiProviderUpdateFormModel } from "./form-constant";
import Form from "../../../../library/common/components/Form";
import ButtonComponent from "../../../../library/common/components/Button";
import ActionMessage from "../../../../library/common/components/ActionMessage";
import { URLS } from "../../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../../library/utilities";
import axiosInstance from "../../../../helpers/Axios";
import { setValidation } from "../../../../helpers/common";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "react-select";

let apiProviderUpdateFormModelArray = apiProviderUpdateFormModel;
class CreateApiProviderUpdate extends React.Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      values: {
        apiURL: "",
        parameterType: "QueryString",
        parameterNameValue: "",
        parameterValue: "",
        parameterFormate: "",
        level: "0",
        parentTableId: "",
        tableNameToUpdate: "",
        secondToUpdate: "",
        status: "active",
      },
      sportTypeId: null,
      parameterKey: [],
      apiProviderId: null,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    const { dbtable, allSportsType, allApiKeyIdentifire } = this.props;
    if (this.props.isEditMode) {
      this.fetchCurrentApiProviderUpdate(this.props.id);
    }
    this.setState({ apiProviderId: this.props.apiProviderId });
    let TablesArray =
      dbtable && typeof dbtable !== "undefined"
        ? Object.keys(dbtable)?.map((item, index) => item)
        : ["HRRunners", "HRRaces", "RunnerOdds"];

    apiProviderUpdateFormModelArray = apiProviderUpdateFormModelArray?.map(
      (fieldItem) => {
        if (fieldItem?.field === "tableNameToUpdate") {
          return {
            ...fieldItem,
            type: "dropdown",
            options: [
              ...TablesArray?.map((tablecol, i) => {
                return { id: i, value: tablecol, label: tablecol };
              }),
            ],
          };
        }
        return fieldItem;
      }
    );
    this.setState((prevState) => {
      return {
        values: {
          ...prevState.values,
          tableNameToUpdate: TablesArray?.length > 0 && TablesArray[0],
        },
      };
    });
    if (allSportsType.length > 0) {
      this.setState({ sportTypeId: allSportsType[0].id });
    }
    if (allApiKeyIdentifire.length > 0) {
      this.setState({ parameterKey: allApiKeyIdentifire[0] });
    }
  }

  componentWillUnmount() {
    apiProviderUpdateFormModelArray = apiProviderUpdateFormModelArray.map(
      (fieldItem) => {
        return { ...fieldItem, errorMessage: "" };
      }
    );
  }

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  fetchCurrentApiProviderUpdate = async (id) => {
    let { allApiKeyIdentifire } = this.props;
    const { status, data } = await axiosInstance.get(
      URLS.apiProviderUpdate + `/${id}`
    );
    if (status === 200) {
      let parameterKey = data.result[0].parameterKey;
      let parameterKeyObj = allApiKeyIdentifire.filter((obj) => {
        return obj.id == parameterKey;
      });
      this.setState({
        values: data.result[0],
        sportTypeId: data.result[0].sportTypeId,
        parameterKey: parameterKeyObj[0],
      });
    }
  };

  validate = () => {
    let {
      apiURL,
      parameterType,
      parameterNameValue,
      parameterValue,
      parameterFormate,
      level,
      parentTableId,
      tableNameToUpdate,
      secondToUpdate,
      status,
    } = this.state.values;
    let flag = true;

    if (
      apiURL === "" ||
      parameterType === "" ||
      parameterNameValue === "" ||
      parameterValue === "" ||
      parameterFormate === "" ||
      level === "" ||
      parentTableId === "" ||
      tableNameToUpdate === "" ||
      secondToUpdate === "" ||
      status === ""
    ) {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      this.setActionMessage(false);
    }

    return flag;
  };

  handleSave = async () => {
    const { apiProviderId, sportTypeId, parameterKey } = this.state;

    const { isEditMode } = this.props;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.apiProviderUpdate}/${this.props.id}`
        : URLS.apiProviderUpdate;

      const values = removeErrorFieldsFromValues(form.formData);
      values["apiProviderId"] = apiProviderId;
      values["sportTypeId"] = sportTypeId;
      values["parameterKey"] = parameterKey?.id;

      apiProviderUpdateFormModelArray = apiProviderUpdateFormModelArray?.map(
        (fieldItem) => {
          return setValidation(fieldItem, values);
        }
      );

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllApiProviderUpdate();
          this.setActionMessage(
            true,
            "Success",
            `Api Provider Update ${
              isEditMode ? "Edited" : "Created"
            } Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${
          isEditMode ? "editing" : "creating"
        } Api Provider Update`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    apiProviderUpdateFormModelArray = apiProviderUpdateFormModelArray?.map(
      (fieldItem) => {
        if (field === fieldItem?.field) {
          return setValidation(fieldItem, values);
        } else {
          return fieldItem;
        }
      }
    );
    this.setActionMessage(false);
  };

  handleSelect = (event, newValue) => {
    this.setState({ parameterKey: newValue });
  };
  handleSelectSport = (e) => {
    this.setState({ sportTypeId: e.target.value });
  };
  render() {
    var { values, messageBox, isLoading, sportTypeId, parameterKey } =
      this.state;
    var { isEditMode, allSportsType, allApiKeyIdentifire } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <div style={{ display: "flex" }}>
                <div className="MuiGrid-root form-group MuiGrid-item MuiGrid-grid-xs-6">
                  <label>Sport Type</label>
                  <select
                    className="select-box-manual"
                    name="sportType"
                    onChange={this.handleSelectSport}
                    value={sportTypeId}
                  >
                    {allSportsType.map((obj, i) => (
                      <option key={i} value={obj.id}>
                        {obj.sportType}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="MuiGrid-root form-group MuiGrid-item MuiGrid-grid-xs-6">
                  <label>Parameter Key</label>
                  <Autocomplete
                    id="combo-box-demo"
                    className="apiprovider-select"
                    options={allApiKeyIdentifire}
                    getOptionLabel={(option) => option.key}
                    value={parameterKey}
                    onChange={(event, newValue) =>
                      this.handleSelect(event, newValue)
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>

              <Form
                values={values}
                model={apiProviderUpdateFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Cancle"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateApiProviderUpdate;
