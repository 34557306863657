import React from "react";
import { Grid, Paper } from "@material-ui/core";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import axiosInstance from "../../../helpers/Axios";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "./champion.scss";
import moment from "moment";

class CreateChampionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      championName: "",
      championFrom: new Date(),
      championTo: new Date(),
      numberOfDefense: "",
      weightClass: "",
      isEditMode: false,
      error: {},
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentChampion(this.props.id);
    }
    // console.log("player detail : ", this.props.playersDetails);
  }
  fetchCurrentChampion = async (id) => {
    const { status, data } = await axiosInstance.get(
      `sports/champian/histroy/${id}`
    );
    if (status === 200) {
      this.setState({
        championName: data?.result?.fighterId,
        leagueId: data?.result?.leagueId,
        championFrom: new Date(data?.result?.from),
        championTo: new Date(data?.result?.to),
        numberOfDefense: data?.result?.defenses,
        weightClass: data?.result?.weightClassId,
      });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { championName } = this.state;
    let flag = true;

    let error_obj = {};

    if (championName === "") {
      flag = false;
      error_obj.championName = "This field is mandatory";
      this.setState({ isLoading: false });
    }

    if (championName !== "") {
      flag = true;
    }

    this.setState({ error: error_obj });
    return flag;
  };

  handleSave = async () => {
    const { isEditMode, leagueId } = this.props;
    const {
      championName,
      championFrom,
      championTo,
      numberOfDefense,
      weightClass,
    } = this.state;
    this.setState({ isLoading: true });
    let fromDate = moment(championFrom).format("YYYY-MM-DD");
    let toDate = moment(championTo).format("YYYY-MM-DD");
    try {
      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `sports/champian/histroy/${this.props.id}`
        : `sports/champian/histroy`;

      let values = {
        fighterId: parseInt(championName),
        leagueId: parseInt(leagueId),
        from: fromDate,
        to: toDate,
        defenses: parseInt(numberOfDefense),
        weightClassId: parseInt(weightClass),
      };

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllHistory();
          this.setActionMessage(
            true,
            "Success",
            `Champion History ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${
          isEditMode ? "editing" : "creating"
        } Champion History`
      );
    }
  };

  handleChange = (e) => {
    let field = e.target.name;
    let value = e.target.value;
    if (field == "numberOfDefense") {
      let reg = new RegExp("[0-9]$");
      if (reg.test(value) || value == "") {
        this.setState({
          [field]: value,
          error: { ...this.state.error, [field]: "" },
        });
      }
    } else {
      this.setState({
        [field]: value,
        error: { ...this.state.error, [field]: "" },
      });
    }
  };

  handleSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
      error: { ...this.state.error, [name]: "" },
    });
  };

  //   handleLeagueChange = (item) => {
  //     this.setState({ leagueId: item, leagueIdError: "" });
  //   };

  render() {
    let {
      messageBox,
      isLoading,
      error,
      championName,
      championFrom,
      championTo,
      numberOfDefense,
      weightClass,
    } = this.state;
    let { isEditMode, playersDetails, WeightClassData } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}
              <div>
                {/* <label>League</label>
                <Select
                  placeholder="League Name"
                  name="leagueId"
                  id="leagueId"
                  isMulti
                  styles={selectboxStyles}
                  value={leagueId}
                  options={leagueList}
                  onChange={(selectedOptions) =>
                    this.handleLeagueChange(selectedOptions)
                  }
                /> */}

                <label>Champion Name</label>
                <select
                  className="select-box-manual"
                  name="championName"
                  onChange={(e) => this.handleChange(e)}
                  value={championName}
                >
                  <option value={""}>No Champion Selected</option>
                  {playersDetails?.length > 0 &&
                    playersDetails?.map((obj, i) => (
                      <option key={i} value={obj?.Player?.id}>
                        {obj?.Player?.name}
                      </option>
                    ))}
                </select>
                {typeof error.championName !== "undefined" &&
                  error.championName !== "" && (
                    <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                      {error.championName}
                    </p>
                  )}

                <Grid container className="champion_date">
                  <Grid item xs={6}>
                    <label>Champion From</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        value={championFrom}
                        autoOk={true}
                        onChange={(date) =>
                          this.setState({ championFrom: date })
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <label>Champion To</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        value={championTo}
                        autoOk={true}
                        onChange={(date) => this.setState({ championTo: date })}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <label>Number of defenses</label>
                <input
                  className="select-box-manual"
                  name="numberOfDefense"
                  onChange={(e) => this.handleChange(e)}
                  value={numberOfDefense}
                  placeholder="number of defenses"
                />

                <label>Weight Class</label>
                <select
                  className="select-box-manual"
                  name="weightClass"
                  onChange={(e) => this.handleChange(e)}
                  value={weightClass}
                >
                  <option value="">No weight class selected</option>
                  {WeightClassData?.length > 0 &&
                    WeightClassData?.map((obj, i) => (
                      <option value={obj?.id} key={i}>
                        {obj?.name}
                      </option>
                    ))}
                </select>
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateChampionHistory;
