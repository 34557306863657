import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { runnerFormModel, runnerHorseFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
// import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";

let runnerFormModelArray = [];

class EditRunnerDetail extends React.Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      values: {
        particiantId: null,
        JockeyWeight: "",
        runnerNumber: "",
        jockeyId: null,
        trainerId: null,
        barrierNumber: "",
      },
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentRunner(this.props.id);
    }
    const { allAnimal, allPlayers, raceName } = this.props;
    runnerFormModelArray =
      raceName === "Horse Racing" ? runnerHorseFormModel : runnerFormModel;
    this.setState((prevState) => {
      return {
        values: {
          ...prevState.values,
          particiantId: allAnimal?.length > 0 ? allAnimal[0].id : "",
          jockeyId: allPlayers?.length > 0 ? allPlayers[0].id : "",
        },
      };
    });
    runnerFormModelArray = runnerFormModelArray?.map((fieldItem) => {
      if (fieldItem?.field === "particiantId") {
        return {
          ...fieldItem,
          label: raceName === "Greyhound Racing" ? "Dogs Name" : "Horse Name",
          type: "dropdown",
          options: [
            ...allAnimal?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "jockeyId") {
        return {
          ...fieldItem,
          label:
            raceName === "Greyhound Racing" ? "Drivers Name" : "Jockey Name",
          type: "dropdown",
          options: [
            ...allPlayers?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "trainerId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...allPlayers?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      }
      return fieldItem;
    });
  }

  componentWillUnmount() {
    runnerFormModelArray = runnerFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  fetchCurrentRunner = async (id) => {
    let { rowToSend } = this.props;
    const { status, data } = await axiosInstance.get(`/race/participant/${id}`);
    if (status === 200) {
      this.setState({ values: data.result });
      this.setState(() => {
        return {
          values: { ...this.state.values, jockeyId: rowToSend?.players[0]?.id },
        };
      });
    }
  };

  validate = () => {
    let { particiantId, runnerNumber, jockeyId, barrierNumber } =
      this.state.values;
    let flag = true;

    if (
      particiantId === null ||
      runnerNumber === "" ||
      jockeyId === null ||
      barrierNumber === ""
    ) {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }

    return flag;
  };

  handleSave = async () => {
    const { isEditMode, raceId, rowToSend, raceName } = this.props;
    this.setState({ isLoading: true });

    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `/race/participant/${this.props.id}`
        : `/race/participant`;

      const values = removeErrorFieldsFromValues(form.formData);

      runnerFormModelArray = runnerFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });
      // values["raceId"] = raceId;
      if (
        rowToSend?.players.length > 0 &&
        rowToSend?.players[0].racePartiicipantMemberId !== "undefined"
      ) {
        values["racePartiicipantMemberId"] =
          rowToSend?.players[0].racePartiicipantMemberId;
      }

      let dataToPass = {
        barrierNumber: values?.barrierNumber,
        particiantId: values?.particiantId,
        raceId: raceId,
        runnerNumber: values?.runnerNumber,
        trainerId: values?.trainerId,
      };
      if (isEditMode) {
        if (rowToSend?.players[0].playerType.name === "jockey") {
          dataToPass["JockeyRaceingParticipantsMemberId"] =
            rowToSend?.players[0].racePartiicipantMemberId;
          dataToPass["jockeyId"] = values?.jockeyId;
        } else {
          dataToPass["trainerRaceingParticipantsMemberId"] =
            rowToSend?.players[0].racePartiicipantMemberId;
          dataToPass["playerId"] = values?.jockeyId;
        }
      } else {
        if (raceName === "Greyhound Racing") {
          dataToPass["playerId"] = values?.jockeyId;
        } else {
          dataToPass["jockeyId"] = values?.jockeyId;
        }
      }
      if (raceName === "Horse Racing") {
        dataToPass["JockeyWeight"] = values?.JockeyWeight;
      }
      if (this.validate()) {
        const { status } = await axiosInstance[method](url, dataToPass);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllRunners();
          this.setActionMessage(
            true,
            "Success",
            `Runner ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${isEditMode ? "editing" : "creating"} Runner`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    runnerFormModelArray = runnerFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  render() {
    var { values, messageBox, isLoading } = this.state;
    var { isEditMode } = this.props;

    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={runnerFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default EditRunnerDetail;
