import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { cmsMenuFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";

let cmsMenuFormModelArray = cmsMenuFormModel;

class CreateCmsMenu extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        menuText: "",
        displayOrder: null,
        status: "Draft",
      },
      pageId: null,
      pageAll: [],
      isEditMode: false,
      error: {},
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentCmsMenu();
    }
    this.fetchAllCmsPages();
  }

  componentWillUnmount() {
    cmsMenuFormModelArray = cmsMenuFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchCurrentCmsMenu = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.cmsMenu + `/${this.props.id}`
    );
    if (status === 200) {
      this.setState({ values: data.result, pageId: data.result.pageId });
    }
  };

  fetchAllCmsPages = async () => {
    const { status, data } = await axiosInstance.get(URLS.cmsPage);
    if (status === 200) {
      this.setState({
        pageAll: data.result,
        pageId: data.result.length > 0 ? data.result[0].id : null,
      });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { menuText, displayOrder, status } = this.state.values;
    let { pageId, error } = this.state;
    let flag = true;

    if (menuText === "" || displayOrder === null || status === "") {
      flag = false;
      this.setState({ isLoading: false });
    }

    if (pageId === null) {
      flag = false;
      error.pageId = "This field is mendatory";
    } else {
      error.pageId = "";
    }

    this.setState({ error });
    return flag;
  };

  handleSave = async () => {
    const { pageId } = this.state;
    const { isEditMode } = this.props;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.cmsMenu}/${this.props.id}`
        : URLS.cmsMenu;

      const values = removeErrorFieldsFromValues(form.formData);
      values["pageId"] = pageId;

      cmsMenuFormModelArray = cmsMenuFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllCmsMenu();
          this.setActionMessage(
            true,
            "Success",
            `Cms Menu ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${
          isEditMode ? "editing" : "creating"
        } Cms Menu`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    cmsMenuFormModelArray = cmsMenuFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  handleSelect = (e) => {
    let value = e.target.value;
    this.setState({ pageId: value, error: {} });
  };

  render() {
    var { values, messageBox, isLoading, pageId, pageAll, error } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={cmsMenuFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <div>
                <label>Page*</label>
                <select
                  className="select-box-manual"
                  name="pageId"
                  onChange={this.handleSelect}
                  value={pageId}
                >
                  {pageAll?.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.pageName}
                    </option>
                  ))}
                </select>
                {typeof error.pageId !== "undefined" && error.pageId !== "" && (
                  <p className="errorText" style={{ margin: "-20px 0 0 0" }}>
                    {error.pageId}
                  </p>
                )}
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateCmsMenu;
