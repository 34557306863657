import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import items from "./menuItems";
import "./sidebarmenu.scss";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { ReactSVG } from "react-svg";

import hourse_img_small from "../../../../images/hourse_img_small.svg";
import greys from "../../../../images/sport_icons/Greyhound.svg";
import harnes from "../../../../images/sport_icons/Harness.svg";
import rugby from "../../../../images/sport_icons/rugbyleague.svg";
import boxing from "../../../../images/menu_icons/Boxing.svg";
import soccer from "../../../../images/sport_icons/soccer.svg";
import cricket from "../../../../images/menu_icons/Cricket.svg";

import mma from "../../../../images/sport_icons/mma.svg";
// import tennis from "../../../../images/sport_icons/tennis.svg";
// import baseball from "../../../../images/sport_icons/baseball.svg";
// import basketball from "../../../../images/sport_icons/basketball.svg";
// import boxing from "../../../../images/menu_icons/Boxing.svg";
// import cricket from "../../../../images/menu_icons/Cricket.svg";

class Sidebar extends Component {
  state = {
    isDropdownOpen: "",
    isSubDropdownOpen: "",
    isSubMenuDropdownOpen: "",
    isInnerDropdownOpen: "",
  };
  handleClick = (value) => {
    this.props.history.replace(value);
  };

  handleDropdown = (label) => {
    if (this.state.isDropdownOpen === label) {
      this.setState({ isDropdownOpen: "" });
    } else {
      this.setState({ isDropdownOpen: label });
    }
  };

  handleSubDropdown = (label) => {
    if (this.state.isSubDropdownOpen === label) {
      this.setState({ isSubDropdownOpen: "" });
    } else {
      this.setState({ isSubDropdownOpen: label });
    }
  };

  handleSubMenuDropdown = (label) => {
    if (this.state.isSubMenuDropdownOpen === label) {
      this.setState({ isSubMenuDropdownOpen: "" });
    } else {
      this.setState({ isSubMenuDropdownOpen: label });
    }
  };

  handleInnerDropdown = (label) => {
    if (this.state.isInnerDropdownOpen === label) {
      this.setState({ isInnerDropdownOpen: "" });
    } else {
      this.setState({ isInnerDropdownOpen: label });
    }
  };

  render() {
    return (
      <div className="sidebar">
        <ul>
          {items.map((item, index) => {
            var is_expaned = this.state.isDropdownOpen === item.label;
            let currentitem = item.subMenu?.filter(
              (item) => item.value === this.props.location.pathname
            );
            if (currentitem?.length > 0) is_expaned = true;
            if (item.isDropdown) {
              if (item.label === "Sports Details") {
                return (
                  <>
                    <li
                      button
                      key={index}
                      onClick={() => this.handleDropdown(item.label)}
                      className={
                        is_expaned ? "menu-main bg-yellow" : "menu-main"
                      }
                    >
                      <p>
                        {item.label}
                        {is_expaned ? <ExpandLess /> : <ExpandMore />}
                      </p>
                    </li>
                    <Collapse in={is_expaned} timeout="auto" unmountOnExit>
                      <li
                        button
                        className={
                          this.state.isSubDropdownOpen === "Racing"
                            ? "menu-main bg-yellow"
                            : "menu-main bg-gray"
                        }
                        onClick={() => this.handleSubDropdown("Racing")}
                      >
                        <p>
                          <span>
                            {/* <ReactSVG src={hourse_img_small} /> */}
                            Racing
                          </span>
                          {this.state.isSubDropdownOpen === "Racing" ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </p>
                      </li>
                      <Collapse
                        in={this.state.isSubDropdownOpen === "Racing"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <li
                          className={
                            this.state.isSubMenuDropdownOpen === "all"
                              ? "menu-main bg-yellow"
                              : "menu-main bg-gray"
                          }
                          //'menu-main bg-light-green'
                          onClick={() => this.handleSubMenuDropdown("all")}
                        >
                          <p>
                            <span className="p20">All</span>
                            {this.state.isSubMenuDropdownOpen === "all" ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </p>
                        </li>
                        <Collapse
                          in={this.state.isSubMenuDropdownOpen === "all"}
                          timeout="auto"
                          unmountOnExit
                        >
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racing/all"
                              className="sports_menu_name p70"
                            >
                              Fixtures
                            </NavLink>
                          </li>
                          {/* <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racingdetail/Horse Racing/1/animal/Horses/1"
                              className="sports_menu_name p70"
                            >
                              Horses
                            </NavLink>
                          </li>
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racingdetail/Horse Racing/1/Jockeys/1"
                              className="sports_menu_name p70"
                            >
                              Jockeys
                            </NavLink>
                          </li>
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racingdetail/Horse Racing/1/Trainers/5"
                              className="sports_menu_name p70"
                            >
                              Trainers
                            </NavLink> 
                          </li>
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racing/Horse Racing/1"
                              className="sports_menu_name p70"
                            >
                              Meetings
                            </NavLink>
                          </li>*/}
                        </Collapse>
                        <li
                          className={
                            this.state.isSubMenuDropdownOpen === "Horse Racing"
                              ? "menu-main bg-yellow"
                              : "menu-main bg-gray"
                          }
                          //'menu-main bg-light-green'
                          onClick={() =>
                            this.handleSubMenuDropdown("Horse Racing")
                          }
                        >
                          <p>
                            <span className="p20">
                              {/* <ReactSVG src={hourse_img_small} /> */}
                              Horse Racing
                            </span>
                            {this.state.isSubMenuDropdownOpen ===
                            "Horse Racing" ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </p>
                        </li>
                        <Collapse
                          in={
                            this.state.isSubMenuDropdownOpen === "Horse Racing"
                          }
                          timeout="auto"
                          unmountOnExit
                        >
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racing/meetings/Horse Racing/1"
                              className="sports_menu_name p70"
                            >
                              Fixtures
                            </NavLink>
                          </li>
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racingdetail/Horse Racing/1/animal/Horses/1"
                              className="sports_menu_name p70"
                            >
                              Horses
                            </NavLink>
                          </li>
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racingdetail/Horse Racing/1/Jockeys/1"
                              className="sports_menu_name p70"
                            >
                              Jockeys
                            </NavLink>
                          </li>
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racingdetail/Horse Racing/1/Trainers/5"
                              className="sports_menu_name p70"
                            >
                              Trainers
                            </NavLink>
                          </li>
                          {/* <li className='menu-sub bg-gray'>
                            <NavLink
                              to='/admin/racing/Horse Racing/1'
                              className='sports_menu_name p70'
                            >
                              Meetings
                            </NavLink>
                          </li> */}
                        </Collapse>
                        <li
                          // className='menu-main bg-light-green'
                          className={
                            this.state.isSubMenuDropdownOpen ===
                            "Harness Racing"
                              ? "menu-main bg-yellow"
                              : "menu-main bg-gray"
                          }
                          onClick={() =>
                            this.handleSubMenuDropdown("Harness Racing")
                          }
                        >
                          <p>
                            <span className="p20">
                              {/* <ReactSVG src={harnes} /> */}
                              Harness Racing
                            </span>
                            {this.state.isSubMenuDropdownOpen ===
                            "Harness Racing" ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </p>
                        </li>
                        <Collapse
                          in={
                            this.state.isSubMenuDropdownOpen ===
                            "Harness Racing"
                          }
                          timeout="auto"
                          unmountOnExit
                        >
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racing/meetings/Harness Racing/2"
                              className="sports_menu_name p70"
                            >
                              Fixtures
                            </NavLink>
                          </li>
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racingdetail/Harness Racing/2/animal/Horses/1"
                              className="sports_menu_name p70"
                            >
                              Horses
                            </NavLink>
                          </li>
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racingdetail/Harness Racing/2/Driver/6"
                              className="sports_menu_name p70"
                            >
                              Drivers
                            </NavLink>
                          </li>
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racingdetail/Harness Racing/2/Trainers/7"
                              className="sports_menu_name p70"
                            >
                              Trainers
                            </NavLink>
                          </li>
                          {/* <li className='menu-sub bg-gray'>
                            <NavLink
                              to='/admin/racing/Harness Racing/2'
                              className='sports_menu_name p70'
                            >
                              Meetings
                            </NavLink>
                          </li> */}
                        </Collapse>
                        <li
                          // className='menu-main bg-light-green'
                          className={
                            this.state.isSubMenuDropdownOpen ===
                            "Greyhound Racing"
                              ? "menu-main bg-yellow"
                              : "menu-main bg-gray"
                          }
                          onClick={() =>
                            this.handleSubMenuDropdown("Greyhound Racing")
                          }
                        >
                          <p>
                            <span className="p20">
                              {/* <ReactSVG src={greys} /> */}
                              Greyhound Racing
                            </span>
                            {this.state.isSubMenuDropdownOpen ===
                            "Greyhound Racing" ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </p>
                        </li>
                        <Collapse
                          in={
                            this.state.isSubMenuDropdownOpen ===
                            "Greyhound Racing"
                          }
                          timeout="auto"
                          unmountOnExit
                        >
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racing/meetings/Greyhound Racing/3"
                              className="sports_menu_name p70"
                            >
                              Fixtures
                            </NavLink>
                          </li>
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racingdetail/Greyhound Racing/3/animal/Greyhounds/2"
                              className="sports_menu_name p70"
                            >
                              Greyhounds
                            </NavLink>
                          </li>
                          <li className="menu-sub bg-gray">
                            <NavLink
                              to="/admin/racingdetail/Greyhound Racing/3/Trainers/8"
                              className="sports_menu_name p70"
                            >
                              Trainers
                            </NavLink>
                          </li>
                          {/* <li className='menu-sub bg-gray'>
                            <NavLink
                              to='/admin/racing/Greyhound Racing/3'
                              className='sports_menu_name p70'
                            >
                              Meetings
                            </NavLink>
                          </li> */}
                        </Collapse>
                        <li className="menu-main bg-gray">
                          <NavLink to="/admin/racing/tracks">
                            <span className="p20">Tracks</span>
                          </NavLink>
                        </li>
                        <li className="menu-main bg-gray">
                          <NavLink to="/admin/racing/markets">
                            <span className="p20">Markets</span>
                          </NavLink>
                        </li>
                        <li className="menu-main bg-gray">
                          <NavLink to="/admin/racing/missingData">
                            <span className="p20">Missing Race Data</span>
                          </NavLink>
                        </li>

                        <li className="menu-main bg-gray">
                          <NavLink to="/admin/racing/errorLogs">
                            <span className="p20">Error Logs</span>
                          </NavLink>
                        </li>

                        {/* <li className='menu-sub'>
                          <NavLink
                            to='/admin/racing/Harness Racing/2'
                            className='sports_menu_name'
                          >
                            <ReactSVG src={harnes} />
                            Harness Racing
                          </NavLink>
                        </li>
                        <li className='menu-sub'>
                          <NavLink
                            to='/admin/racing/Greyhound Racing/3'
                            className='sports_menu_name'
                          >
                            <ReactSVG src={greys} />
                            Greyhound Racing
                          </NavLink>
                        </li> */}
                      </Collapse>
                      {/* <li
                        button
                        // className='menu-main bg-gray'
                        className={
                          this.state.isSubDropdownOpen === "Rugby League"
                            ? "menu-main bg-yellow"
                            : "menu-main bg-gray"
                        }
                        onClick={() => this.handleSubDropdown("Rugby League")}
                      >
                        <p>
                          <span>
                            <ReactSVG src={rugby} />
                            Rugby League
                          </span>
                          {this.state.isSubDropdownOpen === "Rugby League" ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </p>
                      </li> */}
                      <Collapse
                        in={this.state.isSubDropdownOpen === "Rugby League"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/fixture/Rugby League/12"
                            className="sports_menu_name p50"
                          >
                            Games
                          </NavLink>
                        </li>
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Rugby League/12"
                            className="sports_menu_name p50"
                          >
                            Leagues
                          </NavLink>
                        </li>
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/rugbyleague/12/missingData"
                            className="sports_menu_name p50"
                          >
                            Missing Data
                          </NavLink>
                        </li>

                        {/* <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Stadiums/Rugby League/12"
                            className="sports_menu_name p50"
                          >
                            Stadiums
                          </NavLink>
                        </li> */}
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/markets/Rugby League/12"
                            className="sports_menu_name p50"
                          >
                            Markets
                          </NavLink>
                        </li>
                        {/* <li className='menu-sub'>
                          <NavLink
                            to='/admin/sport/Cricket/4'
                            className='sports_menu_name'
                          >
                            <ReactSVG src={cricket} />
                            Cricket
                          </NavLink>
                        </li> */}
                        {/* <li className='menu-sub'>
                          <NavLink
                            to='/admin/sport/Mixed Martial Arts/5'
                            className='sports_menu_name'
                          >
                            <ReactSVG src={mma} />
                            Mixed Martial Arts
                          </NavLink>
                        </li> */}
                        {/* <li className='menu-sub'>
                          <NavLink
                            to='/admin/sport/Boxing/6'
                            className='sports_menu_name'
                          >
                            <ReactSVG src={boxing} />
                            Boxing
                          </NavLink>
                        </li> */}
                        {/* <li className='menu-sub'>
                          <NavLink
                            to='/admin/sport/Tennis/7'
                            className='sports_menu_name'
                          >
                            <ReactSVG src={tennis} />
                            Tennis
                          </NavLink>
                        </li> */}
                        {/* <li className='menu-sub'>
                          <NavLink
                            to='/admin/sport/Soccer/8'
                            className='sports_menu_name'
                          >
                            <ReactSVG src={soccer} />
                            Soccer
                          </NavLink>
                        </li> */}
                        {/* <li className='menu-sub'>
                          <NavLink
                            to='/admin/sport/Australian Rules/9'
                            className='sports_menu_name'
                          >
                            <ReactSVG src={rugby} />
                            Australian Rules
                          </NavLink>
                        </li> */}
                        {/* <li className='menu-sub'>
                          <NavLink
                            to='/admin/sport/Baseball/10'
                            className='sports_menu_name'
                          >
                            <ReactSVG src={baseball} />
                            Baseball
                          </NavLink>
                        </li> */}
                        {/* <li className='menu-sub'>
                          <NavLink
                            to='/admin/sport/Basketball/11'
                            className='sports_menu_name'
                          >
                            <ReactSVG src={basketball} />
                            Basketball
                          </NavLink>
                        </li> */}
                        {/* <li className='menu-sub'>
                          <NavLink
                            to='/admin/sport/Rugby League/12'
                            className='sports_menu_name'
                          >
                            <ReactSVG src={rugby} />
                            Rugby League
                          </NavLink>
                        </li> */}
                      </Collapse>
                      {/* <li
                        button
                        // className='menu-main bg-gray'
                        className={
                          this.state.isSubDropdownOpen === "Mixed Martial Arts"
                            ? "menu-main bg-yellow"
                            : "menu-main bg-gray"
                        }
                        onClick={() =>
                          this.handleSubDropdown("Mixed Martial Arts")
                        }
                      >
                        <p>
                          <span>
                            <ReactSVG src={mma} />
                            Mixed Martial Arts
                          </span>
                          {this.state.isSubDropdownOpen ===
                          "Mixed Martial Arts" ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </p>
                      </li> */}
                      <Collapse
                        in={
                          this.state.isSubDropdownOpen === "Mixed Martial Arts"
                        }
                        timeout="auto"
                        unmountOnExit
                      >
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/fixture/Mixed Martial Arts/5"
                            className="sports_menu_name p50"
                          >
                            Events
                          </NavLink>
                        </li>
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Mixed Martial Arts/5"
                            className="sports_menu_name p50"
                          >
                            Organisations
                          </NavLink>
                        </li>
                        {/* <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Locations/Mixed Martial Arts/5"
                            className="sports_menu_name p50"
                          >
                            Locations
                          </NavLink>
                        </li> */}
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/markets/Mixed Martial Arts/5"
                            className="sports_menu_name p50"
                          >
                            Markets
                          </NavLink>
                        </li>
                      </Collapse>

                      {/* Boxing Menu */}
                      {/* <li
                        button
                        // className='menu-main bg-gray'
                        className={
                          this.state.isSubDropdownOpen === "Boxing"
                            ? "menu-main bg-yellow"
                            : "menu-main bg-gray"
                        }
                        onClick={() => this.handleSubDropdown("Boxing")}
                      >
                        <p>
                          <span>
                            <ReactSVG src={boxing} />
                            Boxing
                          </span>
                          {this.state.isSubDropdownOpen === "Boxing" ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </p>
                      </li> */}
                      <Collapse
                        in={this.state.isSubDropdownOpen === "Boxing"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/fixture/Boxing/6"
                            className="sports_menu_name p50"
                          >
                            Events
                          </NavLink>
                        </li>
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Boxing/6"
                            className="sports_menu_name p50"
                          >
                            Organisations
                          </NavLink>
                        </li>
                        {/* <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Locations/Boxing/6"
                            className="sports_menu_name p50"
                          >
                            Locations
                          </NavLink>
                        </li> */}
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/markets/Boxing/6"
                            className="sports_menu_name p50"
                          >
                            Markets
                          </NavLink>
                        </li>
                      </Collapse>

                      {/* Australian Rules */}
                      {/* <li
                        button
                        // className='menu-main bg-gray'
                        className={
                          this.state.isSubDropdownOpen === "Australian Rules"
                            ? "menu-main bg-yellow"
                            : "menu-main bg-gray"
                        }
                        onClick={() =>
                          this.handleSubDropdown("Australian Rules")
                        }
                      >
                        <p>
                          <span>
                            <ReactSVG src={rugby} />
                            Australian Rules
                          </span>
                          {this.state.isSubDropdownOpen ===
                          "Australian Rules" ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </p>
                      </li> */}
                      <Collapse
                        in={this.state.isSubDropdownOpen === "Australian Rules"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/fixture/Australian Rules/9"
                            className="sports_menu_name p50"
                          >
                            Games
                          </NavLink>
                        </li>
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Australian Rules/9"
                            className="sports_menu_name p50"
                          >
                            Leagues
                          </NavLink>
                        </li>
                        {/* <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Stadiums/Australian Rules/9"
                            className="sports_menu_name p50"
                          >
                            Stadiums
                          </NavLink>
                        </li> */}
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/australianrules/9/missingData"
                            className="sports_menu_name p50"
                          >
                            Missing Data
                          </NavLink>
                        </li>
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/markets/Australian Rules/9"
                            className="sports_menu_name p50"
                          >
                            Markets
                          </NavLink>
                        </li>
                      </Collapse>

                      {/* Soccer */}
                      {/* <li
                        button
                        // className='menu-main bg-gray'
                        className={
                          this.state.isSubDropdownOpen === "Soccer"
                            ? "menu-main bg-yellow"
                            : "menu-main bg-gray"
                        }
                        onClick={() => this.handleSubDropdown("Soccer")}
                      >
                        <p>
                          <span>
                            <ReactSVG src={soccer} />
                            Soccer
                          </span>
                          {this.state.isSubDropdownOpen === "Soccer" ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </p>
                      </li> */}
                      <Collapse
                        in={this.state.isSubDropdownOpen === "Soccer"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/fixture/Soccer/8"
                            className="sports_menu_name p50"
                          >
                            Games
                          </NavLink>
                        </li>
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Soccer/8"
                            className="sports_menu_name p50"
                          >
                            Leagues
                          </NavLink>
                        </li>
                        {/* <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Stadiums/Soccer/8"
                            className="sports_menu_name p50"
                          >
                            Stadiums
                          </NavLink>
                        </li> */}
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/soccer/8/missingData"
                            className="sports_menu_name p50"
                          >
                            Missing Data
                          </NavLink>
                        </li>
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/markets/Soccer/8"
                            className="sports_menu_name p50"
                          >
                            Markets
                          </NavLink>
                        </li>
                      </Collapse>

                      {/* Cricket */}
                      {/* <li
                        button
                        // className='menu-main bg-gray'
                        className={
                          this.state.isSubDropdownOpen === "Cricket"
                            ? "menu-main bg-yellow"
                            : "menu-main bg-gray"
                        }
                        onClick={() => this.handleSubDropdown("Cricket")}
                      >
                        <p>
                          <span>
                            <ReactSVG src={cricket} />
                            Cricket
                          </span>
                          {this.state.isSubDropdownOpen === "Cricket" ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </p>
                      </li> */}
                      <Collapse
                        in={this.state.isSubDropdownOpen === "Cricket"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/fixture/Cricket/4"
                            className="sports_menu_name p50"
                          >
                            Games
                          </NavLink>
                        </li>
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Cricket/4"
                            className="sports_menu_name p50"
                          >
                            Leagues
                          </NavLink>
                        </li>
                        {/* <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Stadiums/Cricket/4"
                            className="sports_menu_name p50"
                          >
                            Stadiums
                          </NavLink>
                        </li> */}
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/markets/Cricket/4"
                            className="sports_menu_name p50"
                          >
                            Markets
                          </NavLink>
                        </li>
                      </Collapse>

                      {/* Rugby Union */}
                      {/* <li
                        button
                        // className='menu-main bg-gray'
                        className={
                          this.state.isSubDropdownOpen === "Rugby Union"
                            ? "menu-main bg-yellow"
                            : "menu-main bg-gray"
                        }
                        onClick={() => this.handleSubDropdown("Rugby Union")}
                      >
                        <p>
                          <span>
                            <ReactSVG src={rugby} />
                            Rugby Union
                          </span>
                          {this.state.isSubDropdownOpen === "Rugby Union" ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </p>
                      </li> */}
                      <Collapse
                        in={this.state.isSubDropdownOpen === "Rugby Union"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/fixture/Rugby Union/13"
                            className="sports_menu_name p50"
                          >
                            Games
                          </NavLink>
                        </li>
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Rugby Union/13"
                            className="sports_menu_name p50"
                          >
                            Leagues
                          </NavLink>
                        </li>
                        {/* <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/Stadiums/Rugby Union/13"
                            className="sports_menu_name p50"
                          >
                            Stadiums
                          </NavLink>
                        </li> */}
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/rugbyunion/13/missingData"
                            className="sports_menu_name p50"
                          >
                            Missing Data
                          </NavLink>
                        </li>
                        <li className="menu-sub bg-gray">
                          <NavLink
                            to="/admin/sport/markets/Rugby Union/13"
                            className="sports_menu_name p50"
                          >
                            Markets
                          </NavLink>
                        </li>
                      </Collapse>
                      <li className="menu-main bg-gray">
                        <NavLink to="/admin/mergePlayer">
                          <span>Merge Player</span>
                        </NavLink>
                      </li>
                      {/* <li
                        button
                        // className='menu-main bg-gray'
                        className={
                          this.state.isSubDropdownOpen == "Aussie Rules"
                            ? "menu-main bg-yellow"
                            : "menu-main bg-gray"
                        }
                        onClick={() => this.handleSubDropdown("Aussie Rules")}
                      >
                        <p>
                          <span>
                            <ReactSVG src={mma} />
                            Aussie Rules
                          </span>
                          {this.state.isSubDropdownOpen == "Aussie Rules" ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </p>
                      </li>
                      <Collapse
                        in={this.state.isSubDropdownOpen == "Aussie Rules"}
                        timeout='auto'
                        unmountOnExit
                      >
                        <li className='menu-sub bg-gray'>
                          <NavLink
                            to='/admin/sport/Aussie Rules/9'
                            className='sports_menu_name p50'
                          >
                            Organisation Details
                          </NavLink>
                        </li>
                        <li className='menu-sub bg-gray'>
                          <NavLink
                            to='/admin/sport/fixture/Aussie Rules/9'
                            className='sports_menu_name p50'
                          >
                            Fixtures
                          </NavLink>
                        </li>
                        <li className='menu-sub bg-gray'>
                          <NavLink
                            to='/admin/sport/Aussie Rules/9/stadiums'
                            className='sports_menu_name p50'
                          >
                            Stadiums
                          </NavLink>
                        </li>
                        <li className='menu-sub bg-gray'>
                          <NavLink
                            to='/admin/sport/Aussie Rules/9/markets'
                            className='sports_menu_name p50'
                          >
                            Markets
                          </NavLink>
                        </li>
                      </Collapse> */}
                    </Collapse>
                  </>
                );
              } else if (item.label === "Master Data") {
                return (
                  <>
                    <li
                      button
                      key={index}
                      onClick={() => this.handleDropdown(item.label)}
                      className={
                        is_expaned ? "menu-main bg-yellow" : "menu-main"
                      }
                    >
                      <p>
                        {item.label}
                        {is_expaned ? <ExpandLess /> : <ExpandMore />}
                      </p>
                    </li>
                    <Collapse in={is_expaned} timeout="auto" unmountOnExit>
                      <li className="menu-sub bg-gray">
                        <NavLink
                          to="/admin/masterdata/Stadiums/Master Data/12"
                          className="sports_menu_name p50"
                        >
                          Stadiums
                        </NavLink>
                      </li>
                      <li className="menu-sub bg-gray">
                        <NavLink
                          to="/admin/countries"
                          className="sports_menu_name p50"
                        >
                          Country
                        </NavLink>
                      </li>
                    </Collapse>
                  </>
                );
              } else {
                return (
                  <>
                    <li
                      button
                      key={index}
                      onClick={() => this.handleDropdown(item.label)}
                      className={
                        is_expaned ? "menu-main bg-yellow" : "menu-main"
                      }
                    >
                      <p>
                        {item.label}
                        {is_expaned ? <ExpandLess /> : <ExpandMore />}
                      </p>
                    </li>
                    <Collapse in={is_expaned} timeout="auto" unmountOnExit>
                      <li className="menu-sub">
                        {item.subMenu.map((menu, i) => (
                          <NavLink
                            key={i}
                            to={menu.value}
                            className="sports_menu_name"
                          >
                            {menu?.icon !== "undefined" && (
                              <ReactSVG src={menu.icon} />
                            )}
                            {menu.label}
                          </NavLink>
                        ))}
                      </li>
                    </Collapse>
                  </>
                );
              }
            } else {
              return (
                <li>
                  <NavLink to={item.value}>{item.label}</NavLink>
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  }
}

export default withRouter(Sidebar);
