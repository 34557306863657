import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { eventsFormModel } from "./form-constant";
import Form from "./../../../../library/common/components/Form";
import ButtonComponent from "./../../../../library/common/components/Button";
import ActionMessage from "./../../../../library/common/components/ActionMessage";
import { URLS } from "./../../../../library/common/constants";
import { removeErrorFieldsFromValues } from "./../../../../library/utilities";
import axiosInstance from "./../../../../helpers/Axios";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { setValidation } from "./../../../../helpers/common";
import _ from "lodash";

let eventsFormModelArray = eventsFormModel;

class CreateEvents extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        venueName: "",
        locationType: "location",
      },
      state: null,
      stateAll: [],
      country: null,
      countryAll: [],
      city: null,
      cityAll: [],
      error: {},
      eventsForm: [],
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentEvents(this.props.id);
    } else {
      // let dataToIntial = { countryId: 1, stateId: 1, citesId: 1 };
      // this.setState({
      //   values: dataToIntial,
      // });
    }
    this.fetchAllCountry();

    // const { allCountry, allState, allCity } = this.props;

    // eventsFormModelArray = eventsFormModelArray?.map((fieldItem) => {
    //   if (fieldItem?.field === "countryId") {
    //     return {
    //       ...fieldItem,
    //       type: "dropdown",
    //       options: [
    //         ...allCountry?.map((tablecol, i) => {
    //           return { id: i, value: tablecol?.id, label: tablecol?.country };
    //         }),
    //       ],
    //     };
    //   } else if (fieldItem?.field === "stateId") {
    //     return {
    //       ...fieldItem,
    //       type: "dropdown",
    //       options: [
    //         ...allState?.map((tablecol, i) => {
    //           return {
    //             id: i,
    //             value: tablecol?.id,
    //             label: tablecol?.state,
    //           };
    //         }),
    //       ],
    //     };
    //   } else if (fieldItem?.field === "cities") {
    //     return {
    //       ...fieldItem,
    //       type: "dropdown",
    //       options: [
    //         ...allCity?.map((tablecol, i) => {
    //           return { id: i, value: tablecol?.id, label: tablecol?.cityName };
    //         }),
    //       ],
    //     };
    //   }
    //   return fieldItem;
    // });
  }

  componentWillUnmount() {
    eventsFormModelArray = eventsFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchAllCountry = async () => {
    const { status, data } = await axiosInstance.get(URLS.country);
    if (status === 200) {
      this.setState({ countryAll: data.result });
    }
  };

  fetchAllState = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.state + `/country/${id}`
    );
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["state"], ["asc"]);
      this.setState({ stateAll: data_obj });
    }
  };

  fetchAllCity = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.cities + `/state/${id}`
    );
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["cityName"], ["asc"]);
      this.setState({ cityAll: data_obj });
    }
  };

  fetchCurrentEvents = async (id) => {
    let { rowToPass } = this.props;

    let dataToAdd = {
      venueName: rowToPass?.venueName,
      locationType: rowToPass?.locationType,
    };
    this.setState({
      values: dataToAdd,
      country: rowToPass?.countryId,
      state: rowToPass?.stateId,
      city: rowToPass?.cities === null ? null : rowToPass?.cities?.id,
    });
    this.fetchAllState(rowToPass?.countryId);
    this.fetchAllCity(rowToPass?.stateId);
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { venueName, locationType } = this.state.values;
    let { state, country, city, error } = this.state;
    let flag = true;

    if (venueName === "" || locationType === "") {
      flag = false;

      this.setState({ isLoading: false });
    } else {
      flag = true;
    }

    if (country === null) {
      error.country = "This field id mandatory";
      flag = false;
      this.setState({ isLoading: false });
    } else {
      error.country = "";
    }

    if (state === null) {
      error.state = "This field is mandatory";
      flag = false;
      this.setState({ isLoading: false });
    } else {
      error.state = "";
    }

    // if (city === null) {
    //   error.city = "This field is mandatory";
    //   flag = false;
    //   this.setState({ isLoading: false });
    // } else {
    //   error.city = "";
    // }

    this.setState({ error });

    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;
    const { country, state, city } = this.state;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode ? `location/${this.props.id}` : `location/create`;

      const values = removeErrorFieldsFromValues(form.formData);

      const DataToCreate = {
        venueName: values?.venueName,
        locationType: values?.locationType,
        stateId: parseInt(state),
        countryId: parseInt(country),
        cities: parseInt(city),
      };

      eventsFormModelArray = eventsFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, DataToCreate);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllEvents();
          this.setActionMessage(
            true,
            "Success",
            `Location ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${
          isEditMode ? "editing" : "creating"
        } Location`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    eventsFormModelArray = eventsFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  handleSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "state") {
      this.setState(
        { state: value, error: { ...this.state.error, ["state"]: "" } },
        () => this.fetchAllCity(value)
      );
    }
    if (name === "country") {
      this.setState(
        { country: value, error: { ...this.state.error, ["country"]: "" } },
        () => this.fetchAllState(value)
      );
    }
    if (name === "city") {
      this.setState({
        city: Number(value),
        error: { ...this.state.error, ["city"]: "" },
      });
    }
  };

  render() {
    var {
      values,
      messageBox,
      isLoading,
      countryAll,
      stateAll,
      cityAll,
      country,
      state,
      city,
      error,
    } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={eventsFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <div>
                <label>Country*</label>
                <select
                  className="select-box-manual"
                  name="country"
                  onChange={this.handleSelect}
                  value={country}
                >
                  <option>No Country Selected</option>
                  {countryAll?.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.country}
                    </option>
                  ))}
                </select>
                {typeof error.country !== "undefined" &&
                  error.country !== "" && (
                    <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                      {error.country}
                    </p>
                  )}
                <label>State*</label>
                <select
                  className="select-box-manual"
                  name="state"
                  onChange={this.handleSelect}
                  value={state}
                >
                  {this.state.country === null ? (
                    <option>Select Country First</option>
                  ) : (
                    <>
                      <option>No State Selected</option>
                      {stateAll?.map((obj, i) => (
                        <option key={i} value={obj.id}>
                          {obj.state}
                        </option>
                      ))}
                    </>
                  )}
                </select>
                {typeof error.state !== "undefined" && error.state !== "" && (
                  <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                    {error.state}
                  </p>
                )}
                <label>City</label>
                <select
                  className="select-box-manual"
                  name="city"
                  onChange={this.handleSelect}
                  value={city}
                >
                  {this.state.state === null ? (
                    <option value={null}>Select State First</option>
                  ) : (
                    <>
                      <option value={null}>No City Selected</option>
                      {cityAll?.map((obj, i) => (
                        <option key={i} value={obj.id}>
                          {obj.cityName}
                        </option>
                      ))}
                    </>
                  )}
                </select>
                {/* {typeof error.city !== "undefined" && error.city !== "" && (
                  <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                    {error.city}
                  </p>
                )} */}
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateEvents;
