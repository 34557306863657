import React, { createRef } from "react";
// import qs from "querystring";
import { Grid, Paper } from "@material-ui/core";
import { teamFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
// import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";
// import DatePicker from "react-datepicker";

let teamFormModelArray = teamFormModel;

class EditTeamDetail extends React.Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      values: {
        eventId: null,
        team: null,
      },
      isEditMode: false,
      eventToDate: new Date(),
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentRunner();
    } else {
      this.fetchNeededData();
    }
    const { allPlayers, locationAll } = this.props;

    teamFormModelArray = teamFormModelArray?.map((fieldItem) => {
      if (fieldItem?.field === "team") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...allPlayers?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "playerB") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...allPlayers?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "locationId") {
        return {
          ...fieldItem,
          options: [
            ...locationAll?.map((tablecol, i) => {
              return { id: i, value: tablecol?.id, label: tablecol?.venueName };
            }),
          ],
        };
      }
      return fieldItem;
    });
  }

  componentWillUnmount() {
    teamFormModelArray = teamFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  fetchNeededData = async (id) => {
    let {
      // selectedRow, leagueID, participantsTypeId, sportId,
      allPlayers,
    } = this.props;

    let dataToShow = {
      team: allPlayers[0].id,
    };

    this.setState({ values: dataToShow });
  };

  fetchCurrentRunner = async (id) => {
    let {
      // selectedRow, leagueID, participantsTypeId, sportId,
      teamToedit,
    } = this.props;

    let dataToShow = {
      eventName: this.props?.eventId,
      team: parseInt(teamToedit),
    };

    this.setState({
      values: dataToShow,
    });
  };

  // handleEventToDateChange = (date) => {
  //   let selectedDate = new Date(date)
  //   this.setState({ eventToDate: selectedDate })
  // }

  validate = () => {
    let { team } = this.state.values;
    let flag = true;

    if (team === "") {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }

    return flag;
  };

  handleSave = async () => {
    const {
      isEditMode,
      // match,
      // leagueID,
      // selectedRow,
      // parent_event_id,
      participantToEdit,
    } = this.props;
    this.setState({ isLoading: true });

    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `teams/updateTeamIntoMatch/${participantToEdit}`
        : `/teams/addTeamIntoMatch`;

      const values = removeErrorFieldsFromValues(form.formData);

      let EditMode = {
        participantsId: parseInt(values?.team),
        eventid: this.props?.eventId,
      };

      let CreateMode = {
        id: parseInt(values?.team),
        eventid: this.props?.eventId,
      };
      const DataToSubmit = isEditMode ? EditMode : CreateMode;
      teamFormModelArray = teamFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        const { status } = await axiosInstance[method](
          url,
          // qs.stringify(DataToSubmit)
          DataToSubmit
        );
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllRunners();
          this.setActionMessage(
            true,
            "Success",
            `Runner ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${isEditMode ? "editing" : "creating"} Fight`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    teamFormModelArray = teamFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  render() {
    var {
      values,
      messageBox,
      isLoading,
      // eventToDate, eventCard
    } = this.state;
    var { isEditMode } = this.props;

    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={teamFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              {/* <div style={{ display: 'flex' }}>
                <div className="input-field" style={{ width: '100%' }}>
                  <label>Event To Date</label>
                  <br></br>
                  <DatePicker
                    selected={eventToDate}
                    onChange={this.handleEventToDateChange}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                  />
                </div>
              </div> */}

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default EditTeamDetail;
