import {Validators} from '../../../library/utilities/Validators';

export const LayoutsForm = [
    {
        label: `Title`,
        value: '',
        type: 'text',
        placeholder: 'title',
        field: 'title',
        validators: [
            {check: Validators.required, message: 'This field is mandatory'},],
        required: true,
        className: '6',
    },
    {
        label: `Description`,
        value: '',
        type: 'text',
        placeholder: 'description',
        field: 'description',
        validators: [{check: Validators.required, message: 'This field is mandatory'},],
        required: true,
        className: '6',
    },
];
