import { Validators } from "../../../library/utilities/Validators";

export const animalTypesFormModel = [
  {
    label: "Name*",
    value: "",
    type: "text",
    placeholder: "Name",
    field: "name",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
];
