import { Validators } from '../../../library/utilities/Validators'

export const teamFormModel = [
  // {
  //   label: 'Event Name*',
  //   value: '',
  //   type: 'text',
  //   placeholder: 'Event Name',
  //   field: 'eventName',
  //   validators: [{ check: Validators.required }],
  //   required: true,
  //   className: '6',
  // },
  {
    label: 'Team',
    value: '',
    type: 'text',
    placeholder: 'Team',
    field: 'team',
    required: true,
    validators: [
      { check: Validators.required },
      { check: Validators.number, message: 'Insert Number Only' },
    ],
    className: '12',
  },
  // {
  //   label: 'Team B*',
  //   value: '',
  //   type: 'text',
  //   placeholder: 'Team B',
  //   field: 'playerB',
  //   required: true,
  //   validators: [{ check: Validators.required }],
  //   className: '6',
  // },
  // {
  //   label: "Stadiums",
  //   value: "",
  //   type: "dropdown",
  //   placeholder: "Location",
  //   field: "locationId",
  //   required: false,
  //   className: "6",
  //   options: [],
  // },
]
