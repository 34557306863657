import React from "react";
import { Grid, Paper } from "@material-ui/core";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import axiosInstance from "../../../helpers/Axios";

class CreateBannerPlaceHolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerId: null,
      placeHolderPageId: null,
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
      error: {},
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentBanner();
    }
    this.setState({ placeHolderPageId: this.props.placeHolderPageId });
  }

  componentWillUnmount() {
    this.setState({ error: {} });
  }

  fetchCurrentBanner = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.bannerPlaceHolder + `/${this.props.id}`
    );
    if (status === 200) {
      this.setState({ bannerId: data.result.bannerId });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { bannerId, error } = this.state;
    let flag = true;

    if (bannerId === null) {
      flag = false;
      error.bannerId = "This field is mendatory";
      this.setState({ isLoading: false });
    } else {
      flag = true;
      error.bannerId = "";
    }
    this.setState({ error });
    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;
    let { bannerId, placeHolderPageId } = this.state;
    this.setState({ isLoading: true });
    try {
      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.bannerPlaceHolder}/${this.props.id}`
        : URLS.bannerPlaceHolder;

      const values = {};
      values["bannerId"] = bannerId;
      values["placeHolderPageId"] = placeHolderPageId;

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllBanner();
          this.setActionMessage(
            true,
            "Success",
            `Banner PlaceHolder ${
              isEditMode ? "Edited" : "Created"
            } Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${
          isEditMode ? "editing" : "creating"
        } Banner PlaceHolder`
      );
    }
  };

  handleSelect = (e) => {
    let value = e.target.value;
    this.setState({ bannerId: value, error: {} });
  };

  render() {
    var { messageBox, isLoading, bannerId, error } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <div>
                <label>Banner*</label>
                <select
                  className="select-box-manual"
                  name="bannerId"
                  onChange={this.handleSelect}
                  value={bannerId}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
                {typeof error.bannerId !== "undefined" &&
                  error.bannerId !== "" && (
                    <p style={{ color: "red", margin: "-14px 0 0 0" }}>
                      {error.bannerId}
                    </p>
                  )}
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateBannerPlaceHolder;
