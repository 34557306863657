import { Validators } from "./../../../../library/utilities/Validators";

export const eventsFormModel = [
  {
    label: "Location Name*",
    value: "",
    type: "text",
    placeholder: "Location Name",
    field: "venueName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  {
    label: "Location Type*",
    value: "",
    type: "dropdown",
    placeholder: "Location Type",
    field: "locationType",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      { id: 1, value: "location", label: "location" },
      { id: 1, value: "stadiums", label: "stadiums" },
    ],
  },
  // {
  //   label: "City",
  //   value: "",
  //   type: "text",
  //   placeholder: "State",
  //   field: "cities",
  //   required: false,
  //   className: "6",
  // },
  // {
  //   label: "State*",
  //   value: "",
  //   type: "text",
  //   placeholder: "State",
  //   field: "stateId",
  //   validators: [{ check: Validators.required }],
  //   required: true,
  //   className: "6",
  // },
  // {
  //   label: "Country",
  //   value: "",
  //   type: "text",
  //   placeholder: "Country",
  //   field: "countryId",
  //   required: false,
  //   className: "6",
  // },
];
