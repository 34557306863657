import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  makeStyles,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Modal,
  Button,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Pagination from "@material-ui/lab/Pagination";
import { ReactSVG } from "react-svg";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import arrowLeft from "../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../../images/blog_img/paginationArrowRight.svg";
import "./animal.scss";
import axiosInstance from "../../../helpers/Axios";
import { URLS } from "../../../library/common/constants";
import { Loader } from "../../../library/common/components";
import CreateAnimal from "../../animal/CreateAnimal";
import ShowModal from "../../../components/common/ShowModal/ShowModal";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInputModalOpen, setIsInputModalOpen] = useState(false);
  const [idToSend, setIdToSend] = useState(null);
  const [data, setData] = useState([]);
  const [allGender, setAllGender] = useState([]);
  const [allColour, setAllColour] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sportCount, setSportCount] = useState(null);
  const [rowPerPage] = useState(20); //setRowPerPage
  const [offset, setOffset] = useState(0);
  const [pageNumbers] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  // let currentPageRow = data;

  useEffect(() => {
    fetchAllGender();
    fetchAllColour();
    if (offset === 0) {
      fetchAnimal();
    } else {
      setOffset(0);
      setCurrentPage(1);
    }
    setSearch("");
    // eslint-disable-next-line
  }, [props.match]);

  useEffect(() => {
    fetchAnimal();
    // eslint-disable-next-line
  }, [offset]);

  const fetchAnimal = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `animal/animaltype/${props.match.params.typeid}?limit=${rowPerPage}&offset=${offset}&matchString=${search}`
      );
      if (status === 200) {
        setData(data.result);
        setIsLoading(false);
        setSportCount(data.count);
      }
    } catch (err) {
      // console.log(err);
      setIsLoading(false);
    }
  };

  const fetchAllGender = async () => {
    const { status, data } = await axiosInstance.get(URLS.animalGender);
    if (status === 200) {
      setAllGender(data.result);
    }
  };

  const fetchAllColour = async () => {
    const { status, data } = await axiosInstance.get(URLS.animalColour);
    if (status === 200) {
      setAllColour(data.result);
    }
  };

  const afterChangeRefresh = () => {
    fetchAnimal();
  };

  const toggleInputModal = () => {
    setIsInputModalOpen(!isInputModalOpen);
  };

  const inputModal = (id, type) => {
    setIsInputModalOpen(true);
    setIdToSend(id);
    setIsEditMode(type);
  };

  const handleAnimalDelete = async () => {
    try {
      const { status } = await axiosInstance.delete(`animal/${itemToDelete}`);
      if (status === 200) {
        afterChangeRefresh();
        toggleDeleteModal();
      }
    } catch (err) {}
  };

  const setItemDelete = (id) => {
    setItemToDelete(id);
    setIsDeleteModalOpen(true);
  };

  const toggleDeleteModal = () => {
    setItemToDelete(null);
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handlePaginationButtonClick = (navDirection) => {
    if (navDirection === "prev") {
      if (offset >= 20) {
        setOffset(offset - 20);
        setCurrentPage(currentPage - 1);
      }
    } else {
      setOffset(offset + 20);
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePaginationClick = (event, page) => {
    setCurrentPage(Number(page));
    setOffset((Number(page) - 1) * rowPerPage);
  };

  if (sportCount > 0) {
    // const indexOfLastTodo = currentPage * rowPerPage;
    // const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
    // currentPageRow = data.slice(indexOfFirstTodo, indexOfLastTodo);

    for (let i = 1; i <= Math.ceil(sportCount / rowPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className="pageWrapper">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" style={{ margin: "0" }}>
              {props.match.params.animalname}
            </Typography>
            <div>
              <TextField
                className={classes.margin}
                label="Search"
                size="small"
                variant="outlined"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <Button
                variant="contained"
                className={classes.margin}
                style={{ backgroundColor: "#00833e", color: "#fff" }}
                onClick={() => fetchAnimal()}
              >
                Search
              </Button>
              <Button
                variant="contained"
                className={classes.margin}
                style={{ backgroundColor: "#00833e", color: "#fff" }}
                onClick={() => inputModal(null, false)}
              >
                Add {props.match.params.animalname}
              </Button>
            </div>
          </Box>
          {isLoading && <Loader />}
          {!isLoading && data.length === 0 && <p>No Data Available</p>}
          {!isLoading && data.length > 0 && (
            <TableContainer component={Paper}>
              <Table className="listTable" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>DID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Age</TableCell>
                    <TableCell>Colour</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Variation</TableCell>
                    <TableCell align="right">Edit</TableCell>
                    <TableCell align="right">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    /* .filter((data) => {
                      if (search === "") return data;
                      else if (
                        data.name
                          .toString()
                          .toLowerCase()
                          .includes(search.toString().toLowerCase())
                      ) {
                        return data;
                      }
                      return "";
                    }) */
                    /* .slice(
                      currentPage * rowPerPage - rowPerPage,
                      currentPage * rowPerPage
                    ) */
                    .map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row?.id}</TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>{row?.age}</TableCell>
                        <TableCell>{row?.colour}</TableCell>
                        <TableCell>{row?.gender}</TableCell>
                        <TableCell>{row?.variation}</TableCell>
                        <TableCell align="right">
                          <EditIcon
                            onClick={() => {
                              inputModal(row?.id, true);
                            }}
                            color="primary"
                            className="mr10 cursor iconBtn admin-btn-green"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <DeleteOutlinedIcon
                            onClick={() => {
                              setItemDelete(row?.id);
                            }}
                            color="secondary"
                            className="cursor iconBtn admin-btn-orange"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {data?.length > 0 && (
                <div className="tablePagination animal-pagination">
                  <button
                    className={
                      offset >= 20
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={offset >= 20 ? false : true}
                    onClick={() => handlePaginationButtonClick("prev")}
                  >
                    <ReactSVG src={arrowLeft} />
                  </button>
                  <Pagination
                    hideNextButton
                    hidePrevButton
                    disabled={sportCount > 0 ? false : true}
                    page={currentPage}
                    onChange={handlePaginationClick}
                    count={pageNumbers[pageNumbers?.length - 1]}
                    siblingCount={2}
                    boundaryCount={1}
                    size="small"
                  />
                  <button
                    className={
                      rowPerPage + offset < sportCount
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={rowPerPage + offset < sportCount ? false : true}
                    onClick={() => handlePaginationButtonClick("next")}
                  >
                    <ReactSVG src={arrowRight} />
                  </button>
                </div>
              )}
            </TableContainer>
          )}
        </Paper>

        <Modal
          className="modal modal-input"
          open={isInputModalOpen}
          onClose={toggleInputModal}
        >
          <div
            className={"paper modal-show-scroll"}
            style={{ position: "relative" }}
          >
            <h3 className="text-center">
              {isEditMode
                ? `Edit ${props.match.params.animalname}`
                : `Create ${props.match.params.animalname}`}
            </h3>
            <CancelIcon
              className="admin-close-icon"
              onClick={toggleInputModal}
            />
            <CreateAnimal
              inputModal={toggleInputModal}
              id={idToSend}
              isEditMode={isEditMode}
              fetchAllAniaml={afterChangeRefresh}
              allGender={allGender}
              allColour={allColour}
              animalTypeId={props.match.params.typeid}
            />
          </div>
        </Modal>

        <ShowModal
          isModalOpen={isDeleteModalOpen}
          onClose={toggleDeleteModal}
          Content="Are you sure you want to delete?"
          onOkayLabel="Yes"
          onOkay={handleAnimalDelete}
          onCancel={toggleDeleteModal}
        />
      </Grid>
    </Grid>
  );
};

export default Index;
