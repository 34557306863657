import { Validators } from "../../../library/utilities/Validators";

export const racingParticipantsMemberFormModel = [
  {
    label: "Race Partiicipant*",
    value: "",
    type: "dropdown",
    placeholder: "Race Partiicipant",
    field: "racePartiicipantId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      { id: 1, value: "1", label: "1" },
      { id: 2, value: "2", label: "2" },
      { id: 3, value: "3", label: "3" },
    ],
  },
  {
    label: "Player*",
    value: "",
    type: "dropdown",
    placeholder: "Player",
    field: "playerId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
];
