import React, { PureComponent } from "react";
import "./layout.scss";

class Layout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showMenu: true };
  }

  toggleMenu = (flag) => {
    this.setState({ showMenu: flag });
  };

  render() {
    const { children } = this.props;
    const { showMenu } = this.state;
    return (
      <>
    

        <div className="content">
          <div className="SideBar" open={showMenu} />
          <div>
            <div>{children}</div>
            <div className="Footer" />
          </div>
        </div>
       
      </>
    );
  }
}

export default Layout;
