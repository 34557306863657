import React from "react";
import {
  Box,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import { Loader } from "../../../library/common/components";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";
import axiosInstance from "../../../helpers/Axios";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import DateFnsUtils from "@date-io/date-fns";
import ButtonComponent from "../../../library/common/components/Button";
import moment from "moment";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
// import CreateEvents from "../../events/CreateEvents";
// import CreateTeams from "../../teams/CreateTeams";
// import { URLS } from "../../../library/common/constants";
import CreateMarket from "./CreateMarket/index";
import ShowModal from "../../../components/common/ShowModal/ShowModal";
import "../sportdetailsteam.scss";
import _ from "lodash";

class SportsFixturesDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
      sportsDetails: [],
      leagueDetails: [],
      teamsDetail: [],
      teamAll: [],
      sportAll: [],
      leagueAll: [],
      locationAll: [],
      weatherAll: [],
      isLoading: false,
      isModalOpen: false,
      isInputModalOpen: false,
      isTeamInputModalOpen: false,
      isChangeTeamModalOpen: false,
      idToSend: null,
      participantsId: "",
      leagueName: "",
      teamName: "",
      fixtureName: "",
      currentPage: 1,
      rowPerPage: 20,
      offset: 0,
      sportCount: null,
      resetButton: false,
      editMode: false,
      eventId: "",
      search: "",
      dateSearch: "",
      sortLabelID: true,
      selectedSort: "",
      itemToDelete: null,
      isDeleteLoading: "",
    };
  }

  componentDidMount() {
    this.fetchAllLeague();
    this.getMarket();
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      itemToDelete: null,
    });
  };

  setItemToDelete = (id) => () => {
    this.setState({ itemToDelete: id, isModalOpen: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match !== this.props.match) {
      if (this.state.offset === 0) {
        this.getMarket();
      } else {
        this.setState({ offset: 0, currentPage: 1 });
      }
      this.fetchAllLeague();
      this.setState({ sportsDetails: [], search: "" });
    }
    if (prevState.offset !== this.state.offset) {
      this.getMarket();
    }
    if (prevState.search !== this.state.search) {
      this.getMarket();
    }
  }

  getMarket = async () => {
    let { id } = this.props.match.params;
    let { rowPerPage, offset, search } = this.state;
    this.setState({ isLoading: true });
    try {
      const { status, data } = await axiosInstance.get(
        `sports/marketBySport/${id}?limit=${rowPerPage}&offset=${offset}&matchString=${search}`
      );
      if (status === 200) {
        this.setState({
          sportsDetails: data.result,
          isLoading: false,
          sportCount: data.count,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isLoading: false });
    }
  };

  afterChangeRefresh = () => {
    this.getMarket();
  };

  fetchAllLeague = async () => {
    let { id } = this.props.match.params;
    try {
      // this.setState({ isLoading: true });
      const { status, data } = await axiosInstance.get(
        `sports/league/list/admin/${id}?limit=20&offset=0`
      );
      if (status === 200) {
        let data_obj = _.orderBy(data?.result, ["leagueName"], ["asc"]);
        this.setState({ leagueDetails: data_obj /*isLoading: false*/ });
      }
    } catch (err) {
      // this.setState({ isLoading: false });
    }
  };

  handleDateChange = (date) => {
    this.setState({
      selectedDate: date,
      dateSearch: moment.utc(date).format("DD/MM/YYYY hh:mm:ss a"),
      resetButton: true,
    });
  };

  handleSelectVariation = (e) => {
    this.setState({ leagueName: String(e.target.value), resetButton: true });
  };

  toggleInputModal = () => {
    this.setState({ isInputModalOpen: !this.state.isInputModalOpen });
  };

  // onSubmit = async () => {
  //   // let { id } = this.props.match.params
  //   let { selectedDate, leagueName, sportsDetails } = this.state
  //   let date = moment(selectedDate).format('YYYY-MM-DD')
  //   let leagueID = leagueName !== '' ? Number(leagueName) : ''

  //   let { id } = this.props.match.params

  //   const { status, data } = await axiosInstance.get(
  //     `sports/marketBySport/${id} `,
  //   )

  //   const filterData = data.result.filter((data) => data.leagueId === leagueID)

  //   this.setState({
  //     sportsDetails: filterData,
  //     resetButton: true,
  //     leagueName: '',
  //   })
  // }

  onResetButton = () => {
    // let date = new Date();
    this.getMarket();
    this.setState({ dateSearch: "", leagueName: "", resetButton: false });
  };

  navigateToCardFight = (id) => {
    let { match } = this.props;
    this.props.history.push(
      `/admin/sport/markets/${match?.params?.name}/${match?.params?.id}/details/${id}`
    );
  };

  DeleteMarket = async (id) => {
    this.setState({
      isDeleteLoading: "marketDelete",
      isModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `sports/market/${this.state.itemToDelete}`
      );
      if (status === 200) {
        this.setState({ itemToDelete: null, isDeleteLoading: "" });
        this.afterChangeRefresh();
      }
    } catch (err) {}
  };

  sortLabelHandler = (id) => {
    let {
      sortLabelID,
      //  selectedSort
    } = this.state;

    this.setState({ sortLabelID: !sortLabelID, selectedSort: id });
  };

  handlePaginationClick = (event, page) => {
    let {
      rowPerPage,
      //  offset
    } = this.state;
    this.setState({
      currentPage: Number(page),
      offset: (Number(page) - 1) * rowPerPage,
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let {
      currentPage,
      //  rowPerPage, sportsDetails,
      offset,
    } = this.state;
    // if (navDirection === "prev") {
    //   if (currentPage > 1) {
    //     this.setState({ currentPage: currentPage - 1 });
    //   }
    // } else {
    //   if (currentPage < sportsDetails.length / rowPerPage)
    //     this.setState({ currentPage: currentPage + 1 });
    // }

    if (navDirection === "prev") {
      if (offset >= 20) {
        this.setState({ offset: offset - 20, currentPage: currentPage - 1 });
      }
    } else {
      this.setState({ offset: offset + 20, currentPage: currentPage + 1 });
    }
  };

  render() {
    // const { match } = this.props;
    let {
      selectedDate,
      sportsDetails,
      isLoading,
      rowPerPage,
      currentPage,
      leagueName,
      leagueDetails,
      isInputModalOpen,
      idToSend,
      resetButton,
      editMode,
      eventId,
      search,
      dateSearch,
      sortLabelID,
      selectedSort,
      offset,
      sportCount,
      isModalOpen,
      isDeleteLoading,
    } = this.state;
    const pageNumbers = [];

    // let currentPageRow = sportsDetails;

    if (sportCount > 0) {
      // const indexOfLastTodo = currentPage * rowPerPage;
      // const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      // currentPageRow = sportsDetails.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(sportCount / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }

    let sortTable = sortLabelID;
    return (
      <>
        <Grid container className="page-content adminLogin sports_details">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              {isDeleteLoading === "marketDelete" && (
                <div class="admin-delete-loader">
                  <Loader />
                </div>
              )}
              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={2}>
                  <h3 className="text-left">Market</h3>
                </Grid>
                <Grid item xs={3}>
                  <select
                    name="leagueName"
                    onChange={(e) => this.handleSelectVariation(e)}
                    value={leagueName}
                    style={{
                      fontSize: "16px",
                      borderRadius: "3px",
                      minHeight: "38px",
                      border: "1px solid #ddd",
                      paddingLeft: "10px",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <option value="">Select</option>
                    {leagueDetails?.length > 0 &&
                      leagueDetails?.map((obj) => (
                        <option value={obj.id}>{obj.leagueName}</option>
                      ))}
                  </select>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: 9 }}
                    value={search}
                    onChange={(e) => {
                      this.setState({ search: e.target.value });
                    }}
                    label="Search Event"
                    size="small"
                  />
                </Grid>
                <Grid item xs={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        value={selectedDate}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        className="sportdetailsteam-picker"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={2}>
                  <Box display="flex" justifyContent="flex-end">
                    {resetButton && (
                      <ButtonComponent
                        className="addButton admin-btn-orange btn-submit"
                        color="primary"
                        value="Reset"
                        onClick={this.onResetButton}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && sportsDetails.length === 0 && (
                <p>No Data Available</p>
              )}

              {/* .filter((data) => {
                    if (search === '') return data
                    else if (
                      data.eventName
                        .toString()
                        .toLowerCase()
                        .includes(search.toString().toLowerCase())
                    ) {
                      return data
                    }
                    return ''
                  }) */}
              {!isLoading &&
                sportsDetails.length > 0 &&
                sportsDetails
                  .filter((data) => {
                    if (dateSearch === "") return data;
                    else if (
                      moment
                        .utc(data.eventToDate)
                        .format("DD/MM/YYYY hh:mm:ss a")
                        .toString()
                        .toLowerCase()
                        .includes(dateSearch.toString().toLowerCase())
                    ) {
                      return data;
                    }
                    return "";
                  })
                  .filter((data) => {
                    if (leagueName === "") return data;
                    else if (
                      data.leagueId
                        .toString()
                        .toLowerCase()
                        .includes(leagueName.toString().toLowerCase())
                    ) {
                      return data;
                    }
                    return "";
                  })
                  .map((obj) => (
                    <>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h6">{obj?.eventName} </Typography>
                        <div>
                          <AddIcon
                            onClick={() => {
                              this.toggleInputModal();
                              this.setState({
                                editMode: false,
                                eventId: obj?.id,
                              });
                            }}
                            color="secondary"
                            style={{
                              borderRadius: "50%",
                              fontSize: 20,
                              padding: 4,
                            }}
                            className="mr10 cursor iconBtn admin-btn-orange"
                          />
                        </div>
                      </Box>

                      <TableContainer component={Paper}>
                        <Table
                          className="listTable api-provider-listTable"
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>DID</TableCell>
                              <TableCell style={{ width: "30%" }}>
                                Market Name
                              </TableCell>
                              <TableCell style={{ width: "20%" }}>
                                <TableSortLabel
                                  active={true}
                                  direction={
                                    selectedSort === obj.id && sortTable
                                      ? "asc"
                                      : "desc"
                                  }
                                  onClick={() => {
                                    this.sortLabelHandler(obj?.id);
                                  }}
                                />
                                Created At
                              </TableCell>
                              <TableCell style={{ width: "20%" }}>
                                Show Details
                              </TableCell>
                              <TableCell align="center">Edit</TableCell>
                              <TableCell align="center">Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {obj?.marketList
                              ?.sort((a, b) => {
                                var key1 = a.createdAt;
                                var key2 = b.createdAt;
                                if (
                                  String(selectedSort) === String(obj.id) &&
                                  String(sortLabelID)
                                    ? key1 < key2
                                    : key1 > key2
                                ) {
                                  return -1;
                                } else if (key1 === key2) {
                                  return 0;
                                } else {
                                  return 1;
                                }
                              })
                              .map((item, i) => (
                                <TableRow key={i}>
                                  <TableCell>{item?.id}</TableCell>
                                  <TableCell>{item?.marketName}</TableCell>
                                  <TableCell>
                                    {moment(item?.createdAt).format(
                                      "DD/MM/YYYY hh:mm:ss a"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      style={{
                                        fontSize: "9px",
                                        backgroundColor: "#ff6b00",
                                        color: "#fff",
                                        fontWeight: "400",
                                        textTransform: "none",
                                        padding: "5px 0",
                                      }}
                                      onClick={() =>
                                        this.navigateToCardFight(item?.id)
                                      }
                                    >
                                      Show Details
                                    </Button>
                                  </TableCell>
                                  <TableCell align="right">
                                    <EditIcon
                                      onClick={() => {
                                        this.toggleInputModal();
                                        this.setState({
                                          editMode: true,
                                          idToSend: item?.id,
                                        });
                                      }}
                                      color="primary"
                                      style={{
                                        borderRadius: "50%",
                                        fontSize: 20,
                                        padding: 4,
                                      }}
                                      className="mr10 cursor iconBtn admin-btn-green"
                                    />
                                  </TableCell>
                                  <TableCell align="right">
                                    <DeleteOutlineIcon
                                      onClick={this.setItemToDelete(item?.id)}
                                      color="primary"
                                      style={{
                                        borderRadius: "50%",
                                        fontSize: 20,
                                        padding: 4,
                                      }}
                                      className="mr10 cursor iconBtn admin-btn-orange"
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ))}
              {sportsDetails.length > 0 && (
                <div>
                  <div className="tablePagination">
                    <button
                      className={
                        offset >= 20
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={offset >= 20 ? false : true}
                      // disabled={
                      //   sportsDetails.length / rowPerPage > 1 ? false : true
                      // }
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={sportCount > 0 ? false : true}
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size="small"
                    />
                    <button
                      className={
                        rowPerPage + offset < sportCount
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={rowPerPage + offset < sportCount ? false : true}
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </div>
              )}
            </Paper>

            <ShowModal
              isModalOpen={isModalOpen}
              onClose={this.toggleModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.DeleteMarket}
              onCancel={this.toggleModal}
            />

            <Modal
              className="modal modal-input"
              open={isInputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {editMode ? "Edit Market" : "Add Market"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />
                <CreateMarket
                  inputModal={this.toggleInputModal}
                  id={idToSend}
                  isEditMode={editMode}
                  fetchAllEvents={this.afterChangeRefresh}
                  eventId={eventId}
                />
              </div>
            </Modal>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default SportsFixturesDetails;
