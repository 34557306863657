import { Validators } from "../../../library/utilities/Validators";
import { CMS_MENU_STATUS } from "../../../helpers/constants";

export const cmsPagesFormModel = [
  {
    label: "Page Name*",
    value: "",
    type: "text",
    placeholder: "Page Name",
    field: "pageName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Page Title*",
    value: "",
    type: "text",
    placeholder: "Page Title",
    field: "pageTitle",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Social Media Tag*",
    value: "",
    type: "text",
    placeholder: "Social Media Tag",
    field: "socailMediaTag",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
  {
    label: "Template*",
    value: "",
    type: "dropdown",
    placeholder: "Template",
    field: "template",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      { id: 1, value: "OneColumn", label: "OneColumn" },
      { id: 2, value: "TwoColumn", label: "TwoColumn" },
    ],
  },
  {
    label: "Status*",
    value: "",
    type: "dropdown",
    placeholder: "Status",
    field: "status",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      ...CMS_MENU_STATUS?.map((item, i) => {
        return { id: 1, value: item, label: item };
      }),
    ],
  },
];
