import React from "react";
import {
  Box,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Modal,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Loader } from "../../../library/common/components";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";
import axiosInstance from "../../../helpers/Axios";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ButtonComponent from "../../../library/common/components/Button";
import moment from "moment";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import CreateEvents from "../../events/CreateEvents";
// import CreateTeams from "../../teams/CreateTeams";
import { URLS } from "../../../library/common/constants";
// import AddIcon from "@material-ui/icons/Add";
import CreateApiEventIdentifire from "../../apiEventIdentifire/CreateApiEventIdentifire";
import ShowModal from "../../../components/common/ShowModal/ShowModal";
import "../sportdetailsteam.scss";
import EditTeamDetail from "../sportsRlTeamEdit";
import CreateResult from "../createResult";
import _ from "lodash";

var max = new Date().getFullYear();
var min = max - 9;

const years = [];

for (var i = max; i >= min; i--) {
  years.push(i);
}
const styles = (theme) => ({
  dropdownStyle: {
    backgroundColor: "#fafafa",
    borderRadius: "5px",
    [theme.breakpoints.down(600)]: {
      width: "100%",
      borderRadius: "10px 10px 0px 0px",
      backgroundColor: "#fff",
    },
    "& li": {
      color: "#707070",
      fontSize: "15px",
      fontWeight: "600",
      fontFamily: "Open Sans",
    },
  },
  yearDropdown: {
    backgroundColor: "#fafafa",
    borderRadius: "5px",
    width: "419px",
    "& ul": {
      display: "flex",
      flexWrap: "wrap",
      padding: "11px 23px 11px 24px",
      [theme.breakpoints.down(600)]: {
        padding: "11px 6px 11px 7px",
      },
      "& li": {
        width: "108px",
        height: "50px",
        justifyContent: "center",
        borderRadius: "5px",
        border: "1px solid #B1B1B1",
        backgroundColor: "#fff",
        fontSize: "15px",
        color: "#707070",
        fontFamily: "open sans",
        margin: "7px 8px",
        [theme.breakpoints.down(600)]: {
          width: "100px",
          margin: "6px 5px",
        },
      },
    },
    [theme.breakpoints.down(600)]: {
      width: "100%",
      borderRadius: "10px 10px 0px 0px",
      backgroundColor: "#fff",
    },
  },
});
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
class SportsFixturesDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
      endDate: new Date(),
      selectedYear: "",
      sportsDetails: [],
      leagueDetails: [],
      teamsDetail: [],
      teamAll: [],
      sportAll: [],
      leagueAll: [],
      locationAll: [],
      weatherAll: [],
      trackAll: [],
      isLoading: false,
      isModalOpen: false,
      isInputModalOpen: false,
      isTeamInputModalOpen: false,
      isChangeTeamModalOpen: false,
      idToSend: null,
      participantsId: "",
      leagueName: "",
      teamName: "",
      fixtureName: "",
      currentPage: 1,
      rowPerPage: 20,
      offset: 0,
      sportCount: null,
      resetButton: false,
      // sortLabel: true,
      identifireModalOpen: false,
      eventIdToSend: null,
      identifireDetails: [],
      isInputFormModalOpen: false,
      isEditMode: false,
      isTeamModalOpen: false,
      allEvents: [],
      allProvider: [],
      isEventDeleteModalOpen: false,
      eventToDelete: null,
      selectedRow: {},
      teamToDelete: null,
      teamDeleteModal: false,
      eventEditMode: false,
      TeamEditMode: false,
      leagueIdToPass: null,
      selectedEvent: "",
      eventId: null,
      teamToedit: null,
      participantToEdit: null,
      identifierToDelete: null,
      identifierDeleteModal: false,
      searchIdentifire: "",
      searchTeam: "",
      isDeleteLoading: "",
      resultModalOpen: false,
      resultData: null,
      isResultCreateModal: false,
      participantsToSend: [],
      isResultLoading: false,
      resultToDelete: null,
      resultDeleteModal: false,
      searchTeam: "",
    };
  }

  componentDidMount() {
    this.fetchAllLeague();
    this.fetchAllFixtures();
    // this.fetchAllSport();
    this.fetchAllLeagues();
    this.fetchAllLocation();
    // this.fetchAllWeather();
    // this.fetchEvents();
    this.fetchAllBookkeepers();
    // this.fetchAllTrack();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match !== this.props.match) {
      if (this.state.offset === 0) {
        this.fetchAllFixtures();
      } else {
        this.setState({ offset: 0, currentPage: 1 });
      }
      this.fetchAllLeague();
      this.setState({
        leagueName: "",
        selectedDate: new Date(),
        endDate: new Date(),
      });
    }
    if (prevState.offset !== this.state.offset) {
      // if (this.state.leagueName === "") {
      this.fetchAllFixtures();
      // }
    }
  }

  togglEventDeleteeModal = () => {
    this.setState({
      isEventDeleteModalOpen: !this.state.isEventDeleteModalOpen,
      eventToDelete: null,
    });
  };

  setItemEventToDelete = (id) => () => {
    this.setState({ eventToDelete: id, isEventDeleteModalOpen: true });
  };

  fetchAllTeam = async (id) => {
    const { status, data } = await axiosInstance.get(
      `/sports/league/teams/${id}`
    );
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["name"], ["asc"]);
      this.setState({ teamAll: data_obj });
    }
  };

  // fetchAllSport = async () => {
  //   const { status, data } = await axiosInstance.get(URLS.sports);
  //   if (status === 200) {
  //     this.setState({ sportAll: data.result });
  //   }
  // };

  fetchAllLeagues = async () => {
    const { status, data } = await axiosInstance.get(URLS.league);
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["leagueName"], ["asc"]);
      this.setState({ leagueAll: data_obj });
    }
  };

  fetchAllLocation = async () => {
    const { status, data } = await axiosInstance.get(URLS.location);
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["venueName"], ["asc"]);
      this.setState({ locationAll: data_obj });
    }
  };

  // fetchAllWeather = async () => {
  //   const { status, data } = await axiosInstance.get(URLS.weather);
  //   if (status === 200) {
  //     this.setState({ weatherAll: data.result });
  //   }
  // };

  // fetchAllTrack = async () => {
  //   const { status, data } = await axiosInstance.get(URLS.track);
  //   if (status === 200) {
  //     this.setState({ trackAll: data.result });
  //   }
  // };

  fetchAllLeague = async () => {
    let { id } = this.props.match.params;
    try {
      this.setState({ isLoading: true });
      const { status, data } = await axiosInstance.get(
        `sports/league/list/admin/${id}`
      );
      if (status === 200) {
        let data_obj = _.orderBy(data?.result, ["leagueName"], ["asc"]);
        this.setState({ leagueDetails: data_obj, isLoading: false });
      }
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  searchTeamSubmit = (e) => {
    e.preventDefault();
    this.fetchAllFixtures();
  };

  fetchAllFixtures = async () => {
    let { id, name } = this.props.match.params;
    let {
      rowPerPage,
      offset,
      isTeamModalOpen,
      eventId,
      selectedDate,
      endDate,
      leagueName,
      searchTeam,
      selectedYear,
    } = this.state;
    let date = moment(selectedDate).format("YYYY-MM-DD");
    let enddate = moment(endDate).format("YYYY-MM-DD");
    let todayDate = moment().format("YYYY-MM-DD");
    let isDateToday = moment(date).isSame(todayDate, "day");
    let isendDateToday = moment(enddate).isSame(todayDate, "day");
    let dateToPass = isDateToday ? "" : date;
    let enddateToPass = isendDateToday ? "" : enddate;
    let leagueId = leagueName !== "" ? Number(leagueName) : "";

    let url =
      name === "Mixed Martial Arts" || name === "Boxing"
        ? `events/parent/${id}?limit=${rowPerPage}&offset=${offset}&timezone=${timezone}`
        : `/sports/fixture/${id}?limit=${rowPerPage}&offset=${offset}&date=${dateToPass}&to_date=${enddateToPass}&year_filter=${selectedYear}&leagueId=${leagueId}&team=${searchTeam}&timezone=${timezone}`;

    try {
      this.setState({ isLoading: true });
      const { status, data } = await axiosInstance.get(url);
      if (status === 200) {
        this.setState(
          {
            sportsDetails:
              name === "Mixed Martial Arts" || name === "Boxing"
                ? data.events
                : data.result,
            isLoading: false,
            sportCount: data.count,
          },
          () => {
            if (isTeamModalOpen) {
              let team_obj = this.state.sportsDetails.filter(
                (obj) => obj.id === eventId
              );
              team_obj = team_obj?.length > 0 ? team_obj[0].participant : "";
              this.setState({ teamsDetail: team_obj });
            }
          }
        );
      }
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  // fetchFixtureById = async (id) => {
  //   try {
  //     this.setState({ isLoading: true });
  //     const { status, data } = await axiosInstance.get(
  //       `sports/league/detail/${id}`
  //     );
  //     if (status === 200) {
  //       this.setState({ sportsDetails: data.result, isLoading: false });
  //     }
  //   } catch (err) {
  //     this.setState({ isLoading: false });
  //   }
  // };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date, selectedYear: "" });
  };
  handleendDateChange = (date) => {
    this.setState({ endDate: date, selectedYear: "" });
  };
  handleSelectVariation = (e) => {
    // this.fetchFixtureById(Number(e.target.value));
    this.setState({ leagueName: e.target.value });
  };

  onSubmit = async () => {
    let { id, name } = this.props.match.params;
    let { selectedDate, leagueName, rowPerPage, endDate, selectedYear } =
      this.state;
    this.setState({ offset: 0, currentPage: 1 });

    let todayDate = moment().format("YYYY-MM-DD");

    let enddate = moment(endDate).format("YYYY-MM-DD");
    let isendDateToday = moment(enddate).isSame(todayDate, "day");
    let enddateToPass = isendDateToday ? "" : enddate;

    let date = moment(selectedDate).format("YYYY-MM-DD");
    let isDateToday = moment(date).isSame(todayDate, "day");
    let dateToPass = isDateToday ? "" : date;
    let leagueId = leagueName !== "" ? Number(leagueName) : "";

    let url =
      name === "Mixed Martial Arts" || name === "Boxing"
        ? `/events/parent/${id}?limit=${rowPerPage}&offset=${0}&date=${dateToPass}&to_date=${enddateToPass}&year_filter=${selectedYear}&leagueId=${leagueId}&timezone=${timezone}`
        : `sports/fixture/${id}?limit=${rowPerPage}&offset=${0}&date=${dateToPass}&to_date=${enddateToPass}&year_filter=${selectedYear}&leagueId=${leagueId}&timezone=${timezone}`;

    this.setState({ isLoading: true, resetButton: true });
    try {
      const { status, data } = await axiosInstance.get(url);
      if (status === 200) {
        this.setState({
          sportsDetails:
            name === "Mixed Martial Arts" || name === "Boxing"
              ? data?.events
              : data?.result,
          isLoading: false,
          sportCount: data.count,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isLoading: false });
    }
  };
  // year change handler
  handleChangeYear = (e) => {
    let { value } = e.target;
    this.setState({
      selectedYear: value,
      selectedDate: new Date(),
      endDate: new Date(),
    });
  };

  sortLabelHandler = () => {
    this.setState({ sortLabel: !this.state.sortLabel });
  };

  onResetButton = () => {
    this.fetchAllFixtures();
    let date = new Date();
    this.setState({ selectedDate: date, leagueName: "", resetButton: false });
  };

  showTeam = (team, name, id, leagueId) => {
    let team_sort = _.orderBy(team, ["Team.name"], ["asc"]);
    this.setState({
      teamsDetail: team_sort,
      isTeamModalOpen: true,
      fixtureName: name,
    });
    this.fetchAllTeam(leagueId);
  };

  toggleTeamModal = () => {
    this.setState({
      isTeamModalOpen: !this.state.isTeamModalOpen,
    });
  };

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  inputModal = (id) => {
    this.setState({ isInputModalOpen: true, idToSend: id });
  };

  inputTeamModal = (id) => {
    this.setState({
      isTeamInputModalOpen: true,
      idToSend: id,
      isModalOpen: false,
    });
  };

  inputChangeTeamModal = (id) => () => {
    this.setState({
      isChangeTeamModalOpen: true,
      idToSend: id,
      isModalOpen: false,
      participantsId: id,
    });
  };

  toggleInputModal = () => {
    this.setState({
      isInputModalOpen: !this.state.isInputModalOpen,
    });
  };

  toggleInputFormModal = () => {
    this.setState({ isInputFormModalOpen: !this.state.isInputFormModalOpen });
  };

  toggleResultFormModal = () => {
    this.setState({ isResultCreateModal: !this.state.isResultCreateModal });
  };

  toggleTeamInputModal = () => {
    this.setState({
      isTeamInputModalOpen: !this.state.isTeamInputModalOpen,
    });
  };

  handleIdentifireDelete = async () => {
    this.setState({
      isDeleteLoading: "identifireDelete",
      identifierDeleteModal: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `/event/identifier/${this.state.identifierToDelete}`
      );
      if (status === 200) {
        this.afterChangeRefresh();
        this.showIdentifire(this.state.eventIdToSend);
        this.setState({ isDeleteLoading: "", identifierToDelete: null });
      }
    } catch (err) {}
  };

  handleResultDelete = async () => {
    this.setState({
      isDeleteLoading: "resultDelete",
      resultDeleteModal: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `/sports/result/${this.state.resultToDelete}`
      );
      if (status === 200) {
        this.setState({ isDeleteLoading: "", resultToDelete: null });
        this.afterChangeRefreshResult();
      }
    } catch (err) {}
  };

  toggleIdentifireModal = () => {
    this.setState({ identifireModalOpen: !this.state.identifireModalOpen });
  };

  toggleResultModal = () => {
    this.setState({ resultModalOpen: !this.state.resultModalOpen });
  };

  toggleChangeTeamModal = () => {
    this.setState({
      isChangeTeamModalOpen: !this.state.isChangeTeamModalOpen,
    });
  };

  afterChangeRefresh = () => {
    this.fetchAllFixtures();
  };

  afterChangeRefreshResult = () => {
    this.fetchResultByEvent(this.state.eventIdToSend);
  };

  // afterChangeTeamRefresh = () =>{
  //   this.fetchAllFixtures();
  // }

  handleSelectTeam = (e) => {
    this.setState({ teamName: e.target.value });
  };

  onChangeTeamSubmit = async () => {
    let { teamName, participantsId } = this.state;
    let params = {};
    params["participantsId"] = Number(teamName);
    try {
      const { status } = await axiosInstance.put(
        `participant/participant/${participantsId}`,
        params
      );
      if (status === 200) {
        this.afterChangeRefresh();
      }
    } catch (err) {}
  };

  setTeamToDelete = (id) => {
    this.setState({ teamToDelete: id, teamDeleteModal: true });
  };

  toggleTeamDeleteModal = () => {
    this.setState({
      teamDeleteModal: !this.state.teamDeleteModal,
      teamToDelete: null,
    });
  };

  setIdentifierToDelete = (id) => {
    this.setState({ identifierToDelete: id, identifierDeleteModal: true });
  };

  setResultToDelete = (id) => {
    this.setState({ resultToDelete: id, resultDeleteModal: true });
  };

  toggleIdentifireDeleteModal = () => {
    this.setState({
      identifierDeleteModal: !this.state.identifierDeleteModal,
      identifierToDelete: null,
    });
  };

  toggleResultDeleteModal = () => {
    this.setState({
      resultDeleteModal: !this.state.resultDeleteModal,
      resultToDelete: null,
    });
  };

  handleTeamDelete = async () => {
    this.setState({ isDeleteLoading: "teamDelete", teamDeleteModal: false });
    try {
      const { status } = await axiosInstance.delete(
        `teams/deleteTeamIntoMatch/${this.state.teamToDelete}`
      );
      if (status === 200) {
        this.toggleChangeTeamModal();
        this.afterChangeRefresh();
        this.setState({ teamToDelete: null, isDeleteLoading: "" });
      }
    } catch (err) {}
  };

  handleLeagueDelete = async (id) => {
    this.setState({
      isDeleteLoading: "eventDelete",
      isEventDeleteModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `sports/event/${this.state.eventToDelete}`
      );
      if (status === 200) {
        this.setState({ eventToDelete: null });
        this.afterChangeRefresh();
        this.setState({ isDeleteLoading: "" });
      }
    } catch (err) {}
  };

  navigateToCardFight = (id) => {
    let { match } = this.props;
    this.props.history.push(
      `/admin/sport/cards/${match?.params?.name}/${match?.params?.id}/${id}`
    );
  };

  handlePaginationClick = (event, page) => {
    let {
      rowPerPage,
      //  offset
    } = this.state;
    this.setState({
      currentPage: Number(page),
      offset: (Number(page) - 1) * rowPerPage,
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let {
      currentPage,
      // rowPerPage,
      offset,
    } = this.state;
    // if (navDirection === "prev") {
    //   if (currentPage > 1) {
    //     this.setState({ currentPage: currentPage - 1 });
    //   }
    // } else {
    //   if (currentPage < meetingsDetails.length / rowPerPage)
    //     this.setState({ currentPage: currentPage + 1 });
    // }

    if (navDirection === "prev") {
      if (offset >= 20) {
        this.setState({ offset: offset - 20, currentPage: currentPage - 1 });
      }
    } else {
      this.setState({ offset: offset + 20, currentPage: currentPage + 1 });
    }
  };

  async fetchAllBookkeepers() {
    const { status, data } = await axiosInstance.get(URLS.apiProvider);
    if (status === 200) {
      let provider_sort = data.result?.sort(function (a, b) {
        if (a.providerName.toLowerCase() < b.providerName.toLowerCase()) {
          return -1;
        }
        if (a.providerName.toLowerCase() > b.providerName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      this.setState({ allProvider: provider_sort });
    }
  }

  getProvider = (id) => {
    let { allProvider } = this.state;
    let provider = allProvider
      .filter((obj) => obj.id === id)
      .map((object) => object.providerName);
    return provider;
  };

  // async fetchEvents() {
  //   const { status, data } = await axiosInstance.get(URLS.events);
  //   if (status === 200) {
  //     this.setState({ allEvents: data.result });
  //   }
  // }

  showIdentifire = async (id) => {
    let { eventIdToSend } = this.state;
    let getId = eventIdToSend === null ? id : eventIdToSend;
    this.setState({
      isLoading: true,
    });
    try {
      const { status, data } = await axiosInstance.get(
        `/event/identifier/eventid/${getId}`
      );
      if (status === 200) {
        this.setState({
          identifireDetails: data.eventIdentifiers,
          isLoading: false,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isLoading: false });
    }
  };

  inputFormModal = (id, type) => () => {
    this.setState({
      isInputFormModalOpen: true,
      idToSend: id,
      isEditMode: type,
    });
  };

  resultFormModal = (id, type) => () => {
    this.setState({
      isResultCreateModal: true,
      idToSend: id,
      isEditMode: type,
    });
  };

  fetchResultByEvent = async (id) => {
    this.setState({ isResultLoading: true });
    try {
      const { status, data } = await axiosInstance.get(`/sports/result/${id}`);
      if (status === 200) {
        this.setState({ resultData: data?.message, isResultLoading: false });
      }
    } catch (err) {
      // console.log(err);
    }
  };

  getResultPlayer = (id) => {
    const { participantsToSend } = this.state;
    let player = "";
    let obj = participantsToSend?.find((obj) => obj?.pteam?.id === id);
    player = obj && obj?.pteam && obj?.pteam?.name;
    return player;
  };

  render() {
    const { match } = this.props;
    let {
      selectedDate,
      sportsDetails,
      isLoading,
      rowPerPage,
      currentPage,
      leagueName,
      leagueDetails,
      // isModalOpen,
      teamsDetail,
      fixtureName,
      isInputModalOpen,
      isTeamInputModalOpen,
      isChangeTeamModalOpen,
      teamAll,
      trackAll,
      teamName,
      idToSend,
      resetButton,
      sortLabel,
      identifireModalOpen,
      eventIdToSend,
      identifireDetails,
      isInputFormModalOpen,
      isEditMode,
      allEvents,
      allProvider,
      offset,
      sportCount,
      isTeamModalOpen,
      isEventDeleteModalOpen,
      selectedRow,
      eventEditMode,
      TeamEditMode,
      leagueIdToPass,
      selectedEvent,
      eventId,
      teamToedit,
      // identifierToDelete,
      identifierDeleteModal,
      searchTeam,
      searchIdentifire,
      isDeleteLoading,
      resultModalOpen,
      resultData,
      isResultCreateModal,
      participantsToSend,
      isResultLoading,
      resultDeleteModal,
      participantToEdit,
    } = this.state;
    let { classes } = this.props;
    const pageNumbers = [];

    searchIdentifire !== "" &&
      (identifireDetails = identifireDetails?.filter(
        (obj) =>
          obj?.apiEventId
            ?.toString()
            .toLowerCase()
            .includes(searchIdentifire.toString().toLowerCase()) ||
          obj?.id
            ?.toString()
            .toLowerCase()
            .includes(searchIdentifire.toString().toLowerCase())
      ));

    searchTeam !== "" &&
      (teamsDetail = teamsDetail?.filter(
        (obj) =>
          obj?.Team?.name
            ?.toString()
            .toLowerCase()
            .includes(searchTeam.toString().toLowerCase()) ||
          obj?.Team?.variation
            ?.toString()
            .toLowerCase()
            .includes(searchTeam.toString().toLowerCase())
      ));

    // let currentPageRow = sportsDetails;

    if (sportCount > 0) {
      // const indexOfLastTodo = currentPage * rowPerPage;
      // const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      // currentPageRow = sportsDetails.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(sportCount / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }

    if (sortLabel !== undefined) {
      sportsDetails = sportsDetails?.sort((a, b) => {
        var key1 = a.eventToDate;
        var key2 = b.eventToDate;
        if (sortLabel ? key1 < key2 : key1 > key2) {
          return -1;
        } else if (key1 === key2) {
          return 0;
        } else {
          return 1;
        }
      });
    }

    let sportName = match?.params?.name;
    let sportId = match?.params?.id;
    return (
      <>
        <Grid container className="page-content adminLogin sports_details">
          <Grid item xs={12} className="filter-wrap">
            <Paper className="pageWrapper">
              {isDeleteLoading === "eventDelete" && (
                <div class="admin-delete-loader">
                  <Loader />
                </div>
              )}

              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={6}>
                  <h3 className="text-left">{match.params.name} Fixtures</h3>
                </Grid>
                <Grid item xs={6}>
                  <ButtonComponent
                    className="addButton admin-btn-green btn-submit"
                    color="primary"
                    value={
                      sportName !== "Mixed Martial Arts" &&
                      sportName !== "Boxing"
                        ? "Create Match"
                        : "Create Event"
                    }
                    onClick={() => {
                      this.inputModal();
                      this.setState({ eventEditMode: false });
                    }}
                  />
                </Grid>
                {/* <Grid item xs={3}>
                  <h3 className="text-left">{match.params.name} Fixtures</h3>
                </Grid> */}
                <Grid item xs={2}>
                  <select
                    name="leagueName"
                    onChange={(e) => this.handleSelectVariation(e)}
                    value={leagueName}
                    style={{
                      fontSize: "16px",
                      borderRadius: "3px",
                      minHeight: "38px",
                      border: "1px solid #ddd",
                      paddingLeft: "10px",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <option value="">Select</option>
                    {leagueDetails?.length > 0 &&
                      leagueDetails?.map((obj) => (
                        <>
                          <option value={obj.id}>{obj.leagueName}</option>
                        </>
                      ))}
                  </select>
                </Grid>
                <Grid item xs={2} className="date-wrap">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        label="Start Date"
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        id="date-picker-inline"
                        value={selectedDate}
                        autoOk={true}
                        maxDate={new Date()}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        className="sportdetailsteam-picker"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2} className="date-wrap">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        label="End Date"
                        disableToolbar
                        maxDate={new Date()}
                        format="MM/dd/yyyy"
                        id="date-picker-inline"
                        value={this.state.endDate}
                        autoOk={true}
                        onChange={this.handleendDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        className="sportdetailsteam-picker"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={1} className="year-wrap">
                  <FormControl>
                    <Select
                      labelId="all_year_rl"
                      id="all_year_rl"
                      className="rl_year_select res-rlallteams-select"
                      displayEmpty
                      IconComponent={KeyboardArrowDownIcon}
                      value={this.state.selectedYear}
                      onChange={(e) => this.handleChangeYear(e)}
                      MenuProps={{
                        classes: { paper: classes.yearDropdown },
                      }}
                    >
                      <MenuItem value="" disabled style={{ display: "none" }}>
                        Year
                      </MenuItem>
                      {years?.map((obj, i) => (
                        <MenuItem
                          key={i}
                          value={obj}
                          className={
                            String(this.state.selectedYear) === String(obj)
                              ? "selected_year_option"
                              : ""
                          }
                        >
                          {obj}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} className="submit-wrap">
                  <Box
                    component="form"
                    onSubmit={(e) => {
                      this.setState({ offset: 0, currentPage: 1 });
                      this.searchTeamSubmit(e);
                    }}
                    display="flex"
                    alignItems="center"
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search Team"
                      value={this.state.searchTeam}
                      onChange={(e) => {
                        this.setState({ searchTeam: e.target.value });
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      className="admin-btn-green"
                      style={{ marginLeft: 9 }}
                      disabled={leagueName === "" ? true : false}
                    >
                      Filter
                    </Button>

                    <Button
                      variant="contained"
                      className="admin-btn-orange"
                      style={{ marginLeft: 9 }}
                      onClick={() => {
                        this.setState(
                          {
                            searchTeam: "",
                            leagueName: "",
                            selectedDate: new Date(),
                            endDate: new Date(),
                            selectedYear: "",
                            offset: 0,
                            currentPage: 1,
                          },
                          () => {
                            this.fetchAllFixtures();
                          }
                        );
                      }}
                    >
                      Reset
                    </Button>
                  </Box>
                </Grid>
                {/* <Grid item xs={2}>
                  <Box display="flex" justifyContent="flex-end">
                    {resetButton && (
                      <ButtonComponent
                        className="addButton admin-btn-orange btn-submit"
                        color="primary"
                        value="Reset"
                        onClick={this.onResetButton}
                      />
                    )}
                    &nbsp;&nbsp;
                    <ButtonComponent
                      className="addButton admin-btn-green btn-submit"
                      color="primary"
                      value="Submit"
                      onClick={this.onSubmit}
                      disabled={leagueName === "" ? true : false}
                    />
                  </Box>
                </Grid> */}
                {/* &nbsp;&nbsp;
                <div style={{ float: "right" }}>
                  <ButtonComponent
                    className="addButton admin-btn-green btn-submit"
                    color="primary"
                    value={
                      sportName !== "Mixed Martial Arts" &&
                      sportName !== "Boxing"
                        ? "Create Match"
                        : "Create Event"
                    }
                    onClick={() => {
                      this.inputModal();
                      this.setState({ eventEditMode: false });
                    }}
                  />
                </div> */}
              </Grid>

              {/* SEARCH TEAM FIELD */}
              {/* <Box
                width={"100%"}
                display="flex"
                justifyContent="flex-end"
                m={1}
                mr={3}
              >

              </Box> */}

              {isLoading && <Loader />}
              {!isLoading && sportsDetails.length === 0 && (
                <p>No Data Available</p>
              )}
              {!isLoading && sportsDetails.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      className="listTable api-provider-listTable"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>DID</TableCell>
                          <TableCell>
                            {sportName !== "Mixed Martial Arts" &&
                            sportName !== "Boxing"
                              ? "Match Name"
                              : "Event Name"}
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={true}
                              onClick={this.sortLabelHandler}
                              direction={sortLabel ? "asc" : "desc"}
                            />
                            {sportName !== "Mixed Martial Arts" &&
                            sportName !== "Boxing"
                              ? "Match Date"
                              : "Event Date"}
                          </TableCell>
                          {sportName !== "Mixed Martial Arts" &&
                            sportName !== "Boxing" && (
                              <>
                                {/* <TableCell>Variation</TableCell> */}
                                <TableCell>Show Team</TableCell>
                                <TableCell>View/Add Identifier</TableCell>
                              </>
                            )}
                          {(sportName === "Mixed Martial Arts" ||
                            sportName === "Boxing") && (
                            <>
                              <TableCell>Location</TableCell>
                              <TableCell>State</TableCell>
                              <TableCell>Card Fight</TableCell>
                            </>
                          )}
                          {/* <TableCell>View API Identifier</TableCell> */}
                          <TableCell>Edit</TableCell>
                          <TableCell>Delete</TableCell>
                          {sportName !== "Mixed Martial Arts" &&
                            sportName !== "Boxing" && (
                              <TableCell style={{ textAlign: "center" }}>
                                Result
                              </TableCell>
                            )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sportsDetails.map((sportsDetails, i) => (
                          <>
                            <TableRow>
                              <TableCell>{sportsDetails?.id}</TableCell>
                              <TableCell>{sportsDetails?.eventName}</TableCell>

                              {sportName !== "Mixed Martial Arts" &&
                                sportName !== "Boxing" && (
                                  <>
                                    <TableCell>
                                      {moment(
                                        sportsDetails?.eventToDate
                                      ).format("DD/MM/YYYY hh:mm:ss a")}
                                    </TableCell>
                                    {/* <TableCell>
                                      {sportsDetails?.variation}
                                    </TableCell> */}
                                    <TableCell>
                                      <Button
                                        style={{
                                          fontSize: "9px",
                                          backgroundColor: "#ff6b00",
                                          color: "#fff",
                                          fontWeight: "400",
                                          textTransform: "none",
                                          padding: "5px 0",
                                        }}
                                        onClick={() => {
                                          this.showTeam(
                                            sportsDetails?.participant,
                                            sportsDetails?.eventName,
                                            sportsDetails?.id,
                                            sportsDetails?.leagueId
                                          );
                                          this.setState({
                                            selectedRow: sportsDetails,
                                            leagueIdToPass:
                                              sportsDetails?.leagueId,
                                            eventId: sportsDetails?.id,
                                          });
                                        }}
                                      >
                                        Show Team
                                      </Button>
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        style={{
                                          fontSize: "9px",
                                          backgroundColor: "#ff6b00",
                                          color: "#fff",
                                          fontWeight: "400",
                                          textTransform: "none",
                                          padding: "5px 5px",
                                        }}
                                        onClick={() => {
                                          this.setState(
                                            {
                                              identifireModalOpen: true,
                                              eventIdToSend: sportsDetails?.id,
                                              selectedEvent:
                                                sportsDetails?.eventName,
                                            },
                                            () =>
                                              this.showIdentifire(
                                                sportsDetails?.id
                                              )
                                          );
                                        }}
                                      >
                                        View/Add Identifier
                                      </Button>
                                    </TableCell>
                                  </>
                                )}
                              {(sportName === "Mixed Martial Arts" ||
                                sportName === "Boxing") && (
                                <>
                                  <TableCell>
                                    {moment(sportsDetails?.eventToDate).format(
                                      "DD/MM/YYYY hh:mm:ss a"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {sportsDetails?.location?.venueName}
                                  </TableCell>
                                  <TableCell>
                                    {sportsDetails?.state?.state}
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      style={{
                                        fontSize: "9px",
                                        backgroundColor: "#ff6b00",
                                        color: "#fff",
                                        fontWeight: "400",
                                        textTransform: "none",
                                        padding: "5px 0",
                                      }}
                                      onClick={() => {
                                        this.navigateToCardFight(
                                          sportsDetails?.id
                                        );
                                      }}
                                    >
                                      Card
                                    </Button>
                                  </TableCell>
                                </>
                              )}
                              {/* <TableCell>
                                  <Button
                                    style={{
                                      fontSize: "9px",
                                      backgroundColor: "#ff6b00",
                                      color: "#fff",
                                      fontWeight: "400",
                                      textTransform: "none",
                                      padding: "5px 0",
                                    }}
                                    onClick={() => {
                                      this.showIdentifire(sportsDetails?.id);
                                      this.setState({
                                        identifireModalOpen: true,
                                        eventIdToSend: sportsDetails?.id,
                                      });
                                    }}
                                  >
                                    Api Identifier
                                  </Button>
                                </TableCell> */}
                              <TableCell>
                                <EditIcon
                                  onClick={() => {
                                    this.inputModal(sportsDetails.id);
                                    this.setState({ eventEditMode: true });
                                  }}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-green"
                                />
                              </TableCell>
                              <TableCell>
                                <DeleteOutlineIcon
                                  onClick={this.setItemEventToDelete(
                                    sportsDetails?.id
                                  )}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-orange"
                                />
                              </TableCell>
                              {sportName !== "Mixed Martial Arts" &&
                                sportName !== "Boxing" && (
                                  <TableCell style={{ textAlign: "center" }}>
                                    {moment(
                                      sportsDetails?.eventToDate
                                    ).isBefore(new Date()) === true ? (
                                      <Button
                                        style={{
                                          fontSize: "9px",
                                          backgroundColor: "#ff6b00",
                                          color: "#fff",
                                          fontWeight: "400",
                                          textTransform: "none",
                                          padding: "5px 0",
                                        }}
                                        onClick={() => {
                                          this.fetchResultByEvent(
                                            sportsDetails?.id
                                          );
                                          let team_sort = _.orderBy(
                                            sportsDetails.participant,
                                            ["Team.name"],
                                            ["asc"]
                                          );
                                          this.setState({
                                            resultModalOpen: true,
                                            eventIdToSend: sportsDetails?.id,
                                            selectedEvent:
                                              sportsDetails?.eventName,
                                            participantsToSend: team_sort,
                                          });
                                        }}
                                      >
                                        Result
                                      </Button>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                )}
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="tablePagination">
                    <button
                      className={
                        offset >= 20
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={offset >= 20 ? false : true}
                      // disabled={
                      //   meetingsDetails.length / rowPerPage > 1 ? false : true
                      // }
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={sportCount > 0 ? false : true}
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size="small"
                    />
                    <button
                      className={
                        rowPerPage + offset < sportCount
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={rowPerPage + offset < sportCount ? false : true}
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </>
              )}
            </Paper>

            <ShowModal
              isModalOpen={isEventDeleteModalOpen}
              onClose={this.togglEventDeleteeModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleLeagueDelete}
              onCancel={this.togglEventDeleteeModal}
            />

            <Modal
              className="modal modal-input"
              open={isTeamModalOpen}
              onClose={this.toggleTeamModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <Grid container>
                  <Grid item xs={7}>
                    <Typography variant="h6" style={{ marginTop: 0 }}>
                      {fixtureName + " Team List"}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <div>
                      <input
                        type="text"
                        placeholder="search"
                        value={searchTeam}
                        onChange={(e) => {
                          this.setState({
                            ...this.state.searchTeam,
                            searchTeam: e.target.value,
                          });
                        }}
                        style={{
                          fontSize: "16px",
                          borderRadius: "3px",
                          minHeight: "30px",
                          border: "1px solid #ddd",
                          marginRight: "15px",
                          marginLeft: "15px",
                        }}
                      />
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#00833e",
                          color: "#fff",
                        }}
                        onClick={() => {
                          this.inputTeamModal();
                          this.setState({ TeamEditMode: false });
                        }}
                      >
                        Add Team
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleTeamModal}
                />
                {isDeleteLoading === "teamDelete" && (
                  <div className="admin-delete-modal-loader">
                    <Loader />
                  </div>
                )}
                {isLoading && (
                  <div className="admin-delete-modal-loader">
                    <Loader />
                  </div>
                )}
                <TableContainer component={Paper}>
                  <Table
                    className="listTable api-provider-listTable"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Team Name</TableCell>
                        <TableCell>Variation</TableCell>
                        <TableCell>Edit</TableCell>
                        <TableCell>Delete</TableCell>
                        {/* <TableCell>Change Team</TableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {teamsDetail?.length > 0 &&
                        teamsDetail?.map((team, i) => (
                          <TableRow>
                            <TableCell>{team?.pteam?.name}</TableCell>
                            <TableCell>{team?.pteam?.variation}</TableCell>
                            <TableCell>
                              <EditIcon
                                onClick={() => {
                                  this.inputTeamModal(team?.Team?.id);
                                  this.setState({
                                    TeamEditMode: true,
                                    eventId: team?.eventId,
                                    teamToedit: team?.pteam?.id,
                                    participantToEdit: team?.id,
                                  });
                                }}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                            </TableCell>
                            <TableCell>
                              <DeleteOutlineIcon
                                onClick={() => this.setTeamToDelete(team?.id)}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-orange"
                              />
                            </TableCell>
                            {/* <TableCell>
                              <AddIcon
                                onClick={this.inputChangeTeamModal(team?.id)}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                            </TableCell> */}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isInputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {eventEditMode
                    ? sportName !== "Mixed Martial Arts" &&
                      sportName !== "Boxing"
                      ? "Edit Match"
                      : "Edit Event"
                    : sportName !== "Mixed Martial Arts" &&
                      sportName !== "Boxing"
                    ? "Create Match"
                    : "Create Event"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />

                <CreateEvents
                  inputModal={this.toggleInputModal}
                  id={this.state.idToSend}
                  isEditMode={eventEditMode}
                  fetchAllEvents={this.afterChangeRefresh}
                  sportId={sportId}
                  leagueAll={this.state.leagueAll}
                  locationAll={this.state.locationAll}
                  weatherAll={this.state.weatherAll}
                  trackAll={trackAll}
                  leagueDetails={leagueDetails}
                  isOtherSport={true}
                  otherSportName={sportName}
                />
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isTeamInputModalOpen}
              onClose={this.toggleTeamInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {TeamEditMode ? "Edit Team to Match" : "Create Team To Match"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleTeamInputModal}
                />
                <EditTeamDetail
                  // inputModal={this.toggleTeamInputModal}
                  // id={this.state.idToSend}
                  // isEditMode={true}
                  // fetchAllTeams={this.afterChangeRefresh}
                  // allSports={this.state.sportAll}
                  // isMainForm={false}

                  inputModal={this.toggleTeamInputModal}
                  eventId={eventId}
                  id={this.state.idToSend}
                  isEditMode={TeamEditMode}
                  fetchAllRunners={this.afterChangeRefresh}
                  leagueID={leagueIdToPass}
                  sportId={sportId}
                  parent_event_id={this.props.match.params.eventId}
                  locationAll={this.state.locationAll}
                  participantsTypeId={1}
                  selectedRow={selectedRow}
                  allPlayers={teamAll}
                  teamToedit={teamToedit}
                  participantToEdit={participantToEdit}
                />
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isChangeTeamModalOpen}
              onClose={this.toggleChangeTeamModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">{"Change Team"}</h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleChangeTeamModal}
                />

                <select
                  name="newTeam"
                  onChange={(e) => this.handleSelectTeam(e)}
                  value={teamName}
                  style={{
                    fontSize: "16px",
                    borderRadius: "3px",
                    minHeight: "38px",
                    border: "1px solid #ddd",
                    paddingLeft: "10px",
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  {teamAll?.length > 0 &&
                    teamAll?.map((obj) => (
                      <option value={obj.id}>{obj.name}</option>
                    ))}
                </select>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "50px",
                    marginBottom: "320px",
                  }}
                >
                  <ButtonComponent
                    className="addButton admin-btn-green btn-submit"
                    color="primary"
                    value="Submit"
                    onClick={this.onChangeTeamSubmit}
                  />
                </div>
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={identifireModalOpen}
              onClose={this.toggleIdentifireModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <Grid container>
                  <Grid item xs={7}>
                    <h3 className="text-center">{`Bookmaker Feed Identifiers (${selectedEvent})`}</h3>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ marginTop: "15px" }}>
                      <input
                        type="text"
                        placeholder="search"
                        value={searchIdentifire}
                        onChange={(e) => {
                          this.setState({
                            ...this.state.searchIdentifire,
                            searchIdentifire: e.target.value,
                          });
                        }}
                        style={{
                          fontSize: "16px",
                          borderRadius: "3px",
                          minHeight: "30px",
                          border: "1px solid #ddd",
                          marginRight: "15px",
                          marginLeft: "15px",
                        }}
                      />
                      <Button
                        style={{
                          textTransform: "none",
                          height: "28px",
                          fontSize: "12px",
                          backgroundColor: "#ff6b00",
                          color: "#fff",
                          fontWeight: "400",
                        }}
                        onClick={this.inputFormModal(null, false)}
                      >
                        Add Identifier
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleIdentifireModal}
                />
                {isDeleteLoading === "identifireDelete" && (
                  <div className="admin-delete-modal-loader">
                    <Loader />
                  </div>
                )}
                {isLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
                {!isLoading && identifireDetails.length === 0 && (
                  <p>No Data Available</p>
                )}
                {!isLoading && identifireDetails.length > 0 && (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        className="listTable api-provider-listTable"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>DID</TableCell>
                            <TableCell>Match FeedId</TableCell>
                            <TableCell>Bookmaker</TableCell>
                            <TableCell>CreatedAt</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {identifireDetails?.map((api, i) => (
                            <TableRow key={i}>
                              <TableCell>{api.id}</TableCell>
                              <TableCell>{api.apiEventId}</TableCell>
                              <TableCell>
                                {this.getProvider(api.providerId)}
                              </TableCell>
                              <TableCell>
                                {moment(api.createdAt).format(
                                  "DD/MM/YYYY hh:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell>
                                <EditIcon
                                  onClick={this.inputFormModal(api.id, true)}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-green"
                                />
                              </TableCell>
                              <TableCell>
                                <DeleteOutlineIcon
                                  onClick={() =>
                                    this.setIdentifierToDelete(api?.id)
                                  }
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-orange"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isInputFormModalOpen}
              onClose={this.toggleInputFormModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {!isEditMode
                    ? "Create Api Event Indentier"
                    : "Edit Api Event Indentier"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputFormModal}
                />
                <CreateApiEventIdentifire
                  inputModal={this.toggleInputFormModal}
                  id={idToSend}
                  eventIdToSend={eventIdToSend}
                  isEditMode={isEditMode}
                  fetchAllEventIdentifire={this.showIdentifire}
                  isMeetings={true}
                  isMeetingsIndentier={true}
                  allEvents={allEvents}
                  allProvider={allProvider}
                  isOtherSport={true}
                  otherSportName={"Match"}
                />
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={resultModalOpen}
              onClose={this.toggleResultModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <Grid container>
                  <Grid item xs={10}>
                    <h3>{`Result (${selectedEvent})`}</h3>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      style={{
                        textTransform: "none",
                        height: "28px",
                        fontSize: "12px",
                        backgroundColor: "#ff6b00",
                        color: "#fff",
                        fontWeight: "400",
                        float: "right",
                        marginTop: "15px",
                      }}
                      disabled={resultData === null ? false : true}
                      onClick={this.resultFormModal(null, false)}
                    >
                      Add Result
                    </Button>
                  </Grid>
                </Grid>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleResultModal}
                />
                {isDeleteLoading === "resultDelete" && (
                  <div className="admin-delete-modal-loader">
                    <Loader />
                  </div>
                )}
                {isResultLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
                {!isResultLoading && resultData === null && (
                  <p className="text-center">No Data Available</p>
                )}
                {!isResultLoading && resultData !== null && (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        className="listTable api-provider-listTable"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>DID</TableCell>
                            <TableCell>EventId</TableCell>
                            <TableCell>SportId</TableCell>
                            <TableCell>Winner</TableCell>
                            <TableCell>
                              {Number(sportId) === 12 || Number(sportId) === 8
                                ? "Winner Score"
                                : "Winner Result"}
                            </TableCell>
                            <TableCell>Loser</TableCell>
                            <TableCell>
                              {Number(sportId) === 12 || Number(sportId) === 8
                                ? "Loser Score"
                                : "Loser Result"}
                            </TableCell>
                            <TableCell>description</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{resultData?.id}</TableCell>
                            <TableCell>{selectedEvent}</TableCell>
                            <TableCell>{sportName}</TableCell>
                            <TableCell>
                              {this.getResultPlayer(resultData?.winnerId)}
                            </TableCell>
                            <TableCell>
                              {Number(sportId) === 12 || Number(sportId) === 8
                                ? resultData?.winnerScore
                                : resultData?.winResult}
                            </TableCell>
                            <TableCell>
                              {this.getResultPlayer(resultData?.looserId)}
                            </TableCell>
                            <TableCell>
                              {Number(sportId) === 12 || Number(sportId) === 8
                                ? resultData?.looserScore
                                : resultData?.looseResult}
                            </TableCell>
                            <TableCell>{resultData?.description}</TableCell>
                            <TableCell>
                              <EditIcon
                                onClick={this.resultFormModal(
                                  resultData?.id,
                                  true
                                )}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                            </TableCell>
                            <TableCell>
                              <DeleteOutlineIcon
                                onClick={() =>
                                  this.setResultToDelete(resultData?.id)
                                }
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-orange"
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isResultCreateModal}
              onClose={this.toggleResultFormModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {!isEditMode ? "Create Result" : "Edit Result"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleResultFormModal}
                />
                <CreateResult
                  inputModal={this.toggleResultFormModal}
                  id={idToSend}
                  sportId={sportId}
                  eventIdToSend={eventIdToSend}
                  isEditMode={isEditMode}
                  participants={participantsToSend}
                  fetchAllResult={this.afterChangeRefreshResult}
                />
              </div>
            </Modal>

            <ShowModal
              isModalOpen={this.state.teamDeleteModal}
              onClose={this.toggleTeamDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleTeamDelete}
              onCancel={this.toggleTeamDeleteModal}
            />

            <ShowModal
              isModalOpen={identifierDeleteModal}
              onClose={this.toggleIdentifireDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleIdentifireDelete}
              onCancel={this.toggleIdentifireDeleteModal}
            />

            <ShowModal
              isModalOpen={resultDeleteModal}
              onClose={this.toggleResultDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleResultDelete}
              onCancel={this.toggleResultDeleteModal}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
export default withStyles(styles)(SportsFixturesDetails);
