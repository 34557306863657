import React, { Component, createRef } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import ActionMessage from "../../library/common/components/ActionMessage";

import { URLS } from "../../library/common/constants";
import axiosInstance from "../../helpers/Axios";
import FileUploader from "../../library/common/components/FileUploader";
import ButtonComponent from "../../library/common/components/Button";

export default class FooterLogo extends Component {
  formRef = createRef();

  state = {
    isLoading: false,
    messageBox: {
      display: false,
      type: "",
      message: "",
    },
    files: [],
    addedFiles: [],
  };

  componentDidMount() {
    this.fetchAllUrls();
  }

  async fetchAllUrls() {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(URLS.footer);
    if (status === 200) {
      this.setState({ addedFiles: data.footer, isLoading: false });
    }
  }

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  handleFileUpload = (files) => {
    this.setState({ files });
  };

  handleSave = async () => {
    try {
      const { files } = this.state;

      this.setState({ isLoading: true });
      const body = new FormData();
      body.append("image", files[0]);
      const { status } = await axiosInstance.post(URLS.footer, body, {
        header: { "Content-Type": "multipart/form-data" },
      });

      if (status === 200) {
        this.setActionMessage(true, "Success", "Image upload successful!");
        this.setState({ isLoading: false, files: [] });
      }
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { messageBox, files, addedFiles } = this.state;

    return (
      <Grid container className="page-content adminLogin">
        <Grid item xs={12}>
          <Paper className="pageWrapper">
            {messageBox.display && (
              <ActionMessage
                message={messageBox.message}
                type={messageBox.type}
                styleClass={messageBox.styleClass}
              />
            )}

            <Grid container direction="row" alignItems="space-around">
              <Grid item xs={10}>
                <h3 className="text-left">Footer Logo</h3>
              </Grid>
            </Grid>

            <Grid container className="text-left">
              <Grid xs={12} style={{ marginBottom: "30px" }}>
                <FileUploader onDrop={this.handleFileUpload} />

                <ButtonComponent
                  value="Upload"
                  className="mt-3 mb-3"
                  onClick={this.handleSave}
                  color="primary admin-btn-green"
                />
              </Grid>
              {addedFiles.map((file, index) => (
                <>
                  {file.fileName && (
                    <img
                      key={index}
                      src={URLS.uploadPath + file.fileName}
                      alt="file"
                    />
                  )}
                </>
              ))}

              {files.map((file, index) => (
                <img key={index} src={file.preview} alt="file" />
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
