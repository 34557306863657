import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { runnerFormModel } from "./form-constant";
import Form from "../../../../library/common/components/Form";
import ButtonComponent from "../../../../library/common/components/Button";
import ActionMessage from "../../../../library/common/components/ActionMessage";
// import { URLS } from "../../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../../library/utilities";
import axiosInstance from "../../../../helpers/Axios";
import { setValidation } from "../../../../helpers/common";
import DatePicker from "react-datepicker";

let runnerFormModelArray = runnerFormModel;

class EditRunnerDetail extends React.Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      values: {
        eventName: "",
        eventCard: "",
        description: "",
        fighterOrder: "",
        playerA: null,
        playerB: null,
        participantAid: null,
        participantBid: null,
        leagueId: null,
        sportId: null,
        participantsTypeId: null,
        locationId: null,
      },
      isEditMode: false,
      eventToDate: new Date(),
      cardTime: new Date(),
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentRunner();
    } else {
      this.fetchNeededData();
    }

    const { allPlayers, locationAll, allWeightClass } = this.props;

    runnerFormModelArray = runnerFormModelArray?.map((fieldItem) => {
      if (fieldItem?.field === "playerA") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...allPlayers?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "playerB") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...allPlayers?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "weightclassId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...allWeightClass?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "locationId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...locationAll?.map((tablecol, i) => {
              return { id: i, value: tablecol?.id, label: tablecol?.venueName };
            }),
          ],
        };
      }
      return fieldItem;
    });
  }

  componentWillUnmount() {
    runnerFormModelArray = runnerFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  fetchNeededData = async (id) => {
    let { leagueID, participantsTypeId, sportId } = this.props;

    let dataToShow = {
      leagueId: leagueID,
      sportId: sportId,
      participantsTypeId: participantsTypeId,
      // playerA: 256,
      // playerB: 256,
    };

    this.setState({ values: { ...this.state.values, dataToShow } });
  };

  fetchCurrentRunner = async (id) => {
    let { selectedRow, leagueID, participantsTypeId, sportId } = this.props;
    let selected_cardname =
      selectedRow?.eventOption &&
      selectedRow?.eventOption?.find((item) => item?.key === "CardName");

    let selected_fightOrder =
      selectedRow?.eventOption &&
      selectedRow?.eventOption?.find((item) => item?.key === "fighterOrder");

    let selected_cardtime =
      selectedRow?.eventOption &&
      selectedRow?.eventOption?.find((item) => item?.key === "cardTime");

    let dataToShow = {
      description: selectedRow?.description,
      eventCard:
        selected_cardname !== undefined ? selected_cardname?.value : "",
      fighterOrder:
        selected_fightOrder !== undefined
          ? Number(selected_fightOrder?.value)
          : "",
      eventName: selectedRow?.eventName,
      playerA: parseInt(selectedRow?.Participants[0].participantsId),
      playerB: parseInt(selectedRow?.Participants[1].participantsId),
      participantAid: parseInt(selectedRow?.Participants[0].id),
      participantBid: parseInt(selectedRow?.Participants[1].id),
      leagueId: leagueID,
      sportId: sportId,
      participantsTypeId: participantsTypeId,
    };

    this.setState({
      values: dataToShow,
      eventToDate: new Date(selectedRow?.eventToDate),
      cardTime:
        selected_cardtime !== undefined
          ? new Date(selected_cardtime?.value)
          : "",
      // cardTime: new Date(selectedRow?.cardTime),
    });
  };

  handleEventToDateChange = (date) => {
    let selectedDate = new Date(date);
    this.setState({ eventToDate: selectedDate });
  };

  handleCardTimeChange = (date) => {
    let selectedDate = new Date(date);
    this.setState({ cardTime: selectedDate });
  };

  validate = () => {
    let { eventCard, eventName, playerA, playerB, fighterOrder } =
      this.state.values;
    let flag = true;

    const number_only = /^[0-9\b]+$/;

    if (
      eventCard === "" ||
      eventName === "" ||
      playerA === "" ||
      playerB === "" ||
      fighterOrder === ""
    ) {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else if (!number_only.test(fighterOrder)) {
      flag = false;
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }

    return flag;
  };

  handleSave = async () => {
    const {
      isEditMode,
      leagueID,
      selectedRow,
      parent_event_id,
      participantsTypeId,
      sportId,
    } = this.props;
    this.setState({ isLoading: true });

    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "patch" : "post";
      const url = isEditMode
        ? `/events/fight/edit/${selectedRow?.id}`
        : `/events/fight/create`;

      const values = removeErrorFieldsFromValues(form.formData);

      let EditMode = {
        description: this.state.values?.description,
        fighterOrder: Number(this.state.values?.fighterOrder),
        eventName: values?.eventName,
        eventCard: values?.eventCard,
        eventToDate: this.state?.eventToDate,
        cardTime: this.state.cardTime,
        playerAId: parseInt(values?.playerA),
        playerBId: parseInt(values?.playerB),
        participantAid: parseInt(this.state.values?.participantAid),
        participantBid: parseInt(this.state.values?.participantBid),
        locationId: parseInt(values?.locationId),
        weightClassId: parseInt(values?.weightclassId),
      };

      let CreateMode = {
        eventName: values?.eventName,
        description: this.state.values?.description,
        fighterOrder: Number(this.state.values?.fighterOrder),
        eventToDate: this.state.eventToDate,
        cardTime: this.state.cardTime,
        sportId: parseInt(sportId),
        leagueId: parseInt(leagueID),
        eventCard: values?.eventCard,
        participantsTypeId: participantsTypeId,
        playerA: parseInt(values?.playerA),
        playerB: parseInt(values?.playerB),
        parent_event_id: parent_event_id,
        locationId: parseInt(values?.locationId),
        weightClassId: parseInt(values?.weightclassId),
      };
      const DataToSubmit = isEditMode ? EditMode : CreateMode;

      runnerFormModelArray = runnerFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, DataToSubmit);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllRunners();
          this.setActionMessage(
            true,
            "Success",
            `Runner ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${isEditMode ? "editing" : "creating"} Fight`
      );
    }
  };

  handleChange = (field, value) => {
    if (field === "playerA" || field === "playerB") {
      if (
        value !== this.state.values.playerA &&
        value !== this.state.values.playerB
      ) {
        let values = { ...this.state.values, [field]: value };
        this.setState({ values: values });
        runnerFormModelArray = runnerFormModelArray?.map((fieldItem) => {
          if (field === fieldItem?.field) {
            return setValidation(fieldItem, values);
          } else {
            return fieldItem;
          }
        });
        this.setActionMessage(false);
      } else {
        let values = {
          ...this.state.values,
          [field]: this.state.values[field],
        };
        this.setState({ values: values });
      }
    } else {
      let values = { ...this.state.values, [field]: value };
      this.setState({ values: values });
      runnerFormModelArray = runnerFormModelArray?.map((fieldItem) => {
        if (field === fieldItem?.field) {
          return setValidation(fieldItem, values);
        } else {
          return fieldItem;
        }
      });
      this.setActionMessage(false);
    }
  };

  render() {
    var { values, messageBox, isLoading, eventToDate, cardTime } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={runnerFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <div style={{ display: "flex" }}>
                <div className="input-field" style={{ width: "50%" }}>
                  <label>Event To Date</label>
                  <br></br>
                  <DatePicker
                    selected={eventToDate}
                    onChange={this.handleEventToDateChange}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                  />
                </div>

                <div className="input-field" style={{ width: "50%" }}>
                  <label>Card Time</label>
                  <br></br>
                  <DatePicker
                    selected={cardTime}
                    onChange={this.handleCardTimeChange}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                  />
                </div>
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default EditRunnerDetail;
