export const STORAGE_ENGINES = {
  SESSION: "session",
  LOCAL: "local",
  REDUX: "redux",
};

export const CATEGORY_MENU_STATUS = {
  ACTIVE: "active",
  DEACTIVE: "deactive",
};

export const CATEGORY_SPORT_TYPES = [
  { value: "", label: "Select" },
  { value: "racing", label: "Racing" },
  { value: "otherracing", label: "Other Racing" },
  { value: "combat", label: "Combat" },
  { value: "teamsport", label: "Team Sports" },
  { value: "other", label: "Other" },
];

export const AFURLS = {
  payups:
    "https://record.affiliates.playup.com/_Y_Ng50hdX2Zhg6WO2I1rgWNd7ZgqdRLk/1/",
  Neds: "https://record.nedsaffiliates.com.au/_dE8b100VOW_BkgpoV0de5mNd7ZgqdRLk/1/",
  Ladbrokes:
    "https://record.wageringaffiliates.com.au/_rKStVKr_NJ-itX36G815rmNd7ZgqdRLk/1/",
  Bookmaker:
    "https://record.wageringaffiliates.com.au/_aoh4_X4Dx1XlD9UB4eAeYmNd7ZgqdRLk/1/",
  BetStar:
    "https://record.wageringaffiliates.com.au/_vB8fbcQfs2keR0indqtVW2Nd7ZgqdRLk/1/",
  Bet365: "https://www.bet365.com/olp/open-account?affiliate=365_00967140",
  PalmerBet:
    "https://record.palmerbet.com/_aK97AHmof7-WLGrCaiaBQGNd7ZgqdRLk/1/",
  vicbet: "https://www.vicbet.com/",
  Winingedge: "https://winningedgeinvestments.com?af=AFC6E92E",
  unibet:
    "https://welcome.unibet.com.au/au/sportsbook/football/tournament/aff/index.html?mktid=1:320669344:61221064-36578&btag=320669344_25423A4B34DA4F90A3329A08ABEBA250&bid=36578&campaignId=2677334&pid=61221064",
  topSport: "http://www.topsport.com.au/?Referrer=smartb",
  realBookie: "https://realbookie.com.au/registration/step-1?btag=Motzaa",
};

export const SAVE_STATUS = ["Active", "Deleted", "Draft"];
export const API_SAVE_STATUS = ["active", "deleted", "draft"];
export const API_UPDATE_STATUS = ["active", "deleted", "inactive"];
export const CMS_MENU_STATUS = ["Draft", "Published", "Deleted"];
export const DATA_TYPES = ["String", "Int", "Boolean", "Date", "Double"];
export const VALUE_TYPES = ["String", "Int", "Double"];
export const PARTICIPANT_VALUE_TYPES = ["String", "Int", "Boolean"];
export const RESPONSE_TYPES = ["json", "xml"];
export const DATE_TIMEZONES = ["UTC", "GMT"];

export const PAGEMETASVALUES = {
  login: { title: "Login - Smartb" },
  register: { title: "Register - Smartb" },
  forgotpwd: { title: "Forgot Password - Smartb" },
  blogs: { title: "Blogs - Smartb", dscription: "" },
  home: { title: "Smartb" },
  aboutus: { title: "About Us - Smartb" },
  membership: { title: "Membership - Smartb" },
  bookmakers: { title: "Bookmakers - Smartb" },
  racingandsportsguid: { title: "Racing & Sports Form Guide - Smartb" },
  livesportsstreaming: { title: "Live Sports Streaming - Smartb" },
  tipingandselectionpartners: {
    title: "Tipping & Selection Partners - Smartb",
  },
  memorabilia: { title: "Memorabilia - Smartb" },
  rewardrebeteprogram: { title: "Rewards Rebate Program - Smartb" },
  poker: { title: "Poker - Smartb" },
  contactus: { title: "Contact Us - Smartb" },
  allsports: { title: "All Sports - Smartb" },
  userdetail: { title: "Your Account - Smartb" },
  faqs: { title: "FAQS - Smartb" },
  terms: { title: "Terms and Conditions - Smartb" },
  privacypolicy: { title: "Privacy Policy - Smartb" },
  responsiblegambling: { title: "Responsible Gambling Policy - Smartb" },
};
