import React from "react";
import {
  Button,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
} from "@material-ui/core";
import { MdKeyboardBackspace } from "react-icons/md";
import { Loader } from "../../library/common/components";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../images/blog_img/paginationArrowRight.svg";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import ButtonComponent from "../../library/common/components/Button";
import { ReactSVG } from "react-svg";
import { URLS } from "../../library/common/constants";
import axiosInstance from "../../helpers/Axios";
import moment from "moment";
// import RateReviewIcon from "@material-ui/icons/RateReview";
// import { getFormetedDate } from "../../helpers/common";
import EditIcon from "@material-ui/icons/Edit";
import "./sportDetails.scss";
import { raceIcon } from "../../helpers/common";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// import CreateEvents from "../events/CreateEvents/index";
import CreateRaceTable from "../raceTable/CreateRaceTable";
import CreateApiRaceIdentifire from "../apiRaceIdentifire/CreateApiRaceIdentifire";
import ShowModal from "../../components/common/ShowModal/ShowModal";
import _ from "lodash";

class SportsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
      selectedToDate: new Date(),
      sportsDetails: [],
      identifireDetails: [],
      isLoading: false,
      searchInput: "",
      currentPage: 1,
      rowPerPage: 20,
      offset: 0,
      sportCount: null,
      inputModalOpen: false,
      identifireModalOpen: false,
      isEditMode: false,
      isRaceEdit: false,
      idToSend: "",
      raceIdToSend: null,
      sportAll: [],
      // locationAll: [],
      weatherAll: [],
      allRaces: [],
      allProvider: [],
      events: [],
      distanceAll: [],
      selectedEvent: "",
      searchIdentifire: "",
      itemToDelete: null,
      isDeleteRaceModalOpen: false,
      isDeleteIdentifireModalOpen: false,
      isDeleteLoading: "",
    };
  }

  componentDidMount() {
    // this.onSubmit();
    // this.fetchAllEvents();
    this.fetchAllRacesByEvent();
    this.fetchAllSport();
    // this.fetchAllLocation();
    this.fetchAllWeather();
    this.fetchAllRaces();
    this.fetchAllProvider();
    this.fetchAllDistance();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match !== this.props.match) {
      // this.onSubmit();
      this.fetchAllRacesByEvent();
      this.setState({ searchInput: "" });
    }
    if (prevState.offset !== this.state.offset) {
      // this.onSubmit();
    }
  }

  fetchAllRacesByEvent = async () => {
    let { eventid } = this.props.match.params;
    this.setState({ isLoading: true });
    try {
      const { status, data } = await axiosInstance.get(
        `/events/race/${eventid}&type=admin`
      );
      if (status === 200) {
        this.setState({
          sportsDetails: data.result,
          isLoading: false,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isLoading: false });
    }
  };

  // async fetchAllEvents() {
  //   this.setState({ isLoading: true });
  //   const { status, data } = await axiosInstance.get(URLS.events);
  //   if (status === 200) {
  //     this.setState({ events: data.result, isLoading: false });
  //   }
  // }

  fetchAllDistance = async () => {
    const { status, data } = await axiosInstance.get(URLS.distance);
    if (status === 200) {
      this.setState({ distanceAll: data.result });
    }
  };

  fetchAllSport = async () => {
    const { status, data } = await axiosInstance.get(URLS.sports);
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["sportName"], ["asc"]);
      this.setState({ sportAll: data_obj });
    }
  };

  // fetchAllLocation = async (id) => {
  //   const { status, data } = await axiosInstance.get(URLS.location);
  //   if (status === 200) {
  //     this.setState({ locationAll: data.result });
  //   }
  // };

  fetchAllWeather = async (id) => {
    const { status, data } = await axiosInstance.get(URLS.weather);
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["weatherType"], ["asc"]);
      this.setState({ weatherAll: data_obj });
    }
  };

  async fetchAllRaces() {
    const { status, data } = await axiosInstance.get(URLS.races);
    if (status === 200) {
      this.setState({ allRaces: data.result });
    }
  }

  async fetchAllProvider() {
    const { status, data } = await axiosInstance.get(URLS.apiProvider);
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["providerName"], ["asc"]);
      this.setState({ allProvider: data_obj });
    }
  }

  getProvider = (id) => {
    let { allProvider } = this.state;
    let provider = allProvider
      .filter((obj) => obj.id === id)
      .map((object) => object.providerName);
    return provider;
  };

  toggleIdentifireModal = () => {
    this.setState({
      identifireModalOpen: !this.state.identifireModalOpen,
      searchIdentifire: "",
    });
  };

  onSubmit = async () => {
    let id = this.props.match.params.id;
    this.setState({ isLoading: true });
    let { selectedToDate, selectedDate, offset, rowPerPage, searchInput } =
      this.state;
    let params = {
      sportId: id,
      date: moment(selectedToDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      endDate: moment(selectedDate)
        .utc()
        .add(1, "days")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      //date: "2021-04-09T00:00:00.000Z",
      //endDate: "2021-04-10T00:00:00.000Z",
    };
    try {
      const { status, data } = await axiosInstance.post(
        `private/races?limit=${rowPerPage}&offset=${offset}&matchString=${searchInput}`,
        params
      );
      if (status === 200) {
        let respose =
          data.respose?.length > 0
            ? data.respose.filter((obj) => obj.event.sportId === id)
            : [];
        this.setState({
          sportsDetails: respose,
          isLoading: false,
          sportCount: data.count,
        });
      }
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  toggleInputModal = () => {
    this.setState({ inputModalOpen: false });
  };

  inputModal = (id, type) => {
    this.setState({ inputModalOpen: true, idToSend: id, isRaceEdit: type });
  };

  afterChangeRefresh = async () => {
    // let { identifireModalOpen, raceIdToSend } = this.state;

    // this.onSubmit()
    this.fetchAllRacesByEvent();
  };

  handleAnimalDelete = async (id) => {
    this.setState({
      isDeleteLoading: "raceDelete",
      isDeleteRaceModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(`races/race/${id}`);
      if (status === 200) {
        this.afterChangeRefresh();
        this.setState({ isDeleteLoading: "", itemToDelete: null });
      }
    } catch (err) {}
  };

  setRaceToDelete = (id) => {
    this.setState({ itemToDelete: id, isDeleteRaceModalOpen: true });
  };

  toggleRaceDeleteModal = () => {
    this.setState({
      isDeleteRaceModalOpen: !this.state.isDeleteRaceModalOpen,
      itemToDelete: null,
    });
  };

  setIdentifireToDelete = (id) => {
    this.setState({ itemToDelete: id, isDeleteIdentifireModalOpen: true });
  };

  toggleIdentifireDeleteModal = () => {
    this.setState({
      isDeleteIdentifireModalOpen: !this.state.isDeleteIdentifireModalOpen,
      itemToDelete: null,
    });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  handleToDateChange = (date) => {
    this.setState({ selectedToDate: date });
  };

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, rowPerPage, sportsDetails } = this.state;
    if (navDirection === "prev") {
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    } else {
      if (currentPage < sportsDetails.length / rowPerPage)
        this.setState({ currentPage: currentPage + 1 });
    }
  };

  navigateUrl = (id, raceName) => {
    let { params } = this.props.match;
    this.props.history.push(
      `/admin/${params?.name}/${params?.id}/${params?.eventname}/${params?.eventid}/runners/${id}/${raceName}`
    );
  };

  showIdentifire = async (id) => {
    let { raceIdToSend } = this.state;
    this.setState({
      identifireModalOpen: true,
      isLoading: true,
    });
    let getId = raceIdToSend === null ? id : raceIdToSend;
    try {
      const { status, data } = await axiosInstance.get(
        `race/identifier/getByRaceId/${getId}`
      );
      if (status === 200) {
        this.setState({
          identifireDetails: data.result,
          isLoading: false,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isLoading: false });
    }
  };

  inputFormModal = (id, type) => () => {
    this.setState({
      isInputFormModalOpen: true,
      idToSend: id,
      isEditMode: type,
      searchIdentifire: "",
    });
  };

  toggleInputFormModal = () => {
    this.setState({ isInputFormModalOpen: !this.state.isInputFormModalOpen });
  };

  handleIdentifireDelete = async (id) => {
    this.setState({
      isDeleteLoading: "identifireDelete",
      isDeleteIdentifireModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(`race/identifier/${id}`);
      if (status === 200) {
        this.showIdentifire();
        this.setState({ isDeleteLoading: "", itemToDelete: null });
      }
    } catch (err) {}
  };

  backToNavigatePage = () => {
    const { match } = this.props;
    this.props.history.push(
      `/admin/racing/meetings/${match?.params?.name}/${match?.params?.id}`
    );
  };

  render() {
    const { match } = this.props;
    let {
      // selectedDate,
      sportsDetails,
      identifireDetails,
      isLoading,
      rowPerPage,
      currentPage,
      searchInput,
      // selectedToDate,
      sportAll,
      // locationAll,
      distanceAll,
      events,
      weatherAll,
      allProvider,
      allRaces,
      identifireModalOpen,
      isInputFormModalOpen,
      raceIdToSend,
      idToSend,
      isEditMode,
      isRaceEdit,
      // offset,
      sportCount,
      selectedEvent,
      searchIdentifire,
      isDeleteRaceModalOpen,
      isDeleteIdentifireModalOpen,
      isDeleteLoading,
    } = this.state;
    const pageNumbers = [];

    searchInput !== "" &&
      (sportsDetails = sportsDetails?.filter((obj) =>
        obj?.raceName
          ?.toString()
          .toLowerCase()
          .includes(searchInput.toString().toLowerCase())
      ));

    searchIdentifire !== "" &&
      (identifireDetails = identifireDetails?.filter(
        (obj) =>
          obj?.apiRaceId
            ?.toString()
            .toLowerCase()
            .includes(searchIdentifire.toString().toLowerCase()) ||
          obj?.id
            ?.toString()
            .toLowerCase()
            .includes(searchIdentifire.toString().toLowerCase())
      ));

    let currentPageRow = sportsDetails;

    if (sportCount > 0) {
      const indexOfLastTodo = currentPage * rowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      currentPageRow = sportsDetails.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(sportCount / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <>
        <Grid container className="page-content adminLogin sports_details">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              {isDeleteLoading === "raceDelete" && (
                <div class="admin-delete-loader">
                  <Loader />
                </div>
              )}
              <Grid
                container
                direction="row"
                alignItems="space-around"
                style={{ alignItems: "center", marginBottom: "10px" }}
              >
                <Grid item xs={9}>
                  <Button
                    className="admin-btn-back"
                    onClick={this.backToNavigatePage}
                  >
                    <MdKeyboardBackspace />
                  </Button>
                  <h3
                    className="text-left admin-page-heading"
                    style={{ margin: "5px 0px 0px 5px" }}
                  >
                    {match.params.name} Details{" "}
                    {`( ${match.params.eventname} )`}
                  </h3>
                </Grid>
                <Grid item xs={2}>
                  <input
                    type="text"
                    className=""
                    placeholder="search"
                    value={searchInput}
                    onChange={(e) =>
                      this.setState({
                        ...this.state.searchInput,
                        searchInput: e.target.value,
                      })
                    }
                    style={{
                      fontSize: "16px",
                      borderRadius: "3px",
                      minHeight: "40px",
                      border: "1px solid #ddd",
                      paddingLeft: "10px",
                      marginLeft: "-100px",
                      marginTop: "4px",
                    }}
                  />
                </Grid>
                {/* <Grid item xs={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify='space-around'>
                      <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='MM/dd/yyyy'
                        margin='normal'
                        id='date-picker-inline'
                        value={selectedToDate}
                        onChange={this.handleToDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        style={{ marginRight: 5 }}
                        className='details-search-picker'
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify='space-around'>
                      <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='MM/dd/yyyy'
                        margin='normal'
                        id='date-picker-inline'
                        value={selectedDate}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        style={{ marginLeft: 5 }}
                        className='details-search-picker'
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>*/}
                {/* <Grid item xs={1}>
                  <ButtonComponent
                    className="addButton admin-btn-green btn-submit"
                    color="primary"
                    value="Submit"
                    onClick={() => this.onSubmit()}
                  />
                </Grid> */}
                <Grid item xs={1}>
                  <Button
                    style={{
                      textTransform: "none",
                      height: "36px",
                      marginTop: "10px",
                      fontSize: "14px",
                      backgroundColor: "#ff6b00",
                      color: "#fff",
                      fontWeight: "400",
                    }}
                    onClick={() => this.inputModal(null, false)}
                  >
                    Add Race
                  </Button>
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && sportsDetails.length === 0 && (
                <p>No Data Available</p>
              )}
              {!isLoading && sportsDetails.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      className="listTable api-provider-listTable"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>DID</TableCell>
                          <TableCell>Race Name</TableCell>
                          <TableCell>Race Number</TableCell>
                          <TableCell>Distance</TableCell>
                          <TableCell>Track</TableCell>
                          <TableCell>Weather</TableCell>
                          <TableCell>Race Date</TableCell>
                          <TableCell>View/Add Identifier</TableCell>
                          <TableCell align="center">Edit Race</TableCell>
                          <TableCell align="center">Delete Race</TableCell>
                          <TableCell>
                            {/* {match?.params?.name == 'Greyhound Racing'
                              ? 'Greyhounds Details'
                              : 'Horses Details'} */}
                            Runner Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentPageRow?.map((sportsDetails, i) => (
                          <TableRow key={i}>
                            <TableCell>{sportsDetails?.id}</TableCell>
                            <TableCell>{sportsDetails?.raceName}</TableCell>
                            <TableCell>{sportsDetails?.raceNumber}</TableCell>
                            <TableCell>
                              {sportsDetails?.distance?.name}
                            </TableCell>
                            <TableCell>
                              {sportsDetails?.trackId?.name}
                            </TableCell>
                            <TableCell>
                              {sportsDetails?.weather?.weatherType}
                            </TableCell>
                            <TableCell>
                              {moment(sportsDetails.startTimeDate).format(
                                "DD/MM/YYYY hh:mm:ss a"
                              )}
                            </TableCell>
                            <TableCell>
                              <Button
                                style={{
                                  fontSize: "9px",
                                  backgroundColor: "#ff6b00",
                                  color: "#fff",
                                  fontWeight: "400",
                                  textTransform: "none",
                                  padding: "5px 5px",
                                }}
                                onClick={() => {
                                  this.setState(
                                    {
                                      selectedEvent: sportsDetails?.raceName,
                                      raceIdToSend: sportsDetails?.id,
                                    },
                                    () => this.showIdentifire(sportsDetails?.id)
                                  );
                                }}
                              >
                                View/Add Identifier
                              </Button>
                            </TableCell>
                            <TableCell align="right">
                              <EditIcon
                                onClick={() => {
                                  this.inputModal(sportsDetails?.id, true);
                                }}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                            </TableCell>
                            <TableCell>
                              <DeleteOutlineIcon
                                onClick={() => {
                                  this.setRaceToDelete(sportsDetails.id);
                                }}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-orange"
                              />
                            </TableCell>
                            <TableCell className="runner_btn_cell">
                              <ReactSVG
                                src={raceIcon(match.params.name)}
                                onClick={() =>
                                  this.navigateUrl(
                                    sportsDetails?.id,
                                    sportsDetails?.raceName
                                  )
                                }
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="tablePagination">
                    <button
                      className={
                        sportsDetails.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={
                        sportsDetails.length / rowPerPage > 1 ? false : true
                      }
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={
                        sportsDetails.length / rowPerPage > 1 ? false : true
                      }
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size="small"
                    />
                    <button
                      className={
                        sportsDetails.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={
                        sportsDetails.length / rowPerPage > 1 ? false : true
                      }
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </>
              )}
            </Paper>

            <Modal
              className="modal modal-input"
              open={this.state.inputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {isRaceEdit
                    ? `Edit ${match.params.name} Details`
                    : `Add ${match.params.name} Details`}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />
                {/* <CreateEvents
                  inputModal={this.toggleInputModal}
                  id={this.state.idToSend}
                  isEditMode={true}
                  fetchAllEvents={this.afterChangeRefresh}
                  sportAll={sportAll}
                  locationAll={locationAll}
                  weatherAll={weatherAll}
                /> */}

                <CreateRaceTable
                  inputModal={this.toggleInputModal}
                  id={idToSend}
                  isEditMode={isRaceEdit}
                  fetchAllRace={this.afterChangeRefresh}
                  eventsAll={events}
                  distanceAll={distanceAll}
                  weatherAll={weatherAll}
                  allSports={sportAll}
                  sportId={this.props.match.params.id}
                  eventID={this.props.match.params.eventid}
                />
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={identifireModalOpen}
              onClose={this.toggleIdentifireModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <Grid container>
                  <Grid item xs={7}>
                    <h3 className="text-center">{`Bookmaker Feed Identifiers (${selectedEvent})`}</h3>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ marginTop: "15px" }}>
                      <input
                        type="text"
                        placeholder="search"
                        value={searchIdentifire}
                        onChange={(e) => {
                          this.setState({
                            ...this.state.searchIdentifire,
                            searchIdentifire: e.target.value,
                          });
                        }}
                        style={{
                          fontSize: "16px",
                          borderRadius: "3px",
                          minHeight: "30px",
                          border: "1px solid #ddd",
                          marginRight: "15px",
                        }}
                      />
                      <Button
                        style={{
                          textTransform: "none",
                          height: "28px",
                          fontSize: "12px",
                          backgroundColor: "#ff6b00",
                          color: "#fff",
                          fontWeight: "400",
                        }}
                        onClick={this.inputFormModal(null, false)}
                      >
                        Add Identifier
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleIdentifireModal}
                />
                {isDeleteLoading === "identifireDelete" && (
                  <div class="admin-delete-modal-loader">
                    <Loader />
                  </div>
                )}
                {isLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
                {!isLoading && identifireDetails.length === 0 && (
                  <p>No Data Available</p>
                )}
                {!isLoading && identifireDetails.length > 0 && (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        className="listTable api-provider-listTable"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>DID</TableCell>
                            <TableCell>Race FeedId</TableCell>
                            <TableCell>Bookmaker</TableCell>
                            <TableCell>CreatedAt</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {identifireDetails?.map((api, i) => (
                            <TableRow key={i}>
                              <TableCell>{api.id}</TableCell>
                              <TableCell>{api.apiRaceId}</TableCell>
                              <TableCell>
                                {this.getProvider(api.providerId)}
                              </TableCell>
                              <TableCell>
                                {moment(api.createdAt).format(
                                  "DD/MM/YYYY hh:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell>
                                <EditIcon
                                  onClick={this.inputFormModal(api.id, true)}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-green"
                                />
                              </TableCell>
                              <TableCell>
                                <DeleteOutlineIcon
                                  onClick={() =>
                                    this.setIdentifireToDelete(api?.id)
                                  }
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-orange"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isInputFormModalOpen}
              onClose={this.toggleInputFormModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {!isEditMode
                    ? "Create Bookmaker And Feed Identifier"
                    : "Edit Bookmaker And Feed Identifier"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputFormModal}
                />
                <CreateApiRaceIdentifire
                  inputModal={this.toggleInputFormModal}
                  id={idToSend}
                  raceIdToSend={raceIdToSend}
                  isEditMode={isEditMode}
                  fetchAllRaceIdentifire={this.showIdentifire}
                  isFixture={true}
                  allRaces={allRaces}
                  allApiProvider={allProvider}
                />
              </div>
            </Modal>

            <ShowModal
              isModalOpen={isDeleteRaceModalOpen}
              onClose={this.toggleRaceDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleAnimalDelete}
              onCancel={this.toggleRaceDeleteModal}
            />

            <ShowModal
              isModalOpen={isDeleteIdentifireModalOpen}
              onClose={this.toggleIdentifireDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleIdentifireDelete}
              onCancel={this.toggleIdentifireDeleteModal}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
export default SportsDetails;
