import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Modal,
  Typography,
} from "@material-ui/core";
import { MdKeyboardBackspace } from "react-icons/md";
import { Loader } from "../../../library/common/components";
import Pagination from "@material-ui/lab/Pagination";
import AddIcon from "@material-ui/icons/Add";
import arrowLeft from "../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";
import ButtonComponent from "../../../library/common/components/Button";
// import { URLS } from '../../../library/common/constants'
import axiosInstance from "../../../helpers/Axios";
import moment from "moment";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditRunnerDetail from "./editRunnerDetail";
import EditMarketdetails from "../sportsMarket/EditMarketDetails";

class RunnersDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      runnersDetails: [],
      RacingOddsDetails: [],
      isInputModalOpen: false,
      idToSend: null,
      isLoading: false,
      isEditMode: true,
      currentPage: 1,
      rowPerPage: 20,
      RacingOddsModal: false,
      oddsInputModalOpen: false,
      marketKey: [],
      bookKeeper: [],
      editMode: false,
      oddsIdtoSend: null,
      oddIdToget: null,
      marketId: null,
    };
  }

  componentDidMount() {
    this.fetchAllRunners();
    this.getMarketKey();
    this.getBookKeeper();
    // this.fetchAllAnimal();
    // this.fetchAllPlayers();
    // console.log(window.history.previous.href);
  }

  fetchAllRunners = async () => {
    let id = this.props.match.params.id;
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(
      `/events/runnerwithodds/${id}`
    );
    if (status === 200) {
      this.setState({ runnersDetails: data.result, isLoading: false });
    }
  };

  getMarketKey = async () => {
    try {
      const { status, data } = await axiosInstance.get(`sports/marketValueKey`);
      if (status === 200) {
        this.setState({
          marketKey: data.result,
          isLoading: false,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isLoading: false });
    }
  };

  getBookKeeper = async () => {
    const {
      //  status,
      data,
    } = await axiosInstance.get(`apiProviders/bookKeeper`);
    this.setState({ bookKeeper: data.result });
  };

  // fetchAllAnimal = async () => {
  //   const { status, data } = await axiosInstance.get("animal");
  //   if (status === 200) {
  //     this.setState({ animalDetails: data.result });
  //   }
  // };

  // fetchAllPlayers = async () => {
  //   const { status, data } = await axiosInstance.get("teams/player");
  //   if (status === 200) {
  //     this.setState({ playersDetails: data.result });
  //   }
  // };

  inputModal = (id, type) => () => {
    this.setState({
      isInputModalOpen: true,
      idToSend: id,
      isEditMode: type === "edit" ? true : false,
    });
  };

  inputOddModal = (id) => {
    this.setState({
      oddsIdtoSend: id,
      oddsInputModalOpen: !this.state.oddsInputModalOpen,
    });
  };

  toggleInputModal = () => {
    this.setState({
      isInputModalOpen: !this.state.isInputModalOpen,
    });
  };

  toggleOddsInputModal = () => {
    this.setState({
      oddsInputModalOpen: !this.state.oddsInputModalOpen,
    });
  };

  toggleOddsModal = () => {
    this.setState({ RacingOddsModal: !this.state.RacingOddsModal });
  };

  afterChangeRefresh = () => {
    this.fetchAllRunners();
  };

  backToNavigatePage = () => {
    // const { sportname } = this.props.match.params;
    this.props.history.push(`/admin/racing/markets`);
  };

  //   componentDidUpdate(prevProps) {
  //     if (prevProps.match !== this.props.match) {
  //       this.fetchAllSports();
  //     }
  //   }

  //   onSubmit = async () => {
  //     let { sportId, selectedDate } = this.state;
  //     let params = {
  //       sportId: sportId,
  //       //   date: moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
  //       //   endDate: moment(selectedDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
  //       date: "2021-04-09T00:00:00.000Z",
  //       endDate: "2021-04-10T00:00:00.000Z",
  //     };
  //     const { status, data } = await axiosInstance.post("private/races", params);
  //     if (status === 200) {
  //       this.setState({ runnersDetails: data.respose });
  //     }
  //   };

  //   getSportId = () => {
  //     let { sports } = this.state;
  //     let sportName = this.props.match.params.name;
  //     let obj = sports
  //       .filter((obj) => obj.sportName == sportName)
  //       .map((object) => object.id);
  //     return obj;
  //   };

  //   handleDateChange = (date) => {
  //     this.setState({ selectedDate: date });
  //   };

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, rowPerPage, runnersDetails } = this.state;
    if (navDirection === "prev") {
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    } else {
      if (currentPage < runnersDetails.length / rowPerPage)
        this.setState({ currentPage: currentPage + 1 });
    }
  };

  HandleDeleteOddsDetails = async (id) => {
    try {
      const { status } = await axiosInstance.delete(`sports/marketValue/${id}`);
      if (status === 200) {
        this.showOddsScreen();
      }
    } catch (err) {}
  };

  handleRunnerDelete = async (id) => {
    try {
      const { status } = await axiosInstance.delete(`/race/participant/${id}`);
      if (status === 200) {
        this.afterChangeRefresh();
      }
    } catch (err) {
      // console.log(err);
    }
  };

  showOddsScreen = async (id) => {
    this.setState({
      isLoading: true,
    });
    let getodID = id === undefined ? this.state.oddIdToget : id;

    try {
      const { status, data } = await axiosInstance.get(
        `/events/odd/${getodID}`
      );
      if (status === 200) {
        this.setState({
          RacingOddsDetails: data.result[0]?.odd,
          isLoading: false,
          marketId: data.result[0]?.odd[0]?.marketId,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { match } = this.props;
    let {
      runnersDetails,
      isLoading,
      rowPerPage,
      currentPage,
      isInputModalOpen,
      isEditMode,
      RacingOddsModal,
      RacingOddsDetails,
      oddsInputModalOpen,
      oddsIdtoSend,
      marketKey,
      bookKeeper,
      editMode,
      marketId,
    } = this.state;
    const pageNumbers = [];
    let currentPageRow = runnersDetails;

    if (runnersDetails?.length > 0) {
      const indexOfLastTodo = currentPage * rowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      currentPageRow = runnersDetails.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(runnersDetails.length / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={10}>
                  <Button
                    className="admin-btn-back"
                    onClick={this.backToNavigatePage}
                  >
                    <MdKeyboardBackspace />
                  </Button>
                  <h3 className="text-left admin-page-heading">
                    Runners Details {`( ${match.params.raceName} )`}
                  </h3>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent
                    className="mt-3 admin-btn-green"
                    onClick={this.inputModal(null, "add")}
                    color="primary"
                    value={"Add Runner"}
                  />
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && runnersDetails.length === 0 && (
                <p>No Data Available</p>
              )}
              {!isLoading && runnersDetails.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      className="listTable api-provider-listTable"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>DID</TableCell>
                          <TableCell>Animal Name</TableCell>
                          <TableCell>Weight</TableCell>
                          <TableCell>Jockey / Driver / Trainer</TableCell>
                          <TableCell>Barrier Number</TableCell>
                          <TableCell>Runner Number</TableCell>
                          <TableCell>Edit</TableCell>
                          <TableCell>Delete</TableCell>
                          <TableCell>View Odds</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentPageRow?.map((runnersDetails, i) => (
                          <TableRow key={i}>
                            <TableCell>{runnersDetails.id}</TableCell>
                            <TableCell>{runnersDetails.animal.name}</TableCell>
                            <TableCell>{runnersDetails.JockeyWeight}</TableCell>
                            <TableCell>
                              {runnersDetails.players.length > 0
                                ? runnersDetails.players[0].name
                                : ""}
                            </TableCell>
                            <TableCell>
                              {runnersDetails.barrierNumber}
                            </TableCell>
                            <TableCell>{runnersDetails.runnerNumber}</TableCell>
                            <TableCell>
                              <EditIcon
                                onClick={this.inputModal(
                                  runnersDetails.id,
                                  "edit"
                                )}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                            </TableCell>
                            <TableCell>
                              <DeleteOutlineIcon
                                onClick={() =>
                                  this.handleRunnerDelete(runnersDetails?.id)
                                }
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-orange"
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                style={{
                                  fontSize: "9px",
                                  backgroundColor: "#ff6b00",
                                  color: "#fff",
                                  fontWeight: "400",
                                  textTransform: "none",
                                  padding: "5px 0",
                                }}
                                onClick={() => {
                                  this.showOddsScreen(
                                    runnersDetails.particiantId
                                  );
                                  this.setState({
                                    RacingOddsModal: true,
                                    oddIdToget: runnersDetails.particiantId,
                                  });
                                }}
                              >
                                View Odds
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="tablePagination">
                    <button
                      className={
                        runnersDetails.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={
                        runnersDetails.length / rowPerPage > 1 ? false : true
                      }
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={
                        runnersDetails.length / rowPerPage > 1 ? false : true
                      }
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size="small"
                    />
                    <button
                      className={
                        runnersDetails.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={
                        runnersDetails.length / rowPerPage > 1 ? false : true
                      }
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </>
              )}
            </Paper>
            <Modal
              className="modal modal-input"
              open={isInputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {isEditMode ? "Edit Runners" : "Add Runners"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />
                <EditRunnerDetail
                  inputModal={this.toggleInputModal}
                  id={this.state.idToSend}
                  isEditMode={isEditMode}
                  fetchAllRunners={this.afterChangeRefresh}
                  raceId={match.params.id}
                  // allAnimal={this.state.animalDetails}
                  // allPlayers={this.state.playersDetails}
                />
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={RacingOddsModal}
              onClose={this.toggleOddsModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="h4"
                    style={{ marginTop: 0 }}
                  >{`Odds Details`}</Typography>
                  &nbsp;&nbsp;&nbsp;
                  <AddIcon
                    onClick={() => {
                      this.inputOddModal();
                      this.setState({ editMode: false });
                    }}
                    color="primary"
                    style={{ borderRadius: "50%", padding: 5, fontSize: 19 }}
                    className="mr10 cursor iconBtn admin-btn-green"
                  />
                </div>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleOddsModal}
                />
                {isLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
                {!isLoading && RacingOddsDetails.length === 0 && (
                  <p>No Data Available</p>
                )}
                {!isLoading && RacingOddsDetails.length > 0 && (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        className="listTable api-provider-listTable"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>DID</TableCell>
                            <TableCell>Market Value</TableCell>
                            <TableCell>BookKeeper</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>CreatedAt</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {RacingOddsDetails?.map((odd, i) => (
                            <TableRow key={i}>
                              <TableCell>{odd?.id}</TableCell>
                              <TableCell>{odd?.MarketValueKey?.key}</TableCell>
                              <TableCell>{odd?.bookKeeper?.name}</TableCell>
                              <TableCell>{odd.value}</TableCell>
                              <TableCell>
                                {moment(odd.createdAt).format(
                                  "DD/MM/YYYY hh:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell>
                                <EditIcon
                                  onClick={() => {
                                    this.inputOddModal(odd?.id);
                                    // setSelectedRow(row)
                                    // setEditMode(true)
                                    this.setState({ editMode: true });
                                  }}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-green"
                                />
                              </TableCell>
                              <TableCell>
                                <DeleteOutlineIcon
                                  onClick={() => {
                                    this.HandleDeleteOddsDetails(odd?.id);
                                  }}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-orange"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={oddsInputModalOpen}
              onClose={this.toggleOddsInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {editMode ? "Edit Market Value" : "Add Market Value"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleOddsInputModal}
                />
                <EditMarketdetails
                  inputModal={this.toggleOddsInputModal}
                  id={oddsIdtoSend}
                  isEditMode={editMode}
                  fetchAllEvents={this.showOddsScreen}
                  MarketKey={marketKey}
                  bookKeeper={bookKeeper}
                  // selectedRow={selectedRow}
                  marketId={marketId}
                />
              </div>
            </Modal>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default RunnersDetails;
