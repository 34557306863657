import { config } from "../../../helpers/config";

export default function SportsReducer(
  state = {
    selected_sport_cat_id:
      config.baseUrl === "https://local.smartb.com.au/api" ? 10 : 15,
    selected_sport:
      config.baseUrl === "https://local.smartb.com.au/api" ? 10 : 15,
  },
  action
) {
  switch (action.type) {
    case "SELECTED_SPORT":
      return Object.assign({}, state, { selected_sport: action.payload });

    case "SELECTED_SPORT_CAT_ID":
      return Object.assign({}, state, {
        selected_sport_cat_id: action.payload,
      });

    default:
      return state;
  }
}
