import React, { Component } from "react";
import CKEditor from "react-ckeditor-component";

class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: this.props.updatedBookingMessageList,
    };
  }

  componentDidMount() {
    const { content } = this.state;
    this.props.updateContent(content);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.updatedBookingMessageList !==
      prevProps.updatedBookingMessageList
    ) {
      this.setState({ content: this.props.updatedBookingMessageList });
    }
  }

  onChange = (evt) => {
    const newContent = evt.editor.getData();
    this.setState({
      content: newContent,
    });
    const field = this.props.field && this.props.field;
    this.props.updateContent(newContent, field, false);
  };

  editSource = (mode) => {
    const editor = mode.editor;
    if (editor.mode === "source") {
      const editable = editor._.editable;
      editable.attachListener(editable, "input", (e) => {
        this.onChange(mode);
      });
    }
  };

  render() {
    const { content } = this.state;
    return (
      <CKEditor
        type="classic"
        activeClass="p10"
        content={content}
        events={{
          change: this.onChange,
          mode: this.editSource,
        }}
        config={{
          allowedContent: true,
        }}
      />
    );
  }
}

export default TextEditor;
