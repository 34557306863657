import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Grid,
  Paper,
  Checkbox,
  FormControl,
  Breadcrumbs,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableHead,
  TableRow,
  TableContainer,
  Table,
  TableCell,
} from "@material-ui/core";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import axiosInstance from "../../helpers/Axios";
import ButtonComponent from "../../library/common/components/Button";
import Horse from "../../images/dark_horse.svg";
import Greys from "../../images/dar-greyhound.svg";
import Harnes from "../../images/dark_harness.svg";
import TabHorses from "../../images/sport_icons/tab_horse.svg";
import TabGreyhounds from "../../images/sport_icons/tab_greyhounds.svg";
import TabHarness from "../../images/sport_icons/tab_harness.svg";
import { ReactComponent as Unchecked } from "../../images/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../images/smartb-check.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Dashboard.scss";
import DashboardCollapase from "./DashboardCollapase";
const day = [
  {
    id: 0,
    name: "Yesterday",
    date: moment().utc().subtract(1, "days").format("YYYY-MM-DD"),
    dayName: moment().utc().subtract(1, "days").format("dddd"),
  },
  {
    id: 1,
    name: "Today",
    date: moment().utc().format("YYYY-MM-DD"),
    dayName: moment().utc().format("dddd"),
  },
  {
    id: 2,
    name: "Tomorrow",
    date: moment().utc().add(1, "days").format("YYYY-MM-DD"),
    dayName: moment().utc().add(1, "days").format("dddd"),
  },
  {
    id: 3,
    name: "Wednesday",
    date: moment().utc().add(2, "days").format("YYYY-MM-DD"),
    dayName: moment().utc().add(2, "days").format("dddd"),
  },
  {
    id: 4,
    name: "Thursday",
    date: moment().utc().add(3, "days").format("YYYY-MM-DD"),
    dayName: moment().utc().add(3, "days").format("dddd"),
  },
  {
    id: 5,
    name: "Friday",
    date: moment().utc().add(4, "days").format("YYYY-MM-DD"),
    dayName: moment().utc().add(4, "days").format("dddd"),
  },
  {
    id: 6,
    name: "Saturday",
    date: moment().utc().add(5, "days").format("YYYY-MM-DD"),
    dayName: moment().utc().add(5, "days").format("dddd"),
  },
  {
    id: 7,
    dayName: "Futures",
    date: "",
  },
  {
    id: 8,
    dayName: "Archive",
    date: "",
  },
];

const Dashboard = () => {
  const [filterRaceType, setfilterRaceType] = useState([
    {
      id: 1,
      Racevalue: "Horse Racing",
      isRaceChecked: true,
      icon: (
        <span>
          <img src={Horse} />
        </span>
      ),
    },
    {
      id: 3,
      Racevalue: "Greyhound Racing",
      isRaceChecked: true,
      icon: (
        <span>
          <img src={Greys} />
        </span>
      ),
    },
    {
      id: 2,
      Racevalue: "Harness Racing",
      isRaceChecked: true,
      icon: (
        <span>
          <img src={Harnes} />
        </span>
      ),
    },
  ]);
  const [filterCountry, setfilterCountry] = useState([
    {
      id: 1,
      name: "Aus/NZ",
      value: "Aus/NZ",
      isChecked: true,
    },
    {
      id: 2,
      name: "Int'l",
      value: "Intl",
      isChecked: true,
    },
  ]);
  const navigate = useHistory();
  const [value, setValue] = useState(1);
  const [selectedRaceType, setselectedRaceType] = useState([1, 2, 3]);
  const [selectedCountryType, setselectedCountryType] = useState([
    "Aus/NZ",
    "Intl",
  ]);
  const [selectedDate, setselectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [isRefetch, setIsRefetch] = useState(false);

  // Change Day Tab
  const handleChange = (event, value) => {
    if (value == "7" || value == "8") {
      navigate("/");
    } else {
      setValue(value);
      let SelectDate = day.filter((item) => {
        return item.id === value;
      });
      setselectedDate(
        SelectDate?.map((item) => {
          return item?.date;
        })
      );
    }
  };

  // Change Racing Filter

  const ChangeRaceFilter = (event, item) => {
    const flag = event.target.checked;

    if (flag) {
      const newItem = [...selectedRaceType, item];
      setselectedRaceType(newItem);
    } else {
      if (selectedRaceType?.length >= 2) {
        const removeItem = selectedRaceType?.filter((eItem) => eItem !== item);
        setselectedRaceType(removeItem);
      }
    }
  };

  const ChangeCountryFilter = (event, item) => {
    const flag = event.target.checked;
    if (flag) {
      const newItem = [...selectedCountryType, item];
      setselectedCountryType(newItem);
    } else {
      if (selectedCountryType.length >= 2) {
        const removeItem = selectedCountryType.filter(
          (eItem) => eItem !== item
        );
        setselectedCountryType(removeItem);
      }
    }
  };

  const refethButton = async () => {
    let params = {
      providerId: 2,
    };
    const { status, data } = await axiosInstance.post(`sync/fixture`, params);
    if (status === 200) {
      alert("sync process start");
    }
  };
  console.log("racetypeeee", selectedRaceType, selectedCountryType);
  const Today = moment().utc().format("YYYY-MM-DD");
  const Tommorow = moment().utc().add(1, "days").format("YYYY-MM-DD");
  const yesterDay = moment().utc().subtract(1, "days").format("YYYY-MM-DD");
  return (
    <Grid container className="page-content adminLogin">
      <Grid item xs={12}>
        <Box className="pageWrapper">
          <Box className="bredcrumn-wrap">
            <Breadcrumbs
              separator="/"
              aria-label="breadcrumb"
              className="breadcrumb"
            >
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Typography className="active_p">racing</Typography>
            </Breadcrumbs>
          </Box>
          <Grid container direction="row" alignItems="space-around">
            <Grid item xs={10}>
              <h1 className="text-left">RACING </h1>
            </Grid>
            <Grid item xs={2}>
              <ButtonComponent
                className="addButton admin-btn-green btn"
                onClick={() => {
                  setIsRefetch(true);
                  refethButton();
                }}
                color="primary"
                value="Refetch"
              />
            </Grid>
          </Grid>

          <Box className="sport-tab">
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              className="racing-tab-detail"
              disableRipple
              disableFocusRipple
            >
              {day?.map((item, index) => {
                return (
                  <>
                    <Tab
                      disableRipple
                      disableFocusRipple
                      //   label={item?.dayName}
                      label={
                        item?.date == Today
                          ? "Today"
                          : item?.date == Tommorow
                          ? "Tommorow"
                          : item?.date == yesterDay
                          ? "Yesterday"
                          : item?.dayName
                      }
                      value={item?.id}
                      className={item?.id == value ? "active" : ""}
                      onChange={(event, newValue) =>
                        handleChange(event, item?.id)
                      }
                    />
                  </>
                );
              })}
            </Tabs>
            <Box className="Filteritemlist-wrap">
              <ul className="Filteritemlist-racing">
                {filterRaceType?.length > 0 &&
                  filterRaceType?.map((filter, i) => (
                    <li>
                      <label>
                        <Checkbox
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          className="filter-racing"
                          icon={<Unchecked />}
                          checkedIcon={<Checked />}
                          name="filter"
                          value={filter?.id}
                          onChange={(event) => {
                            ChangeRaceFilter(event, filter?.id);
                          }}
                          checked={selectedRaceType?.includes(filter?.id)}
                        />
                        {filter?.icon}
                      </label>
                    </li>
                  ))}
              </ul>

              <ul className="Filteritemlist-racing">
                {filterCountry?.length > 0 &&
                  filterCountry?.map((countryItem, i) => (
                    <li>
                      <label>
                        <Checkbox
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          className="filter-country"
                          icon={<Unchecked />}
                          checkedIcon={<Checked />}
                          name="filtercountry"
                          value={countryItem?.value}
                          onChange={(event) => {
                            ChangeCountryFilter(event, countryItem?.value);
                          }}
                          checked={selectedCountryType.includes(
                            countryItem?.value
                          )}
                        />

                        {countryItem?.name}
                      </label>
                    </li>
                  ))}
              </ul>
            </Box>
          </Box>
          <DashboardCollapase
            selectedDate={selectedDate}
            selectedRaceType={selectedRaceType}
            selectedCountryType={selectedCountryType}
            isRefetch={isRefetch}
          />
          <Box className="fixture-info">
            <ul>
              <li>
                <span className="sqare fixture"></span> Found Fixture
              </li>
              <li>
                <span className="sqare notfixture"> </span> Can't Find Fixture
              </li>
              <li>
                <span className="sqare ignore"> </span> Marked by User as Ignore
              </li>
            </ul>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
