import { Validators } from './../../../../library/utilities/Validators'

export const eventsFormModel = [
  {
    label: 'Key*',
    value: '',
    type: 'dropdown',
    placeholder: 'Key',
    field: 'key',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
    options: [],
  },
  {
    label: 'value*',
    value: '',
    type: 'text',
    placeholder: 'Value',
    field: 'value',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
  },
  {
    label: 'BookKeeper',
    value: '',
    type: 'dropdown',
    placeholder: 'BookKeeper',
    field: 'bookKeepersId',
    validators: [{ check: Validators.required }],
    required: true,
    className: '6',
    options: [],
  },
]
