import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const VariationsInput = (props) => {
  const [selectvalue, setselectvalue] = useState([]);
  const [inputValue, setinputValue] = useState("");
  useEffect(() => {
    setselectvalue(props.value);
  }, [props.value]);
  const handleChange = (value) => {
    props.onChange(value, props?.field);
    setselectvalue(value);
  };

  const handleInputChange = (inputValue) => {
    setinputValue(inputValue);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        props.onChange([...selectvalue, createOption(inputValue)], props.field);
        setselectvalue([...selectvalue, createOption(inputValue)]);
        setinputValue("");
        event.preventDefault();
    }
  };

  return (
    <div className="input-field">
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type variation and press enter..."
        value={selectvalue}
      />
      <span style={{ fontSize: "13px", opacity: "0.8" }}>
        Type and Press Tab or Enter to add new variation
      </span>
    </div>
  );
};

VariationsInput.propTypes = {
  value: PropTypes.array,
  field: PropTypes.string,
};

VariationsInput.defaultProps = {};

export default VariationsInput;
