import { combineReducers } from "redux";
import CommonReducer from "./common/reducers/common.reducers";
import AuthReducer from "../library/common/reducers/AuthReducer";
import RegisterReducer from "../components/register/RegisterReducer";
import MetaReducer from "../library/common/reducers/MetaReducer";
import AdsReducer from "./common/reducers/ads.reducers";
import BlogsReducer from "./common/reducers/blogs.reducers";
import SportsReducer from "./common/reducers/sports.reducers";
import BetsReducer from "./common/reducers/bets.reducers";

const rootReducers = combineReducers({
  common: CommonReducer,
  authReducer: AuthReducer,
  registerReducer: RegisterReducer,
  metaReducer: MetaReducer,
  adsReducer: AdsReducer,
  blogs: BlogsReducer,
  sports: SportsReducer,
  bets: BetsReducer,
});

export default rootReducers;
