import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { raceTableFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

let raceTableFormModelArray = raceTableFormModel;
class CreateRaceTable extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        eventId: "",
        // raceId: "",
        raceName: "",
        distance: "",
        // trackId: null,
        sportId: null,
        description: "",
        comment: "",
        weather: null,
      },
      raceTableForm: [],
      startTimeDate: new Date(),
      // startDate: new Date(),
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentRace();
    }
    const { eventsAll, weatherAll, distanceAll, allSports } = this.props;
    this.setState((prevState) => {
      return {
        values: {
          ...prevState.values,
          eventId: eventsAll?.length > 0 ? eventsAll[0].id : null,
          distance: distanceAll?.length > 0 ? distanceAll[0].id : null,
          weather: weatherAll?.length > 0 ? weatherAll[0].id : null,
          sportId: allSports?.length > 0 ? allSports[0].id : null,
        },
      };
    });
    raceTableFormModelArray = raceTableFormModelArray?.map((fieldItem) => {
      if (fieldItem?.field === "eventId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...eventsAll?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.eventName,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "weather") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...weatherAll?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.weatherType,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "distance") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...distanceAll?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "sportId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...allSports?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.sportName,
              };
            }),
          ],
        };
      }
      return fieldItem;
    });
    this.setState({ raceTableForm: raceTableFormModelArray });
  }

  componentWillUnmount() {
    raceTableFormModelArray = raceTableFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchCurrentRace = async (id) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/races/race/${this.props.id}?type=admin`
      );
      if (status === 200) {
        this.setState({
          values: data.result,
          // startDate: new Date(data.result.startDate),
          // startTimeDate: new Date(data.result.startTimeDate),
        });
      }
    } catch (err) {}
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let {
      // eventId,
      // raceId,
      raceName,
      // description,
      distance,
      // comment,
      // weather,
    } = this.state.values;
    let { /*startDate,*/ startTimeDate } = this.state;
    let flag = true;

    if (
      // eventId === "" ||
      // raceId === "" ||
      raceName === "" ||
      // description === "" ||
      distance === "" ||
      // comment === "" ||
      // weather === null ||
      // startDate === "" ||
      startTimeDate === ""
    ) {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }

    return flag;
  };

  handleSave = async () => {
    const { /* startDate, */ startTimeDate } = this.state;
    const { isEditMode, eventID, sportId } = this.props;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode ? `${URLS.races}/${this.props.id}` : URLS.races;

      const values = removeErrorFieldsFromValues(form.formData);
      values["eventId"] = parseInt(eventID);
      values["sportId"] = parseInt(sportId);
      // values["startDate"] = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
      values["startTimeDate"] = moment.utc(startTimeDate).format();
      raceTableFormModelArray = raceTableFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        if (values.weather === "") {
          values["weather"] = null;
        }
        // if (values.trackId === "") {
        //   values["trackId"] = null;
        // }
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllRace();
          this.setActionMessage(
            true,
            "Success",
            `Race Table ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${
          isEditMode ? "editing" : "creating"
        } Race Table`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    raceTableFormModelArray = raceTableFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  // handleStartDate = (date) => {
  //   let selectedDate = new Date(date);
  //   this.setState({ startDate: selectedDate });
  // };

  handleStartTimeDate = (date) => {
    let selectedDate = new Date(date);
    this.setState({ startTimeDate: selectedDate });
  };

  render() {
    var { values, messageBox, isLoading, raceTableForm } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={raceTableFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />
              <div style={{ display: "flex" }}>
                {/* <div className='input-field' style={{ width: "100%" }}>
                  <label>Start Date</label>
                  <br></br>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleStartDate}
                    timeInputLabel='Time:'
                    dateFormat='MM/dd/yyyy h:mm aa'
                    showTimeInput
                  />
                </div> */}
                <div className="input-field" style={{ width: "100%" }}>
                  <label>Start Time Date</label>
                  <br></br>
                  <DatePicker
                    selected={this.state.startTimeDate}
                    onChange={this.handleStartTimeDate}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                  />
                </div>
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateRaceTable;
