import React from "react";
import ListItemMapping from "./ListItemMapping";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

const ListMappings = ({ mappings,mappingMeetings,saveMapping }) => {
    return (<>
    <TableContainer component={Paper}>
      <Table className="listTable1" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Generation Web Meeting Name</TableCell>
            <TableCell>Mapping Meeting Name</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mappings.map((item, index) => (
            <ListItemMapping item={item} index={index} mappingMeetings={mappingMeetings} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
};

export default ListMappings;
