import { Validators } from "../../../library/utilities/Validators";

export const runnerFormModel = [
  //   {
  //     label: "Horse Name*",
  //     value: "",
  //     type: "text",
  //     placeholder: "Horse Name",
  //     field: "animal",
  //     validators: [
  //       { check: Validators.required },
  //       { check: Validators.number, message: "Insert Number Only" },
  //     ],
  //     required: true,
  //     className: "6",
  //   },
  {
    label: "Weight*",
    value: "",
    type: "text",
    placeholder: "Weight",
    field: "JockeyWeight",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Jockey Name*",
    value: "",
    type: "text",
    placeholder: "Jockey Name",
    field: "particiantId",
    required: true,
    validators: [
      { check: Validators.required },
      { check: Validators.number, message: "Insert Number Only" },
    ],
    className: "6",
  },
  {
    label: "Barrier Number*",
    value: "",
    type: "text",
    placeholder: "Barrier Number",
    field: "barrierNumber",
    required: true,
    validators: [{ check: Validators.required }],
    className: "6",
  },
  {
    label: "Runner Number*",
    value: "",
    type: "text",
    placeholder: "Runner Number",
    field: "runnerNumber",
    required: true,
    validators: [{ check: Validators.required }],
    className: "6",
  },
];
