import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Modal,
  TextField,
} from "@material-ui/core";
import { MdKeyboardBackspace } from "react-icons/md";
import { Loader } from "../../../library/common/components";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";
import ButtonComponent from "../../../library/common/components/Button";
// import { URLS } from "../../../library/common/constants";
import axiosInstance from "../../../helpers/Axios";
// import moment from "moment";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditRunnerDetail from "./editRunnerDetail";
import ShowModal from "../../../components/common/ShowModal/ShowModal";
import _ from "lodash";

class RunnersDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      runnersDetails: [],
      isInputModalOpen: false,
      idToSend: null,
      isLoading: false,
      isEditMode: true,
      currentPage: 1,
      rowPerPage: 20,
      searchInput: "",
      rowToSend: null,
      itemToDelete: null,
      isDeleteRunnerModalOpen: false,
      isDeleteLoading: "",
      animalDetails: [],
      playersDetails: [],
    };
  }

  componentDidMount() {
    this.fetchAllRunners();
    this.fetchAllAnimal();
    this.fetchAllPlayers();

    // console.log(window.history.previous.href);
    // const { params } = this.props.match;
    // console.log("runner : ", params);
  }

  fetchAllRunners = async () => {
    let id = this.props.match.params.id;
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(
      `/events/runnerwithodds/${id}`
    );
    if (status === 200) {
      let data_obj = _.orderBy(data?.result, ["animal.name"], ["asc"]);
      this.setState({ runnersDetails: data_obj, isLoading: false });
    }
  };

  fetchAllAnimal = async () => {
    const { status, data } = await axiosInstance.get(
      "animal?size=10000&page=1"
    );
    if (status === 200) {
      let Aarray = [...this.state.animalDetails];

      let count = data.totalCount / 10000;
      for (let i = 0; i < Math.ceil(count); i++) {
        const { status, data } = await axiosInstance.get(
          `animal?size=10000&page=${i + 1}`
        );

        if (status === 200) {
          data.result.map((obj) => {
            return Aarray.push(obj);
          });

          this.setState({
            animalDetails: Aarray,
          });
        }
      }
    }
  };

  fetchAllPlayers = async () => {
    const { status, data } = await axiosInstance.get(
      "teams/player?size=10000&page=1"
    );

    if (status === 200) {
      let Parray = [...this.state.playersDetails];

      let count = data.totalCount / 10000;
      for (let i = 0; i < Math.ceil(count); i++) {
        const { status, data } = await axiosInstance.get(
          `teams/player?size=10000&page=${i + 1}`
        );

        if (status === 200) {
          data.result.map((obj) => {
            return Parray.push(obj);
          });
          this.setState({
            playersDetails: Parray,
          });
        }
      }
    }
  };

  inputModal = (id, type, data) => () => {
    this.setState({
      isInputModalOpen: true,
      idToSend: id,
      isEditMode: type === "edit" ? true : false,
      rowToSend: data,
    });
  };

  toggleInputModal = () => {
    this.setState({
      isInputModalOpen: !this.state.isInputModalOpen,
    });
  };

  afterChangeRefresh = () => {
    this.fetchAllRunners();
  };

  backToNavigatePage = () => {
    const { params } = this.props.match;
    this.props.history.push(
      `/admin/racing/${params?.sportname}/${params?.sportid}/${params?.eventid}/${params?.eventname}`
    );
  };

  //   componentDidUpdate(prevProps) {
  //     if (prevProps.match !== this.props.match) {
  //       this.fetchAllSports();
  //     }
  //   }

  //   onSubmit = async () => {
  //     let { sportId, selectedDate } = this.state;
  //     let params = {
  //       sportId: sportId,
  //       //   date: moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
  //       //   endDate: moment(selectedDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
  //       date: "2021-04-09T00:00:00.000Z",
  //       endDate: "2021-04-10T00:00:00.000Z",
  //     };
  //     const { status, data } = await axiosInstance.post("private/races", params);
  //     if (status === 200) {
  //       this.setState({ runnersDetails: data.respose });
  //     }
  //   };

  //   getSportId = () => {
  //     let { sports } = this.state;
  //     let sportName = this.props.match.params.name;
  //     let obj = sports
  //       .filter((obj) => obj.sportName == sportName)
  //       .map((object) => object.id);
  //     return obj;
  //   };

  //   handleDateChange = (date) => {
  //     this.setState({ selectedDate: date });
  //   };

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, rowPerPage, runnersDetails } = this.state;
    if (navDirection === "prev") {
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    } else {
      if (currentPage < runnersDetails.length / rowPerPage)
        this.setState({ currentPage: currentPage + 1 });
    }
  };

  handleRunnerDelete = async (id) => {
    this.setState({
      isDeleteLoading: "runnerDelete",
      isDeleteRunnerModalOpen: false,
    });
    try {
      const { status } = await axiosInstance.delete(`/race/participant/${id}`);
      if (status === 200) {
        this.afterChangeRefresh();
        this.setState({ isDeleteLoading: "", itemToDelete: null });
      }
    } catch (err) {
      // console.log(err);
    }
  };

  setRunnerToDelete = (id) => {
    this.setState({ itemToDelete: id, isDeleteRunnerModalOpen: true });
  };

  toggleRunnerDeleteModal = () => {
    this.setState({
      isDeleteRunnerModalOpen: !this.state.isDeleteRunnerModalOpen,
      itemToDelete: null,
    });
  };

  render() {
    const { match } = this.props;
    let {
      runnersDetails,
      isLoading,
      rowPerPage,
      currentPage,
      isInputModalOpen,
      isEditMode,
      searchInput,
      rowToSend,
      isDeleteLoading,
      isDeleteRunnerModalOpen,
      animalDetails,
      playersDetails,
    } = this.state;
    const pageNumbers = [];

    searchInput !== "" &&
      (runnersDetails = runnersDetails?.filter(
        (obj) =>
          obj?.animal?.name
            ?.toString()
            .toLowerCase()
            .includes(searchInput.toString().toLowerCase()) ||
          obj?.players[0].name
            ?.toString()
            .toLowerCase()
            .includes(searchInput.toString().toLowerCase())
      ));

    let currentPageRow = runnersDetails;

    if (runnersDetails?.length > 0) {
      const indexOfLastTodo = currentPage * rowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      currentPageRow = runnersDetails.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(runnersDetails.length / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              {isDeleteLoading === "runnerDelete" && (
                <div class="admin-delete-loader">
                  <Loader />
                </div>
              )}
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ marginBottom: "10px" }}
              >
                <Grid item xs={7}>
                  <Button
                    className="admin-btn-back"
                    onClick={this.backToNavigatePage}
                  >
                    <MdKeyboardBackspace />
                  </Button>
                  <h3
                    className="text-left admin-page-heading"
                    style={{ margin: "5px 0px 0px 5px" }}
                  >
                    Runners Details {`( ${match.params.raceName} )`}
                  </h3>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    color="primary"
                    size="small"
                    label="Search"
                    value={searchInput}
                    onChange={(e) =>
                      this.setState({
                        ...this.state.searchInput,
                        searchInput: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent
                    className="mt-3 admin-btn-green"
                    onClick={this.inputModal(null, "add", null)}
                    color="primary"
                    value={"Add Runner"}
                  />
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && runnersDetails.length === 0 && (
                <p>No Data Available</p>
              )}
              {!isLoading && runnersDetails.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      className="listTable api-provider-listTable"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>DID</TableCell>
                          <TableCell>
                            {match?.params?.sportname === "Greyhound Racing"
                              ? "Dogs Name"
                              : "Horse Name"}
                          </TableCell>
                          {match?.params?.sportname === "Horse Racing" && (
                            <TableCell>Weight</TableCell>
                          )}
                          <TableCell>
                            {match?.params?.sportname === "Horse Racing"
                              ? "Jockey Name"
                              : match?.params?.sportname === "Harness Racing"
                              ? "Drivers Name"
                              : "Trainers Name"}
                          </TableCell>
                          <TableCell>Barrier Number</TableCell>
                          <TableCell>Runner Number</TableCell>
                          <TableCell>Edit</TableCell>
                          <TableCell>Delete</TableCell>
                          {/* <TableCell>Weather</TableCell> */}
                          {/* <TableCell>Action</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentPageRow?.map((runnersDetails, i) => (
                          <TableRow key={i}>
                            <TableCell>{runnersDetails.id}</TableCell>
                            <TableCell>{runnersDetails.animal.name}</TableCell>
                            {match?.params?.sportname === "Horse Racing" && (
                              <TableCell>
                                {runnersDetails.JockeyWeight}
                              </TableCell>
                            )}
                            <TableCell>
                              {runnersDetails.players.length > 0
                                ? runnersDetails.players[0].name
                                : ""}
                            </TableCell>
                            <TableCell>
                              {runnersDetails.barrierNumber}
                            </TableCell>
                            <TableCell>{runnersDetails.runnerNumber}</TableCell>
                            <TableCell>
                              <EditIcon
                                onClick={this.inputModal(
                                  runnersDetails.id,
                                  "edit",
                                  runnersDetails
                                )}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                            </TableCell>
                            <TableCell>
                              <DeleteOutlineIcon
                                onClick={() =>
                                  this.setRunnerToDelete(runnersDetails?.id)
                                }
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-orange"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="tablePagination">
                    <button
                      className={
                        runnersDetails.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={
                        runnersDetails.length / rowPerPage > 1 ? false : true
                      }
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={
                        runnersDetails.length / rowPerPage > 1 ? false : true
                      }
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size="small"
                    />
                    <button
                      className={
                        runnersDetails.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={
                        runnersDetails.length / rowPerPage > 1 ? false : true
                      }
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </>
              )}
            </Paper>
            <Modal
              className="modal modal-input"
              open={isInputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {isEditMode ? "Edit Runners" : "Add Runners"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />
                <EditRunnerDetail
                  inputModal={this.toggleInputModal}
                  id={this.state.idToSend}
                  isEditMode={isEditMode}
                  fetchAllRunners={this.afterChangeRefresh}
                  raceId={match.params.id}
                  allAnimal={animalDetails}
                  allPlayers={playersDetails}
                  raceName={match.params.sportname}
                  rowToSend={rowToSend}
                />
              </div>
            </Modal>

            <ShowModal
              isModalOpen={isDeleteRunnerModalOpen}
              onClose={this.toggleRunnerDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handleRunnerDelete}
              onCancel={this.toggleRunnerDeleteModal}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
export default RunnersDetails;
