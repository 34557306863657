import React, { createRef } from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import { eventsPlaceFormModel, eventsFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { setValidation } from "../../../helpers/common";
import "./event.scss";
import { Validators } from "../../../library/utilities/Validators";

let eventsFormModelArray = [];

class CreateEvents extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        eventName: "",
        description: "",
        comment: "",
        leagueId: null,
        fighterOrder: "",
      },
      eventsForm: [],
      sportId: null,
      categoryId: null,
      weather: null,
      // eventDate: new Date(),
      eventToDate: new Date(),
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    eventsFormModelArray = this.props.isOtherSport
      ? eventsPlaceFormModel
      : eventsFormModel;
    if (this.props.isEditMode) {
      this.fetchCurrentEvents(this.props.id);
    }
    this.setState({ sportId: this.props.sportId });
    const {
      // sportAll,
      // leagueAll,
      weatherAll,
      locationAll,
      trackAll,
      isOtherSport,
      otherSportName,
      leagueDetails,
    } = this.props;

    this.setState((prevState) => {
      return {
        values: {
          ...prevState.values,
          // sportId: sportAll?.length > 0 ? sportAll[0].id : '',
          leagueId: leagueDetails?.length > 0 ? leagueDetails[0].id : "",
          locationId:
            locationAll?.length > 0 ? parseInt(locationAll[0].id) : "",
          weather: weatherAll?.length > 0 ? weatherAll[0].id : "",
        },
      };
    });
    eventsFormModelArray = eventsFormModelArray?.map((fieldItem) => {
      if (fieldItem?.field === "eventName") {
        return {
          ...fieldItem,
          label: isOtherSport
            ? otherSportName === "Rugby League" ||
              otherSportName === "Australian Rules" ||
              otherSportName === "Soccer" ||
              otherSportName === "Cricket" ||
              otherSportName === "Rugby Union"
              ? "Match Name"
              : "Event Name"
            : "Event Name",
          type: "text",
        };
      } else if (fieldItem?.field === "weather") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...weatherAll?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.weatherType,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "locationId") {
        return {
          ...fieldItem,
          label: isOtherSport
            ? otherSportName === "Rugby League" ||
              otherSportName === "Australian Rules" ||
              otherSportName === "Soccer" ||
              otherSportName === "Cricket" ||
              otherSportName === "Rugby Union"
              ? "Stadiums"
              : "Location"
            : "Location",
          type: "dropdown",
          options: [
            ...locationAll?.map((tablecol, i) => {
              return { id: i, value: tablecol?.id, label: tablecol?.venueName };
            }),
          ],
        };
      }
      // else if (fieldItem?.field === 'sportId') {
      //   return {
      //     ...fieldItem,
      //     type: 'dropdown',
      //     options: [
      //       ...sportAll?.map((tablecol, i) => {
      //         return { id: i, value: tablecol?.id, label: tablecol?.sportName }
      //       }),
      //     ],
      //   }
      // }
      else if (fieldItem?.field === "leagueId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...leagueDetails?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.leagueName,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "round") {
        if (
          otherSportName === "Rugby League" ||
          otherSportName === "Australian Rules" ||
          otherSportName === "Soccer" ||
          otherSportName === "Cricket" ||
          otherSportName === "Rugby Union"
        ) {
          return {
            ...fieldItem,
          };
        } else {
          return {
            ...fieldItem,
            label: "Fighter Order",
            placeholder: "fighter order",
            field: "fighterOrder",
            validators: [
              { check: Validators.required },
              { check: Validators.number, message: "insert number only" },
            ],
          };
        }
      } else if (fieldItem?.field === "trackId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...trackAll?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      }
      return fieldItem;
    });
  }

  componentWillUnmount() {
    eventsFormModelArray = eventsFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchCurrentEvents = async (id) => {
    const { status, data } = await axiosInstance.get(URLS.events + `/${id}`);
    if (status === 200) {
      let fighterOrder = data?.result?.options?.find(
        (obj) => obj?.key === "fighterOrder"
      );
      let round = data?.result?.options?.find((obj) => obj?.key === "round");
      this.setState(
        {
          values: data.result,
          sportId: data.result.sportId,
          categoryId: data.result.categoryId,
          weather: data.result.weather,
          // eventDate: new Date(data.result.eventDate),
          eventToDate: new Date(data.result.eventToDate),
        },
        () =>
          this.setState({
            values: {
              ...this.state.values,
              fighterOrder:
                fighterOrder !== undefined ? fighterOrder?.value : "",
              round: round?.value !== undefined ? round?.value : "",
            },
          })
      );
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { eventName, fighterOrder } = this.state.values;
    const { otherSportName } = this.props;
    let flag = true;

    if (eventName === "") {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }
    if (
      otherSportName !== "Rugby League" &&
      otherSportName !== "Australian Rules" &&
      otherSportName !== "Soccer" &&
      otherSportName !== "Cricket" &&
      otherSportName !== "Rugby Union"
    ) {
      if (fighterOrder === "" && !/^[0-9\b]+$/.test(fighterOrder)) {
        flag = false;
        this.setState({ isLoading: false });
      } else {
        flag = true;
      }
    }

    return flag;
  };

  handleSave = async () => {
    const {
      // eventDate,
      eventToDate,
    } = this.state;
    const {
      isEditMode,
      // sportId,
      isOtherSport,
      otherSportName,
    } = this.props;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode ? `${URLS.events}/${this.props.id}` : URLS.events;

      const values = removeErrorFieldsFromValues(form.formData);

      let DataToCreate = {
        comment: values?.comment,
        leagueId: parseInt(values?.leagueId),
        description: values?.description,
        // eventDate: moment(values?.eventDate).format('YYYY-MM-DD HH:mm:ss'),
        eventName: values?.eventName,
        eventToDate: moment.utc(eventToDate).format(),
        // eventToDate: "2021-05-23t02:02:00z",
        locationId: parseInt(values?.locationId),
        sportId: parseInt(this.state.sportId),
        variation: values?.variation,
      };

      if (!isOtherSport) {
        DataToCreate["trackId"] = parseInt(values?.trackId);
      }
      if (
        otherSportName === "Rugby League" ||
        otherSportName === "Australian Rules" ||
        otherSportName === "Soccer" ||
        otherSportName === "Cricket" ||
        otherSportName === "Rugby Union"
      ) {
        DataToCreate["round"] = values?.round;
      } else {
        DataToCreate["fighterOrder"] = parseInt(values?.fighterOrder);
      }

      eventsFormModelArray = eventsFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, DataToCreate);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllEvents();
          this.setActionMessage(
            true,
            "Success",
            `Events ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${isEditMode ? "editing" : "creating"} Events`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    eventsFormModelArray = eventsFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  // handleEventDateChange = (date) => {
  //   let selectedDate = new Date(date)
  //   this.setState({ eventDate: selectedDate })
  // }

  handleEventToDateChange = (date) => {
    let selectedDate = new Date(date);
    this.setState({ eventToDate: selectedDate });
  };

  render() {
    var {
      values,
      messageBox,
      isLoading,
      // eventDate,
      eventToDate,
    } = this.state;
    var { isEditMode } = this.props;

    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            {/* <Paper className="pageWrapper api-provider"> */}
            {messageBox.display && (
              <ActionMessage
                message={messageBox.message}
                type={messageBox.type}
                styleClass={messageBox.styleClass}
              />
            )}

            <Form
              values={values}
              model={eventsFormModelArray}
              ref={this.formRef}
              onChange={this.handleChange}
            />

            <div style={{ display: "flex" }}>
              {/* <div className='input-field' style={{ width: "100%" }}>
                  <label>Event Date</label>
                  <br></br>
                  <DatePicker
                    selected={eventDate}
                    onChange={this.handleEventDateChange}
                    timeInputLabel='Time:'
                    dateFormat='MM/dd/yyyy h:mm aa'
                    showTimeInput
                  />
                </div> */}
              <div className="input-field" style={{ width: "100%" }}>
                <label>Event To Date</label>
                <br></br>
                <DatePicker
                  // minDate={moment().toDate()}
                  selected={eventToDate}
                  onChange={this.handleEventToDateChange}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                />
              </div>
            </div>

            <Grid container>
              <Grid item xs={3}>
                <div
                  className="pd-btm"
                  style={{
                    marginTop: "20px",
                    display: "flex",
                  }}
                >
                  {!isEditMode ? (
                    <ButtonComponent
                      className="mt-3 admin-btn-purple"
                      onClick={this.handleSave}
                      color="primary"
                      value={!isLoading ? "Save" : "Loading..."}
                      disabled={isLoading}
                    />
                  ) : (
                    <ButtonComponent
                      className="mt-3 admin-btn-purple"
                      onClick={this.handleSave}
                      color="secondary"
                      value={!isLoading ? "Update" : "Loading..."}
                      disabled={isLoading}
                    />
                  )}

                  {/* <ButtonComponent
                    onClick={this.props.inputModal}
                    className="mr-lr-30"
                    value="Back"
                  /> */}
                  <Button
                    className="mr-lr-30 admin-btn-outlined"
                    variant="outlined"
                    // color="primary"
                    onClick={this.props.inputModal}
                  >
                    Back
                  </Button>
                </div>
              </Grid>
            </Grid>
            {/* </Paper> */}
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateEvents;
