import React, { useEffect, useState } from "react";
import { IconButton, Grid } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

import { DATA_TYPES } from "../../../../helpers/constants";

const RepeaterFieldsApisFieldMapping = (props) => {
  const [SelectedTableColumnsArray, setSelectedTableColumnsArray] = useState(
    []
  );

  useEffect(() => {
    onTableChange(props?.values?.tableName);
  }, [props?.values?.tableName]);

  const errors =
    typeof props?.error[props?.id] !== "undefined" && props?.error[props?.id];
  const checkIfidentifierAlreadySelected = (id, val) => {
    let foundInPrevSavedFilter =
      props.apisFeildMapping?.length > 0
        ? props.apisFeildMapping?.filter(
            (item) => item.id !== id && item.apiIdentifierValue === val
          )
        : false;
    let foundInPrevSaved = foundInPrevSavedFilter?.length;
    let fountItemFilter = props?.repeaterValues?.filter(
      (item) => item.id !== id && item.apiIdentifierValue === val
    );
    let fountItem = fountItemFilter?.length;
    if (props.isEditMode) {
      return fountItem > 0 ? true : false;
    }
    return foundInPrevSaved > 0 || fountItem > 0 ? true : false;
  };

  const onTableChange = (value) => {
    let SelectedTableColumnsArray = Object.keys(props.dbtable)?.some(
      (item, index) => value === item
    )
      ? [...Object.keys(props.dbtable[value])?.map((item, index) => item)]
      : [];
    setSelectedTableColumnsArray(SelectedTableColumnsArray);
  };

  return (
    <>
      {props?.isEditMode !== true && (
        <div className="repeater-item-remove">
          <IconButton onClick={() => props.removeMappingItem(props.id)}>
            <Delete />
          </IconButton>
        </div>
      )}

      <div className="repeater-item-field" style={{ width: "70%" }}>
        <label>Api Identifier Value*</label>
        <Grid container>
          <Grid item xs={4}>
            <div className="radio_input_wrap">
              <input
                type="radio"
                id="null"
                name={"apiIdentifierValue[" + props.id + "]"}
                value="null"
                onChange={(e) =>
                  props.setRepeaterInputOnchange(
                    props.id,
                    "apiIdentifierValue",
                    e.target.value
                  )
                }
                checked={props?.values?.apiIdentifierValue === "null"}
                style={{ margin: "6px 10px" }}
                disabled={props?.isEditMode === true}
              />
              <label for="null">null</label>
            </div>
          </Grid>
          {props?.apiIdentifierValueOptions?.map((obj, i) => {
            let showItem = true;
            if (checkIfidentifierAlreadySelected(props.id, obj)) {
              showItem = false;
            }
            if (showItem) {
              return (
                <Grid item xs={4}>
                  <div className="radio_input_wrap">
                    <input
                      type="radio"
                      id={obj + props.id}
                      name={"apiIdentifierValue[" + props.id + "]"}
                      value={obj}
                      onChange={(e) =>
                        props.setRepeaterInputOnchange(
                          props.id,
                          "apiIdentifierValue",
                          e.target.value
                        )
                      }
                      checked={props?.values?.apiIdentifierValue === obj}
                      style={{ margin: "6px 10px" }}
                      disabled={props?.isEditMode === true}
                    />
                    <label for={obj + props.id}>{obj}</label>
                  </div>
                </Grid>
              );
            }
          })}
          <Grid item xs={4}>
            <div className="radio_input_wrap">
              <input
                type="radio"
                id="other"
                name={"apiIdentifierValue[" + props.id + "]"}
                value="other"
                onChange={(e) =>
                  props.setRepeaterInputOnchange(
                    props.id,
                    "apiIdentifierValue",
                    e.target.value
                  )
                }
                // checked={props?.values?.apiIdentifierValue === "other"}
                checked={props?.isOther.includes(props.id)}
                style={{ margin: "6px 10px" }}
                disabled={props?.isEditMode === true}
              />
              <label for="other">other</label>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className="radio_input_wrap"
              style={{
                display:
                  //props?.isOther === "apiIdentifierValue[" + props.id + "]"
                  props?.isOther.includes(props.id) ? "block" : "none",
              }}
            >
              <input
                style={{
                  width: "80%",
                  marginLeft: "5%",
                }}
                name={"apiIdentifierValue[" + props.id + "]"}
                value={props?.values?.apiIdentifierValue}
                onChange={(e) =>
                  props?.handleOtherValueChange(
                    props?.id,
                    "apiIdentifierValue",
                    e.target.value
                  )
                }
                // onChange={(e) =>
                //   props.setRepeaterInputOnchange(
                //     props.id,
                //     "apiIdentifierValue",
                //     e.target.value
                //   )
                // }
                type="text"
                placeholder="add other Identifier value"
              />
            </div>
          </Grid>
        </Grid>
        {typeof errors?.apiIdentifierValue !== "undefined" &&
          errors?.apiIdentifierValue !== "" && (
            <p className="errorText">
              {props?.error[props?.id].apiIdentifierValue}
            </p>
          )}
      </div>
      <div className="repeater-item-field" style={{ width: "29%" }}>
        <label>Api Identifier*</label>
        <select
          className="select-box-manual"
          name="apiIdentifier"
          onChange={(e) => {
            props.setRepeaterInputOnchange(
              props.id,
              "apiIdentifier",
              e.target.value
            );
          }}
          value={props?.values?.apiIdentifier}
        >
          {props.allKeyIdentifire?.map((obj, i) => (
            <option key={i} value={obj?.id}>
              {obj?.key}
            </option>
          ))}
        </select>
        {typeof errors?.apiIdentifier !== "undefined" &&
          errors?.apiIdentifier !== "" && (
            <p className="errorText">{errors?.apiIdentifier}</p>
          )}
      </div>
      <div className="repeater-item-field thirdwidth">
        <label>Is Element*</label>
        <div className="radio_input_wrap">
          <input
            type="radio"
            id={"isElement_yes" + props.id}
            name={"isElement[" + props.id + "]"}
            value={true}
            onChange={(e) =>
              props.setRepeaterInputOnchange(
                props.id,
                "isElement",
                e.target.value
              )
            }
            checked={
              props?.values?.isElement === "1" ||
              props?.values?.isElement === "true"
            }
            style={{ margin: "6px 10px" }}
          />
          <label for={"isElement_yes" + props.id}>Yes</label>
        </div>
        <div className="radio_input_wrap">
          <input
            type="radio"
            id={"isElement_no" + props.id}
            name={"isElement[" + props.id + "]"}
            value={false}
            onChange={(e) =>
              props.setRepeaterInputOnchange(
                props.id,
                "isElement",
                e.target.value
              )
            }
            checked={
              props?.values?.isElement === "0" ||
              props?.values?.isElement === "false"
            }
            style={{ margin: "6px 10px" }}
          />
          <label for={"isElement_no" + props.id}>No</label>
        </div>
        {typeof errors?.isElement !== "undefined" &&
          errors?.isElement !== "" && (
            <p className="errorText">{errors?.isElement}</p>
          )}
      </div>
      <div className="repeater-item-field thirdwidth">
        <label>Value Type*</label>
        <select
          className="select-box-manual"
          name="valueType"
          onChange={(e) => {
            props.setRepeaterInputOnchange(
              props.id,
              "valueType",
              e.target.value
            );
          }}
          value={props?.values?.valueType}
        >
          {DATA_TYPES?.map((item, i) => (
            <option key={i} value={item}>
              {item}
            </option>
          ))}
        </select>
        {typeof errors?.valueType !== "undefined" &&
          errors?.valueType !== "" && (
            <p className="errorText">{errors?.valueType}</p>
          )}
      </div>
      <div className="repeater-item-field thirdwidth">
        <label>Table Field*</label>
        <select
          className="select-box-manual"
          name="tableFeild"
          onChange={(e) => {
            props.setRepeaterInputOnchange(
              props.id,
              "tableFeild",
              e.target.value
            );
          }}
          value={props?.values?.tableFeild}
        >
          {props?.TablesColumnsArray?.map((item, i) => (
            <option key={i} value={item}>
              {item}
            </option>
          ))}
        </select>
        {typeof errors?.tableFeild !== "undefined" &&
          errors?.tableFeild !== "" && (
            <p className="errorText">{errors?.tableFeild}</p>
          )}
      </div>
      <div
        className="repeater-item-field thirdwidth"
        style={{ marginBottom: "28px" }}
      >
        <label>Is Reference*</label>
        <div className="radio_input_wrap">
          <input
            type="radio"
            id={"isReference_yes" + props.id}
            name={"isReference[" + props.id + "]"}
            value={true}
            onChange={(e) =>
              props.setRepeaterInputOnchange(
                props.id,
                "isReference",
                e.target.value
              )
            }
            checked={
              props?.values?.isReference === "1" ||
              props?.values?.isReference === "true"
            }
            style={{ margin: "6px 10px" }}
          />
          <label for={"isReference_yes" + props.id}>Yes</label>
        </div>
        <div className="radio_input_wrap">
          <input
            type="radio"
            id={"isReference_no" + props.id}
            name={"isReference[" + props.id + "]"}
            value={false}
            onChange={(e) =>
              props.setRepeaterInputOnchange(
                props.id,
                "isReference",
                e.target.value
              )
            }
            checked={
              props?.values?.isReference === "0" ||
              props?.values?.isReference === "false"
            }
            style={{ margin: "6px 10px" }}
          />
          <label for={"isReference_no" + props.id}>No</label>
        </div>
        {typeof errors?.isReference !== "undefined" &&
          errors?.isReference !==
          <p className="errorText">{errors?.isReference}</p>}
      </div>
      {(props?.values?.isReference === "1" ||
        props?.values?.isReference === "true") && (
        <>
          <div
            className="repeater-item-field thirdwidth"
            style={{ marginBottom: "0" }}
          >
            <label>Table Name*</label>
            <select
              className="select-box-manual"
              name="tableName"
              onChange={(e) => {
                onTableChange(e.target.value);
                props.setRepeaterInputOnchange(
                  props.id,
                  "tableName",
                  e.target.value
                );
              }}
              value={props?.values?.tableName}
            >
              {props.TablesArray?.map((obj, i) => (
                <option key={i} value={obj}>
                  {obj}
                </option>
              ))}
            </select>
            {typeof errors?.tableName !== "undefined" &&
              errors?.tableName !== "" && (
                <p className="errorText">{errors?.tableName}</p>
              )}
          </div>
          <div
            className="repeater-item-field thirdwidth"
            style={{ marginBottom: "0" }}
          >
            <label>Column Name*</label>
            <select
              className="select-box-manual"
              name="columnName"
              onChange={(e) =>
                props.setRepeaterInputOnchange(
                  props.id,
                  "columnName",
                  e.target.value
                )
              }
              value={props?.values?.columnName}
            >
              {SelectedTableColumnsArray?.map((obj, i) => (
                <option key={i} value={obj}>
                  {obj}
                </option>
              ))}
            </select>
            {typeof errors?.columnName !== "undefined" &&
              errors?.columnName !== "" && (
                <p className="errorText">{errors?.columnName}</p>
              )}
          </div>
        </>
      )}
      <div
        className="repeater-item-field thirdwidth"
        style={{ display: "block" }}
      >
        <label>Insert Check Flag*</label>
        <select
          className="select-box-manual"
          name="insertCheckFlag"
          onChange={(e) => {
            props.setRepeaterInputOnchange(
              props.id,
              "insertCheckFlag",
              e.target.value
            );
          }}
          value={props?.values?.insertCheckFlag}
        >
          <option value={1}>Yes</option>
          <option value={0}>No</option>
        </select>
        {typeof errors?.insertCheckFlag !== "undefined" &&
          errors?.insertCheckFlag !== "" && (
            <p className="errorText">{errors?.insertCheckFlag}</p>
          )}
      </div>
    </>
  );
};
export default RepeaterFieldsApisFieldMapping;
