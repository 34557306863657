import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import ButtonComponent from "../../library/common/components/Button";
import axiosInstance from "../../helpers/Axios";
import ActionMessage from "../../library/common/components/ActionMessage";
import { Loader } from "../../library/common/components";
import CreateMarketsUpdate from "./CreateMarkets";
import ShowModal from "../../components/common/ShowModal/ShowModal";
import { URLS } from "../../library/common/constants";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";

class Markets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markets: [],
      allEvents: [],
      allParticipants: [],
      allTeams: [],
      allPlayer: [],
      // allBookkeeper: [],
      isInputModalOpen: false,
      idToSend: null,
      isModalOpen: false,
      isLoading: false,
      itemToDelete: null,
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      currentPage: 1,
      rowPerPage: 20,
    };
  }

  componentDidMount() {
    this.fetchAllMarkets();
  }

  fetchAllMarkets = async () => {
    this.setState({ isLoading: true });
    try {
      const { status, data } = await axiosInstance.get(URLS?.markets);
      if (status === 200) {
        this.setState({ markets: data.result, isLoading: false });
        this.fetchAllEvents();
        this.fetchAllParticipants();
        this.fetchAllTeams();
        this.fetchAllPlayers();
        // this.fetchAllBookkeeper();
      } else {
        this.setState({ markets: [], isLoading: false });
      }
    } catch (e) {
      this.setState({ markets: [], isLoading: false });
    }
  };

  async fetchAllParticipants() {
    const { status, data } = await axiosInstance.get(URLS.participants);
    if (status === 200) {
      this.setState({ allParticipants: data.result });
    }
  }

  getParticipant = (id) => {
    let { allParticipants } = this.state;
    let participant = allParticipants
      .filter((obj) => obj.id === id)
      .map((object) => object.participantsId);
    return participant;
  };

  async fetchAllEvents() {
    const { status, data } = await axiosInstance.get(URLS.events);
    if (status === 200) {
      this.setState({ allEvents: data.result });
    }
  }

  getEvent = (id) => {
    let { allEvents } = this.state;
    let event = allEvents
      .filter((obj) => obj.id === id)
      .map((object) => object.eventName);
    return event;
  };

  async fetchAllTeams() {
    const { status, data } = await axiosInstance.get(URLS.teams);
    if (status === 200) {
      this.setState({ allTeams: data.result });
    }
  }

  getTeams = (id) => {
    let { allTeams } = this.state;
    let name = allTeams
      .filter((obj) => obj.id === id)
      .map((object) => object.name);

    return name;
  };

  async fetchAllPlayers() {
    const { status, data } = await axiosInstance.get(URLS.players);
    if (status === 200) {
      this.setState({ allPlayer: data.result });
    }
  }

  getPlayers = (id) => {
    let { allPlayer } = this.state;
    let name = allPlayer
      .filter((obj) => obj.id === id)
      .map((object) => object.name);

    return name;
  };

  // async fetchAllBookkeeper() {
  //   const { status, data } = await axiosInstance.get(URLS.bookkeeper);
  //   if (status === 200) {
  //     this.setState({ allBookkeeper: data.result });
  //   }
  // }

  // getBookkeeper = (id) => {
  //   let { allBookkeeper } = this.state;
  //   let name = allBookkeeper
  //     .filter((obj) => obj.id === id)
  //     .map((object) => object.name);

  //   return name;
  // };

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      itemToDelete: null,
    });
  };

  toggleInputModal = () => {
    this.setState({
      isInputModalOpen: !this.state.isInputModalOpen,
    });
  };

  inputModal = (id, type) => () => {
    this.setState({ isInputModalOpen: true });
    if (type === "edit") {
      this.setState({ idToSend: id, isEditMode: true });
    } else {
      this.setState({ isEditMode: false });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } }, () =>
      setTimeout(
        () =>
          this.setState({
            messageBox: { display: false, type: "", message: "" },
          }),
        3000
      )
    );
  };

  setItemToDelete = (id) => () => {
    this.setState({ itemToDelete: id, isModalOpen: true });
  };

  deleteItem = async () => {
    try {
      const { status } = await axiosInstance.delete(
        `${URLS?.markets}/${this.state.itemToDelete}`
      );
      if (status === 200) {
        this.setState({ itemToDelete: null, isModalOpen: false }, () => {
          this.fetchAllMarkets();
        });
        this.setActionMessage(true, "Success", "Markets Deleted Successfully!");
      }
    } catch (err) {
      this.setActionMessage(true, "Error", "An error occurred while deleting.");
    }
  };

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, rowPerPage, markets } = this.state;
    if (navDirection === "prev") {
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    } else {
      if (currentPage < markets.length / rowPerPage)
        this.setState({ currentPage: currentPage + 1 });
    }
  };

  afterChangeRefresh = () => {
    this.fetchAllMarkets();
  };

  render() {
    var {
      markets,
      isLoading,
      isModalOpen,
      messageBox,
      isInputModalOpen,
      isEditMode,
      rowPerPage,
      currentPage,
    } = this.state;
    const pageNumbers = [];
    let currentPageRow = markets;

    if (markets?.length > 0) {
      const indexOfLastTodo = currentPage * rowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      currentPageRow = markets.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(markets.length / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }

    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={10}>
                  <h3 className="text-left">Markets</h3>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent
                    className="addButton admin-btn-green"
                    onClick={this.inputModal(null, "create")}
                    color="primary"
                    value="Add New"
                  />
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && markets.length === 0 && <p>No Data Available</p>}
              {!isLoading && markets.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      className="listTable api-provider-listTable"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>DID</TableCell>
                          <TableCell>Event</TableCell>
                          <TableCell>Is Race Event</TableCell>
                          <TableCell>Partipants</TableCell>
                          <TableCell>Team</TableCell>
                          <TableCell>Player</TableCell>
                          {/* <TableCell>bookKeeper</TableCell> */}
                          <TableCell>Market Name</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentPageRow?.map((marketsItem, i) => (
                          <TableRow key={i}>
                            <TableCell>{marketsItem.id}</TableCell>
                            <TableCell>
                              {this.getEvent(marketsItem.eventId)}
                            </TableCell>
                            <TableCell>
                              {marketsItem.isRaceEvent ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>
                              {this.getParticipant(marketsItem.partipantsId)}
                            </TableCell>
                            <TableCell>
                              {this.getTeams(marketsItem.teamId)}
                            </TableCell>
                            <TableCell>
                              {this.getPlayers(marketsItem.playerId)}
                            </TableCell>
                            {/* <TableCell>
                              {this.getBookkeeper(marketsItem.bookKeeperId)}
                            </TableCell> */}
                            <TableCell>{marketsItem.marketName}</TableCell>
                            <TableCell>
                              <EditIcon
                                onClick={this.inputModal(
                                  marketsItem.id,
                                  "edit"
                                )}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                              <DeleteOutlineIcon
                                onClick={this.setItemToDelete(marketsItem.id)}
                                color="secondary"
                                className="mr10 cursor iconBtn admin-btn-orange"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="tablePagination">
                    <button
                      className={
                        markets.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={markets.length / rowPerPage > 1 ? false : true}
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={markets.length / rowPerPage > 1 ? false : true}
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size="small"
                    />
                    <button
                      className={
                        markets.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={markets.length / rowPerPage > 1 ? false : true}
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </>
              )}
            </Paper>

            <ShowModal
              isModalOpen={isModalOpen}
              onClose={this.toggleModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.deleteItem}
              onCancel={this.toggleModal}
            />

            <Modal
              className="modal modal-input"
              open={isInputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {!isEditMode ? "Create New Market" : "Edit Market"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />
                <CreateMarketsUpdate
                  inputModal={this.toggleInputModal}
                  id={this.state.idToSend}
                  isEditMode={isEditMode}
                  allEvents={this.state.allEvents}
                  allParticipants={this.state.allParticipants}
                  allTeams={this.state.allTeams}
                  allPlayer={this.state.allPlayer}
                  // allBookkeeper={this.state.allBookkeeper}
                  fetchAllMarkets={this.afterChangeRefresh}
                />
              </div>
            </Modal>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default Markets;
