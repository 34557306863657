// const currentEnv = process.env.REACT_APP_ENV;
let be_host_url = "https://testing.smartb.com.au/api";
let be_wp_url = "https://mz2.smartb.com.au";

export const config = {
  baseUrl: process.env.REACT_APP_API_BASE_URL || be_host_url,
  WPurl: process.env.REACT_APP_WP_BASE_URL || be_wp_url,
  individualApiUrl: process.env.REACT_APP_API_Individual_URL,
  buildEnv: process.env.REACT_APP_BUILD_ENV,
};
