import { Validators } from "../../../library/utilities/Validators";

export const UsersFormModel = [
  {
    label: "First Name*",
    value: "",
    type: "text",
    placeholder: "First Name",
    field: "firstName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Last Name*",
    value: "",
    type: "text",
    placeholder: "Last Name",
    field: "lastName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Email/Username*",
    value: "",
    type: "text",
    placeholder: "Email",
    field: "username",
    validators: [
      { check: Validators.required },
      { check: Validators.email, message: "Provide valid email" },
    ],
    required: true,
    className: "6",
  },
  {
    label: "Password*",
    value: "",
    type: "password",
    placeholder: "Password",
    field: "password",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Role*",
    value: "",
    type: "dropdown",
    placeholder: "Role",
    field: "role",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      { id: 1, value: "member", label: "Member" },
      { id: 2, value: "admin", label: "Admin" },
    ],
  },
  // {
  //   label: "Otp Code",
  //   value: "",
  //   type: "text",
  //   placeholder: "Otp Code",
  //   field: "otpcode",
  //   validators: [
  //     { check: Validators.required, message: "This field is mandatory" },
  //   ],
  //   required: true,
  //   className: "12",
  // },
  {
    label: "Verify Status*",
    value: "",
    type: "dropdown",
    placeholder: "Verify Status",
    field: "isVarify",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      { id: 1, value: true, label: "Verified" },
      { id: 2, value: false, label: "Not Verified" },
    ],
  },
];
