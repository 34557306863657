import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";

import "./addMappingStyles.css";
import CategoryDropdown from "./CategoryDropdown";
import axiosInstance from "../../../helpers/Axios";
import { Loader } from "../../../library/common/components";

export default class AddMapping extends Component {
  state = {
    mcatId: null,
    msubcatId: null,
    afId: null,
    afcatId: null,
    afsubcatId: null,
    isLoading: false,
  };

  handleChange = (value, field) => {
    this.setState({
      [field]: value,
    });
  };

  fetchSmartbSubCategoryData = (id) => {
    const { mcategories } = this.props.data;
    let array = [];

    if (id) {
      const selectedCategory = mcategories.find(
        (category) => category.id === id
      );
      if (selectedCategory) {
        array = selectedCategory.subCats;
      }
    }

    return array;
  };

  fetchVendorCategoryData = (id) => {
    const { afs } = this.props.data;
    let array = [];

    if (id) {
      const selectedCategory = afs.find((afs) => afs.id === id);
      if (selectedCategory) {
        array = selectedCategory.categories;
      }
    }

    return array;
  };

  fetchVendorSubCategoryData = (afId, afCatId) => {
    const { afs } = this.props.data;
    let array = [];

    if (afId && afCatId) {
      const selectedCategory = afs.find((afs) => afs.id === afId);
      const category = selectedCategory.categories.find(
        (category) => category.id === afCatId
      );

      if (category) {
        array = category.subCats;
      }
    }

    return array;
  };

  saveMapping = async () => {
    try {
      const { mcatId, afId, afcatId } = this.state;

      this.setState({ isLoading: true });

      const body = { mcatId, afId, afcatId };
      const { status } = await axiosInstance.post("/af/map", [
        ...this.props.mappings,
        body,
      ]);
      if (status === 200) {
        this.setState({
          isLoading: false,
          mcatId: null,
          msubcatId: null,
          afId: null,
          afcatId: null,
          afsubcatId: null,
        });
        this.props.onSave(true);
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.props.onSave(false);
    }
  };

  render() {
    const { data } = this.props;
    const { mcatId, msubcatId, afId, afcatId, afsubcatId, isLoading } =
      this.state;

    return (
      <div className="add-mapping-container">
        <Grid container direction="row" alignItems="space-around" spacing={5}>
          <Grid item xs={3}>
            <CategoryDropdown
              field="mcatId"
              title="Select Smartb Category"
              value={mcatId}
              data={data.mcategories}
              onChange={this.handleChange}
            />
          </Grid>

          {/* {mcatId && (
            <Grid item xs={4}>
              <CategoryDropdown
                field="msubcatId"
                title="Select Smartb Sub Category"
                value={msubcatId}
                data={this.fetchSmartbSubCategoryData(mcatId)}
                onChange={this.handleChange}
              />
            </Grid>
          )} */}

          {mcatId && (
            <Grid item xs={3}>
              <CategoryDropdown
                field="afId"
                title="Select Vendor Network"
                value={afId}
                data={data.afs}
                onChange={this.handleChange}
              />
            </Grid>
          )}

          {afId && (
            <Grid item xs={3}>
              <CategoryDropdown
                field="afcatId"
                title="Select Vendor Category"
                value={afcatId}
                data={this.fetchVendorCategoryData(afId)}
                onChange={this.handleChange}
              />
            </Grid>
          )}

          {/* {afcatId && (
            <Grid item xs={4}>
              <CategoryDropdown
                field="afsubcatId"
                title="Select Vendor Sub Category"
                value={afsubcatId}
                data={this.fetchVendorSubCategoryData(afId, afcatId)}
                onChange={this.handleChange}
              />
            </Grid>
          )} */}

          {afcatId && (
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading}
                onClick={this.saveMapping}
              >
                {isLoading ? "Loading" : "Submit"}
              </Button>
              {isLoading && <Loader />}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}
