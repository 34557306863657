import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';

const ActionMessage = ({message, type, backRoute}) => {
    const displayStyle = () => {
        if (type === 'Error') {
            return 'error';
        }

        if (type === 'Success') {
            return 'success';
        }

        if (type === 'warning') {
            return 'warning';
        }
    };

    return (
        <div className='vp10'>
            <Alert severity={`${displayStyle()}`}>{message}</Alert>
        </div>
    );
};

ActionMessage.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
};

ActionMessage.defaultProps = {
    type: null,
    message: null,
};

export default ActionMessage;
