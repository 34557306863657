import React, { Component, createRef } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import ActionMessage from "../../../library/common/components/ActionMessage";
import ButtonComponent from "../../../library/common/components/Button";
import Form from "../../../library/common/components/Form";
import axiosInstance from "../../../helpers/Axios";
import { URLS } from "../../../library/common/constants";
import { categoryModel } from "./form-constant";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import FileUploader from "../../../library/common/components/FileUploader";
import { CATEGORY_MENU_STATUS } from "../../../helpers/constants";

export default class CreateCategory extends Component {
  formRef = createRef();

  state = {
    isLoading: false,
    messageBox: {
      display: false,
      type: "",
      message: "",
    },
    values: {
      name: "",
      description: "",
      sportType: "",
      status: CATEGORY_MENU_STATUS.ACTIVE
        ? CATEGORY_MENU_STATUS.ACTIVE
        : "active",
    },
    files: [],
  };

  componentDidMount() {
    if (this.props.match.url.includes("edit")) {
      this.setState({ isEditMode: true, values: this.props.location.state });
    }
  }

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  handleSave = async () => {
    const { isEditMode, files } = this.state;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.allCategory}/${this.props.match.params.id}`
        : URLS.allCategory;

      const values = removeErrorFieldsFromValues(form.formData);

      if (form.isFormValid) {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("description", values.description);
        formData.append("image", files.length ? files[0] : null);
        formData.append("status", values.status);
        formData.append("sportType", values.sportType);
        const { status } = await axiosInstance[method](url, formData, {
          header: { "Content-Type": "multipart/form-data" },
        });
        if (status === 200) {
          this.setState({ isLoading: false });
          this.setActionMessage(
            true,
            "Success",
            `Category ${isEditMode ? "Edited" : "Created"} Successfully`
          );
          this.back();
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${
          isEditMode ? "editing" : "creating"
        } category`
      );
    }
  };

  back = () => {
    this.props.history.push("/admin/category");
  };

  handleFileUpload = (files) => {
    this.setState({ files });
  };

  render() {
    const { messageBox, values, files, isLoading } = this.state;

    return (
      <Grid container className="page-content adminLogin">
        <Grid item xs={12}>
          <Paper className="pageWrapper">
            {messageBox.display && (
              <ActionMessage
                message={messageBox.message}
                type={messageBox.type}
                styleClass={messageBox.styleClass}
              />
            )}

            <Grid container direction="row" alignItems="space-around">
              <Grid item xs={10}>
                <h3 className="text-left">Create Category</h3>
              </Grid>
            </Grid>

            <Grid container className="text-left">
              <Grid xs={12}>
                <div className="logo-form">
                  <Form
                    model={categoryModel}
                    ref={this.formRef}
                    values={values}
                  />
                </div>
              </Grid>
            </Grid>

            <div className="blog-file-upload">
              <FileUploader onDrop={this.handleFileUpload} />
              {files.map((file, index) => (
                <img key={index} src={file.preview} alt="file" />
              ))}
            </div>

            <Grid container className="text-left">
              <Grid item xs={3}>
                <div style={{ marginTop: "20px" }}>
                  <ButtonComponent
                    className="mt-3 admin-btn-green"
                    onClick={this.handleSave}
                    color="primary"
                    disabled={isLoading}
                    value={!isLoading ? "Save" : "Loading..."}
                  />

                  <ButtonComponent
                    onClick={this.back}
                    className="mr-lr-30"
                    value="Back"
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
