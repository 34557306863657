import React, { useState } from "react";
// import Select from "react-select";
import PropTypes from "prop-types";

import { validateInput } from "../../../utilities/Validators";
import "./dropdownStyles.scss";

const Dropdown = ({
  value,
  field,
  label,
  testId,
  options,
  isMulti,
  validators,
  placeholder,
  isSearchable,
  isDisabled,
  onChange,
  closeMenuOnSelect,
  extraProps,
}) => {
  const [error, setError] = useState(null);

  // const handleChange = (selectedOption) => {
  //   const inputError = validateInput(validators, selectedOption);
  //   setError(inputError);
  //   onChange(selectedOption, field, inputError);
  // };

  const handleChange = (event) => {
    const { value } = event.target;
    const inputError = validateInput(validators, value);
    setError(inputError);
    onChange(value, field, inputError);
  };

  return (
    <div className="app-dropdown select-box">
      {label !== "" ? <label className="modal-label">{label}</label> : null}
      {/* <Select
        data-test={testId}
        value={value}
        options={options}
        isMulti={isMulti}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isSearchable={isSearchable}
        onChange={handleChange}
        closeMenuOnSelect={closeMenuOnSelect}
      /> */}
      <select
        className="dropdown select-box-arrow"
        onChange={handleChange}
        value={value}
        {...extraProps}
      >
        {options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.label}
          </option>
        ))}
      </select>
      {error && error.error && (
        <span className="text-danger">{error.message}</span>
      )}
    </div>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  options: PropTypes.array,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  validators: PropTypes.array,
  value: PropTypes.object,
  testId: PropTypes.string,
  field: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  closeMenuOnSelect: PropTypes.bool,
};

Dropdown.defaultProps = {
  isMulti: false,
  placeholder: "Please select",
  isSearchable: false,
  isDisabled: false,
  validators: [],
  testId: "",
  field: "",
  value: {},
  label: "",
  closeMenuOnSelect: true,
};
