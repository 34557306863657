import React from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Modal,
} from "@material-ui/core";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CancelIcon from "@material-ui/icons/Cancel";
import Pagination from "@material-ui/lab/Pagination";
import { ReactSVG } from "react-svg";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import arrowLeft from "../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../../images/blog_img/paginationArrowRight.svg";
import axiosInstance from "../../../helpers/Axios";
import { URLS } from "../../../library/common/constants";
import { Loader } from "../../../library/common/components";
import moment from "moment";
import ShowModal from "./../../../components/common/ShowModal/ShowModal";
import CreateLocation from "./CreateLocation";

class SportsPlace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeDetails: [],
      sportAll: [],
      leagueAll: [],
      locationAll: [],
      weatherAll: [],
      isLoading: false,
      isModalOpen: false,
      idToSend: null,
      // sortLabel: true,
      searchInput: "",
      currentPage: 1,
      rowPerPage: 20,
      offset: 0,
      sportCount: null,
      DeleteEventModal: false,
      itemToDelete: null,
      rowToPass: {},
      allState: [],
      allCountry: [],
      allCity: [],
      isEditMode: false,
      isDeleteLoading: "",
    };
  }

  componentDidMount() {
    this.fetchAllPlace();
    this.fetchAllLeagues();
    this.fetchAllLocation();
    this.fetchAllSport();
    this.fetchAllWeather();
    this.fetchAllState();
    this.fetchAllCountry();
    this.fetchAllCity();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match !== this.props.match) {
      this.fetchAllPlace();
    }
    if (prevState.offset !== this.state.offset) {
      this.fetchAllPlace();
    }
  }

  fetchAllCountry = async () => {
    const { status, data } = await axiosInstance.get(`/countries/country`);
    if (status === 200) {
      this.setState({ allCountry: data.result });
    }
  };

  fetchAllState = async () => {
    const { status, data } = await axiosInstance.get(`/countries/state`);
    if (status === 200) {
      this.setState({ allState: data.result });
    }
  };

  fetchAllCity = async () => {
    const { status, data } = await axiosInstance.get(`/countries/city`);
    if (status === 200) {
      this.setState({ allCity: data.result });
    }
  };

  toggleEventDeleteModal = () => {
    this.setState({
      DeleteEventModal: !this.state.DeleteEventModal,
      itemToDelete: null,
    });
  };

  fetchAllPlace = async () => {
    // let { id } = this.props.match.params
    // let { rowPerPage, offset, searchInput } = this.state
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(`/location`);
    if (status === 200) {
      let data_obj = data.result?.sort(function (a, b) {
        if (a.venueName.toLowerCase() < b.venueName.toLowerCase()) {
          return -1;
        }
        if (a.venueName.toLowerCase() > b.venueName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      this.setState({
        placeDetails: data_obj,
        isLoading: false,
        // sportCount: data.count,
      });
    }
  };

  fetchAllSport = async () => {
    const { status, data } = await axiosInstance.get(URLS.sports);
    if (status === 200) {
      this.setState({ sportAll: data.result });
    }
  };

  fetchAllLeagues = async () => {
    const { status, data } = await axiosInstance.get(URLS.league);
    if (status === 200) {
      this.setState({ leagueAll: data.result });
    }
  };

  fetchAllLocation = async () => {
    const { status, data } = await axiosInstance.get(URLS.location);
    if (status === 200) {
      this.setState({ locationAll: data.result });
    }
  };

  fetchAllWeather = async () => {
    const { status, data } = await axiosInstance.get(URLS.weather);
    if (status === 200) {
      this.setState({ weatherAll: data.result });
    }
  };

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  inputModal = (id) => {
    this.setState({ isModalOpen: true, idToSend: id });
  };

  handlePaginationClick = (event, page) => {
    let {
      rowPerPage,
      // offset
    } = this.state;
    this.setState({
      currentPage: Number(page),
      offset: (Number(page) - 1) * rowPerPage,
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let {
      currentPage,
      // rowPerPage,
      offset,
    } = this.state;
    // if (navDirection === "prev") {
    //   if (currentPage > 1) {
    //     this.setState({ currentPage: currentPage - 1 });
    //   }
    // } else {
    //   if (currentPage < placeDetails.length / rowPerPage)
    //     this.setState({ currentPage: currentPage + 1 });
    // }

    if (navDirection === "prev") {
      if (offset >= 20) {
        this.setState({ offset: offset - 20, currentPage: currentPage - 1 });
      }
    } else {
      this.setState({ offset: offset + 20, currentPage: currentPage + 1 });
    }
  };

  afterChangeRefresh = () => {
    this.fetchAllPlace();
  };

  setItemToDelete = (id) => () => {
    this.setState({ DeleteEventModal: true, itemToDelete: id });
  };

  handlePlaceDelete = async (id) => {
    this.setState({
      isDeleteLoading: "placeDelete",
      DeleteEventModal: false,
    });
    try {
      const { status } = await axiosInstance.delete(
        `location/${this.state.itemToDelete}`
      );
      if (status === 200) {
        this.setState({
          isDeleteLoading: "",
          itemToDelete: null,
        });
        this.afterChangeRefresh();
      }
    } catch (err) {}
  };

  sortLabelHandler = () => {
    this.setState({ sortLabel: !this.state.sortLabel });
  };

  render() {
    let { match } = this.props;
    let {
      placeDetails,
      isLoading,
      rowPerPage,
      isModalOpen,
      currentPage,
      idToSend,
      sortLabel,
      searchInput,
      offset,
      sportCount,
      DeleteEventModal,
      rowToPass,
      allCountry,
      allState,
      allCity,
      isEditMode,
      isDeleteLoading,
    } = this.state;
    const pageNumbers = [];

    // searchInput !== '' &&
    //   (placeDetails = placeDetails?.filter(
    //     (obj) =>
    //       obj?.eventName
    //         ?.toString()
    //         .toLowerCase()
    //         .includes(searchInput.toString().toLowerCase()) ||
    //       obj?.location?.venueName
    //         ?.toString()
    //         .toLowerCase()
    //         .includes(searchInput.toString().toLowerCase()),
    //   ))

    // let currentPageRow = placeDetails;

    if (sportCount > 0) {
      // const indexOfLastTodo = currentPage * rowPerPage;
      // const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      // currentPageRow = placeDetails.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(sportCount / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }

    if (sortLabel !== undefined) {
      placeDetails = placeDetails?.sort((a, b) => {
        var key1 = a.createdAt;
        var key2 = b.createdAt;
        if (sortLabel ? key1 < key2 : key1 > key2) {
          return -1;
        } else if (key1 === key2) {
          return 0;
        } else {
          return 1;
        }
      });
    }
    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              {isDeleteLoading === "placeDelete" && (
                <div class="admin-delete-loader">
                  <Loader />
                </div>
              )}
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ paddingBottom: 15 }}
              >
                <Grid item xs={7}>
                  <div style={{ display: "flex" }}>
                    <Typography variant="h6" style={{ margin: 0 }}>
                      {match.params.placename}
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <TextField
                    variant="outlined"
                    color="primary"
                    size="small"
                    placeholder="search"
                    value={searchInput}
                    onChange={(e) =>
                      this.setState({
                        ...this.state.searchInput,
                        searchInput: e.target.value,
                      })
                    }
                  />
                  &nbsp;
                  <div>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#00833e", color: "#fff" }}
                      onClick={() => {
                        this.fetchAllPlace();
                      }}
                    >
                      Search
                    </Button>
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <div>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#00833e", color: "#fff" }}
                      onClick={() => {
                        this.inputModal();
                        this.setState({
                          isEditMode: false,
                        });
                      }}
                    >
                      Add Stadium
                    </Button>
                  </div>
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && placeDetails.length === 0 && (
                <p>No Data Available</p>
              )}
              {!isLoading && placeDetails.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      className="listTable api-provider-listTable"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>DID</TableCell>
                          <TableCell>Location Name</TableCell>
                          <TableCell>Location Type</TableCell>
                          <TableCell>City</TableCell>
                          <TableCell>State</TableCell>
                          <TableCell>Country</TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={true}
                              onClick={this.sortLabelHandler}
                              direction={sortLabel ? "asc" : "desc"}
                            />
                            Create Date
                          </TableCell>
                          <TableCell>Edit</TableCell>
                          <TableCell>Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {placeDetails?.map((location, i) => (
                          <TableRow key={i}>
                            <TableCell>{location?.id}</TableCell>
                            <TableCell>{location?.venueName}</TableCell>
                            <TableCell>{location?.locationType}</TableCell>
                            <TableCell>{location?.cities?.cityName}</TableCell>
                            <TableCell>{location?.state?.state}</TableCell>
                            <TableCell>{location?.country?.country}</TableCell>
                            <TableCell>
                              {location?.createdAt !== null &&
                                moment(location?.createdAt).format(
                                  "DD/MM/YYYY hh:mm:ss a"
                                )}
                            </TableCell>
                            <TableCell>
                              <EditIcon
                                onClick={() => {
                                  this.inputModal(location?.id);
                                  this.setState({
                                    rowToPass: location,
                                    isEditMode: true,
                                  });
                                }}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                            </TableCell>
                            <TableCell>
                              <DeleteOutlineIcon
                                onClick={this.setItemToDelete(location?.id)}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-orange"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="tablePagination">
                    <button
                      className={
                        offset >= 20
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={offset >= 20 ? false : true}
                      // disabled={
                      //   meetingsDetails.length / rowPerPage > 1 ? false : true
                      // }
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={sportCount > 0 ? false : true}
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size="small"
                    />
                    <button
                      className={
                        rowPerPage + offset < sportCount
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={rowPerPage + offset < sportCount ? false : true}
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </>
              )}
            </Paper>

            <ShowModal
              isModalOpen={DeleteEventModal}
              onClose={this.toggleEventDeleteModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.handlePlaceDelete}
              onCancel={this.toggleEventDeleteModal}
            />

            <Modal
              className="modal modal-input"
              open={isModalOpen}
              onClose={this.toggleModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {isEditMode ? "Edit Stadium" : "Add Stadium"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleModal}
                />

                <CreateLocation
                  inputModal={this.toggleModal}
                  id={idToSend}
                  isEditMode={isEditMode}
                  fetchAllEvents={this.afterChangeRefresh}
                  rowToPass={rowToPass}
                  allCountry={allCountry}
                  allState={allState}
                  allCity={allCity}
                />
              </div>
            </Modal>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default SportsPlace;
