import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { animalFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";

let animalFormModelArray = animalFormModel;

class CreateAnimal extends React.Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        variation: "",
        runnerNumber: "",
        age: "",
        genderId: "",
        colourId: "",
        silkColour: "",
      },
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentAnimal(this.props.id);
    }
    const { allGender, allColour } = this.props;
    this.setState((prevState) => {
      return {
        values: {
          ...prevState.values,
          genderId: allGender?.length > 0 ? allGender[0].id : "",
          colourId: allColour?.length > 0 ? allColour[0].id : "",
        },
      };
    });
    animalFormModelArray = animalFormModelArray?.map((fieldItem) => {
      if (fieldItem?.field === "genderId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...allGender?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "colourId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...allColour?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      }
      return fieldItem;
    });
  }

  componentWillUnmount() {
    animalFormModelArray = animalFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  fetchCurrentAnimal = async (id) => {
    const { status, data } = await axiosInstance.get(URLS.animal + `/${id}`);
    if (status === 200) {
      this.setState({ values: data.result });
    }
  };

  validate = () => {
    let { name, variation, runnerNumber, age } = this.state.values;
    let flag = true;

    if (name === "" || variation === "" || runnerNumber === "" || age === "") {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }

    return flag;
  };

  handleSave = async () => {
    const { isEditMode, animalTypeId } = this.props;
    this.setState({ isLoading: true });

    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS?.animal}/${this.props.id}`
        : `${URLS?.animal}`;

      const values = removeErrorFieldsFromValues(form.formData);
      values["animalTypeId"] = animalTypeId;

      animalFormModelArray = animalFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllAniaml();
          this.setActionMessage(
            true,
            "Success",
            `Animal ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${isEditMode ? "editing" : "creating"} Animal`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    animalFormModelArray = animalFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  render() {
    var { values, messageBox, isLoading } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={animalFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateAnimal;
