import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { statsFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";

let statsFormModelArray = statsFormModel;

class CreateStats extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        referenceType: "1",
        referenceId: "",
        statkeyId: "",
        isReference: "0",
        tableName: "",
        value: "",
        valueType: "1",
      },
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentStats(this.props.id);
    }
    const { allStatsKeys } = this.props;
    this.setState((prevState) => {
      return {
        values: {
          ...prevState.values,
          statkeyId: allStatsKeys?.length > 0 ? allStatsKeys[0].id : "",
        },
      };
    });
    statsFormModelArray = statsFormModelArray?.map((fieldItem) => {
      if (fieldItem?.field === "statkeyId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...allStatsKeys?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.name,
              };
            }),
          ],
        };
      }
      return fieldItem;
    });
  }

  componentWillUnmount() {
    statsFormModelArray = statsFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchCurrentStats = async (id) => {
    const { status, data } = await axiosInstance.get(URLS.stats + `/${id}`);
    if (status === 200) {
      this.setState({
        values: data.result,
      });
      this.setState((prevState) => {
        return {
          values: {
            ...this.state.values,
            isReference: data.result.isReference ? "1" : "0",
          },
        };
      });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let {
      referenceType,
      referenceId,
      statkeyId,
      isReference,
      tableName,
      value,
      valueType,
    } = this.state.values;
    let flag = true;

    if (
      referenceType === "" ||
      referenceId === "" ||
      statkeyId === "" ||
      isReference === "" ||
      tableName === "" ||
      value === "" ||
      valueType === ""
    ) {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }

    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode ? `${URLS.stats}/${this.props.id}` : URLS.stats;

      const values = removeErrorFieldsFromValues(form.formData);
      statsFormModelArray = statsFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllStats();
          this.setActionMessage(
            true,
            "Success",
            `Stats ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occurred while ${isEditMode ? "editing" : "creating"} Stats`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    statsFormModelArray = statsFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  render() {
    var { values, messageBox, isLoading } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={statsFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateStats;
