import { Validators } from "../../../library/utilities/Validators";

export const locationFormModel = [
  {
    label: "Location*",
    value: "",
    type: "text",
    placeholder: "Location",
    field: "venueName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
];
