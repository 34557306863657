import React, { Component, useEffect, useState } from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Button,
  Box,
  Typography,
  makeStyles,
  TextField,
  Modal,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Pagination from "@material-ui/lab/Pagination";
import axiosInstance from "./../../../helpers/Axios";
import { ReactSVG } from "react-svg";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ClearIcon from "@material-ui/icons/Clear";
import ButtonComponent from "../../../library/common/components/Button";
import arrowLeft from "./../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "./../../../images/blog_img/paginationArrowRight.svg";
import ShowModal from "./../../../components/common/ShowModal/ShowModal";
import { URLS } from "../../../library/common/constants";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Loader } from "../../../library/common/components";
import CreatePlayers from "../../players/CreatePlayers";
import "./playermerge.scss";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export const RacingMergePlayer = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInputModalOpen, setIsInputModalOpen] = useState(false);
  const [isPlayerModalOpen, setisPlayerModalOpen] = useState(false);
  const [idToSend, setIdToSend] = useState(null);
  const [data, setData] = useState([]);
  const [allPlayersType, setAllPlayersType] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [selectedSport, setselectedSport] = useState("");
  const [playetTypeList, setplayetTypeList] = useState([]);
  const [sportList, setsportList] = useState([]);
  const [selectedPlayerType, setselectedPlayerType] = useState("");
  const [pageNumbers] = useState([]);
  const [sportCount, setSportCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMergeModalOpen, setisMergeModalOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState("");
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isSelected, setisSelected] = useState(false);
  const [selectedPlayerList, setselectedPlayerList] = useState([]);
  const [mergeSelectedPlayer, setmergeSelectedPlayer] = useState([]);
  const [checked, setchecked] = useState([]);

  useEffect(() => {
    getSports();
    setSearch("");
  }, []);

  useEffect(() => {
    if (selectedPlayerType) {
      getJockey(selectedPlayerType);
    }
  }, [offset]);

  useEffect(() => {
    if (selectedPlayerList.length === 0) {
      setisPlayerModalOpen(false);
    }
  }, [selectedPlayerList]);

  // Pagination
  const handlePaginationButtonClick = (navDirection) => {
    if (navDirection === "prev") {
      if (offset >= 20) {
        setOffset(offset - 20);
        setCurrentPage(currentPage - 1);
      }
    } else {
      setOffset(offset + 20);
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePaginationClick = (event, page) => {
    setCurrentPage(Number(page));
    setOffset((Number(page) - 1) * rowPerPage);
  };

  if (sportCount > 0) {
    for (let i = 1; i <= Math.ceil(sportCount / rowPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  // Get Sport List
  const getSports = async () => {
    try {
      const { status, data } = await axiosInstance.get("/sports/sport");
      if (status === 200) {
        setsportList(
          data.result.filter(
            (x) => x.sportName !== null
            // && x.sportTypeId === 1
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Get PlayerType List
  const getPlayertype = async (sportId) => {
    try {
      const { status, data } = await axiosInstance.get("teams/playerType");
      if (status === 200) {
        let playerdata = data?.result?.filter((x) => x.sportId == sportId);
        setplayetTypeList(playerdata);
      }
    } catch (err) {}
  };

  // Get Player Liste As per PlayerType Id
  const getJockey = async (id) => {
    setIsLoading(true);
    let url =
      URLS.getJockeys +
      `${id}?limit=${rowPerPage}&offset=${offset}&matchString=${search}`;

    try {
      const { status, data } = await axiosInstance.get(url);
      setData(data?.result);
      setIsLoading(false);
      setSportCount(data.count);
    } catch (err) {
      setIsLoading(false);
    }
  };

  // Delete Player
  const handlePlayersDelete = async () => {
    setIsDeleteLoading("Player Delete");
    setIsDeleteModalOpen(false);
    try {
      const { status } = await axiosInstance.delete(
        `teams/player/${itemToDelete}`
      );
      if (status === 200) {
        afterChangeRefresh();
        setItemToDelete(null);
        setIsDeleteLoading("");
      }
    } catch (err) {}
  };

  // Post MergePlayer
  const mergePlayer = async () => {
    setisMergeModalOpen(false);
    setIsLoading(true);
    let playerid = mergeSelectedPlayer?.map((x) => x.id);
    let player = playerid[0];
    const mergePlayerid = selectedPlayerList.filter((item, idx) => {
      return playerid.some((e) => item.id !== e);
    });

    let newmergePlayer = mergePlayerid?.map((x) => x.id);
    let url =
      selectedSport == "1" || selectedSport == "2" || selectedSport == "3"
        ? "events/mergePlayer"
        : "events/other/mergePlayer";
    let payload = {
      player: player,
      mergePlayer: newmergePlayer,
    };
    try {
      const { status, data } = await axiosInstance.post(url, payload);
      if (status === 200) {
        setIsLoading(false);
        setSearch("");
        setisPlayerModalOpen(!isPlayerModalOpen);
        setmergeSelectedPlayer([]);
        setselectedPlayerList([]);
        setisSelected(false);
        setIsLoading(true);
        let url =
          URLS.getJockeys +
          `${selectedPlayerType}?limit=${rowPerPage}&offset=${offset}&matchString=`;

        try {
          const { status, data } = await axiosInstance.get(url);
          setData(data?.result);
          setIsLoading(false);
          setSportCount(data.count);
        } catch (err) {
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  // edit  Delete player
  const afterChangeRefresh = () => {
    getJockey(selectedPlayerType);
  };
  const toggleInputModal = () => {
    setIsInputModalOpen(!isInputModalOpen);
  };

  const inputModal = (id, type) => {
    setIsInputModalOpen(true);
    setIdToSend(id);
    setIsEditMode(type);
  };

  const setItemDelete = (id) => {
    setItemToDelete(id);
    setIsDeleteModalOpen(true);
  };

  // Merge Popup
  const togglePlayerModal = () => {
    setisPlayerModalOpen(!isPlayerModalOpen);
    setmergeSelectedPlayer([]);
  };

  const playerModal = () => {
    setisPlayerModalOpen(true);
  };

  // delete Confirmation
  const toggleDeleteModal = () => {
    setItemToDelete(null);
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  // Player checkbox Select
  // const selecPlayerHandler = (e, item) => {
  //   const flag = e.target.checked;

  //   if (flag) {
  //     // add
  //     const newItem = [...selectedPlayerList, item];
  //     console.log(" newItem", newItem);
  //     setselectedPlayerList(newItem);
  //   } else {
  //     const removeItem = selectedPlayerList.filter((eItem) => eItem !== item);
  //     setselectedPlayerList(removeItem);
  //     console.log("removeItem", removeItem, item);
  //   }
  // };

  // Player checkbox Select
  const selectLogHandler = (value, item) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked.filter((x) => x !== 0)];

    if (currentIndex === -1) {
      newChecked.push(value);
      const newItem = [...selectedPlayerList, item];
      setselectedPlayerList(newItem);
    } else {
      newChecked.splice(currentIndex, 1);
      const removeItem = selectedPlayerList.filter(
        (eItem) => eItem.id !== item.id
      );
      setselectedPlayerList(removeItem);
    }
    setchecked(newChecked);
  };

  // Merge Player popup checkbox select
  const selecMergePlayer = (e, item) => {
    const flag = e.target.checked;
    if (flag) {
      const newItem = [item];
      setmergeSelectedPlayer(newItem);
    } else {
      const removeItem = mergeSelectedPlayer.filter((eItem) => eItem !== item);
      setmergeSelectedPlayer(removeItem);
    }
  };

  const mergeModel = () => {
    setisMergeModalOpen(true);
  };
  const toggleMergeModal = () => {
    setisMergeModalOpen(false);
  };

  // Remove Merge Player From Merge Player Popup
  const removePlayer = (item) => {
    const removeItem = selectedPlayerList.filter(
      (eItem) => eItem?.id !== item?.id
    );
    const currentIndex = checked.indexOf(item?.id);
    const newChecked = [...checked.filter((x) => x !== 0)];
    newChecked.splice(currentIndex, 1);
    setchecked(newChecked);
    setselectedPlayerList(removeItem);
    setmergeSelectedPlayer([]);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {isDeleteLoading === "jockeyDelete" && (
          <div class="admin-delete-loader">
            <Loader />
          </div>
        )}
        <Paper className="pageWrapper">
          <Grid container alignItems="center" spacing={2} direction="row">
            <Grid item xs={5} sm={5} md={5} lg={5}>
              <h3 className="text-left">Sport Player Merge</h3>
            </Grid>
            <Grid item xs={2}>
              <select
                name="sportName"
                style={{
                  fontSize: "16px",
                  borderRadius: "3px",
                  minHeight: "44px",
                  border: "1px solid #ddd",
                  paddingLeft: "10px",
                  width: "100%",
                  marginRight: "15px",
                }}
                value={selectedSport}
                onChange={(e) => {
                  setselectedPlayerList([]);
                  setselectedSport(e.target.value);
                  getPlayertype(e.target.value);
                }}
              >
                <option value="">Select Sport</option>
                {sportList?.map((x) => {
                  return <option value={x.id}>{x.sportName}</option>;
                })}
              </select>
            </Grid>
            <Grid item xs={2}>
              <select
                name="PlayerType"
                style={{
                  fontSize: "16px",
                  borderRadius: "3px",
                  minHeight: "44px",
                  border: "1px solid #ddd",
                  paddingLeft: "10px",
                  width: "100%",
                  marginRight: "15px",
                }}
                value={selectedPlayerType}
                onChange={(e) => {
                  setselectedPlayerList([]);
                  setchecked([]);
                  setselectedPlayerType(e.target.value);
                  getJockey(e.target.value);
                }}
              >
                <option value="">Select Player Type </option>
                {playetTypeList?.map((x) => {
                  return <option value={x.id}>{x.name}</option>;
                })}
              </select>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              style={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                className={classes.margin}
                label="Search"
                size="small"
                variant="outlined"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <Box pl={1}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ background: "#00833e" }}
                  onClick={() => {
                    getJockey(selectedPlayerType);
                    // setselectedPlayerList([]);
                    {
                      search !== ""
                        ? setisSelected(true)
                        : setisSelected(false);
                    }
                  }}
                >
                  search
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="end" mb={1}>
                <Button
                  variant="contained"
                  onClick={playerModal}
                  disabled={selectedPlayerList?.length > 1 ? false : true}
                  style={{ textTransform: "capitalize", marginLeft: 15 }}
                >
                  Merge Selected Players
                </Button>
              </Box>
            </Grid>
          </Grid>
          {isLoading && <Loader />}
          {!isLoading && data.length === 0 && <p>No Data Available</p>}
          {!isLoading && data.length > 0 && (
            <TableContainer component={Paper}>
              <Table className="listTable" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* {isSelected ? <TableCell></TableCell> : ""} */}
                    <TableCell></TableCell>
                    <TableCell>DID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Weight</TableCell>
                    <TableCell>Variation</TableCell>
                    <TableCell align="right">Edit</TableCell>
                    <TableCell align="right">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {data
                    .filter((val) => {
                      if (search === "" || search === null) return val;
                      else if (
                        val?.name
                          .toString()
                          .toLowerCase()
                          .includes(search.toString().toLowerCase())
                      ) {
                        return val;
                      }
                    })
                    .slice(
                      currentPage * rowPerPage - rowPerPage,
                      currentPage * rowPerPage
                    ) */}
                  {data.map((row, index) => {
                    return (
                      <>
                        <TableRow key={row?.id}>
                          {/* {isSelected ? ( */}
                          <TableCell>
                            {" "}
                            <Checkbox
                              className="mz-checkbox"
                              checked={checked.indexOf(row.id) !== -1}
                              onChange={(e) => selectLogHandler(row?.id, row)}
                            />
                          </TableCell>
                          {/* ) : (
                        ""
                      )} */}

                          <TableCell>{row?.id}</TableCell>
                          <TableCell>{row?.name}</TableCell>
                          <TableCell>{row?.weight}</TableCell>
                          <TableCell>{row?.variation}</TableCell>
                          <TableCell align="right">
                            <EditIcon
                              onClick={() => {
                                inputModal(row?.id);
                              }}
                              color="primary"
                              className="mr10 cursor iconBtn admin-btn-green"
                            />
                          </TableCell>
                          <TableCell align="right">
                            <DeleteOutlinedIcon
                              onClick={() => {
                                setItemDelete(row?.id);
                              }}
                              color="secondary"
                              className="cursor iconBtn admin-btn-orange"
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
              {data?.length > 0 && (
                <div className="tablePagination trainers-pagination">
                  <button
                    className={
                      data.length / rowPerPage > 1
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={data.length / rowPerPage > 1 ? false : true}
                    onClick={() => handlePaginationButtonClick("prev")}
                  >
                    <ReactSVG src={arrowLeft} />
                  </button>
                  <Pagination
                    hideNextButton
                    hidePrevButton
                    disabled={sportCount > 0 ? false : true}
                    page={currentPage}
                    onChange={handlePaginationClick}
                    count={pageNumbers[pageNumbers?.length - 1]}
                    siblingCount={2}
                    boundaryCount={1}
                    size="small"
                  />
                  <button
                    className={
                      rowPerPage + offset < sportCount
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={rowPerPage + offset < sportCount ? false : true}
                    onClick={() => handlePaginationButtonClick("next")}
                  >
                    <ReactSVG src={arrowRight} />
                  </button>
                </div>
              )}
            </TableContainer>
          )}
        </Paper>
        <Modal
          className="modal modal-input"
          open={isInputModalOpen}
          onClose={toggleInputModal}
        >
          <div
            className={"paper modal-show-scroll"}
            style={{ position: "relative" }}
          >
            <h3 className="text-center">{"Edit Trainers"}</h3>
            <CancelIcon
              className="admin-close-icon"
              onClick={toggleInputModal}
            />
            <CreatePlayers
              inputModal={toggleInputModal}
              id={idToSend}
              isEditMode={true}
              fetchAllPlayers={afterChangeRefresh}
              allPlayersType={allPlayersType}
            />
          </div>
        </Modal>
        <ShowModal
          isModalOpen={isDeleteModalOpen}
          onClose={toggleDeleteModal}
          Content="Are you sure you want to delete?"
          onOkayLabel="Yes"
          onOkay={handlePlayersDelete}
          onCancel={toggleDeleteModal}
        />
        <ShowModal
          isModalOpen={isMergeModalOpen}
          onClose={toggleMergeModal}
          Content="Are you sure you want to merge that this operation can not be reverse"
          onOkayLabel="Yes"
          onOkay={mergePlayer}
          onCancel={toggleMergeModal}
        />
        <Modal
          className="modal modal-input"
          open={isPlayerModalOpen}
          onClose={togglePlayerModal}
        >
          <div
            className={"paper modal-show-scroll"}
            style={{ position: "relative" }}
          >
            <h3 className="text-center">{"Merge Player"}</h3>
            <CancelIcon
              className="admin-close-icon"
              onClick={togglePlayerModal}
            />
            <Box>
              <TableContainer component={Paper}>
                <Table className="listTable" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Select Target</TableCell>
                      <TableCell>Player Id</TableCell>
                      <TableCell>Player Name</TableCell>
                      <TableCell>Remove</TableCell>
                    </TableRow>
                  </TableHead>
                  {selectedPlayerList?.map((row) => {
                    return (
                      <TableBody>
                        <TableCell>
                          <Checkbox
                            className="mz-checkbox"
                            checked={mergeSelectedPlayer.includes(row)}
                            onChange={(e) => selecMergePlayer(e, row)}
                          />
                        </TableCell>
                        <TableCell>{row?.id}</TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>
                          <ClearIcon
                            onClick={() => removePlayer(row)}
                            color="secondary"
                            className="cursor iconBtn admin-btn-orange"
                          />
                        </TableCell>
                      </TableBody>
                    );
                  })}
                </Table>
              </TableContainer>
              <Box display="flex" justifyContent="end" mb={1} mt={1}>
                <ButtonComponent
                  className="mt-3 admin-btn-green mr"
                  onClick={mergeModel}
                  color="primary"
                  value={!isLoading ? "Merge" : "Merging..."}
                  disabled={
                    selectedPlayerList.length > 1
                      ? mergeSelectedPlayer.length < 1
                        ? true
                        : false
                      : true
                  }
                />
                {""}
                <ButtonComponent
                  className="mt-3 admin-btn-orange"
                  onClick={() => {
                    setisPlayerModalOpen(!isPlayerModalOpen);
                    setmergeSelectedPlayer([]);
                  }}
                  color="secondary"
                  value={"Cancle"}
                  disabled={isLoading}
                />
              </Box>
            </Box>
          </div>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default RacingMergePlayer;
