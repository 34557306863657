import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import RateReviewIcon from "@material-ui/icons/RateReview";
import ButtonComponent from "../../library/common/components/Button";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import ActionMessage from "../../library/common/components/ActionMessage";
import { Loader } from "../../library/common/components";
import ShowModal from "../../components/common/ShowModal/ShowModal";
import CreateFixtures from "./CreateFixtures";

class Fixtures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixtures: [],
      isInputModalOpen: false,
      idToSend: null,
      isModalOpen: false,
      isLoading: false,
      itemToDelete: null,
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
    };
  }

  componentDidMount() {
    this.setState({ fixtures: this.Fixtures });
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      itemToDelete: null,
    });
  };

  toggleInputModal = () => {
    this.setState({
      isInputModalOpen: !this.state.isInputModalOpen,
    });
  };

  inputModal = (id, type) => () => {
    this.setState({ isInputModalOpen: true });
    if (type === "edit") {
      this.setState({ idToSend: id, isEditMode: true });
    } else {
      this.setState({ isEditMode: false });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } }, () =>
      setTimeout(
        () =>
          this.setState({
            messageBox: { display: false, type: "", message: "" },
          }),
        3000
      )
    );
  };

  setItemToDelete = (id) => () => {
    this.setState({ itemToDelete: id, isModalOpen: true });
  };

  Fixtures = [
    {
      id: 1,
      sportId: 1,
      eventName: "event",
      description: "description",
      categoryId: 1,
      location: 2,
      eventDate: "2021-01-14",
      eventToDate: "2021-01-18",
      comment: "comment",
      weather: "weather",
    },
    {
      id: 2,
      sportId: 1,
      eventName: "event",
      description: "description",
      categoryId: 1,
      location: 2,
      eventDate: "2021-01-14",
      eventToDate: "2021-01-18",
      comment: "comment",
      weather: "weather",
    },
    {
      id: 3,
      sportId: 1,
      eventName: "event",
      description: "description",
      categoryId: 1,
      location: 2,
      eventDate: "2021-01-14",
      eventToDate: "2021-01-18",
      comment: "comment",
      weather: "weather",
    },
    {
      id: 4,
      sportId: 1,
      eventName: "event",
      description: "description",
      categoryId: 1,
      location: 2,
      eventDate: "2021-01-14",
      eventToDate: "2021-01-18",
      comment: "comment",
      weather: "weather",
    },
    {
      id: 5,
      sportId: 1,
      eventName: "event",
      description: "description",
      categoryId: 1,
      location: 2,
      eventDate: "2021-01-14",
      eventToDate: "2021-01-18",
      comment: "comment",
      weather: "weather",
    },
    {
      id: 6,
      sportId: 1,
      eventName: "event",
      description: "description",
      categoryId: 1,
      location: 2,
      eventDate: "2021-01-14",
      eventToDate: "2021-01-18",
      comment: "comment",
      weather: "weather",
    },
  ];
  render() {
    var {
      fixtures,
      isLoading,
      isModalOpen,
      messageBox,
      isInputModalOpen,
      isEditMode,
    } = this.state;
    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={10}>
                  <h3 className="text-left">Fixtures</h3>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent
                    className="addButton admin-btn-green"
                    onClick={this.inputModal(null, "create")}
                    color="primary"
                    value="Add New"
                  />
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && fixtures.length === 0 && <p>No Data Available</p>}
              {!isLoading && fixtures.length > 0 && (
                <TableContainer component={Paper}>
                  <Table
                    className="listTable api-provider-listTable"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>DID</TableCell>
                        <TableCell>Sport</TableCell>
                        <TableCell>Event Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Event date</TableCell>
                        <TableCell> date</TableCell>
                        <TableCell>comment</TableCell>
                        <TableCell>Weather</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fixtures?.map((fixture, i) => (
                        <TableRow key={i}>
                          <TableCell>{fixture.id}</TableCell>
                          <TableCell>{fixture.sportId}</TableCell>
                          <TableCell>{fixture.eventName}</TableCell>
                          <TableCell>{fixture.description}</TableCell>
                          <TableCell>{fixture.categoryId}</TableCell>
                          <TableCell>{fixture.location}</TableCell>
                          <TableCell>{fixture.eventDate}</TableCell>
                          <TableCell>{fixture.eventToDate}</TableCell>
                          <TableCell>{fixture.comment}</TableCell>
                          <TableCell>{fixture.weather}</TableCell>
                          <TableCell>
                            <EditIcon
                              onClick={this.inputModal(fixture.id, "edit")}
                              color="primary"
                              className="mr10 cursor iconBtn admin-btn-green"
                            />
                            <DeleteOutlineIcon
                              onClick={this.setItemToDelete(fixture.id)}
                              color="secondary"
                              className="mr10 cursor iconBtn admin-btn-orange"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>

            <ShowModal
              isModalOpen={isModalOpen}
              onClose={this.toggleModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.toggleModal}
              onCancel={this.toggleModal}
            />

            <Modal
              className="modal modal-input"
              open={isInputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div className={"paper"}>
                <CreateFixtures
                  inputModal={this.toggleInputModal}
                  id={this.state.idToSend}
                  isEditMode={isEditMode}
                />
              </div>
            </Modal>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default Fixtures;
