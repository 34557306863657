import React from "react";
import {
  Button,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
} from "@material-ui/core";
import { Loader } from "./../../../library/common/components";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "./../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "./../../../images/blog_img/paginationArrowRight.svg";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
import ButtonComponent from "./../../../library/common/components/Button";
import { ReactSVG } from "react-svg";
import { URLS } from "./../../../library/common/constants";
import axiosInstance from "./../../../helpers/Axios";
import moment from "moment";
// import RateReviewIcon from "@material-ui/icons/RateReview";
// import { getFormetedDate } from "./../../../helpers/common";
import EditIcon from "@material-ui/icons/Edit";
import "./sportDetails.scss";
import { raceIcon } from "./../../../helpers/common";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// import CreateEvents from "./../../events/CreateEvents/index";
import CreateApiRaceIdentifire from "./../../apiRaceIdentifire/CreateApiRaceIdentifire";
import CreateRaceTable from "../../raceTable/CreateRaceTable";

class SportsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
      selectedToDate: new Date(),
      sportsDetails: [],
      identifireDetails: [],
      isLoading: false,
      searchInput: "",
      currentPage: 1,
      rowPerPage: 20,
      offset: 0,
      sportCount: null,
      inputModalOpen: false,
      identifireModalOpen: false,
      isEditMode: false,
      idToSend: "",
      raceIdToSend: null,
      sportAll: [],
      // locationAll: [],
      weatherAll: [],
      allRaces: [],
      allProvider: [],
      events: [],
      distanceAll: [],
      selectedEvent: "",
    };
  }

  componentDidMount() {
    this.onSubmit();
    this.fetchAllEvents();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match !== this.props.match) {
      this.onSubmit();
    }
    if (prevState.offset !== this.state.offset) {
      this.onSubmit();
    }
  }

  async fetchAllEvents() {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(URLS.events);
    if (status === 200) {
      this.setState({ events: data.result, isLoading: false });
      this.fetchAllSport();
      // this.fetchAllLocation();
      this.fetchAllWeather();
      this.fetchAllRaces();
      this.fetchAllProvider();
      this.fetchAllDistance();
    }
  }

  fetchAllDistance = async () => {
    const { status, data } = await axiosInstance.get(URLS.distance);
    if (status === 200) {
      this.setState({ distanceAll: data.result });
    }
  };

  fetchAllSport = async () => {
    const { status, data } = await axiosInstance.get(URLS.sports);
    if (status === 200) {
      this.setState({ sportAll: data.result });
    }
  };

  // fetchAllLocation = async (id) => {
  //   const { status, data } = await axiosInstance.get(URLS.location);
  //   if (status === 200) {
  //     this.setState({ locationAll: data.result });
  //   }
  // };

  fetchAllWeather = async (id) => {
    const { status, data } = await axiosInstance.get(URLS.weather);
    if (status === 200) {
      this.setState({ weatherAll: data.result });
    }
  };

  async fetchAllRaces() {
    const { status, data } = await axiosInstance.get(URLS.races);
    if (status === 200) {
      this.setState({ allRaces: data.result });
    }
  }

  async fetchAllProvider() {
    const { status, data } = await axiosInstance.get(URLS.apiProvider);
    if (status === 200) {
      this.setState({ allProvider: data.result });
    }
  }

  getProvider = (id) => {
    let { allProvider } = this.state;
    let provider = allProvider
      .filter((obj) => obj.id === id)
      .map((object) => object.providerName);
    return provider;
  };

  toggleIdentifireModal = () => {
    this.setState({ identifireModalOpen: !this.state.identifireModalOpen });
  };

  onSubmit = async () => {
    let id = "1";
    this.setState({ isLoading: true });
    let { selectedToDate, selectedDate, offset, rowPerPage, searchInput } =
      this.state;
    let params = {
      sportId: id,
      date: moment(selectedToDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      endDate: moment(selectedDate)
        .utc()
        .add(1, "days")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      //date: "2021-04-09T00:00:00.000Z",
      //endDate: "2021-04-10T00:00:00.000Z",
    };
    try {
      const { status, data } = await axiosInstance.post(
        `private/races?limit=${rowPerPage}&offset=${offset}&matchString=${searchInput}`,
        params
      );
      if (status === 200) {
        let respose =
          data.respose.length > 0
            ? data.respose.filter(
                (obj) => Number(obj.event.sportId) === Number(id)
              )
            : [];

        this.setState({
          sportsDetails: respose,
          isLoading: false,
          sportCount: data.count,
        });
      }
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  toggleInputModal = () => {
    this.setState({ inputModalOpen: false });
  };

  inputModal = (id) => {
    this.setState({ inputModalOpen: true, idToSend: id });
  };

  afterChangeRefresh = async () => {
    this.onSubmit();
  };

  handleAnimalDelete = async (id) => {
    try {
      const { status } = await axiosInstance.delete(`events/event/${id}`);
      if (status === 200) {
        this.afterChangeRefresh();
      }
    } catch (err) {}
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  handleToDateChange = (date) => {
    this.setState({ selectedToDate: date });
  };

  handlePaginationClick = (event, page) => {
    let { rowPerPage } = this.state;
    this.setState({
      currentPage: Number(page),
      offset: (Number(page) - 1) * rowPerPage,
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let {
      currentPage,
      //  rowPerPage,
      offset,
    } = this.state;
    // if (navDirection === "prev") {
    //   if (currentPage > 1) {
    //     this.setState({ currentPage: currentPage - 1 });
    //   }
    // } else {
    //   if (currentPage < sportsDetails.length / rowPerPage)
    //     this.setState({ currentPage: currentPage + 1 });
    // }

    if (navDirection === "prev") {
      if (offset >= 20) {
        this.setState({ offset: offset - 20, currentPage: currentPage - 1 });
      }
    } else {
      this.setState({ offset: offset + 20, currentPage: currentPage + 1 });
    }
  };

  navigateUrl = (id, raceName) => {
    // let name = this.props.match.params.name;
    this.props.history.push(`/admin/runners/${id}/market/${raceName}`);
  };

  showIdentifire = async (id) => {
    this.setState({
      identifireModalOpen: true,
      isLoading: true,
      raceIdToSend: id,
    });
    try {
      const { status, data } = await axiosInstance.get(
        `race/identifier/getByRaceId/${id}`
      );
      if (status === 200) {
        this.setState({
          identifireDetails: data.result,
          isLoading: false,
        });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ isLoading: false });
    }
  };

  inputFormModal = (id, type) => () => {
    this.setState({
      isInputFormModalOpen: true,
      idToSend: id,
      isEditMode: type,
    });
  };

  toggleInputFormModal = () => {
    this.setState({ isInputFormModalOpen: !this.state.isInputFormModalOpen });
  };

  handleIdentifireDelete = async (id) => {
    try {
      const { status } = await axiosInstance.delete(`race/identifier/${id}`);
      if (status === 200) {
        this.afterChangeRefresh();
      }
    } catch (err) {}
  };

  render() {
    const { match } = this.props;
    let {
      // selectedDate,
      sportsDetails,
      identifireDetails,
      isLoading,
      rowPerPage,
      currentPage,
      searchInput,
      // selectedToDate,
      sportAll,
      // locationAll,
      distanceAll,
      events,
      weatherAll,
      allProvider,
      allRaces,
      identifireModalOpen,
      isInputFormModalOpen,
      raceIdToSend,
      idToSend,
      isEditMode,
      offset,
      sportCount,
      selectedEvent,
    } = this.state;
    const pageNumbers = [];

    // searchInput !== '' &&
    //   (sportsDetails = sportsDetails?.filter(
    //     (obj) =>
    //       obj?.event.eventName
    //         ?.toString()
    //         .toLowerCase()
    //         .includes(searchInput.toString().toLowerCase()) ||
    //       obj?.race.raceName
    //         ?.toString()
    //         .toLowerCase()
    //         .includes(searchInput.toString().toLowerCase()),
    //   ))

    // let currentPageRow = sportsDetails;

    if (sportCount > 0) {
      // const indexOfLastTodo = currentPage * rowPerPage
      // const indexOfFirstTodo = indexOfLastTodo - rowPerPage
      // currentPageRow = sportsDetails.slice(indexOfFirstTodo, indexOfLastTodo)

      for (let i = 1; i <= Math.ceil(sportCount / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <>
        <Grid container className="page-content adminLogin sports_details">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              <Grid container direction="row" alignItems="center">
                <Grid item xs={8}>
                  <h3 className="text-left">{match.params.name} Market</h3>
                </Grid>
                <Grid item xs={3}>
                  <input
                    type="text"
                    className=""
                    placeholder="search"
                    value={searchInput}
                    onChange={(e) =>
                      this.setState({
                        ...this.state.searchInput,
                        searchInput: e.target.value,
                      })
                    }
                    style={{
                      fontSize: "16px",
                      borderRadius: "3px",
                      minHeight: "40px",
                      border: "1px solid #ddd",
                      paddingLeft: "10px",
                      marginLeft: "-100px",
                      marginTop: "4px",
                    }}
                  />
                </Grid>
                {/* <Grid item xs={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify='space-around'>
                      <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='MM/dd/yyyy'
                        margin='normal'
                        id='date-picker-inline'
                        value={selectedToDate}
                        onChange={this.handleToDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        style={{ marginRight: 5 }}
                        className='details-search-picker'
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify='space-around'>
                      <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='MM/dd/yyyy'
                        margin='normal'
                        id='date-picker-inline'
                        value={selectedDate}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        style={{ marginLeft: 5 }}
                        className='details-search-picker'
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid> */}
                <Grid item xs={1}>
                  <ButtonComponent
                    className="admin-btn-green"
                    color="primary"
                    value="Submit"
                    onClick={this.onSubmit}
                  />
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && sportsDetails.length === 0 && (
                <p>No Data Available</p>
              )}
              {!isLoading && sportsDetails.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      className="listTable api-provider-listTable"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>DID</TableCell>
                          <TableCell>Event Name</TableCell>
                          {/* <TableCell>Event Date</TableCell> */}
                          <TableCell>Race Number</TableCell>
                          <TableCell>Race Name</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Distance</TableCell>
                          <TableCell>Weather</TableCell>
                          <TableCell>View/Add Identifier</TableCell>
                          <TableCell align="center">Edit Race</TableCell>
                          {/* <TableCell align='center'>Delete</TableCell> */}
                          <TableCell>
                            {/* {match?.params?.name == 'Greyhound Racing'
                              ? 'Greyhounds Details'
                              : 'Horses Details'} */}
                            Runner Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sportsDetails?.map((sportsDetails, i) => (
                          <TableRow key={i}>
                            <TableCell>{sportsDetails.race.id}</TableCell>
                            <TableCell>
                              {sportsDetails.event.eventName}
                            </TableCell>
                            {/* <TableCell>
                              {sportsDetails.event.eventDate}
                            </TableCell> */}
                            <TableCell>
                              {sportsDetails.race.raceNumber}
                            </TableCell>
                            <TableCell>{sportsDetails.race.raceName}</TableCell>
                            <TableCell>
                              {moment(sportsDetails.race.startTimeDate).format(
                                "DD/MM/YYYY hh:mm:ss a"
                              )}
                            </TableCell>
                            <TableCell>{sportsDetails.race.distance}</TableCell>
                            <TableCell>{sportsDetails.race.weather}</TableCell>
                            <TableCell>
                              <Button
                                style={{
                                  fontSize: "9px",
                                  backgroundColor: "#ff6b00",
                                  color: "#fff",
                                  fontWeight: "400",
                                  textTransform: "none",
                                  padding: "5px 5px",
                                }}
                                onClick={() => {
                                  this.showIdentifire(sportsDetails?.race?.id);
                                  this.setState({
                                    selectedEvent: sportsDetails.race.raceName,
                                  });
                                }}
                              >
                                View/Add Identifier
                              </Button>
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                style={{
                                  fontSize: "10px",
                                  backgroundColor: "#00833e",
                                  color: "#fff",
                                  fontWeight: "400",
                                  textTransform: "none",
                                  padding: "3px 0",
                                }}
                                onClick={() => {
                                  this.inputModal(sportsDetails.race.id);
                                }}
                                color="primary"
                                // className="mr10 cursor iconBtn admin-btn-green"
                              >
                                Edit
                              </Button>
                            </TableCell>
                            {/* <TableCell>
                              <Button
                                style={{
                                  fontSize: "9px",
                                  backgroundColor: "#ff6b00",
                                  color: "#fff",
                                  fontWeight: "400",
                                  textTransform: "none",
                                  padding: "5px 0",
                                }}
                                onClick={() => {
                                  this.handleAnimalDelete(
                                    sportsDetails.race.eventId
                                  );
                                }}
                                color='secondary'
                                // className="cursor iconBtn admin-btn-orange"
                              >
                                Delete Track
                              </Button>
                            </TableCell> */}
                            <TableCell className="runner_btn_cell">
                              <ReactSVG
                                src={raceIcon("Horse Racing")}
                                onClick={() =>
                                  this.navigateUrl(
                                    sportsDetails.race.id,
                                    sportsDetails.race.raceName
                                  )
                                }
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="tablePagination">
                    <button
                      className={
                        offset >= 20
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={offset >= 20 ? false : true}
                      // disabled={
                      //   sportsDetails.length / rowPerPage > 1 ? false : true
                      // }
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={sportCount > 0 ? false : true}
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size="small"
                    />
                    <button
                      className={
                        rowPerPage + offset < sportCount
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={rowPerPage + offset < sportCount ? false : true}
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </>
              )}
            </Paper>

            <Modal
              className="modal modal-input"
              open={this.state.inputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {`Edit ${match.params.name} Details`}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />
                {/* <CreateEvents
                  inputModal={this.toggleInputModal}
                  id={this.state.idToSend}
                  isEditMode={true}
                  fetchAllEvents={this.afterChangeRefresh}
                  sportAll={sportAll}
                  locationAll={locationAll}
                  weatherAll={weatherAll}
                /> */}

                <CreateRaceTable
                  inputModal={this.toggleInputModal}
                  id={idToSend}
                  isEditMode={true}
                  fetchAllRace={this.afterChangeRefresh}
                  eventsAll={events}
                  distanceAll={distanceAll}
                  weatherAll={weatherAll}
                  allSports={sportAll}
                />
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={identifireModalOpen}
              onClose={this.toggleIdentifireModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3 className="text-center">{`Bookmaker Feed Identifiers (${selectedEvent})`}</h3>
                  <Button
                    style={{
                      textTransform: "none",
                      height: "28px",
                      marginTop: "15px",
                      fontSize: "12px",
                      backgroundColor: "#ff6b00",
                      color: "#fff",
                      fontWeight: "400",
                    }}
                    onClick={this.inputFormModal(null, false)}
                  >
                    Add Identifier
                  </Button>
                </div>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleIdentifireModal}
                />

                {isLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
                {!isLoading && identifireDetails.length === 0 && (
                  <p>No Data Available</p>
                )}
                {!isLoading && identifireDetails.length > 0 && (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        className="listTable api-provider-listTable"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>DID</TableCell>
                            <TableCell>Api Race Id</TableCell>
                            <TableCell>Bookmaker</TableCell>
                            <TableCell>CreatedAt</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {identifireDetails?.map((api, i) => (
                            <TableRow key={i}>
                              <TableCell>{api.id}</TableCell>
                              <TableCell>{api.apiRaceId}</TableCell>
                              <TableCell>
                                {this.getProvider(api.providerId)}
                              </TableCell>
                              <TableCell>
                                {moment(api.createdAt).format(
                                  "DD/MM/YYYY hh:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell>
                                <EditIcon
                                  onClick={this.inputFormModal(api.id, true)}
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-green"
                                />
                              </TableCell>
                              <TableCell>
                                <DeleteOutlineIcon
                                  onClick={() =>
                                    this.handleIdentifireDelete(api?.id)
                                  }
                                  color="primary"
                                  className="mr10 cursor iconBtn admin-btn-orange"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </Modal>

            <Modal
              className="modal modal-input"
              open={isInputFormModalOpen}
              onClose={this.toggleInputFormModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {!isEditMode
                    ? "Create Api Race Identifier"
                    : "Edit Api Race Identifier"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputFormModal}
                />
                <CreateApiRaceIdentifire
                  inputModal={this.toggleInputFormModal}
                  id={idToSend}
                  raceIdToSend={raceIdToSend}
                  isEditMode={isEditMode}
                  fetchAllEventIdentifire={this.afterChangeRefresh}
                  isFixture={true}
                  allRaces={allRaces}
                  allApiProvider={allProvider}
                />
              </div>
            </Modal>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default SportsDetails;
